<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'LoginLayout',
};
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
  list-style: none;
}
</style>

import { HTTP } from '@/http';

const studyGroups = {
  namespaced: true,
  state: {
    ids: [],
    isLoaded: false,
    newGroupStudents: [],
    newGroupName: '',
    newGroupColor: 'primary',
  },
  getters: {
    list(state, getters, rootState) {
      return state.ids.map((id) => {
        const item = rootState.DB.groups[id];
        if (item) return item;
        return { _id: id, pending: true };
      });
    },
    newAdded(state, getters, rootState) {
      return state.newGroupStudents.map((id) => rootState.DB.students[id]);
    },
  },
  mutations: {
    FETCH_GROUPS: (state, payload) => {
      state.ids = [...new Set([...state.ids, ...payload])];
    },
    REMOVE_GROUP: (state, ids) => {
      ids.forEach((id) => {
        const idx = state.ids.indexOf(id);
        if (idx >= 0) {
          state.ids.splice(idx, 1);
        }
      });
    },
    SET_LOADED: (state, payload) => {
      state.isLoaded = payload;
    },
    ADD_STUDENT_TO_NEW_GROUP: (state, payload) => {
      const params = Array.isArray(payload) ? payload : [payload];
      state.newGroupStudents = [...new Set([...state.newGroupStudents, ...params])];
    },
    REMOVE_STUDENT_FROM_NEW_GROUP: (state, id) => {
      const students = state.newGroupStudents;
      const idx = students.indexOf(id);

      if (idx !== -1) {
        students.splice(idx, 1);
      }
    },
    DROP_NEW_GROUP: (state) => {
      state.newGroupStudents = [];
      state.newGroupName = '';
      state.newGroupColor = 'primary';
    },
  },
  actions: {
    dropNewGroup({ commit }) {
      commit('DROP_NEW_GROUP');
    },
    addToNewGroup({ commit }, id) {
      commit('ADD_STUDENT_TO_NEW_GROUP', id);
    },
    removeFromNewGroup({ commit }, id) {
      commit('REMOVE_STUDENT_FROM_NEW_GROUP', id);
    },
    removeFromGroup({ rootState, dispatch }, { groupId, students }) {
      const group = rootState.DB.groups[groupId];
      if (group) {
        const newList = [...group.students];

        students.forEach((stuedentId) => {
          const idx = newList.indexOf(stuedentId);
          if (idx >= 0) newList.splice(idx, 1);
        });

        dispatch('createGroup', {
          _id: group._id,
          students: newList,
        });
      }
    },
    createGroup({ commit }, params) {
      return HTTP.post('/crud/studygroup/', { ...params })
        .then((response) => {
          const { _id } = response.data;
          if (_id) {
            commit('DROP_NEW_GROUP');
            commit('FETCH_GROUPS', [_id]);
            commit(
              'FETCH_MODELS',
              {
                type: 'groups',
                payload: [response.data],
              },
              { root: true }
            );
          }
          return _id;
        })
        .catch((error) => {
          console.log('createGroup error', error);
        });
    },
    async fetchGroups({ state, commit, dispatch }, payload = {}) {
      const hasId = payload._id && state.ids.indexOf(payload._id) >= 0;
      if (hasId || state.isLoaded) return null;
      const params = { ...payload };
      try {
        const result = await dispatch('fetchModel', { model: 'studygroup', options: { params } }, { root: true });
        const data = result.data.models;

        if (!payload._id) {
          commit('SET_LOADED', true);
        }
        commit(
          'FETCH_GROUPS',
          data.map((n) => n._id)
        );
        commit(
          'FETCH_MODELS',
          {
            type: 'groups',
            payload: data,
          },
          { root: true }
        );
        return result;
      } catch (error) {
        console.log('fetchGroups error', error);
      }
      return null;
    },
    async removeGroup({ commit, dispatch }, params) {
      const ids = Array.isArray(params) ? params : [params];

      try {
        const result = await dispatch('deleteModel', { model: 'studygroup', options: { data: ids } }, { root: true });
        if (result.data.removed > 0) {
          commit('REMOVE_GROUP', ids);
        }
        return result;
      } catch (err) {
        console.log('err', err);
      }
      return null;
    },
  },
};

export default studyGroups;

<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.6055 5.77126C8.49262 6.31938 8.85149 6.85343 9.40163 6.95602L11.8271 7.40832C13.0814 7.64223 13.4895 9.23586 12.502 10.0438L5.87102 15.4692C5.27463 15.9571 4.4009 15.4026 4.58849 14.6552L5.68871 10.2717C5.82992 9.70912 5.46436 9.14478 4.89317 9.04362L2.21629 8.56958C0.953585 8.34597 0.535513 6.74366 1.528 5.93162L8.25508 0.42765C8.83813 -0.0493875 9.69718 0.470336 9.54523 1.20818L8.6055 5.77126Z"
      fill="#FFBB00"
    />
  </svg>
</template>

<script>
export default {
  props: {
    viewBox: String,
    width: Number,
    height: Number,
  },
};
</script>

<template>
  <div v-if="show" class="ntf-feed">
    <div class="container ntf-feed__container">
      <div class="ntf-feed__content">
        <div class="ntf-feed__header">
          <div class="ntf-feed__title">{{ 'Уведомления' }}</div>
          <div class="ntf-feed-alert">
            <div class="ntf-feed-alert__count">3</div>
          </div>
        </div>
        <div class="ntf-feed__body">
          <ul class="ntf-feed-list">
            <li class="ntf-feed-list__item">
              <div class="ntf-feed-item">
                <div class="ntf-feed-item__text-content">
                  <div class="ntf-feed-item__teacher">{{ 'Алексеева Наталья Владимировна' }}</div>
                  <div class="ntf-feed-item-lesson">
                    <div class="ntf-feed-item-lesson__title">{{ 'К уроку «Дроби и деление натуральных чисел»' }}</div>
                    <div class="ntf-feed-item-lesson__icon">
                      <img src="../../assets/refactor/math.svg" alt="math" />
                    </div>
                  </div>
                  <div class="ntf-feed-item__message">
                    {{ lastMessg | spliceEndText }}
                  </div>
                </div>
                <div class="ntf-feed-item__checkbox">
                  <label class="ntf-feed-item-check">
                    <div class="ntf-feed-item-check-inner">
                      <input type="checkbox" class="ntf-feed-item-check__input" />
                      <div class="ntf-feed-item-check__icon">
                        <img src="../../assets/refactor/check-nth.svg" alt="check" />
                      </div>
                    </div>
                    <div class="ntf-feed-item-check__text">{{ 'Прочитано' }}</div>
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      message: 'Здравствуй, Аня.Просмотрела выполненный тобой урок и хотела дать ',
      lastMessage: 'дополнительные задания на тренировку деления',
    };
  },
  computed: {
    lastMessg() {
      return this.message + this.lastMessage;
    },
  },
  filters: {
    spliceEndText(val) {
      if (val.length > 109) {
        return `${val.substr(0, 109)}...`;
      }
      return val;
    },
  },
};
</script>
<style lang="less" scoped>
@import '~less_vars';
.ntf-feed {
  margin-top: 36px;
  padding: 0 25px;
  width: 100%;
  left: 0;
  position: fixed;
  top: 64px;
  z-index: 10;
  &__container {
    position: relative;
    display: flex;
    justify-content: flex-end;
  }
  &__content {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 100%;
    padding: 30px 26px 30px 16px;
    flex-basis: 413px;
    background: white;
    box-shadow: -11px 14px 41px rgba(0, 0, 0, 0.37);
    border-radius: 30px;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  &__title {
    font-weight: 600;
    font-size: 22px;
    color: @base-color;
  }
  &-alert {
    display: inline-block;
    line-height: 0;
    //background: @wrong-color;
    border-radius: 100%;
    &__count {
      display: inline-block;
      padding: 50% 0;
      margin: 0 12px;
      font-weight: 600;
      font-size: 20px;
      color: white;
    }
  }
  &-list {
    list-style-type: none;
    margin: 0;
    padding-left: 0;
  }
  &-item {
    padding: 16px 0;
    display: flex;
    border-bottom: 1px solid var(--borderColor);
    &__teacher,
    &-lesson {
      max-width: 268px;
      margin-right: 10px;
    }
    &__teacher {
      margin-bottom: 10px;
      color: @base-color;
    }
    &-lesson {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      &__title {
        margin-right: 5px;
        color: @base-color;
      }
      &__icon {
        max-width: 36px;
        img {
          display: block;
          max-width: 100%;
        }
      }
    }
    &__message {
      width: 324px;
      font-style: italic;
      font-size: 14px;
      color: @base-color;
    }
    &-check {
      display: block;
      cursor: pointer;
      &-inner {
        margin-bottom: 11px;
      }
      &__text {
        font-size: 10px;
        color: rgba(0, 24, 66, 0.8);
        user-select: none;
      }
      &__input {
        display: none;
      }
      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 26px;
        height: 26px;
        border: 1px solid @primary-color;
        border-radius: 4px;
        background-color: transparent;
        img {
          opacity: 0;
        }
      }
      &__input:checked + &__icon {
        background-color: @primary-color;
        img {
          opacity: 1;
        }
      }
    }
    &__text-content {
      margin-top: 5px;
    }
  }
  @media screen and (max-width: 500px) {
    &-item {
      justify-content: space-between;
      &__message {
        width: 280px;
      }
    }
  }
  @media screen and (max-width: @mobile-width) {
    display: none;
  }
}
</style>

<template>
  <div class="login-form">
    <div class="login-form__tabs" role="tablist">
      <router-link
        role="tab"
        class="login-form__tab"
        v-for="link in tabs"
        :active="activeRouteName === link.name"
        :class="{ 'is-active': activeRouteName === link.name }"
        :to="{ name: link.name }"
        :key="`login-link-${link.name}`"
        :aria-label="`Способ авторизации ${link.title}`"
      >
        <div class="login-form__tab__title">{{ $t(link.title) }}</div>
        <div class="login-form__tab__text" v-if="link.text">
          {{ $t(link.text) }}
        </div>
      </router-link>
    </div>
    <div class="login-form__body">
      <div class="login-form__inner">
        <div class="login-form__title">{{ $t('Авторизация') }}</div>
        <!-- <button @click="toggleInterface">super button</button> -->
        <div class="login-form__content">
          <div class="login-form__spinner" v-show="pending">
            <base-spinner size="60px" />
          </div>
          <router-view :error="error"></router-view>
          <div class="login-form__terms-of-use">
            {{ $t('Нажимая кнопку "Войти" я принимаю') }}
            <a href="#" aria-label="Читать пользовательское соглашение" role="button" @click="openModal">{{
              $t('пользовательское соглашение')
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ModalTermsOfUse from '@/components/Modal/ModalTermsOfUse';

export default {
  name: 'LoginForm',
  props: {
    pending: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tabs: [
        {
          name: 'loginbypass',
          title: 'С паролем',
          text: 'Я задал пароль',
        },
        {
          name: 'loginbyemail',
          title: 'Без пароля',
          text: 'Меня пригласили',
        },
        {
          name: 'loginbycode',
          title: 'По коду',
          text: 'Выдан учителем',
        },
        {
          name: 'loginbyservice',
          title: 'Сервисы',
          text: `ГИС ЭШ НСО, ЦОК, \n ЦОП ХМАО - Югры`,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['getCurrentInterface']),
    activeRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapMutations('auth', ['SET_INTERFACE']),
    openModal() {
      this.$emit('vuedals:new', {
        name: 'ModalTermsOfUse',
        component: ModalTermsOfUse,
        dismissable: false,
        size: 'xl',
      });
    },
    toggleInterface() {
      const currentInterface = this.getCurrentInterface;
      const newInterface = currentInterface === 'old' ? 'new' : 'old';
      this.SET_INTERFACE(newInterface);
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.main-login {
  .login-form {
    // max-width: 400px;
    // min-width: 400px;
    // background-color: @bg-color;
    display: flex;
    flex-direction: column-reverse;

    &__body {
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      position: relative;
      overflow: hidden;
    }

    &__logo {
      width: 70%;
      margin: 50px auto 80px 37px;
      position: relative;
      z-index: 1;

      img {
        width: 100%;
        max-width: 280px;
      }
    }

    &__inner {
      padding: 15px 32px 50px;
      background: #fff;
    }

    &__title {
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      margin: 20px 0 25px;
    }

    .base-input,
    .base-checkbox {
      margin-bottom: 17px;
    }

    &__terms-of-use {
      color: rgba(0, 0, 0, 0.42);
      font-size: 13px;
      margin-top: 15px;

      a {
        color: @primary-color;
        cursor: pointer;
        transition: color 0.3s;
        text-decoration: none;

        &:hover {
          color: @secondary-color;
        }
      }
    }

    &__tabs {
      display: flex;
      margin-top: -10px;

      @media (max-width: @mobile-width) {
        flex-wrap: wrap;
        gap: 1px;
        margin-top: 0;
      }
    }

    &__tab {
      width: 25%;
      padding: 20px 12px 10px;
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12);
      border-radius: 3px;
      text-decoration: unset;
      transition: all 0.2s ease;
      cursor: pointer;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: @mobile-width) {
        width: calc(50% - 1px);
        box-shadow: none;
        border-radius: 0px;
        padding: 10px;

        &:nth-child(3) {
          border-bottom-left-radius: 3px;
        }

        &:nth-child(4) {
          border-bottom-right-radius: 3px;
        }
      }

      &__title {
        font-size: @base-fz;
        color: @primary-color;
        font-weight: 600;
        line-height: 22px;
        transition: inherit;
      }

      &__text {
        font-size: @small-fz;
        color: rgba(0, 0, 0, 0.42);
        line-height: 15px;
        transition: inherit;
      }

      &:hover &__title {
        color: #fc2767;
      }

      &.is-active {
        background: #4407c6;

        .login-form__tab__title {
          color: #fff;
        }

        .login-form__tab__text {
          color: #fff;
          opacity: 0.7;
        }
      }
    }

    &__content {
      position: relative;
      min-width: 200px;
    }

    &__spinner {
      position: absolute;
      top: 0;
      z-index: 2;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: fade(#fff, 80%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: @small-width) {
      min-width: 100%;
      max-width: 100%;

      &__body {
        border-radius: 0;

        &:before {
          width: 657px;
          height: 657px;
          left: -234px;
          top: -469px;
        }
      }

      &__logo {
        margin: 40px 15px;

        img {
          max-width: 200px;
        }
      }

      &__inner {
        padding: 15px 10% 50px;
      }

      &__title {
        font-size: @h3-fz;
        font-weight: bold;
        margin-top: 30px;
      }

      .base-input,
      .base-checkbox {
        margin-bottom: 20px;
      }

      .base-btn {
        width: 100%;
        height: 40px;
        margin-top: 15px;
        margin-bottom: 15px;
      }

      &__tab__title {
        white-space: nowrap;
      }
    }
    @media (max-width: @mobile-width) {
      &__inner {
        padding: 15px;
      }

      &__tab__text {
        margin-top: 3px;
      }
    }
  }
}
</style>

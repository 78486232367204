<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.61538 7.46154H2.84615C1.82655 7.46154 1 6.63499 1 5.61538V2.84615C1 1.82655 1.82655 1 2.84615 1H5.61538C6.63499 1 7.46154 1.82655 7.46154 2.84615V5.61538C7.46154 6.63499 6.63499 7.46154 5.61538 7.46154ZM5.61538 15H2.84615C1.82655 15 1 14.1734 1 13.1538V10.3846C1 9.36501 1.82655 8.53846 2.84615 8.53846H5.61538C6.63499 8.53846 7.46154 9.36501 7.46154 10.3846V13.1538C7.46154 14.1734 6.63499 15 5.61538 15ZM10.3846 15H13.1538C14.1734 15 15 14.1734 15 13.1538V10.3846C15 9.36501 14.1734 8.53846 13.1538 8.53846H10.3846C9.36501 8.53846 8.53846 9.36501 8.53846 10.3846V13.1538C8.53846 14.1734 9.36501 15 10.3846 15ZM13.1538 7.46154H10.3846C9.36501 7.46154 8.53846 6.63499 8.53846 5.61538V2.84615C8.53846 1.82655 9.36501 1 10.3846 1H13.1538C14.1734 1 15 1.82655 15 2.84615V5.61538C15 6.63499 14.1734 7.46154 13.1538 7.46154Z"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height'],
};
</script>

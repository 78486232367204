const journal = {
  namespaced: true,
  state: {
    mod: 'all',
    currentWeek: 0,
    selectNumber: null,
    selectLiter: null,
    selectGroup: null,
  },
  mutations: {
    SET_MOD(state, payload) {
      state.mod = payload;
    },
    SET_CURRENT_WEEK(state, payload) {
      state.currentWeek = payload;
    },
    SET_SELECT_NUMBER(state, payload) {
      state.selectNumber = payload;
    },
    SET_SELECT_LITER(state, payload) {
      state.selectLiter = payload;
    },
    SET_SELECT_GROUP(state, payload) {
      state.selectGroup = payload;
    },
  },
  getters: {},
  actions: {
    CHANGE_CURRENT_WEEK({ commit }, payload) {
      commit('SET_CURRENT_WEEK', payload);
    },
    CHANGE_MOD({ commit }, payload) {
      commit('SET_MOD', payload);
    },
    CHANGE_SELECT_NUMBER({ commit }, payload) {
      commit('SET_SELECT_NUMBER', payload);
    },
    CHANGE_SELECT_LITER({ commit }, payload) {
      commit('SET_SELECT_LITER', payload);
    },
    CHANGE_SELECT_GROUP({ commit }, payload) {
      commit('SET_SELECT_GROUP', payload);
    },
  },
};
export default journal;

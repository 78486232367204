import { HTTP } from '@/http';

const authEducont = {
  namespaced: true,
  state: {
    statusAuth: 'ACTIVE',
  },
  getters: {
    getStatus(state) {
      return state.statusAuth;
    },
  },
  mutations: {
    SET_STATUS(state, payload) {
      state.statusAuth = payload;
    },
  },
  actions: {
    // eslint-disable-next-line
    async fetchStatusUser({ commit }) {
      try {
        const response = await HTTP.get(`/api/educont/fetchstatususer`);
        if (response?.data?.status) {
          commit('SET_STATUS', response.data.status);
          return response.data.status;
        }
        return null;
      } catch (e) {
        console.log(e);
      }
    },
    continueWithActiveAccount(context, params) {
      return HTTP.post(`/auth/api/educont/continuewithactiveaccount/`, params)
        .then((data) => {
          return data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};

export default authEducont;

import slugify from '@/helpers/slugify';

const help = {
  namespaced: true,
  state: {
    content: {
      teacher: [
        {
          name: 'Доступ к системе',
          items: [
            {
              title: 'Как получить доступ к системе',
              isPopular: true,
              answer: `
                Для того чтобы получить доступ к ресурсам портала «Открытая школа — 2035», необходимо обратиться к ответственному администратору системы в школе и попросить зарегистрировать вас.
                Когда на вашу почту, указанную при регистрации, придёт письмо, содержащее ссылку для входа на платформу, перейдите по этой ссылке.
                <br> <b>Внимание!</b> Если вы не нашли письмо в разделе «Входящие» вашего почтового ящика, проверьте, пожалуйста, также папку «Спам».`,
            },
            {
              title: 'Как установить пароль',
              isPopular: true,
              answer: `
                <div class="article-headline">Чтобы установить пароль для дальнейшего входа на платформу, нужно сделать следующее:</div>
                <br> 1. Перейдите в свой профиль. Для этого нужно щёлкнуть по своему имени в верхней части страницы.
                <div> 2. Затем щёлкните по кнопке <b>Установить пароль:</b></div>
                <img src="/img/faq-images/password1.png" alt="Установка пароля">
                <div> 3. В новом диалоговом окне дважды повторите пароль, который в дальнейшем будете использовать для входа в систему. Затем щелкните по кнопке <b>Установить пароль:</b></div>
                <br> <img style="width: 50%" src="/img/faq-images/password2.png" alt="Установка пароля">`,
            },
            {
              title: 'Как восстановить пароль',
              answer: `
                <div class="article-headline">Если вы забыли пароль для входа в систему, то для его восстановления на странице авторизации выберите вариант «Без пароля»:</div>
                <br> <img style="width: 30%" src="/img/faq-images/password3.png" alt="Восстановление пароля">
                <div> Далее в поле для ввода введите электронный адрес, который был указан при регистрации в системе, и щёлкните по кнопке <b>Получить ссылку:</b></div>
                <br> <img style="width: 30%" src="/img/faq-images/password4.png" alt="Восстановление пароля">
                <div> После этого на указанный вами электронный адрес придёт письмо, содержащее ссылку, с помощью которой вы сможете автоматически авторизоваться. Чтобы установить новый пароль, нужно сделать следующее:</div>
                <br> 1. Перейдите в свой профиль (щёлкните по своему имени в верхней части страницы):
                <br> <img src="/img/faq-images/password5.png" alt="Восстановление пароля">
                <div> 2. Щёлкните по кнопке <b>Сменить пароль:</b></div>
                </br> <img src="/img/faq-images/password6.png" alt="Восстановление пароля">
                <div> 3. В открывшемся окне дважды введите новый пароль (там же можно изменить адрес электронной почты). После этого щёлкните по кнопке <b>Сменить пароль:</b></div>
                <img src="/img/faq-images/password7.png" alt="Восстановление пароля">`,
            },
          ],
        },
        {
          name: 'Дополнительно о системе',
          items: [
            {
              title: 'Разделы в системе',
              isPopular: true,
              answer: `
                <div class="article-headline-2">Система предусматривает несколько разделов — классы и группы, задания, уроки.</div>
                <br> <img src="/img/faq-images/teachersections.png" alt="Разделы сайта">
                <div> В разделе для классов и групп можно видеть все классы, которые созданы в школе, отмечать избранные классы (например, те, в которых вы работаете), создавать и редактировать группы учеников.</div>
                <div> Раздел заданий позволяет видеть выданные ученикам задания и, при необходимости, редактировать их.</div>
                <div> Раздел с уроками предназначен для поиска и просмотра уроков (заданий), доступных в системе, а также для выдачи их отдельным ученикам, группам учеников или целым классам.</div>`,
            },
            {
              title: 'Роли в системе',
              isPopular: true,
              answer: `
                <div class="article-headline">Система предусматривает несколько ролей: ответственный за школу, завуч школы, учитель и ученик.</div>
                <div class="article-headline-2">В функционал роли учителя входят:</div>
                <br> 1. Добавление фотографии к своему профилю;
                <br> 2. Выделение избранных классов;
                <br> 3. Создание и редактирование групп;
                <br> 4. Просмотр электронного образовательного контента;
                <br> 5. Выдача заданий ученикам, группам или классам;
                <br> 6. Редактирование выданных заданий.`,
            },
          ],
        },
        {
          name: 'Редактирование профиля',
          items: [
            {
              title: 'Как изменить имя',
              isPopular: true,
              answer: `
                <div class="article-headline">Чтобы изменить имя в профиле, нужно сделать следующее:</div>
                <br> 1. Перейдите в свой профиль.
                <div> 2. Щёлкните по кнопке <b>Изменить имя:</b></div>
                <br> <img src="/img/faq-images/name1.png" alt="Изменение имени">
                <div> 3. В открывшемся окне внесите необходимые изменения и щёлкните по кнопке <b>Сохранить имя:</b></div>
                <br> <img src="/img/faq-images/name2.png" alt="Изменение имени">`,
            },
            {
              title: 'Как добавить фотографию к своему профилю',
              isPopular: true,
              answer: `
                <div class="article-headline">Чтобы добавить фотографию к своему профилю, нужно сделать следующее:</div>
                <br> 1. Перейдите в свой профиль.
                <div> 2. Щёлкните по кнопке <b>Сменить фото:</b></div>
                <br> <img src="/img/faq-images/photo1.png" alt="Добавление фото">
                <div> 3. В новом диалоговом окне щёлкните по кнопке <b>Выбрать файл:</b></div>
                <br> <img src="/img/faq-images/photo2.png" alt="Добавление фото">
                <br> На компьютере найдите файл, который вы хотите добавить к своему профилю (разрешены следующие форматы файлов: jpg, jpeg, png).
                <div> 5. Если добавленное изображение подходит, щёлкните по кнопке <b>Отправить:</b></div>
                <br><img src="/img/faq-images/photo3.png" alt="Добавление фото">`,
            },
          ],
        },
        {
          name: 'Работа с классами и группами',
          items: [
            {
              title: 'Как выделить классы, с которыми вы работаете',
              isPopular: true,
              answer: `
                <div>Вы можете добавить те классы, с которыми работаете, в <b>Избранное</b>
                (по умолчанию система автоматически отображает только те классы, которые добавлены в <b>Избранное</b>).
                Для того чтобы добавить класс в <b>Избранное</b>, нужно сделать следующее:</div>
                <br> 1. Перейдите в раздел «Классы и группы»:
                <br> <img src="/img/faq-images/class1.png" alt="Работа с классами">
                <br> 2. Уберите галочку рядом с пунктом «Только избранные», чтобы отобразились все классы, созданные в системе:
                </br> <img src="/img/faq-images/class2.png" alt="Работа с классами">
                <br> 3. Щёлкните по значку звёздочки рядом с теми классами, которые вы хотите добавить в Избранное:
                </br> <img src="/img/faq-images/class3.png" alt="Работа с классами">
                <br> 4. Теперь, если поставить галочку у пункта «Только избранные», на экране отобразятся выбранные вами классы:
                <img src="/img/faq-images/class4.png" alt="Работа с классами">
                <br> 5. Чтобы удалить класс из Избранного, нужно будет снова щёлкнуть по значку звёздочки, чтобы снять выделение.`,
            },
            {
              title: 'Как создать группу учеников',
              isPopular: true,
              answer: `
                <div class="article-headline">Платформа позволяет объединять учащихся в группы как в рамках одного класса, так и в рамках параллели, и даже создавать разноуровневые группы, например, для организации внеурочной деятельности.
                После объединения учащихся в группы им можно выдавать задания из платформы и собирать статистику по их выполнению.</div>
                <div>Для создания новой группы нужно сделать следующее:</div>
                <br> 1. Перейдите в раздел «Классы и группы»:
                <br> <img src="/img/faq-images/group1.png" alt="Работа с группами">
                <div> 2. Щёлкните по кнопке <b>Группы:</b></div>
                <br> <img src="/img/faq-images/group2.png" alt="Работа с группами">
                <div> 3. Для добавления новой группы щёлкните по кнопке <b>+ Группа:</b></div>
                <img src="/img/faq-images/group3.png" alt="Работа с группами">
                <br> 4. В новом диалоговом окне введите название новой группы, здесь же можно выбрать цветовое обозначение для неё:
                <br> <img src="/img/faq-images/group4.png" alt="Работа с группами">
                <br> 5. Далее добавьте учащихся в новую группу. Для этого в разделе «Ученики» нужно щёлкнуть по значку в виде плюса рядом с именем того ученика, которого вы хотите добавить в группу:
                <br> <img src="/img/faq-images/group5.png" alt="Работа с группами">
                <br> 6. По умолчанию в разделе «Ученики» отображаются учащиеся, которые не прикреплены ни к каким классам. Можно также выбрать определённый класс и сформировать группу из учеников выбранного класса. Для этого необходимо выбрать номер и букву нужного класса:
                </br> <img src="/img/faq-images/group6.png" alt="Работа с группами">
                <div><b>Внимание!</b> Все ученики, которых нужно добавить в группу, должны быть уже созданы в системе.</div>
                <div> 7. После того как все нужные учащиеся будут добавлены в новую группу, щёлкните по кнопке <b>Создать группу:</b></div>
                <br> <img src="/img/faq-images/group7.png" alt="Работа с группами"> <br>`,
            },
            {
              title: 'Как редактировать группу',
              answer: `
                <div class="article-headline">Чтобы внести изменения в уже созданную группу, нужно сделать следующее:</div>
                <br> 1. Перейдите в раздел «Группы».
                <br> 2. Щёлкните по той группе, в которую необходимо внести изменения:
                <br> <img src="/img/faq-images/group8.png" alt="Работа с группами">
                <div> 3. Для внесения изменений в группу щёлкните по кнопке <b>Редактировать группу:</b></div>
                <br> <img src="/img/faq-images/group9.png" alt="Работа с группами">
                <br> 4. Для удаления учащегося из группы щёлкните по значку в виде крестика рядом с его именем:
                <br> <img src="/img/faq-images/group10.png" alt="Работа с группами">
                <div> 5. После внесения всех изменений щёлкните по кнопке <b>Сохранить группу</b></div>`,
            },
            {
              title: 'Как удалить группу',
              answer: `
                <div class="article-headline">Чтобы удалить группу, нужно сделать следующее:</div>
                <br> 1. Перейдите в раздел «Группы».
                <br> 2. Выберите группу, которую необходимо удалить:
                <img src="/img/faq-images/group11.png" alt="Работа с группами">
                <div> 3. Щёлкните по кнопке <b>Удалить группу:</b></div>
                <br> <img src="/img/faq-images/group12.png" alt="Работа с группами">`,
            },
          ],
        },
        {
          name: 'Поиск и просмотр уроков',
          items: [
            {
              title: 'Как пользоваться фильтрами',
              answer: `
                <div class="article-headline">Чтобы найти нужный урок, нужно сделать следующее:</div>
                <br> 1. Перейдите в раздел «Уроки»:
                <br> <img src="/img/faq-images/filter1.png" alt="Работа с фильтрами">
                <br> 2. В выпадающем списке «Предмет» выберите название нужного предмета. Можно также указать «Область знаний» выбранного предмета:
                <br> <img src="/img/faq-images/filter2.png" alt="Работа с фильтрами">
                <br> 3. Выберите класс. Отобразятся все уроки, которые относятся к выбранному предмету и классу:
                <br> <img src="/img/faq-images/filter3.png" alt="Работа с фильтрами">
                <br> 4. Можно также воспользоваться строкой «Поиск темы», в которую нужно ввести название искомой темы. По мере ввода запроса система будет автоматически отображать максимально близкие совпадения:
                <br> <img src="/img/faq-images/filter4.png" alt="Работа с фильтрами">`,
            },
            {
              title: 'Как пользоваться тегами',
              answer: `
                <div class="article-headline">Платформа включает в себя систему тегов, соответствующих примерному содержанию учебников. Чтобы воспользоваться тегами, нужно сделать следующее:</div>
                <br> 1. Перейдите в раздел «Уроки»:
                <br> <img src="/img/faq-images/tag1.png" alt="Работа с тегами">
                <br> 2. В выпадающем списке «Предмет» выберите название нужного предмета:
                <br> <img src="/img/faq-images/tag2.png" alt="Работа с тегами">
                <br> 3. В панели «Уроки» слева нажмите на стрелку около названия нужного предмета и класса. После этого должен открыться выпадающий список. В нём вы увидите примерное содержание программы для выбранного класса:
                <br> <img src="/img/faq-images/tag3.png" alt="Работа с тегами">
                <br> 4. Отметьте галочкой нужные темы. В правой части экрана автоматически отобразятся уроки, которые к ним относятся:
                <br> <img src="/img/faq-images/tag4.png" alt="Работа с тегами">`,
            },
            {
              title: 'Как редактировать урок',
              answer: `
                <div class="article-headline">Вы можете скрывать те блоки урока, которые не планируете использовать в рамках занятия или не хотите включать в состав домашнего задания для учащихся. Чтобы оставить в содержании урока только нужные вам блоки, необходимо сделать следующее:</div>
                <br> 1. Перейдите в раздел «Уроки» и найдите урок, который вам нужно отредактировать:
                <br><img src="/img/faq-images/edit1.png" alt="Редактирование урока">
                <br> 2. Щёлкните по значку в виде шестерёнки на карточке нужного урока:
                <br><img src="/img/faq-images/edit2.png" alt="Редактирование урока"><br>
                3. В открывшемся окне уберите галочки рядом с теми блоками, которые хотите скрыть в уроке:
                <br><img src="/img/faq-images/edit3.png" alt="Редактирование урока"><br>
                4. Обратите внимание, что вы можете скрывать блоки не только среди заданий для работы в классе, но и среди домашних заданий. Для этого нужно перейти на вкладку «Дома»:
                <br><img src="/img/faq-images/edit4.png" alt="Редактирование урока"><br>
                <div>5. После того как вы внесёте необходимые изменения в содержание урока, щёлкните по кнопке <b>Сохранить и закрыть внизу страницы:</b></div>
                <br><img src="/img/faq-images/edit5.png" alt="Редактирование урока"><br>
                6. Обратите внимание, что в дальнейшем при необходимости можно продолжить работу с данным уроком и включить скрытые ранее блоки.`,
            },
            {
              title: 'Режимы отображения урока',
              answer: `
                <div class="article-headline">Чтобы начать работу с выбранным уроком, нужно щёлкнуть по карточке урока левой кнопкой мыши:</div>
                <br><img src="/img/faq-images/mode1.png" alt="Режимы отображения урока"><br>
                При работе с открытым уроком на платформе есть два режима его просмотра — список и слайды:
                <br><img src="/img/faq-images/mode2.png" alt="Режимы отображения урока"><br>
                Режим «Список» отображает содержимое урока в виде интерактивного оглавления. При его использовании можно переходить к блокам урока не последовательно, а произвольно, щёлкая левой клавишей мыши по названию нужных блоков в содержании:
                <br><img src="/img/faq-images/mode3.png" alt="Режимы отображения урока"><br>
                Данный режим наиболее удобен при индивидуальной работе с уроком. <br>
                Режим «Слайды» представляет собой последовательность слайдов, каждый из которых содержит один блок учебного материала: текст, видео, тренажёр и т. д. Чтобы начать отображение материала в виде слайдов, нужно выбрать режим «Слайды», нажав на соответствующую иконку, а затем щёлкнуть по кнопке с указанием количества слайдов в данном уроке:
                <br><img src="/img/faq-images/mode4.png" alt="Режимы отображения урока"><br>
                После этого в нижнем правом углу экрана появится панель для перехода между слайдами, а также для включения полноэкранного режима:
                <br><img src="/img/faq-images/mode5.png" alt="Режимы отображения урока"><br>
                В полноэкранном режиме можно переходить как к предыдущему слайду, так и к следующему, а также изменять размер отображаемого шрифта:
                <br><img src="/img/faq-images/mode6.png" alt="Режимы отображения урока"><br>
                Данный режим удобно использовать при фронтальной работе с классом.`,
            },
            {
              title: 'Структура уроков',
              answer: `
                <div class="article-headline">Каждый урок в системе — это электронный образовательный ресурс, который содержит в себе ряд логически скомпонованных тем. Вы можете использовать его полностью (например, при организации дистанционного обучения) либо фрагментарно.</div>
                <br> Открыв такой ресурс, вы увидите его содержание, Оно интерактивно и позволяет переходить к любой его части. Для этого достаточно щелкнуть по соответствующей теме.
                <br><img src="/img/faq-images/structure1.png" alt="Структура уроков">
                <br> <b>Существует еще два способа перехода по страницам ресурса:</b>
                <br> 1. Стрелки в нижней части страницы;
                <br><img src="/img/faq-images/structure2.png" alt="Структура уроков">
                <br> 2. Нумерация в нижней части страницы.
                <br><img src="/img/faq-images/structure3.png" alt="Структура уроков">
                <br> Каждая страница ресурса содержит электронный образовательный материал.
                <div></div>
                <div class="article-headline"><b>Далее представлены различные типы этого материала:</b></div>
                <div><b>Видеоматериалы</b></div>
                <br> Небольшие учебные ролики продолжительностью не более трех минут, объясняющие учебный материал или демонстрирующие практическое применение знаний. Они обладают всеми функциями видеоролика: запуск и остановка, пауза, возможность использования в полноэкранном режиме, управление громкостью звука.
                <br><img src="/img/faq-images/structure4.png" alt="Структура уроков">
                <div><b>Текстовые материалы</b></div>
                <br> Краткая текстовая информация, которая должна быть воспринята учениками при изучении данной темы (определения, формулы, ключевые факты, примеры и т. д.).
                <br><img src="/img/faq-images/structure5.png" alt="Структура уроков">
                <div> <b>Тренажеры</b></div>
                <br> Интерактивные задания разного типа (установление соответствия, поиск аналогий, выбор ответа из списка и т. д.). С их помощью ученик может проверить свой уровень освоения изучаемого материала. Если он допускает ошибку, то система автоматически дает наводящие указания. Пример такой всплывающей подсказки приведен в нижней части задания красным цветом.
                <br><img src="/img/faq-images/structure6.png" alt="Структура уроков">
                <div><b>Тесты</b></div>
                <br> Интерактивные задания, которые позволяют оценить уровень усвоения материала. При выполнении заданий такого типа система не дает никаких подсказок, что позволяет осуществить контроль по изученной теме.
                <br><img src="/img/faq-images/structure6.png" alt="Структура уроков">`,
            },
          ],
        },
        {
          name: 'Работа с заданиями',
          items: [
            {
              title: 'Как выдать задание ученикам',
              answer: `
                <div class="article-headline">Система позволяет выдавать задания всему классу, группе учащихся или отдельным ученикам.</div>
                <div> Для этого нужно сделать следующее:</div>
                <br> 1. Перейдите в раздел «Уроки»:
                <br> <img src="/img/faq-images/task1.png" alt="Работа с заданиями">
                <br> 2. Отметьте галочкой урок или уроки, которые вы собираетесь выдать ученикам:
                </br> <img src="/img/faq-images/task2.png" alt="Работа с заданиями">
                <div> 3. После того как будут выбраны все нужные уроки, щёлкните по кнопке <b>Назначить задание</b> (в скобках автоматически отмечается количество выбранных уроков):</div>
                <br> <img src="/img/faq-images/task3.png" alt="Работа с заданиями">
                <br> 4. После этого откроется новое диалоговое окно «Назначить задание (2)». В этом окне при помощи выпадающих списков выберите дату, к которой учащиеся должны будут выполнить задание:
                <br> <img src="/img/faq-images/task4.png" alt="Работа с заданиями">
                <br> 5. Затем выберите класс, группу или отдельных учеников, которым вы собираетесь назначить задание к выбранной дате. После этого щёлкните по кнопке Назначить:
                <br> <img src="/img/faq-images/task5.png" alt="Работа с заданиями"> <br>`,
            },
            {
              title: 'Как редактировать задание',
              answer: `
                <div class="article-headline">При необходимости можно отредактировать выданное задание. Для этого нужно сделать следующее</div>
                <br> 1. Щёлкните по значку в виде карандаша рядом с тем заданием, в которое нужно внести изменения:
                <br> <img src="/img/faq-images/task6.png" alt="Работа с заданиями">
                <div> 2. В открывшемся диалоговом окне внесите необходимые изменения. Например, можно изменить срок выполнения или добавить учащихся, которые должны будут выполнить задание. После внесения всех изменений щёлкните по кнопке <b>Изменить:</b></div>
                <br> <img src="/img/faq-images/task7.png" alt="Работа с заданиями">`,
            },
            {
              title: 'Как удалить задание',
              answer: `
                <div class="article-headline">Для удаления выданного задания нужно сделать следующее:</div>
                <br> 1. Щёлкните по значку в виде карандаша рядом с тем заданием, которое нужно удалить:
                <br> <img src="/img/faq-images/task8.png" alt="Работа с заданиями">
                <div> 2. В открывшемся диалоговом окне щёлкните по кнопке <b>Удалить:</b></div>
                <img src="/img/faq-images/task9.png" alt="Работа с заданиями">`,
            },
            {
              title: 'Как просмотреть статистику выполнения задания',
              answer: `
                <div class="article-headline">После выполнения заданий учащимися статистика отображается в разделе «Мониторинг». Чтобы проанализировать результаты выполнения заданий учащимися, нужно сделать следующее:</div>
                1. Откройте рабочую панель в правом верхнем углу экрана, нажав на значок в виде трёх коротких горизонтальных полос.
                <br><img src="/img/faq-images/stat0.png" alt="Статистика выполнения задания">
                2. В открывшейся панели перейдите в раздел «Мониторинг»:
                <br><img src="/img/faq-images/stat1.png" alt="Статистика выполнения задания"><br>
                3. По умолчанию «Мониторинг» демонстрирует только рабочие дни. Однако можно включить также отображение субботы и воскресенья, если домашние задания назначались на выходные. Для этого нужно поставить галочку рядом с пунктом «Показывать сб и вс»:
                <br><img src="/img/faq-images/stat2.png" alt="Статистика выполнения задания"><br>
                <div>4. Кнопки <b>Предыдущая неделя</b> и <b>Следующая неделя</b> расположены внизу страницы слева и справа соответственно. Щёлкая по ним, можно найти дату, к которой учащимся был назначен урок:</div>
                <br><img src="/img/faq-images/stat3.png" alt="Статистика выполнения задания"><br>
                <div>5. В статистике отобразится следующая информация:</div>
                1) Кружок бледного цвета означает, что ученик ещё не приступал к выполнению задания. При наведении курсора мыши на кружок отображается название урока.
                <br> 2) Окружность яркого цвета означает, что ученик приступил к выполнению задания, но ещё его не закончил. При наведении курсора мыши отображается тема урока, статус урока «В работе» и количество правильно выполненных заданий. Рядом с кружком отображается текущий процент успешности выполнения заданий.
                <br> 3) Кружок яркого цвета означает, что ученик закончил выполнять задание. При наведении курсора мыши отображается тема урока и количество правильно решённых заданий. Рядом с кружком отображается итоговый процент успешности выполнения заданий.
                <div></div> 6. Щёлкнув по кружку рядом с именем ученика, можно посмотреть, в каких заданиях он допустил ошибки:
                <br><img src="/img/faq-images/stat4.png" alt="Статистика выполнения задания">`,
            },
          ],
        },
      ],
      student: [
        {
          name: 'Доступ к системе',
          items: [
            {
              title: 'Как получить доступ к системе',
              isPopular: true,
              answer: `
                <div class="article-headline">Доступ к платформе выдается классным руководителем в школе и содержит кодовое слово и QR-код:</div>
                <br> <img style="width: 30%;" src="/img/faq-images/getaccess1.png" alt="Доступ к системе">
                <div class="article-headline-2"> Чтобы войти в систему с использованием кодового слова, нужно сделать следующее:</div>
                <br> 1. Перейдите на сайт «Открытой школы».
                <br> 2. В окне «Авторизация» выберите вкладку «По коду»:
                <br> <img style="width: 30%" src="/img/faq-images/getaccess2.png" alt="Доступ к системе">
                <div> 3. Введите кодовое слово и щёлкните по кнопке <b>Войти</b></div>
                <div class="article-headline">Чтобы авторизоваться на сайте с использованием QR-кода, нужно сделать следующее.</div>
                <br> 1. Установите на смартфон или планшет любой QR-ридер:
                <br> <img style="width: 30%" src="/img/faq-images/getaccess3.png" alt="Доступ к системе">
                <br> 2. Отсканируйте представленный QR-код, используя установленное приложение:
                <br> <img style="width: 30%" src="/img/faq-images/getaccess3.png" alt="Доступ к системе">`,
            },
            {
              title: 'Как установить пароль',
              isPopular: true,
              answer: `
                <div class="article-headline">В дальнейшем для входа в систему вы можете пользоваться не кодовым словом, а своим логином и паролем. Для установки пароля нужно сделать следующее:</div>
                <br> 1. Авторизуйтесь на сайте с использованием кодового слова или QR-кода.
                <div> 2. Щёлкните по кнопке <b>Установить пароль:</b></div>
                <img src="/img/faq-images/getaccess4.png" alt="Доступ к системе">
                <div> 3. В открывшемся окне укажите свой электронный адрес и дважды повторите пароль, который в дальнейшем будете использовать для входа в систему. Затем щёлкните по кнопке <b>Установить пароль:</b></div>
                <img style="width: 50%" src="/img/faq-images/getaccess5.png" alt="Доступ к системе">
                <br> 4. В следующий раз для входа в систему в окне «Авторизация» выберите вкладку «С паролем» и введите новые данные:
                <br> <img style="width: 30%;" src="/img/faq-images/getaccess6.png" alt="Доступ к системе">`,
            },
            {
              title: 'Как восстановить забытый пароль',
              answer: `
                <div>Если вы забыли свой пароль для входа на сайт, то можете воспользоваться входом через кодовое слово или QR-код, который был выдан классным руководителем.
                 После авторизации в системе вам станет доступной кнопка <b>Сменить пароль</b>, с помощью которой вы сможете задать новый пароль для входа:</div>
                <br> <img src="/img/faq-images/getaccess7.png" alt="Доступ к системе">`,
            },
          ],
        },
        {
          name: 'Дополнительно о системе',
          isPopular: true,
          items: [
            {
              title: 'Разделы в системе',
              answer: `
                <div class="article-headline">На платформе существует несколько разделов — задания, уроки и функционал ученика.</div>
                <br> <img src="/img/faq-images/studentsections.png" alt="Разделы в системе">
                <br> В разделе с заданиями отображаются задания, которые выданы тебе учителями, а также сроки их выполнения.
                <div> <b>В разделе с уроками представлены образовательные материалы, с которыми ты можешь ознакомиться в любой момент времени: </b></div>
                <br> 1. Посмотреть образовательные видеоролики;
                <br> 2. Поработать с тренажерами;
                <br> 3. Проанализировать уровень своих знаний по изученной теме.
                <div> <b>В разделе с функционалом для ученика можно:</b></div>
                <br> 1. Добавить фотографии к профилю;
                <br> 2. Поработать над заданиями учителя;
                <br> 3. Ознакомиться с электронными образовательными ресурсами платформы.`,
            },
          ],
        },
        {
          name: 'Редактирование профиля',
          items: [
            {
              title: 'Как добавить фотографию к профилю',
              answer: `
                <div class="article-headline">Чтобы добавить фотографию к своему профилю, нужно сделать следующее:</div>
                <br> 1. Щёлкните по значку в виде фотоаппарата рядом со своим именем:
                <br> <img src="/img/faq-images/getaccess8.png" alt="Доступ к системе">
                <div> 2. В новом диалоговом окне щёлкните по кнопке <b>Выбрать файл:</b></div>
                <br> <img src="/img/faq-images/getaccess9.png" alt="Доступ к системе">
                <div> 3. На компьютере найдите файл, который вы хотите добавить к своему профилю. Если добавленное изображение подходит, щёлкните по кнопке <b>Отправить:</b></div>
                <br> <img src="/img/faq-images/getaccess10.png" alt="Доступ к системе">`,
            },
          ],
        },
        {
          name: 'Работа с заданиями',
          items: [
            {
              title: 'Как выполнять задания',
              answer: `
                <div class="article-headline-2">После входа на платформу вы автоматически попадёте в раздел «Задания», где отображаются все выданные учителем уроки:</div>
                <br> <img src="/img/faq-images/dotask1.png" alt="Работа с заданиями">
                <br> В списке отображается количество заданных уроков, указано, по какому предмету и каким учителем выдано задание, а также отображается срок, до которого необходимо выполнить работу.
                <br> Для выполнения работы щёлкните по строке с нужным заданием, а затем — по самому уроку, с которым предстоит ознакомиться:
                <br> <img src="/img/faq-images/dotask2.png" alt="Работа с заданиями">
                <br> После этого откроется содержание данного урока. Переходя по страницам урока, вы можете знакомиться с его теоретическим материалом, а также работать с электронными тренажёрами и тестами.`,
            },
          ],
        },
        {
          name: 'Просмотр уроков',
          items: [
            {
              title: 'Как просмотреть любой урок',
              answer: `
                <div class="article-headline">Ты можешь знакомиться с любым уроком, который есть на платформе. <b>Для этого тебе необходимо:</b></div>
                <br> 1. Перейти в раздел «Уроки»; <br> <img src="/img/faq-images/viewlesson1.png" alt="Работа с заданиями">
                <br> 2. Найти интересующие тебя уроки, используя фильтр или теги;
                <br> <img src="/img/faq-images/viewlesson2.png" alt="Работа с заданиями">
                <br> 3. Щелкнуть по уроку, который тебе интересен;
                <br> <img src="/img/faq-images/viewlesson3.png" alt="Работа с заданиями">
                <br> 4. Знакомиться с материалами урока.
                <br> <img src="/img/faq-images/viewlesson4.png" alt="Работа с заданиями">`,
            },
          ],
        },
      ],
      head: [
        {
          name: 'Доступ к платформе',
          items: [
            {
              title: 'Как получить доступ к платформе',
              isPopular: true,
              answer: `
                <div class="article-headline-2">Доступ к платформе для администратора системы в школе предоставляется технической поддержкой онлайн-платформы «Открытая школа».
                 Для получения логина и пароля вам необходимо направить запрос на почтовый адрес: support@2035school.ru. и указать название образовательной организации, фамилию, имя, отчество и контактный телефон для связи.</div>`,
            },
            {
              title: 'Как установить пароль',
              answer: `
                <div class="article-headline">Чтобы установить пароль для дальнейшего входа на платформу, нужно сделать следующее:</div>
                <br> 1. Переключитесь с роли «Администратор» на роль «Учитель»:
                <br> <img src="/img/faq-images/password8.png" alt="Установка пароля">
                <div> 2. Зайдите в свой профиль, щёлкнув по своему имени в верхней части страницы. Затем щёлкните по кнопке <b>Установить пароль:</b></div>
                <br> <img src="/img/faq-images/password1.png" alt="Установка пароля">
                <div> 3. В новом диалоговом окне дважды введите пароль, который в дальнейшем будете использовать для входа в систему. Затем щёлкните по кнопке <b>Установить пароль:</b></div>
                <br> <img src="/img/faq-images/password2.png" alt="Установка пароля">`,
            },
            {
              title: 'Как восстановить пароль',
              answer: `
                <div class="article-headline">Если вы забыли пароль для входа в систему, то для его восстановления на странице авторизации необходимо выбрать раздел «Без пароля»:</div>
                <br> <img style="width: 30%" src="/img/faq-images/password3.png" alt="Восстановление пароля">
                <div> Далее в поле для ввода введите электронный адрес, который был указан при регистрации в системе, и щёлкните по кнопке <b>Получить ссылку:</b></div>
                <br> <img style="width: 30%" src="/img/faq-images/password4.png" alt="Восстановление пароля">
                <div class="article-headline-2"> После этого на указанный вами электронный адрес придёт письмо, содержащее ссылку, с помощью которой вы сможете автоматически авторизоваться. Чтобы установить новый пароль, нужно сделать следующее:</div>
                <br> 1. Перейдите в свой профиль, щёлкнув по своему имени в верхней части страницы:
                <br> <img src="/img/faq-images/password5.png" alt="Восстановление пароля">
                <br> 2. Щелкнуть по кнопке Сменить пароль:
                </br> <img src="/img/faq-images/password6.png" alt="Восстановление пароля">
                <div> 3. В открывшемся окне дважды введите новый пароль. Здесь же можно изменить адрес электронной почты. После внесения всех необходимых изменений щёлкните по кнопке <b>Сменить пароль:</b></div>
                <br> <img src="/img/faq-images/password7.png" alt="Восстановление пароля">`,
            },
          ],
        },
        {
          name: 'Дополнительно о системе',
          items: [
            {
              title: 'Разделы в системе',
              isPopular: true,
              answer: `
                <div class="article-headline">В системе доступны несколько разделов — для учителя, для классов и учеников.</div>
                <br> <img src="/img/faq-images/sections.png" alt="Разделы сайта">
                <br> Раздел для учителя позволяет создавать новых учителей в системе, импортировать их из файла, редактировать профили уже существующих, удалять учителей из системы.
                <br> В разделе для классов и учеников можно увидеть все классы, которые созданы в системе, создать новый класс, отредактировать или удалить класс. Можно добавить нового ученика в систему или импортировать группу учеников из файла электронной таблицы.`,
            },
            {
              title: 'Роли в системе',
              answer: `
                <div class="article-headline">В системе предусмотрено несколько ролей — ответственный за школу, учитель и ученик.</div>
                <br> Функционал ответственного за школу:
                <br> 1. Добавление нового учителя (импорт группы учителей), редактирование профиля учителя и удаление учителя.
                <br> 2. Создание нового класса, редактирование и удаление класса
                <br> 3. Импорт в систему группы учеников;
                <br> 4. Добавление нового ученика, редактирование и удаление ученика.`,
            },
          ],
        },
        {
          name: 'Редактирование профиля',
          items: [
            {
              title: 'Как добавить фотографию к своему профилю',
              isPopular: true,
              answer: `
                <div class="article-headline">Любой пользователь может добавить фотографию к своему профилю.</div>
                <div class="article-headline-2"> Для этого ему необходимо сделать следующее:</div>
                <br> 1. Авторизоваться в системе:
                <img src="/img/faq-images/profile1.png" alt="Авторизация в системе" >
                <br> 2. Щелкнуть по своему имени и фамилии в верхнем левом углу:
                <img src="/img/faq-images/profile2.png" alt="Авторизация в системе">
                <br> 3. В профиле щелкнуть по кнопке «Сменить фото»:
                <img src="/img/faq-images/profile3.png" alt="Демонстрация настроек профиля" >
                <br> 4. В новом диалоговом окне щелкнуть по кнопке «Выбрать файл»:
                <img src="/img/faq-images/profile4.png" alt="Диалоговое окно" >
                <br> 5. На компьютере найти файл, который он хочет добавить к своему профилю (разрешены следующие форматы файлов: jpg, jpeg, png), и щелкнуть по кнопке «Открыть»:
                <img src="/img/faq-images/profile5.png" alt="Выбор файла" >
                <br> 6. Если добавленное изображение подходит, то щелкнуть по кнопке «Отправить»:
                <img src="/img/faq-images/profile6.png" alt="Завершение отправки" >
                <br> После этого в верхней части страницы рядом с именем отобразится загруженная фотография.
                <img src="/img/faq-images/profile7.png" alt="Профиль с загруженной фотографией" >`,
            },
          ],
        },
        {
          name: 'Работа с учителями',
          items: [
            {
              title: 'Как добавить учителя в систему',
              isPopular: true,
              answer: `
                <div class="article-headline">Чтобы добавить нового учителя в систему, нужно сделать следующее:</div>
                <br> 1. Войдите на платформу под своим логином и паролем.
                <div> 2. В разделе «Учителя» щёлкните по кнопке <b>Добавить:</b></div>
                <br> <img src="/img/faq-images/addteacher1.png" alt="Добавить учителя">
                <br> 3. В новом диалоговом окне заполните все поля:
                <br> <img src="/img/faq-images/addteacher2.png" alt="Добавить учителя">
                <br> При необходимости можно поставить галочку около пункта «Классный руководитель» и выбрать класс, в котором данный педагог является классным руководителем:
                <br> <img src="/img/faq-images/addteacher3.png" alt="Добавить учителя">
                <br> В выпадающем списке «Предмет» можно выбрать предметы, которые преподает данный учитель. Щёлкая последовательно по названиям предметов, можно указать их несколько:
                <img src="/img/faq-images/addteacher4.png" alt="Добавить учителя">
                <br> 4. После заполнения всех полей щёлкните по кнопке <b>Добавить учителя:</b>
                <br> <img src="/img/faq-images/addteacher5.png" alt="Добавить учителя">
                <br> Новый учитель появится в системе, а на его электронную почту придёт приглашение, содержащее ссылку для авторизации в системе.`,
            },
            {
              title: 'Как добавить сразу несколько учителей в систему',
              isPopular: true,
              answer: `
                <div class="article-headline">Чтобы добавить в систему одновременно нескольких педагогов, нужно воспользоваться функцией импорта. Для этого нужно сделать следующее:</div>
                <div> 1. В разделе «Учителя» щёлкните по кнопке <b>Добавить:</b></div>
                <br> <img src="/img/faq-images/addteacher8.png" alt="Добавить учителя">
                <br> 2. В появившемся подразделе «Добавить учителей списком» нажмите на иконку с надписью «Скачать шаблон Excel», чтобы скачать шаблон файла для заполнения (либо вы можете сами создать аналогичный файл в формате .xls):
                <img src="/img/faq-images/addteacher9.png" alt="Добавить учителя">
                <br> 3. Заполните и сохраните файл электронной таблицы на компьютере:
                <br> <img src="/img/faq-images/addteacher10.png" alt="Добавить учителя">
                <br> 4. Перетащите сохранённый файл в поле для создания новых педагогов в системе, на надпись «Перетяните XLS-файл со списком учителей»:
                <br> <img src="/img/faq-images/addteacher11.png" alt="Добавить учителя">
                <br> 5. Система представит вам список новых пользователей:
                <img src="/img/faq-images/addteacher12.png" alt="Добавить учителя">
                <br> 6. При необходимости вы можете внести изменения, щёлкнув по значку в виде карандаша рядом с именем педагога:
                <img src="/img/faq-images/addteacher13.png" alt="Добавить учителя">
                <div> При этом появятся дополнительные поля, которые вы можете также заполнить — пункт «Классный руководитель» и выпадающий список «Предмет». Когда все нужные данные учителя будут указаны, щёлкните по кнопке <b>Редактировать учителя:</b></div>
                <br> <img src="/img/faq-images/addteacher14.png" alt="Добавить учителя">
                <div> 7. Когда все необходимые данные всех педагогов будут указаны, щёлкните по кнопке <b>Добавить учителей:</b></div>
                <br> <img src="/img/faq-images/addteacher15.png" alt="Добавить учителя">`,
            },
            {
              title: 'Как дать учителю доступ к платформе',
              isPopular: true,
              answer: `
                <div class="article-headline">После того как вы добавили новых учителей в систему, они получают доступ к образовательным ресурсам платформы.</div>
                <div class="article-headline-2"> Для того чтобы авторизоваться на платформе, учителя могут воспользоваться следующими вариантами:</div>
                <br> 1. Каждый раз получать на почту новые ссылки для авторизации в системе;
                <br> 2. Установить пароль для входа.
                <div class="article-headline-2"> Для получения ссылки для авторизации учителю необходимо:</div>
                <br> 1. Перейти на сайт http://2035school.ru:
                <br> <img style="width: 30%;" src="/img/faq-images/authteacher1.png" alt="Вход без пароля">
                <br> 2. В окне Авторизации выбрать раздел «Без пароля» и ввести адрес электронной почты, который был указан при создании пользователя в системе, и щелкнуть по кнопке «Получить ссылку»:
                <br> <img style="width: 30%;" src="/img/faq-images/authteacher2.png" alt="Получение ссылки авторизации">
                <br> После этого на указанный адрес придет письмо со ссылкой, перейдя по которой учитель автоматически будет авторизован на платформе.`,
            },
            {
              title: 'Как восстановить пароль учителю',
              answer: `
                <div class="article-headline">Если учитель потерял свой пароль для доступа к системе, то вы можете выслать на его почту приглашение повторно. Для этого нужно сделать следующее:</div>
                <br> 1. Перейдите в раздел «Учителя» на сайте «Открытой школы — 2035».
                <br> 2. Щёлкните по значку в виде карандаша рядом с именем учителя, которому необходимо выслать приглашение:
                <br> <img src="/img/faq-images/addteacher6.png" alt="Добавить учителя">
                <div> 3. В новом диалоговом окне щёлкните по кнопке <b>Выслать приглашение:</b></div>
                <br> <img src="/img/faq-images/addteacher7.png" alt="Добавить учителя">`,
            },
            {
              title: 'Как пригласить учителя',
              answer: `
                <div class="article-headline">Если учитель потерял свой пароль для доступа к системе, то вы можете выслать на его почту приглашение повторно.</div>
                <div class="article-headline-2">Для этого необходимо:</div>
                <br> 1. Перейти в раздел «Учителя» на сайте https://2035school.ru и щелкнуть по изображению карандаша рядом с именем учителя, которому необходимо выслать приглашение:
                <br> <img src="/img/faq-images/inviteteacher1.png" alt="Учителя школы">
                <br> 2. В новом диалоговом окне щелкнуть по кнопке «Выслать приглашение»:
                <br> <img src="/img/faq-images/authteacher3.png" alt="Редактировать учителя">`,
            },
            {
              title: 'Как редактировать данные учителя',
              answer: `
                <div class="article-headline">Чтобы внести изменения в данные созданного ранее учителя, нужно сделать следующее.</div>
                <br> 1. Щёлкните по значку в виде карандаша рядом с фамилией нужного педагога.
                <br> <img src="/img/faq-images/addteacher16.png" alt="Добавить учителя">
                <div> 2. В новом диалоговом окне внесите необходимые изменения. Затем щёлкните по кнопке <b>Редактировать учителя:</b></div>
                <br> <img src="/img/faq-images/addteacher17.png" alt="Добавить учителя">`,
            },
            {
              title: 'Как удалить учителя',
              answer: `
                <div class="article-headline">Чтобы удалить учителя из системы, нужно сделать следующее:</div>
                <br> 1. Щелкнуть по значку крестика рядом с фамилией того учителя, которого необходимо удалить из системы:
                <br> <img src="/img/faq-images/addteacher18.png" alt="Добавить учителя">
                <div> 2. Щёлкните по кнопке <b>Да</b> в диалоговом окне подтверждения:</div>
                <br> <img src="/img/faq-images/addteacher19.png" alt="Добавить учителя">`,
            },
          ],
        },
        {
          name: 'Работа с классами',
          items: [
            {
              title: 'Как добавить класс в систему',
              isPopular: true,
              answer: `
                <div class="article-headline">Для создания нового класса нужно сделать следующее:</div>
                <br> 1. Перейдите в раздел «Классы и ученики»:
                <br> <img src="/img/faq-images/addclass1.png" alt="Работа с классами">
                <div> 2. Щёлкните по кнопке <b>Класс:</b></div>
                <br> <img src="/img/faq-images/addclass2.png" alt="Работа с классами">
                <br> 3. В новом диалоговом окне, в разделе «Новый класс», в выпадающем списке выберите номер нового класса и букву. В поле «Название» можно ввести название класса, например, «математический»:
                <br> <img src="/img/faq-images/addclass3.png" alt="Работа с классами">
                <br> 4. В разделе «Ученики» выберите тех обучающихся, которые учатся в данном классе. Для этого щёлкните по значку в виде плюса рядом с данными ученика:
                <img src="/img/faq-images/addclass4.png" alt="Работа с классами">
                <br> По умолчанию в разделе «Ученики» отображаются те пользователи системы, которые не числятся ни в одном из классов. При необходимости можно воспользоваться фильтром и открыть список учеников уже созданных классов. Для этого выберите в выпадающих меню нужные класс и букву. Найдите нужного ученика в списке, щёлкните по значку в виде плюса рядом с его данными и, таким образом, добавьте учащегося в создаваемый класс:
                <img src="/img/faq-images/addclass5.png" alt="Работа с классами">
                <div> 5. После того как все учащиеся будут добавлены, щёлкните по кнопке <b>Создать класс:</b></div>
                <br> <img src="/img/faq-images/addclass6.png" alt="Работа с классами">
                <div> <b>Внимание!</b> Вы можете также создать пустой класс (без учащихся) и уже позднее добавить в него учеников.</div>`,
            },
            {
              title: 'Как редактировать класс',
              answer: `
                <div class="article-headline">Чтобы внести изменения в данные существующего класса, нужно сделать следующее:</div>
                <br> 1. Перейдите в раздел «Классы и ученики».
                <br> 2. Щёлкните по классу, который необходимо отредактировать:
                <br> <img src="/img/faq-images/addclass7.png" alt="Работа с классами">
                <div> 3. Для редактирования класса щёлкните по кнопке <b>Редактировать:</b></div>
                <br> <img src="/img/faq-images/addclass8.png" alt="Работа с классами">
                <br> 4. В новом диалоговом окне можно добавить новых учащихся в класс:
                <br> <img src="/img/faq-images/addclass9.png" alt="Работа с классами">
                <br> 5. Если необходимо удалить учащегося из класса, щёлкните по значку в виде крестика рядом с фамилией ученика, которого необходимо удалить:
                <img src="/img/faq-images/addclass10.png" alt="Работа с классами">
                <div> <b>Внимание!</b> Ученик из класса будет удалён сразу, без запроса на подтверждение.</div>
                <div> 6. После внесения всех изменений в класс щёлкните по кнопке <b>Сохранить:</b></div>
                <img src="/img/faq-images/addclass11.png" alt="Работа с классами">`,
            },
            {
              title: 'Как удалить класс из системы',
              answer: `
                <div class="article-headline">Чтобы удалить класс из системы, нужно сделать следующее:</div>
                <br> 1. Перейдите в раздел «Классы и ученики».
                <br> 2. Щёлкните по классу, который необходимо удалить:
                <img src="/img/faq-images/addclass12.png" alt="Работа с классами">
                <div> 3. Щёлкните по кнопке <b>Удалить класс:</b></div>
                <br> <img src="/img/faq-images/addclass13.png" alt="Работа с классами">
                <div> 4. Щёлкните по кнопке <b>Да</b> для подтверждения удаления класса:</div>
                <br> <img src="/img/faq-images/addclass14.png" alt="Работа с классами">
                <div><b>Внимание!</b> После удаления класса все учащиеся, которые относились к нему, остаются в системе, но уже без привязки к какому-либо классу.</div>`,
            },
            {
              title: 'Как добавить класс в избранное',
              answer: `
                <div class="article-headline">Система позволяет добавлять отдельные классы в группу «Избранное». Например, для выделения тех классов, в которых учитель преподает, или тех, с которыми сейчас осуществляет работу, и отображать во вкладке «Классы» только их.</div>
                <br> <img src="/img/faq-images/favclass1.png" alt="Работа с классами">
                <div class="article-headline-2"> Для того чтобы добавить класс в «Избранное», необходимо:</div>
                <br> 1. Перейти в раздел «Классы и Ученики» и убрать галочку «Только избранные», чтобы отобразились все классы, созданные в системе:
                <img src="/img/faq-images/favclass1.png" alt="Работа с классами">
                <br> 2. Щелкнуть по значку звездочки рядом с теми классами, которые нужно  добавить в Избранные:
                <br> <img src="/img/faq-images/favclass2.png" alt="Работа с классами">
                <br> 3. При этом значок закрашивается в красный цвет:
                <img src="/img/faq-images/favclass3.png" alt="Работа с классами">
                <br> Теперь, если поставить галочку «Только избранные», на экране отобразятся выбранные классы:
                <br> <img src="/img/faq-images/favclass4.png" alt="Работа с классами">
                <br> Для того чтобы убрать класс из «Избранного», нужно щелкнуть по значку звездочки, чтобы снять выделение:
                <br> <img src="/img/faq-images/favclass5.png" alt="Работа с классами">
                <br> После этого класс перестанет отображаться на странице «Избранное»:
                <br> <img src="/img/faq-images/favclass6.png" alt="Работа с классами">`,
            },
          ],
        },
        {
          name: 'Работа с учениками',
          items: [
            {
              title: 'Как добавить ученика в систему',
              isPopular: true,
              answer: `
                <div class="article-headline">Для добавления одного ученика нужно сделать следующее:</div>
                <br> 1. Перейдите в раздел «Классы и ученики»:
                <br> <img src="/img/faq-images/addstudent1.png" alt="Работа с учениками">
                <div> 2. Щёлкните по кнопке <b>Ученик:</b></div>
                <br> <img src="/img/faq-images/addstudent2.png" alt="Работа с учениками">
                <br> 3. Заполните все необходимые поля (обязательными для заполнения являются «Фамилия» и «Имя»):
                <br> <img src="/img/faq-images/addstudent3.png" alt="Работа с учениками">
                <div> 4. Щёлкните по кнопке <b>Добавить ученика:</b></div>
                <br> <img src="/img/faq-images/addstudent4.png" alt="Работа с учениками">
                <br> 5. Новый ученик будет добавлен в систему. Откроется новое окно, с помощью которого можно внести изменения в данные ученика, а также распечатать приглашение для ученика или создать ссылку для доступа в систему:
                <br> <img src="/img/faq-images/addstudent5.png" alt="Работа с учениками">
                <div> <b>Внимание!</b> Если при создании нового ученика не выбрать класс, то ученик будет добавлен в систему, но без привязки к какому-либо классу.</div>`,
            },
            {
              title: 'Как добавить группу учеников в систему',
              isPopular: true,
              answer: `
                <div class="article-headline">Для импорта в систему группы учащихся необходимо подготовить файл электронной таблицы, заполненный на основе шаблона Excel.</div>
                <div class="article-headline-2"> Для этого нужно сделать следующее:</div>
                <br> 1. Перейдите в раздел «Классы и ученики».
                <div> 2. Щёлкните по кнопке <b>Ученик:</b></div>
                <br> <img src="/img/faq-images/addstudent6.png" alt="Работа с учениками">
                <br> 3. Щёлкните по иконке с надписью «Скачать шаблон Excel» в подразделе «Добавить учеников списком»:
                <br> <img src="/img/faq-images/addstudent7.png" alt="Работа с учениками">
                <br> 4. Откройте и заполните файл:
                <img src="/img/faq-images/addstudent8.png" alt="Работа с учениками">
                <br> 5. Сохраните файл на компьютере, затем перетащите его в диалоговое окно создания нового учащегося, в поле под заголовком «Добавить учеников списком»:
                <br> <img src="/img/faq-images/addstudent9.png" alt="Работа с учениками">
                <br> 6. Проверьте все указанные данные учеников, при необходимости внесите коррективы. Для этого нужно щёлкнуть по строке с именем учащегося:
                <img src="/img/faq-images/addstudent10.png" alt="Работа с учениками">
                <div> 7. После ввода всех необходимых данных и внесения всех изменений щёлкните по кнопке <b>Добавить учеников:</b></div>
                <img src="/img/faq-images/addstudent11.png" alt="Работа с учениками">
                <br> 8. После этого появится возможность распечатать приглашения для всех новых учащихся:
                <br> <img src="/img/faq-images/addstudent12.png" alt="Работа с учениками">`,
            },
            {
              title: 'Как распечатать приглашения для уже созданного класса',
              answer: `
                <div class="article-headline">Можно распечатать приглашения для отдельного ученика или для целого класса. Для этого нужно сделать следующее:</div>
                <div> 1. Перейдите в раздел «Классы и ученики».</div>
                <div> 2. Выберите класс, для которого необходимо распечатать приглашения:</div>
                <br> <img src="/img/faq-images/addstudent13.png" alt="Работа с учениками">
                <br> 3. Щёлкните по значку печати приглашений для всего класса, это второй слева значок от номера и буквы класса:
                <br> <img src="/img/faq-images/addstudent14.png" alt="Работа с учениками">
                <br> 4. Распечатайте полученные приглашения для учащихся всего класса:
                <br> <img src="/img/faq-images/addstudent15.png" alt="Работа с учениками">
                <br> Или щёлкните по первому слева значку от имени ученика (при наведении на него появится надпись «Распечатать код»), чтобы распечатать приглашение только для него:
                <br> <img src="/img/faq-images/addstudent16.png" alt="Работа с учениками">`,
            },
            {
              title: 'Как дать ученику доступ к платформе',
              answer: `
                <div class="article-headline">Доступ учеников в систему осуществляется следующими способами:</div>
                <div> 1. По кодовому слову из приглашения;</div>
                <div> 2. По QR‐коду из приглашения.</div>
                <div> 3. После авторизации с введением пароля.</div>`,
            },
            {
              title: 'Как авторизоваться используя кодовое слово',
              answer: `
                <div class="article-headline">Для того чтобы войти в систему с использованием кодового слова, необходимо:</div>
                <br> 1. Перейти на сайт: http://2035school.ru и в окне авторизации выбрать вкладку «По коду»:
                <br> <img style="width: 30%" src="/img/faq-images/codeauth1.png" alt="Авторизация по коду">
                <br> 2. Ввести кодовое слово и щелкнуть по кнопке «Войти»:
                <br> <img style="width: 30%;" src="/img/faq-images/codeauth2.png" alt="Авторизация по коду">`,
            },
            {
              title: 'Как авторизоваться используя QR-код',
              answer: `
                <div class="article-headline">Для того чтобы авторизироваться на сайте с использованием QR-кода, необходимо сделать следующее:</div>
                <br> 1. Установить на планшет (смартфон) любой QR‐сканер:
                <br> <img style="width: 30%;" src="/img/faq-images/qrcodeauth1.png" alt="QR-сканеры">
                <br> 2. Отсканировать представленный QR‐код, используя установленное приложение:
                <br> <img style="width: 30%;" src="/img/faq-images/qrcodeauth2.png" alt="Сканирование QR-кода">`,
            },
            {
              title: 'Как установить пароль ученику',
              answer: `
                <div class="article-headline">Для установки пароля  необходимо сделать следующее:</div>
                <br> 1. Авторизоваться на сайте с использованием кодового слова или QR‐кода и щелкнуть по кнопке «Установить пароль»:
                <br> <img src="/img/faq-images/passauth1.png" alt="Установка пароля">
                <br> 2. В новом окне указать электронный адрес и дважды повторить пароль, который в дальнейшем будет использоваться для входа в систему, после чего щелкнуть по кнопке «Установить пароль»:
                <br> <img src="/img/faq-images/passauth2.png" alt="Установка пароля">
                <br> Если пароль будет задан, появится следующее сообщение:
                <br> <img src="/img/faq-images/passauth3.png" alt="Установка пароля">
                <br> Теперь нужно щелкнуть по кнопке «Отлично!».`,
            },
          ],
        },
      ],
    },
    section: null,
    article: null,
  },
  getters: {
    content: (state) => {
      Object.entries(state.content).forEach(([, value]) => {
        value.forEach((segment) => {
          segment.slug = slugify(segment.name);
          segment.items.forEach((article) => {
            article.slug = slugify(article.title);
          });
        });
      });

      return state.content;
    },
    section(state) {
      return state.section;
    },
    article(state) {
      return state.article;
    },
  },
  mutations: {
    SET_SECTION: (state, payload) => {
      state.section = payload;
    },
    SET_ARTICLE: (state, payload) => {
      state.article = payload;
    },
  },
  actions: {
    setSectionByRoleAndSlug({ getters, commit }, payload) {
      const roleContent = getters.content[payload.role] ? getters.content[payload.role] : getters.content.student;

      roleContent.forEach((section) => {
        if (section.slug === payload.sectionSlug) {
          commit('SET_SECTION', section);
        }
      });
    },
    setSectionAndArticleByRoleAndSlugs({ getters, commit }, payload) {
      const roleContent = getters.content[payload.role] ? getters.content[payload.role] : getters.content.student;
      roleContent.forEach((section) => {
        if (section.slug === payload.sectionSlug) {
          commit('SET_SECTION', section);
          section.items.forEach((article) => {
            if (article.slug === payload.articleSlug) {
              commit('SET_ARTICLE', article);
            }
          });
        }
      });
    },
  },
};

export default help;

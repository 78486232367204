<template>
  <div class="main-partners">
    <div class="main-partners__title">{{ $t('Партнёры') }}</div>
    <div class="main-partners__list">
      <div class="main-partners__partner is-big">
        <img
          src="../../assets/logo-misis.png"
          alt=""
          class="main-partners__partner-logo"
          srcset="../../assets/logo-misis@2x.png 2x"
        />
      </div>
      <div class="main-partners__partner is-small">
        <img
          src="../../assets/logo-2035.png"
          alt=""
          class="main-partners__partner-logo"
          srcset="../../assets/logo-2035@2x.png 2x"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainPartners',
  components: {},
};
</script>

<style lang="less">
@import '~less_vars';

.main-partners {
  position: relative;
  display: flex;
  align-items: center;

  &__title {
    font-weight: bold;
    font-size: 50px;
    color: #fc2767;
    letter-spacing: 0.025em;
    margin-left: 75px;
    margin-right: 100px;
    margin-bottom: 95px;
  }

  &__list {
    display: flex;
  }

  &__partner {
    background: rgba(233, 242, 249, 0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.is-big {
      width: 410px;
      height: 410px;
      margin-right: 30px;
      margin-bottom: 73px;
    }

    &.is-small {
      width: 350px;
      height: 350px;
      margin-top: 153px;
    }
  }

  @media (max-width: 1200px) {
    &__title {
      margin-right: 50px;
    }

    &__partner {
      &.is-big {
        width: 350px;
        height: 350px;
      }

      &.is-small {
        width: 300px;
        height: 300px;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    &__title {
      margin: 0;
      margin-bottom: 50px;
    }

    &__list {
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    &__partner {
      &.is-big {
        margin: 0;
      }

      &.is-small {
        margin: 0;
      }
    }
  }

  @media (max-width: 480px) {
    &__title {
      margin-bottom: 25px;
      font-size: 35px;
    }

    &__list {
      flex-direction: column;
    }

    &__partner {
      margin-bottom: 20px !important;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>

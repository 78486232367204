const platform = {
  namespaced: true,
  state: {
    platformTypes: {
      os: 'os',
      erg: 'erg',
      almaty: 'almaty',
      aman: 'aman',
    },
    ergHostnames: ['ergdigitalmektebi', 'testn'],
    almatyHostnames: ['almatysifrlyqmektebi'],
    amanHostnames: ['aman'],
    titles: {
      os: 'Открытая школа',
      erg: 'ERG Digital Mektebi',
      almaty: 'Almaty Sifrlyq Mektebi',
      aman: 'Aman Sultan',
    },
    officialNames: {
      os: 'ООО «Открытая школа»',
      erg: 'ERG Digital Mektebi',
      almaty: 'Almaty Sifrlyq Mektebi',
      aman: 'Aman Sultan',
    },
    loginIntro: {
      os: 'Наконец-то, школа, в которой классно учиться и учить.',
      erg: 'Цифровая платформа персонализированного обучения',
      almaty: 'Цифровая платформа персонализированного обучения',
      aman: 'Цифровая платформа персонализированного обучения',
    },
    // enableErg: true,
    enableErg: false,
    // enableAlmaty: true,
    enableAlmaty: false,
    // enableAman: true,
    enableAman: false,
  },
  getters: {
    isErgPlatform(state) {
      return state.enableErg || state.ergHostnames.some((item) => window.location.hostname.includes(item));
    },
    isAlmatyPlatform(state) {
      return state.enableAlmaty || state.almatyHostnames.some((item) => window.location.hostname.includes(item));
    },
    isAmanPlatform(state) {
      return state.enableAman || state.amanHostnames.some((item) => window.location.hostname.includes(item));
    },
    isKzPlatform(state, getters) {
      return getters.isErgPlatform || getters.isAlmatyPlatform || getters.isAmanPlatform;
    },
    getPlatformType(state, getters) {
      if (getters.isErgPlatform) return state.platformTypes.erg;
      if (getters.isAlmatyPlatform) return state.platformTypes.almaty;
      if (getters.isAmanPlatform) return state.platformTypes.aman;
      return state.platformTypes.os;
    },
    getPlatformTitle(state, getters) {
      return state.titles[getters.getPlatformType];
    },
    getPlatformOfficialName(state, getters) {
      return state.officialNames[getters.getPlatformType];
    },
    getLoginIntro(state, getters) {
      return state.loginIntro[getters.getPlatformType];
    },
    getContactInfo(state, getters) {
      let data;
      if (getters.isErgPlatform) {
        data = {
          phoneCountryCode: '+7',
          phoneAreaCode: '701',
          phoneNumber: '345-29-57',
          emailUser: 'info',
          emailDomain: 'ergdigitalmektebi.kz',
        };
      } else if (getters.isAlmatyPlatform) {
        data = {
          phoneCountryCode: '+7',
          phoneAreaCode: '701',
          phoneNumber: '345-82-64',
          emailUser: 'info',
          emailDomain: 'almatysifrlyqmektebi.kz',
        };
      } else if (getters.isAmanPlatform) {
        data = {
          phoneCountryCode: '8',
          phoneAreaCode: '800',
          phoneNumber: '775-76-72',
          emailUser: 'support',
          emailDomain: 'amansultan.kz',
        };
      } else {
        data = {
          phoneCountryCode: '8',
          phoneAreaCode: '800',
          phoneNumber: '775-76-72',
          emailUser: 'support',
          emailDomain: '2035school.ru',
        };
      }

      data.phoneNumberFull = `${data.phoneCountryCode} ${data.phoneAreaCode} ${data.phoneNumber}`;
      data.emailFull = `${data.emailUser}@${data.emailDomain}`;

      return data;
    },
  },
  mutations: {
    SET_PLATFORM_THEME: (state, payload) => {
      state.enableErg = false;
      state.enableAlmaty = false;
      state.enableAman = false;

      if (payload === 'erg') {
        state.enableErg = true;
      }
      if (payload === 'alm') {
        state.enableAlmaty = true;
      }
      if (payload === 'aman') {
        state.enableAman = true;
      }
    },
  },
  actions: {},
};

export default platform;

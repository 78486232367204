<template>
  <svg
    id="icon-burger"
    width="26px"
    height="22px"
    :class="{
      'base-header__burger-icon': status === 'burger',
      'base-header__close-icon': status === 'close',
      'burger-icon__clicked': clicked,
    }"
    viewBox="0 0 38 32"
    fill="none"
  >
    <rect class="shortrect" x="1" y="3.8" width="13.5" height="4" fill="var(--primary-color)" />
    <rect class="shortrect" x="14" y="3.8" width="10" height="4" fill="var(--primary-color)" />
    <rect class="shortrect" x="23.5" y="3.8" width="14" height="4" fill="var(--primary-color)" />
    <rect x="1" y="14" width="36" height="4" fill="var(--primary-color)" />
    <rect x="1" y="24" width="36" height="4" fill="var(--primary-color)" />
  </svg>
</template>

<script>
export default {
  props: {
    status: String,
    clicked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';
svg.base-header__burger-icon {
  &.burger-icon__clicked {
    rect.shortrect {
      animation: backburger-first_rect 0.5s linear 1;
      animation-fill-mode: forwards;
    }
    rect:nth-child(4) {
      animation: backburger-second_rect 0.5s linear 1;
      animation-fill-mode: forwards;
    }
    rect:last-child {
      animation: backburger-last_rect 0.5s linear 1;
      animation-fill-mode: forwards;
    }
  }
}
@keyframes backburger-first_rect {
  0% {
    transform: translate(7.5px, 0.5px) rotate(45deg);
  }
}
@keyframes backburger-second_rect {
  0% {
    transform: translate(-8px, 19px) rotate(-43deg);
    fill: @secondary-color;
  }
}
@keyframes backburger-last_rect {
  0% {
    width: 0%;
  }
}

svg.base-header__close-icon {
  rect.shortrect {
    animation: closeburger-first_rect 0.5s linear 1;
    animation-fill-mode: forwards;
  }
  rect.shortrect:nth-child(2) {
    animation: closeburger-first_rect_middle 0.5s linear 1;
    animation-fill-mode: forwards;
  }
  rect:nth-child(4) {
    animation: closeburger-second_rect 0.5s linear 1;
    animation-fill-mode: forwards;
  }
  rect:last-child {
    animation: closeburger-last_rect 0.3s linear 1;
    animation-fill-mode: forwards;
  }
}
@keyframes closeburger-first_rect {
  100% {
    transform: translate(7.5px, 0.5px) rotate(45deg);
  }
}
@keyframes closeburger-first_rect_middle {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
    transform: translate(7.5px, 0.5px) rotate(45deg);
  }
}
@keyframes closeburger-second_rect {
  100% {
    transform: translate(-8px, 19px) rotate(-43deg);
    fill: @secondary-color;
  }
}
@keyframes closeburger-last_rect {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
</style>

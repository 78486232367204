<template>
  <div class="main-page">
    <div class="main-page__aside">
      <div class="main-page__intro">
        <div class="main-page__logo">
          <BaseLogo :width="178.52" :height="75" :white="true" />
        </div>

        <div class="main-page__intro-title">{{ $t(getLoginIntro) }}</div>
      </div>
    </div>

    <div class="main-page__content">
      <RefactorLoginForm :screenWidth="screenWidth" :pending="pending" :error="error" class="main-page__login-form" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import RefactorLoginForm from '@/components/NewComponents/RefactorLoginForm';
import BaseLogo from '@/components/NewComponents/BaseLogo';

export default {
  name: 'RefactorPageIndex',
  components: {
    RefactorLoginForm,
    BaseLogo,
  },

  computed: {
    ...mapState('auth', {
      pending: (state) => state.pending,
      error: (state) => state.error,
    }),
    ...mapState({
      screenWidth: (state) => state.screenWidth,
    }),
    ...mapGetters('platform', ['getLoginIntro']),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const { user } = vm.$store.state.auth;
      if (user) next('/');
      else next();
    });
  },
};
</script>

<style lang="less" scoped>
@import '~less_vars';

.main-page {
  display: flex;
  min-height: 100vh;

  &__aside {
    flex: 0 0 45%;
    background: var(--loginPageColor);
    border-radius: 0 40px 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      width: 45vw;
      height: 45vw;
      background: @primary-color;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      transform: translate(-55%, -43%);
    }

    &::after {
      content: '';
      width: 45vw;
      height: 45vw;
      border: 120px solid @secondary-color;
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 50%;
      transform: translate(55%, 43%);
    }
  }

  &__intro {
    width: 375px;
    height: 440px;
    max-width: 90%;
    max-height: 90%;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 15px 25px rgba(75, 14, 205, 0.1);
    backdrop-filter: blur(40px);
    border-radius: 20px;
    padding: 20px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-title {
      color: #fff;
      font-size: 28px;
      line-height: 36 / 28;
      margin-bottom: 58px;
    }
  }

  &__logo {
    width: 160px;
    max-width: 100%;
    position: relative;
    margin-bottom: 20px;

    img {
      width: 100%;
    }

    &:before {
      content: '';
      width: 128px;
      height: 2px;
      background: #fff;
      position: absolute;
      bottom: -20px;
      left: 0;
    }
  }

  &__content {
    flex: 0 0 55%;
    padding: 100px 30px 30px 30px;
    display: flex;
  }

  &__login-form {
    margin: auto;
    //max-width: 540px;
  }

  @media (max-width: 1024px) {
    overflow: hidden;
    &__aside {
      flex: 1;
      &::before {
        content: '';
        width: 45vw;
        height: 45vw;
        transform: translate(-57%, -38%);
      }

      &::after {
        border: 90px solid @secondary-color;
        transform: translate(59%, 35%);
      }
    }
    &__content {
      flex: 1;
      padding: 100px 30px 30px 30px;
      display: flex;
    }
  }
  @media (max-width: @medium-width) {
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    &__aside {
      flex: 0 0 262px;
      border-radius: 0 0 20px 20px;
      &::before {
        content: '';
        width: 45vw;
        height: 45vw;
        transform: translate(-57%, -38%);
      }

      &::after {
        border: 60px solid @secondary-color;
        transform: translate(55%, 45%);
      }
    }
    &__intro {
      width: 0px;
      height: 131px;
      border-radius: 0;
      padding: 0;
      &-title {
        display: none;
      }
    }
    &__logo {
      position: absolute;
      width: 320px;
      max-width: unset;
      margin-bottom: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        width: 100%;
      }

      &:before {
        content: none;
      }
    }
    &__content {
      flex: 1;
      padding: 48px 24px 24px 24px;
      display: flex;
    }
    &__login-form {
      margin: unset;
      width: 100%;
    }
  }
  @media (max-width: @mobile-width) {
    &__aside {
      flex: 0 0 131px;
      &::before {
        width: 166px;
        height: 166px;
        transform: translate(-55%, -46%);
      }

      &::after {
        width: 197.91px;
        height: 197.91px;
        border: 30px solid @secondary-color;
        bottom: -25%;
        transform: translate(61%, 41%);
      }
    }

    &__intro {
      width: 0px;
      height: 131px;
      border-radius: 0;
      padding: 0;
      &-title {
        display: none;
      }
    }

    &__logo {
      width: 180px;
    }

    &__content {
      flex: 1;
      width: 100%;
      padding: 24px 16px 16px 16px;
    }
  }
}
</style>

import router from '@/router/index';
import store from '@/store';
import Vue from 'vue';

function checkAccess(role, to) {
  if (to.meta.permissions) {
    return to.meta.permissions.roles.includes(role);
  }
  return true;
}

function sendEventToAmplitude(to) {
  const { href: currentUrl, pathname: currentPath } = window.location;
  const { referrer } = document;
  const urlFrom = referrer || window.location.origin;
  const urlTo = `${window.location.origin}${to.fullPath}`;

  // Проверка на дублирование при переходе на лессонс
  if (referrer && urlTo.includes(currentUrl) && currentUrl !== referrer) {
    return;
  }
  if (currentPath === to.path) {
    return;
  }

  const fromSlideNum = window.location.pathname?.split('/')?.slice(-2, -1)[0];
  const toSlideNum = to?.path?.split('/')?.slice(-2, -1)[0];

  // Проверяем, является ли переход внутри уроков
  if (to.params.path && fromSlideNum !== toSlideNum && fromSlideNum.length === toSlideNum.length) {
    return;
  }

  // Обновление страницы
  if (referrer && referrer === currentUrl && currentUrl.includes(to.fullPath)) {
    return;
  }

  // Проверяем, является ли переход с добавлением хэша к URL
  if (urlTo.includes('#') && urlTo !== currentUrl) {
    return;
  }

  let shortUrlTo = `${window.location.origin}${to.path}`;
  shortUrlTo = to.params?.path ? shortUrlTo.split(to.params.path)[0] : shortUrlTo;
  const eventProperties = { from: urlFrom, to: shortUrlTo };
  store.dispatch('amplitude/sendEvent', { eventName: 'openPage', eventProperties });
}

function navigate(to, next) {
  sendEventToAmplitude(to);
  const role = store.getters['navs/getRole'];
  if (to.fullPath !== '/' && checkAccess(role, to)) {
    next();
  } else {
    switch (role) {
      case 'head':
      case 'assistant':
        next('/principal/');
        break;
      case 'teacher':
        next('/lessons/');
        break;
      case 'guest':
        next('/lessons/');
        break;
      case 'student':
        next('/diary/');
        break;
      case 'content':
        next('/tags/');
        break;
      case 'subs':
        next('/schools/');
        break;
      case 'municipal':
        next('/stats/');
        break;
      case 'super':
        next('/administrators/');
        break;
      default:
        next('/lessons/');
        break;
    }
  }
}

async function checkAccessToRoute(to, from, next) {
  try {
    if (to.meta.noAuth) {
      return navigate(to, next);
    }
    const authToken = localStorage.getItem('os_token');
    const authCookies = Vue.$cookies.get('authToken');
    if (!authToken && !authCookies) {
      return next('/login');
    }
    let me = await store.getters['auth/me'];
    if (!me) {
      await store.dispatch('auth/getMe');
      me = store.getters['auth/me'];
    }
    if (me) {
      await navigate(to, next);
      return null;
    }
    return next('/login');
  } catch (e) {
    console.log(e);
  }
  return null;
}

function validateHash(hash) {
  if (!hash) return null;
  const flag = hash.slice(1, 2);
  const token = hash.slice(2);
  const validFlag = flag === '0' || flag === '1';
  if (validFlag && token) {
    return {
      flag: +flag,
      token,
    };
  }
  return null;
}

function getHash(to, from, next) {
  const { hash, ...rest } = to;
  const parsed = validateHash(hash);
  if (parsed) {
    store.commit('auth/SET_TOKEN', {
      token: parsed.token,
      memorize: !!parsed.flag,
    });
    if (rest.fullPath.includes('auth-by-hash')) {
      next('/');
    }
    next(rest);
  } else {
    next();
  }
}

function checkOAuth(to, from, next) {
  const { query } = to;
  if (query.code) {
    store.commit('auth/SET_TOKEN', {
      token: query.code,
      memorize: true,
    });
    delete query.code;
    next(to);
  }
  next();
}

function updateRouterSession(to, from, next) {
  store.commit('UPDATE_ROUTER_SESSION', to.fullPath);
  next();
}

function init() {
  const token = localStorage.getItem('os_token');

  if (token) {
    store.commit('auth/SET_TOKEN', { token, memorize: true });
  }

  router.beforeEach(getHash);
  router.beforeEach(checkOAuth);
  router.beforeEach(checkAccessToRoute);
  router.beforeEach(updateRouterSession);
}

export default {
  init,
};

<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.292878 0.292892C0.683402 -0.0976321 1.31657 -0.0976321 1.70709 0.292892L6.70709 5.29289C7.09762 5.68342 7.09762 6.31658 6.70709 6.70711L1.70709 11.7071C1.31657 12.0976 0.683403 12.0976 0.292879 11.7071C-0.0976458 11.3166 -0.0976458 10.6834 0.292878 10.2929L4.58577 6L0.292878 1.70711C-0.0976463 1.31658 -0.0976463 0.683417 0.292878 0.292892Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    viewBox: String,
    width: Number,
    height: Number,
  },
};
</script>

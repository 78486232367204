<template>
  <div class="locale-changer">
    <ul class="locale-changer__languages">
      <li class="locale-changer__languages-item" v-for="(lang, idx) in languages" :key="idx">
        <a
          class="locale-changer__link"
          :class="{ 'locale-changer__link--active': isLangActive(lang) }"
          href="#"
          @click.prevent="changeLang(lang)"
        >
          {{ getLanguageTitle(lang) }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import Language from '@/helpers/services/Language';

export default {
  name: 'LocaleChanger',
  computed: {
    languages() {
      return Language.getAll();
    },
    isLangActive() {
      return (lang) => Language.isCurrent(lang);
    },
  },
  methods: {
    changeLang(lang) {
      Language.change(lang);
    },
    getLanguageTitle(lang) {
      const titles = {
        kk: 'KZ',
        ru: 'RU',
      };
      return titles[lang] || '';
    },
  },
};
</script>

<style scoped lang="less">
@import '~less_vars';

.locale-changer {
  &__languages {
    display: flex;
    margin: 0;
    padding: 0;
    gap: 10px;
    list-style: none;
  }

  &__link {
    text-decoration: none;
    color: @text-gray;
    font-style: normal;
    font-weight: normal;

    transition: color 0.3s;

    &:hover,
    &--active {
      color: @text-accent;
    }
  }
}
</style>

<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24 12C24 15.5976 21.7203 18.7514 18.2991 20.5131C15.2366 22.3449 9.58979 23.2326 4.47542 23.4474C3.73401 23.4786 3.42096 22.4992 3.96482 21.9943C4.55217 21.4491 4.99262 20.9019 5.31076 20.3034C2.10747 18.5078 0 15.459 0 12C0 6.47715 5.37258 2 12 2C18.6274 2 24 6.47715 24 12ZM6.5 10C5.39543 10 4.5 10.8954 4.5 12C4.5 13.1046 5.39543 14 6.5 14C7.60457 14 8.5 13.1046 8.5 12C8.5 10.8954 7.60457 10 6.5 10ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM17.5 10C16.3954 10 15.5 10.8954 15.5 12C15.5 13.1046 16.3954 14 17.5 14C18.6046 14 19.5 13.1046 19.5 12C19.5 10.8954 18.6046 10 17.5 10Z"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height'],
};
</script>

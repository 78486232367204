<!--eslint-disable max-len-->
<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.62591 4.62614C5.01643 4.23562 5.6496 4.23562 6.04012 4.62615L15.9995 14.5857L25.959 4.62615C26.3495 4.23562 26.9826 4.23562 27.3732 4.62614C27.7637 5.01666 27.7637 5.64983 27.3732 6.04035L17.4137 15.9999L27.3732 25.9595C27.7637 26.35 27.7637 26.9832 27.3732 27.3737C26.9826 27.7642 26.3495 27.7642 25.959 27.3737L15.9995 17.4141L6.04012 27.3737C5.6496 27.7642 5.01643 27.7642 4.62591 27.3737C4.23538 26.9832 4.23537 26.35 4.6259 25.9595L14.5853 15.9999L4.6259 6.04035C4.23538 5.64983 4.23538 5.01666 4.62591 4.62614Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CloseMenu',
};
</script>

<style scoped></style>

<template>
  <span class="base-tag" :class="applyClasses" @click="$emit('click', $event)">
    <slot class="base-tag__tag-icon-left" name="tagIconLeft"></slot>
    <slot>Тег</slot>
    <slot class="base-tag__tag-icon-right" name="tagIconRight"></slot>
  </span>
</template>

<script>
import { toBemModifer } from '@/helpers/index';
import getTagsColor from '@/helpers/getTagsColor';

export default {
  name: 'NewBaseTag',
  props: {
    theme: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
  },
  computed: {
    applyClasses() {
      const lazyTheme = getTagsColor(this.$slots.default[0].text);
      const theme = this.theme || lazyTheme;
      const { size } = this;
      const baseClass = 'base-tag';
      return [...toBemModifer(theme, baseClass, 'theme'), ...toBemModifer(size, baseClass, 'size')];
    },
  },
};
</script>

<style lang="less" scoped>
@import '~less_vars';

.base-tag {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  max-width: 188px;
  height: 32px;
  border-radius: 32px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.02em;
  gap: 5px;
  white-space: nowrap;
  &--size-md {
    font-size: @medium-fz;
    height: 19px;
    line-height: 18px;
  }

  &--size-lg {
    font-size: @base-fz;
    height: 42px;
    line-height: 42px;
    border-radius: 4px;
    padding: 0 15px;
  }

  &--theme-grey {
    background-color: #f8f5ff;
    color: #060e1f;
  }

  &--theme-default {
    background-color: @primary-color;
    color: @button-text-color;
  }

  &--theme-pink {
    background-color: @pink-color;
    color: @button-text-color;
  }

  &--theme-orange {
    background-color: @orange-color;
    color: @button-text-color;
  }

  &--theme-carrot {
    background-color: @carrot-color;
    color: @button-text-color;
  }

  &--theme-yellow {
    background-color: @yellow-color;
    color: #303030;
  }

  &--theme-lime {
    background-color: @lime-color;
    color: #303030;
  }

  &--theme-green {
    background-color: @green-color;
    color: @button-text-color;
  }

  &--theme-tag-red {
    background-color: @tag-red;
    color: @button-text-color;
  }

  &--theme-tag-blue {
    background-color: @tag-blue;
    color: @button-text-color;
  }

  &--theme-tag-dark-blue {
    background-color: @tag-dark-blue;
    color: @button-text-color;
  }

  &--theme-tag-light-green {
    background-color: @tag-light-green;
    color: @button-text-color;
  }

  &--theme-tag-khaki {
    background-color: @tag-khaki;
    color: @button-text-color;
  }

  &--theme-tag-violet {
    background-color: @tag-violet;
    color: @button-text-color;
  }

  &--theme-tag-orange {
    background-color: @tag-orange;
    color: @button-text-color;
  }

  &--theme-tag-green {
    background-color: @tag-green;
    color: @button-text-color;
  }

  &--theme-tag-yellow {
    background-color: @tag-yellow;
    color: @button-text-color;
  }

  &--theme-tag-dark-orange {
    background-color: @tag-dark-orange;
    color: @button-text-color;
  }

  &--theme-tag-dark-brown {
    background-color: @tag-dark-brown;
    color: @button-text-color;
  }

  &--theme-tag-dark-ping {
    background-color: @tag-dark-ping;
    color: @button-text-color;
  }

  &--theme-tag-light-blue {
    background-color: @tag-light-blue;
    color: @button-text-color;
  }

  &--theme-tag-dark-green {
    background-color: @tag-dark-green;
    color: @button-text-color;
  }

  &--theme-tag-light-pink {
    background-color: @tag-light-pink;
    color: @button-text-color;
  }

  &--theme-tag-dark-violet {
    background-color: @tag-dark-violet;
    color: @button-text-color;
  }

  &--theme-tag-default {
    background-color: @surface-light-accent-bg;
    color: @text-black;
  }

  &--theme-tag-primary {
    background: @surface-accent;
    color: #ffffff;
  }

  &--theme-cyan {
    background-color: @cyan-color;
    color: #303030;
  }

  &--theme-sky-blue {
    background-color: @sky-blue-color;
    color: @button-text-color;
  }

  &--theme-blue {
    background-color: @blue-color;
    color: @button-text-color;
  }

  &--theme-dark-blue {
    background-color: @dark-blue-color;
    color: @button-text-color;
  }

  &--theme-white {
    background: #ffffff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    color: @primary-color;

    &:hover {
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
    }
  }
}
</style>

<template>
  <div class="form">
    <form action="#" @submit.prevent="submitHandler">
      <refactor-base-input
        width="100%"
        :placeholder="$t('Введите код')"
        type="text"
        required
        @input="setCode"
      ></refactor-base-input>
      <refactor-base-checkbox v-model="memorize">{{ $t('Запомнить меня') }}</refactor-base-checkbox>
      <refactor-base-button>{{ $t('Войти') }}</refactor-base-button>

      <div v-show="showError" class="form-error">
        <p>{{ $t('Ошибка авторизации. Неверный код') }}</p>
      </div>
    </form>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import RefactorBaseInput from '@/components/NewComponents/RefactorBaseInput';
import RefactorBaseCheckbox from '@/components/NewComponents/RefactorBaseCheckbox';

export default {
  name: 'RefactorLoginByCode',
  components: { RefactorBaseCheckbox, RefactorBaseInput },
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      memorize: true,
      code: null,
      redirect: '/',
    };
  },
  computed: {
    showError() {
      return this.error && this.error.type === 'code';
    },
  },
  methods: {
    ...mapActions('auth', ['authByCode']),
    submitHandler() {
      const { redirect, code, memorize } = this;
      if (redirect && code) {
        this.authByCode({ redirect, code, memorize });
      }
    },
    setCode(e) {
      this.code = e.target.value;
    },
  },
};
</script>

import { init, track, identify, Identify } from '@amplitude/analytics-browser';
import moment from 'moment';
import { HTTP } from '@/http';

// TODO: после тестирования вставить ключ с продакшен аккаунта амплитуды
// # Open School Test - Development
const API_KEY = process.env.VUE_APP_AMPLITUDE_API_KEY;

const Amplitude = {
  namespaced: true,
  state: {
    userId: null,
  },
  getters: {
    userId: (state) => state.userId,
    isAmplitudeEnabled(state, getters, rootState, rootGetters) {
      const allowedRoles = ['teacher', 'student'].includes(rootGetters['navs/getRole']);
      const { hostname } = window.location;
      const allowedHosts = hostname.includes('2035school') && !hostname.includes('preprod.2035school');
      return allowedRoles && allowedHosts;
    },
  },
  mutations: {
    SET_USER_ID: (state, payload) => {
      state.userId = payload;
    },
  },
  // ---------------------- //
  actions: {
    // eslint-disable-next-line no-empty-pattern
    sendUserSource(empty, params) {
      try {
        HTTP.post('/api/updatesource/', params);
      } catch (e) {
        console.error('sendUserSource error', e);
      }
    },
    async sendEvent({ getters, dispatch }, params) {
      try {
        if (!getters.isAmplitudeEnabled) return;

        if (!getters.userId) {
          await dispatch('initAmplitude');
        }
        track(params.eventName, params.eventProperties);
      } catch (e) {
        console.error(`error during amplitude sendEvent(): ${e}`);
      }
    },
    async initAmplitude({ commit, getters, dispatch, rootGetters }) {
      try {
        if (!getters.isAmplitudeEnabled) return;

        if (rootGetters['auth/me']) {
          const userId = rootGetters['auth/me']._id;
          commit('SET_USER_ID', userId);
          init(API_KEY, getters.userId);
          dispatch('setUserProperties');
          console.info('amplitude initialized');
        }
      } catch (e) {
        console.error(`error during initAmplitude(): ${e}`);
      }
    },
    async setUserProperties({ getters, dispatch, rootGetters }) {
      try {
        const me = rootGetters['auth/me'];
        const identifyObj = new Identify();

        const subjects = await dispatch('subjectsV2/fetchSubjects', {}, { root: true });
        const userSubjects = me.subjects.map((subId) => subjects.find((sub) => sub._id === subId));

        const platform = window.location.hostname;
        const { email } = me;
        const currentRole = rootGetters['navs/getRole'] || 'empty';
        const testSubs = me?.subs?.isTest || 'empty';
        const screenResol = `${Math.round(window.screen.width * window.devicePixelRatio)}x${Math.round(
          window.screen.height * window.devicePixelRatio
        )}`;
        const subsActive = me?.subs?.endDate ? moment() <= moment(me.subs.endDate) : false;
        const isTest = me?.isTest || false;
        const cityName = me?.geography?.cityName || 'empty';
        const regionName = me?.geography?.regionName || 'empty';
        const schoolName = me?.geography?.schoolName || 'empty';
        const dateRegistration = moment(me.dateRegistration).format('YYYY-MM-DD HH:mm:ss') || 'empty';
        const startSession = moment().format('YYYY-MM-DD HH:mm:ss');
        const firstSource = me?.visitSource?.firstSource || 'empty';
        const lastSource = me?.visitSource?.lastSource || 'empty';
        let classNumber = 'empty';
        const baseSubj1 = userSubjects?.[0]?.name || 'empty';
        const baseSubj2 = userSubjects?.[1]?.name || 'empty';
        const baseSubj3 = userSubjects?.[2]?.name || 'empty';
        const fio = me.fullName || 'empty';
        const startSubs = me?.subs?.startDate ? moment(me.subs.startDate).format('YYYY-MM-DD') : 'empty';
        const endSubs = me?.subs?.endDate ? moment(me.subs.endDate).format('YYYY-MM-DD') : 'empty';

        if (currentRole === 'student') {
          const studyClass = await dispatch('studyClasses/fetchClass', { id: me.studyClass }, { root: true });
          classNumber = `${studyClass.number} ${studyClass.litera}`;
        }

        identifyObj.set('platform', platform);
        identifyObj.set('email', email);
        identifyObj.set('current_role', currentRole);
        identifyObj.set('test_subs', testSubs);
        identifyObj.set('screen_resol', screenResol);
        identifyObj.set('subs_active', subsActive);
        identifyObj.set('is_test', isTest);
        identifyObj.set('city_name', cityName);
        identifyObj.set('region_name', regionName);
        identifyObj.set('school_name', schoolName);
        identifyObj.set('date_registration', dateRegistration);
        identifyObj.set('start_session', startSession);
        identifyObj.set('first_source', firstSource);
        identifyObj.set('last_source', lastSource);
        identifyObj.set('class_number', classNumber);
        identifyObj.set('base_subj1', baseSubj1);
        identifyObj.set('base_subj2', baseSubj2);
        identifyObj.set('base_subj3', baseSubj3);
        identifyObj.set('fio', fio);
        identifyObj.set('start_subs', startSubs);
        identifyObj.set('end_subs', endSubs);

        identify(identifyObj, {
          user_id: getters.userId,
        });
      } catch (e) {
        console.error(`error during amplitude setUserProperties(): ${e}`);
      }
    },
    async refreshRoleUserProperty({ getters, rootGetters }) {
      const identifyObj = new Identify();
      const currentRole = rootGetters['navs/getRole'] || 'empty';
      identifyObj.set('current_role', currentRole);
      identify(identifyObj, {
        user_id: getters.userId,
      });
    },
  },
};
export default Amplitude;

import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      component: () => import(/* webpackChunkName: "index.route" */ '@/views/PageIndex'),
      children: [
        {
          path: '',
          name: 'loginbypass',
          component: () => import(/* webpackChunkName: "index.route" */ '@/components/LoginForm/LoginByPass'),
          meta: {
            layout: 'MainPageLayout',
            noAuth: true,
          },
        },
        {
          path: 'email',
          name: 'loginbyemail',
          component: () => import(/* webpackChunkName: "index.route" */ '@/components/LoginForm/LoginByEmail'),
          meta: {
            layout: 'MainPageLayout',
            noAuth: true,
          },
        },
        {
          path: 'code',
          name: 'loginbycode',
          component: () => import(/* webpackChunkName: "index.route" */ '@/components/LoginForm/LoginByCode'),
          meta: {
            layout: 'MainPageLayout',
            noAuth: true,
          },
        },
        {
          path: 'service',
          name: 'loginbyservice',
          component: () => import(/* webpackChunkName: "index.route" */ '@/components/LoginForm/LoginByService'),
          meta: {
            layout: 'MainPageLayout',
            noAuth: true,
          },
        },
      ],
    },
    {
      path: '/edu',
      component: () => import(/* webpackChunkName: "st.route.npf" */ '@/views/PageEdu'),
      name: 'edu',
      meta: {
        layout: 'StaticLayout',
        noAuth: true,
      },
      redirect: 'edu/programs/webinars/',
      children: [
        {
          path: 'tutors',
          component: () => import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/EduTutors'),
          meta: {
            layout: 'StaticLayout',
            noAuth: true,
          },
          redirect: '/edu/tutors/seminars/',
          children: [
            {
              path: 'seminars',
              component: () => import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/TutorsSeminars'),
              meta: {
                layout: 'StaticLayout',
                noAuth: true,
              },
            },
            {
              path: 'reports',
              component: () => import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/TutorsReports'),
              meta: {
                layout: 'StaticLayout',
                noAuth: true,
              },
            },
          ],
        },
        {
          path: 'bonus',
          component: () => import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/EduBonus'),
          meta: {
            layout: 'StaticLayout',
            noAuth: true,
          },
        },
        {
          path: 'contest',
          component: () => import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/EduContest'),
          meta: {
            layout: 'StaticLayout',
            noAuth: true,
          },
        },
        {
          path: 'events',
          component: () => import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/EduEvents'),
          meta: {
            layout: 'StaticLayout',
            noAuth: true,
          },
        },
        {
          path: 'methods',
          component: () => import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/EduMethods'),
          meta: {
            layout: 'StaticLayout',
            noAuth: true,
          },
        },
        {
          path: 'programs',
          component: () => import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/EduPrograms'),
          meta: {
            layout: 'StaticLayout',
            noAuth: true,
          },
          redirect: '/edu/programs/webinars/',
          children: [
            {
              path: 'webinars',
              component: () => import(/* webpackChunkName: "st.route.npf" */ '@/views/PageWebinars'),
              meta: {
                layout: 'StaticLayout',
                noAuth: true,
              },
            },
            {
              path: 'courses',
              component: () => import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/EduCourses'),
              meta: {
                layout: 'StaticLayout',
                noAuth: true,
              },
            },
            /*
            {
              path: "seminars",
              component: EduSeminars,
              meta: {
                layout: "StaticLayout",
                noAuth: true
              }
            }
            */
          ],
        },
        {
          path: 'services',
          component: () => import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/EduServices'),
          meta: {
            layout: 'StaticLayout',
            noAuth: true,
          },
          redirect: '/edu/services/distance/',
          children: [
            {
              path: 'distance',
              component: () => import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/Services/EduServicesDistance'),
              meta: {
                layout: 'StaticLayout',
                noAuth: true,
              },
            },
            {
              path: 'presentations',
              component: () =>
                import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/Services/EduServicesPresentations'),
              meta: {
                layout: 'StaticLayout',
                noAuth: true,
              },
            },
            {
              path: 'feedback',
              component: () => import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/Services/EduServicesFeedback'),
              meta: {
                layout: 'StaticLayout',
                noAuth: true,
              },
            },
            {
              path: 'group',
              component: () => import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/Services/EduServicesGroup'),
              meta: {
                layout: 'StaticLayout',
                noAuth: true,
              },
            },
          ],
        },
        {
          path: 'materials',
          component: () => import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/EduMaterials'),
          meta: {
            layout: 'StaticLayout',
            noAuth: true,
          },
          redirect: '/edu/materials/general/',
          children: [
            {
              path: 'general',
              component: () =>
                import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/Materials/EduMaterialsGeneral'),
              meta: {
                layout: 'StaticLayout',
                noAuth: true,
              },
            },
            {
              path: 'english',
              component: () =>
                import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/Materials/EduMaterialsEnglish'),
              meta: {
                layout: 'StaticLayout',
                noAuth: true,
              },
            },
            {
              path: 'history',
              component: () =>
                import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/Materials/EduMaterialsHistory'),
              meta: {
                layout: 'StaticLayout',
                noAuth: true,
              },
            },
            {
              path: 'math',
              component: () => import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/Materials/EduMaterialsMath'),
              meta: {
                layout: 'StaticLayout',
                noAuth: true,
              },
            },
            {
              path: 'elementary',
              component: () =>
                import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/Materials/EduMaterialsElementary'),
              meta: {
                layout: 'StaticLayout',
                noAuth: true,
              },
            },
            {
              path: 'russian',
              component: () =>
                import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/Materials/EduMaterialsRussian'),
              meta: {
                layout: 'StaticLayout',
                noAuth: true,
              },
            },
            {
              path: 'physics',
              component: () =>
                import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/Materials/EduMaterialsPhysics'),
              meta: {
                layout: 'StaticLayout',
                noAuth: true,
              },
            },
            {
              path: 'chemistry',
              component: () =>
                import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/Materials/EduMaterialsChemistry'),
              meta: {
                layout: 'StaticLayout',
                noAuth: true,
              },
            },
          ],
        },
        {
          path: 'contacts',
          component: () => import(/* webpackChunkName: "st.route.npf" */ '@/components/EduPage/EduContacts'),
          meta: {
            layout: 'StaticLayout',
            noAuth: true,
          },
        },
      ],
    },
    {
      path: '/help',
      component: () => import(/* webpackChunkName: "st.route.npf" */ '@/views/PageHelp'),
      name: 'help',
      meta: {
        layout: 'BaseLayout',
        noAuth: false,
      },
    },
    {
      path: '/help/:section',
      component: () => import(/* webpackChunkName: "linkE.route.npf" */ '@/views/PageHelpSection'),
      name: 'helpSection',
      meta: {
        layout: 'BaseLayout',
        noAuth: false,
      },
    },
    {
      path: '/help/:section/:article',
      component: () => import(/* webpackChunkName: "linkE.route.npf" */ '@/views/PageHelpArticle'),
      name: 'helpSectionArticle',
      meta: {
        layout: 'BaseLayout',
        noAuth: false,
      },
    },
    {
      path: '/docs',
      name: 'documentation',
      component: () => import(/* webpackChunkName: "st.route.npf" */ '@/views/PageDocumentation'),
      meta: {
        layout: 'StaticLayout',
        noAuth: true,
      },
    },
    {
      path: '/rules',
      component: () => import(/* webpackChunkName: "st.route.npf" */ '@/views/PageRules'),
      name: 'rules',
      meta: {
        layout: 'StaticLayout',
        noAuth: true,
      },
    },
    {
      path: '/feedback',
      component: () => import(/* webpackChunkName: "st.route.npf" */ '@/views/PageFeedback'),
      name: 'feedback',
      meta: {
        layout: 'StaticLayout',
        noAuth: true,
      },
    },
    {
      path: '/diary',
      component: () => import(/* webpackChunkName: "tasks.route.npf" */ '@/views/PageDiary'),
      name: 'diary',
      meta: {
        permissions: {
          roles: ['student'],
        },
        noAuth: false,
      },
    },
    {
      path: '/principal',
      redirect: {
        path: '/principal/teachers/',
      },
      meta: {
        permissions: {
          roles: ['head'],
        },
        noAuth: false,
      },
    },
    {
      path: '/principal/teachers',
      name: 'teachers',
      component: () => import(/* webpackChunkName: "pr.route.npf" */ '@/views/PagePrincipal'),
      meta: {
        permissions: {
          roles: ['head', 'assistant'],
        },
        noAuth: false,
      },
    },
    {
      path: '/journal',
      component: () => import(/* webpackChunkName: "j.route.npf" */ '@/views/PageJournal'),
      children: [
        {
          path: '',
          name: 'classes',
          component: () => import(/* webpackChunkName: "j.route.npf" */ '@/views/JournalClasses'),
          meta: {
            permissions: {
              roles: ['teacher', 'assistant', 'head'],
            },
          },
        },
        {
          path: 'groups',
          name: 'groups',
          component: () => import(/* webpackChunkName: "j.route.npf" */ '@/views/JournalGroups'),
          meta: {
            permissions: {
              roles: ['teacher'],
            },
          },
        },
        {
          path: ':classId',
          name: 'class',
          component: () => import(/* webpackChunkName: "j.route.npf" */ '@/views/JournalClass'),
          meta: {
            permissions: {
              roles: ['teacher', 'assistant', 'head'],
            },
          },
        },
        {
          path: 'groups/:groupId',
          name: 'group',
          component: () => import(/* webpackChunkName: "j.route.npf" */ '@/views/JournalGroup'),
          meta: {
            permissions: {
              roles: ['teacher'],
            },
          },
        },
      ],
    },
    {
      path: '/lessons',
      name: 'lessons',
      component: () => import(/* webpackChunkName: "lessons.route.npf" */ '@/views/LessonsCatalog'),
      meta: {
        layout: 'BaseLayout',
        permissions: {
          roles: ['teacher', 'student', 'content', 'guest'],
        },
        noAuth: false,
      },
    },
    {
      path: '/eljurlessons',
      name: 'eljurlessons',
      component: () => import('@/views/LessonsCatalog'),
      meta: {
        layout: 'EmptyLayout',
      },
    },
    {
      path: '/lesson/:id',
      component: () => import(/* webpackChunkName: "lesson.route.npf" */ '@/views/LessonsCatalog'),
      name: 'oldlesson',
    },
    {
      path: '/expired',
      component: () => import(/* webpackChunkName: "linkE.route.npf" */ '@/views/PageLinkExpired'),
      name: 'PageLinkExpired',
      meta: {
        noAuth: true,
      },
      children: [
        {
          path: ':inviteId',
          component: () => import(/* webpackChunkName: "linkE.route.npf" */ '@/views/PageLinkExpired'),
          name: 'PageLinkExpiredId',
          meta: {
            noAuth: true,
          },
        },
      ],
    },
    {
      path: '/htmllesson/:path',
      component: () => import(/* webpackChunkName: "lesson.route.npf" */ '@/views/PageLesson'),
      name: 'lesson',
      meta: {
        layout: 'BaseLayout',
      },
      children: [
        {
          path: ':slideNum',
          component: () => import(/* webpackChunkName: "lesson.route.npf" */ '@/views/PageLesson'),
          name: 'lessonSlides',
        },
      ],
    },
    {
      path: '/lessonreview/:lesson/:student',
      component: () => import(/* webpackChunkName: "lesson.route.npf" */ '@/views/PageLesson'),
      name: 'review',
      props: {
        isReview: true,
      },
      meta: {
        permissions: {
          roles: ['teacher'],
        },
      },
    },
    {
      path: '/tasks',
      component: () => import(/* webpackChunkName: "tasks.route.npf" */ '@/views/PageTasks'),
      name: 'tasks',
      meta: {
        permissions: {
          roles: ['teacher'],
        },
      },
    },
    {
      path: '/tags',
      component: () => import(/* webpackChunkName: "tags.route.npf" */ '@/views/PageTags'),
      name: 'tags',
      meta: {
        permissions: {
          roles: ['content'],
        },
      },
    },
    {
      path: '/schools/',
      component: () => import(/* webpackChunkName: "schools.route.npf" */ '@/views/PageSchools'),
      name: 'schools',
      meta: {
        permissions: {
          roles: ['subs'],
        },
      },
    },
    {
      path: '/regions',
      component: () => import(/* webpackChunkName: "regions.route.npf" */ '@/views/PageRegions'),
      name: 'regions',
      meta: {
        permissions: {
          roles: ['subs'],
        },
      },
    },
    {
      path: '/printinvite',
      component: () => import(/* webpackChunkName: "pInv.route.npf" */ '@/views/PagePrintInvite'),
      name: 'printinvite',
      meta: {
        layout: 'SimpleLayout',
        noAuth: true,
      },
    },
    {
      path: '/administrators',
      component: () => import(/* webpackChunkName: "a.route.npf" */ '@/views/PageAdministrators'),
      name: 'administrators',
      meta: {
        permissions: {
          roles: ['super'],
        },
      },
    },
    {
      path: '/profile',
      component: () => import(/* webpackChunkName: "profile.route.npf" */ '@/views/PageProfile'),
      name: 'profile',
    },
    {
      path: '/subjects',
      component: () => import(/* webpackChunkName: "subj.route.npf" */ '@/views/PageSubjects'),
      name: 'subjects',
      meta: {
        permissions: {
          roles: ['content'],
        },
      },
    },
    {
      path: '/subjectslinks',
      component: () => import(/* webpackChunkName: "subj.route.npf" */ '@/views/PageSubjectsLinks'),
      name: 'subjectslinks',
      meta: {
        permissions: {
          roles: ['content'],
        },
      },
    },
    {
      path: '/stats',
      component: () => import(/* webpackChunkName: "statistics.route.npf" */ '@/views/Statistics'),
      meta: {
        permissions: {
          roles: ['super', 'municipal', 'assistant'],
        },
      },
      children: [
        {
          path: '/',
          component: () => import(/* webpackChunkName: "statistics.route.npf" */ '@/components/Statistics/CityStats'),
          name: 'CityStats',
          meta: {
            permissions: {
              roles: ['super', 'municipal', 'assistant'],
            },
          },
        },
        {
          path: ':schoolId',
          component: () => import(/* webpackChunkName: "statistics.route.npf" */ '@/components/Statistics/SchoolStats'),
          name: 'SchoolStats',
          meta: {
            permissions: {
              roles: ['super', 'municipal', 'assistant'],
            },
          },
        },
        {
          path: ':schoolId/:studyClassId',
          component: () => import(/* webpackChunkName: "statistics.route.npf" */ '@/components/Statistics/StudyClassStats'),
          name: 'StudyClassStats',
          meta: {
            permissions: {
              roles: ['super', 'municipal', 'assistant'],
            },
          },
        },
      ],
    },
    {
      path: '/impersonate',
      component: () => import(/* webpackChunkName: "ast.route.npf" */ '@/views/PageAdminImpersonate'),
      name: 'impersonate',
      meta: {
        layout: 'ModernLayout',
        permissions: {
          roles: ['super'],
        },
      },
    },
    {
      path: '/progress',
      component: () => import(/* webpackChunkName: "prog.route.npf" */ '@/views/PageProgressTable'),
      name: 'progress',
    },
    {
      path: '/chart',
      component: () => import(/* webpackChunkName: "ch.route.npf" */ '@/views/PageChart'),
      name: 'chart',
    },
    {
      path: '/partners',
      component: () => import(/* webpackChunkName: "part.route.npf" */ '@/views/PagePartners'),
      name: 'partners',
    },
    {
      path: '/accessclosed',
      component: () => import(/* webpackChunkName: "pac.route.npf" */ '@/views/PageAccessClosed'),
      name: 'PageAccessClosed',
      meta: {
        noAuth: true,
        layout: 'StaticLayout',
      },
    },
    {
      path: '/homework/:id',
      component: () => import(/* webpackChunkName: "home.route.npf" */ '@/views/PageHomework'),
      name: 'pageHomework',
    },
    {
      path: '/intro',
      component: () => import(/* webpackChunkName: "intro.route.npf" */ '@/views/PageIntro'),
      name: 'pageIntro',
      meta: {
        layout: 'ModernLayout',
        noAuth: false,
      },
    },
    {
      path: '/dashboard',
      component: () => import(/* webpackChunkName: "dashboard.route.npf" */ '@/views/PageDashboard'),
      name: 'pageDashboard',
      meta: {
        layout: 'ModernLayout',
        noAuth: false,
      },
    },
    {
      path: '/news',
      component: () => import(/* webpackChunkName: "news.route.npf" */ '@/views/PageNews'),
      name: 'pageNews',
      meta: {
        layout: 'ModernLayout',
        noAuth: false,
      },
    },
    {
      path: '/news/:id',
      component: () => import(/* webpackChunkName: "news.route.npf" */ '@/views/PageNewsItem'),
      name: 'pageNewsItem',
      meta: {
        layout: 'ModernLayout',
        noAuth: false,
      },
    },
    {
      path: '/reviews',
      component: () => import(/* webpackChunkName: "a.route.npf" */ '@/views/PageReviews'),
      name: 'pageReviews',
      meta: {
        layout: 'ModernLayout',
        permissions: {
          roles: ['content'],
          noAuth: true,
        },
      },
    },
    {
      path: '/teachermaterials/:id',
      component: () => import(/* webpackChunkName: "news.route.npf" */ '@/views/PageTeacherMaterialsItem'),
      name: 'pageTeacherMaterialsItem',
      meta: {
        layout: 'ModernLayout',
        noAuth: false,
      },
    },
    // Всегда последний
    {
      path: '*',
      component: () => import(/* webpackChunkName: "404.route.npf" */ '@/views/Page404'),
      name: 'error',
    },
  ],
});

<template>
  <div class="main-subjects-item" v-bind:class="[{ 'is-two-row': subject.isAvailable }, subject.type]">
    <div class="main-subjects-item__title">{{ $t(subject.title) }}</div>
    <div class="main-subjects-item__subtitle">{{ subject.subtitle }}</div>
  </div>
</template>

<script>
export default {
  name: 'MainSubjectsItem',
  props: {
    subject: Object,
  },
};
</script>

<style lang="less">
@import '~less_vars';
.main-subjects-item {
  width: 32%;
  background: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  padding: 25px 0 30px 100px;
  margin-bottom: 25px;
  background-repeat: no-repeat;
  background-position: 10% 50%;

  &.is-math {
    background-image: url(../assets/ico-lesson-math.svg);
    background-repeat: no-repeat;
    background-position: 10% 50%;
  }

  &.is-russian-history {
    background-image: url(../assets/ico-lesson-russian-history.svg);
    background-repeat: no-repeat;
    background-position: 10% 50%;
  }

  &.is-algebra {
    background-image: url(../assets/ico-lesson-algebra.svg);
    background-repeat: no-repeat;
    background-position: 10% 50%;
  }

  &.is-geometry {
    background-image: url(../assets/ico-lesson-geometry.svg);
    background-repeat: no-repeat;
    background-position: 10% 50%;
  }

  &.is-physics {
    background-image: url(../assets/ico-lesson-physics.svg);
    background-repeat: no-repeat;
    background-position: 10% 50%;
  }

  &.is-chemistry {
    background-image: url(../assets/ico-lesson-chemistry.svg);
    background-repeat: no-repeat;
    background-position: 10% 50%;
  }

  &.is-russian {
    background-image: url(../assets/ico-lesson-russian-lang.svg);
    background-repeat: no-repeat;
    background-position: 10% 50%;
  }

  &.is-literature {
    background-image: url(../assets/ico-lesson-literature.svg);
    background-repeat: no-repeat;
    background-position: 10% 50%;
  }

  &.is-english {
    background-image: url(../assets/ico-lesson-english-lang.svg);
    background-repeat: no-repeat;
    background-position: 10% 50%;
  }

  &.is-world-history {
    background-image: url(../assets/ico-lesson-world-history.svg);
    background-repeat: no-repeat;
    background-position: 10% 50%;
  }

  &.is-biology {
    background-image: url(../assets/ico-lesson-biology.svg);
    background-repeat: no-repeat;
    background-position: 10% 50%;
  }

  &.is-social {
    background-image: url(../assets/ico-lesson-social.svg);
    background-repeat: no-repeat;
    background-position: 10% 50%;
  }

  &.is-it {
    background-image: url(../assets/ico-lesson-it.svg);
    background-repeat: no-repeat;
    background-position: 10% 50%;
  }

  &.is-geography {
    background-image: url(../assets/ico-lesson-geography.svg);
    background-repeat: no-repeat;
    background-position: 10% 50%;
  }

  &.is-two-row {
    width: 48.5%;
  }

  &__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
    margin-bottom: 5px;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 20px;
    color: #7e7e7e;
  }

  @media (max-width: 1200px) {
    width: 31.8%;
  }

  @media (max-width: 768px) {
    width: 48.5%;
  }

  @media (max-width: 480px) {
    width: 100%;

    &.is-two-row {
      width: 100%;
    }
  }
}
</style>

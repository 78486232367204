import { HTTP } from '@/http';

const partners = {
  namespaced: true,
  state: {
    ids: [],
  },
  mutations: {
    FETCH_PARTNERS: (state, payload) => {
      state.ids = [...new Set([...state.ids, ...payload])];
    },
    CREATE_PARTNER: (state, payload) => {
      state.tempTag = payload;
    },
    REMOVE_PARTNER: (state, id) => {
      const idx = state.ids.indexOf(id);
      if (idx !== -1) {
        state.ids.splice(idx, 1);
      }
    },
  },
  getters: {
    list(state, getters, rootState) {
      return state.ids.map((id) => rootState.DB.partners[id]);
    },
  },
  actions: {
    fetchPartners({ commit }) {
      return HTTP.get('/crud/partner/').then((response) => {
        commit(
          'FETCH_PARTNERS',
          response.data.models.map((n) => n._id)
        );
        commit(
          'FETCH_MODELS',
          {
            type: 'partners',
            payload: response.data.models,
          },
          { root: true }
        );
      });
    },
    async addPartner({ commit }, params) {
      try {
        const res = await HTTP.get('/api/generatesecretkey/');
        params.key = res.data.key;
      } catch (error) {
        console.log('error', error);
      }

      HTTP.post('/crud/partner/', { ...params }).then((response) => {
        if (response.data._id) {
          commit('FETCH_PARTNERS', [response.data._id]);
          commit('CREATE_PARTNER', response.data);
          commit(
            'FETCH_MODELS',
            {
              type: 'partners',
              payload: [response.data],
            },
            { root: true }
          );
        }
      });
    },
    removePartner({ commit }, params) {
      HTTP.delete('/crud/partner/', { data: { ...params } })
        .then(() => {
          commit('REMOVE_PARTNER', params._id);
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
  },
};

export default partners;

<template>
  <div class="base-toggle" :class="applyClasses">
    <slot>Переключатель</slot>
    <base-button size="medium" tag="router-link" variant="toggle" :theme="getTheme('class')" :to="{ path: '/' }">
      {{ $t('Классы') }}
    </base-button>
    <base-button size="medium" tag="router-link" variant="toggle" :theme="getTheme('group')" :to="{ path: '/groups' }">
      {{ $t('Группы') }}
    </base-button>
  </div>
</template>

<script>
import { toBemModifer } from '@/helpers/index';

export default {
  name: 'BaseToggle',
  props: {
    theme: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    mod: {
      type: String,
      default: null,
    },
  },
  computed: {
    applyClasses() {
      const { theme, size } = this;
      const baseClass = 'base-toggle';
      return [...toBemModifer(theme, baseClass, 'theme'), ...toBemModifer(size, baseClass, 'size')];
    },
  },
  methods: {
    getTheme(btnMod = 'class') {
      return btnMod === this.mod ? 'default' : 'disabled';
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.base-toggle {
  overflow: hidden;
  display: inline-block;

  button.base-btn--variant-toggle {
    margin: 0;

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      border-radius: 0 3px 3px 0;
    }
  }
}
</style>

import { HTTP } from '@/http';

export default {
  deleteModel(context, { model, options }) {
    return HTTP.delete(`/crud/${model}/`, options);
  },
  fetchModel(context, { model, options }) {
    return HTTP.get(`/crud/${model}/`, options);
  },
  saveModel(context, { model, options }) {
    return HTTP.get(`/crud/${model}/`, options);
  },
};

import Vue from 'vue';
import VueYandexMetrika from 'vue-yandex-metrika';
import router from '@/router';
import config from '../../config';

function init() {
  const yandexCounter = config?.[window.location.origin]?.YANDEX_METRIKA_COUNTER;
  if (yandexCounter) {
    Vue.use(VueYandexMetrika, {
      id: yandexCounter,
      router,
      env: process.env.NODE_ENV,
      options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      },
    });
  }
}

export default {
  init,
};

import { HTTP } from '@/http';
import { objToQuery } from '@/helpers/index';

const students = {
  namespaced: true,
  state: {
    requests: {},
    ids: [],
    tempStudents: [], // students recieved from file
  },
  getters: {
    list(state, getters, rootState) {
      return state.ids.map((_id) => {
        const student = rootState.DB.students[_id];
        if (student) return student;
        return { _id, pending: true };
      });
    },
    tempList(state) {
      return state.tempStudents;
    },
  },
  actions: {
    async checkStudentsXlsx(empty, payload) {
      try {
        const { data } = await HTTP.post(`/api/v2/xlsx-students/`, payload);
        return data;
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    fetchStudents({ state, commit }, payload = {}) {
      const { forceUpdate } = payload;
      delete payload.forceUpdate;
      const params = { ...payload, roles: { $in: ['student'] } };
      const query = objToQuery(params);

      return new Promise((resolve, reject) => {
        if (!forceUpdate && (!query || state.requests[query])) resolve();
        else {
          HTTP.get('/crud/user/', { params })
            .then((response) => {
              const data = response.data.models.map((n) => {
                n.fullName = `${n.lastName} ${n.firstName}`;
                return n;
              });
              const ids = data.map((n) => n._id);
              commit('SAVE_LIST', ids);
              commit('SAVE_REQUEST', query);
              commit(
                'FETCH_MODELS',
                {
                  type: 'students',
                  payload: data,
                },
                { root: true }
              );
              resolve(data);
            })
            .catch((error) => reject(error));
        }
      });
    },
    fetchCount(context, payload = {}) {
      const params = { ...payload, roles: { $in: ['student'] } };
      return HTTP.get('/crud/user/count/', { params }).then((response) => {
        const { count } = response.data;
        if (count) return count;
        return null;
      });
    },
    addStudents({ commit, dispatch, rootState }, params) {
      const payload = Array.isArray(params) ? params : [params];
      // TODO! Зачем это надо?
      payload.forEach((student) => {
        student.roles = ['student'];
        student.part = 'student';
        student.school = rootState.DB.users[rootState.auth.user].school;
      });

      return HTTP.post('/api/createstudents/', [...payload])
        .then((response) => {
          const { created, updated } = response.data;
          const isCreated = created && created.length;
          const isUpdated = updated && updated.length;

          // eslint-disable-next-line no-nested-ternary
          const studnets = isCreated ? created : isUpdated ? updated : [];

          if (studnets.length) {
            studnets.forEach((student) => {
              const { firstName, lastName } = student;
              student.fullName = `${lastName} ${firstName}`;
            });

            commit(
              'SAVE_LIST',
              studnets.map((s) => s._id)
            );
            commit(
              'FETCH_MODELS',
              {
                type: 'students',
                payload: studnets,
              },
              { root: true }
            );
            dispatch('studyClasses/refetchClasses', null, { root: true });
          }
          return studnets;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async createStudent(empty, payload) {
      try {
        const data = await HTTP.post('/api/v2/users', payload);
        return data;
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async deleteStudent(empty, params) {
      try {
        const data = await HTTP.delete('/api/v2/users', { params });
        return data;
      } catch (e) {
        console.log(e);
        throw Error();
      }
    },
    async generateLinks(context, payload) {
      const res = await HTTP.post('/api/generatelinks/', { ...payload });
      return res;
    },
    generateCodes(context, payload) {
      return HTTP.post('/api/generatecodes/', { ...payload });
    },
    fetchCodes(context, payload) {
      return HTTP.post('/api/v2/fetch-codes/', { ...payload });
    },
    fetchQrLinks(context, payload) {
      return HTTP.post(`/auth/api/v2/fetch-qr-links/`, { ...payload });
    },
    async generateLinksAndCodes({ dispatch }, payload) {
      // eslint-disable-next-line no-return-await
      const promises = [dispatch('generateLinks', payload), dispatch('generateCodes', payload)];
      return Promise.all(promises);
    },
    setTempStudents({ commit }, payload) {
      commit('SET_TEMP_STUDENTS', payload);
    },
  },
  mutations: {
    SAVE_REQUEST: (state, payload) => {
      state.requests[payload] = true;
    },
    DROP_REQUESTS: (state) => {
      state.requests = {};
    },
    SAVE_LIST: (state, payload) => {
      state.ids = [...new Set([...state.ids, ...payload])];
    },
    SET_TEMP_STUDENTS: (state, payload) => {
      payload.forEach((student) => {
        if (!student.email) {
          student.email = '';
        }
        if (!student.phone) {
          student.phone = '';
        }
      });
      state.tempStudents = payload;
    },
  },
};

export default students;

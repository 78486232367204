<template>
  <div v-if="!isNewInterface && !isKzPlatform">
    <form action="#" @submit.prevent="submitHandler">
      <label class="form__label" for="email">{{ $t('Логин(еmail)') }}</label>
      <base-input type="text" required id="email" name="email" @input="inputHandler"></base-input>
      <label class="form__label" for="password">{{ $t('Пароль') }}</label>
      <base-input type="password" required id="password" name="password" @input="inputHandler"></base-input>
      <base-checkbox v-model="form.memorize">{{ $t('Запомнить меня') }}</base-checkbox>
      <base-button size="large">{{ $t('Войти') }}</base-button>
      <div v-show="showError" class="form-error">
        <p>{{ $t('Неверный логин или пароль') }}</p>
      </div>
    </form>
  </div>
  <div v-else>
    <refactor-login-by-pass />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import RefactorLoginByPass from '@/components/NewComponents/RefactorLoginByPass';

export default {
  name: 'LoginByPass',
  components: { RefactorLoginByPass },
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
        memorize: true,
      },
      pending: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['getCurrentInterface']),
    ...mapGetters('platform', ['isKzPlatform']),

    isNewInterface() {
      return this.getCurrentInterface === 'new';
    },
    showError() {
      return this.error && this.error.type === 'login';
    },
  },
  methods: {
    ...mapActions('auth', ['authByLogin']),
    inputHandler(e) {
      const name = e.target.getAttribute('name');
      this.form[name] = e.target.value;
    },
    async submitHandler() {
      const { email, password, memorize } = this.form;
      if (email && password) {
        this.authByLogin({ email, password, memorize });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.form {
  &__label {
    display: block;
    font-size: 15px;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.42);
    margin-bottom: 10px;
  }
}
</style>

<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.99858 8.49787V14.9846C6.99858 15.3994 7.47463 15.6338 7.80344 15.3809L9.60829
                        13.9925C9.85438 13.8032 9.99858 13.5104 9.99858 13.1999V8.4979C9.99858 8.37538
                        10.0436 8.25712 10.125 8.16558L14.2781 3.49698C15.1385 2.52975 14.4519 1 13.1574
                        1H3.84082C2.54636 1 1.85972 2.52958 2.71998 3.49684L6.8722 8.16559C6.95361 8.25713
                        6.99858 8.37537 6.99858 8.49787Z"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height'],
};
</script>

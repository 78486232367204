import VueCookies from 'vue-cookies';
import { HTTP } from '@/http';
import { objToQuery } from '@/helpers/index';

const lessons = {
  namespaced: true,
  state: {
    requests: {},
    ids: [],
    tasks: [],
    passedLessons: [],
    filter: {
      grades: null,
      subjects: null,
    },

    // это список уроков доступных для гостей по qr коду
    lessonGuestQr: [
      'tatar1_6',
      'tatar1_7',
      'tatar_2_3',
      'tatar_2_5',
      'tatar3_6',
      'geog_5_7',
      'geog_6_5',
      'geog_7_10',
      'eng_2_20',
      'eng_3_7',
      'eng_4_12',
      'eng_5_49',
      'eng_06_02',
      'eng_7_2',
      'eng_8_1',
      'eng_9_10',
      'mat_5_38',
      'mat_5_12',
      'mat_6_27',
      'alg_7_8',
      'Geo_7_58',
      'inf_7_15',
      'inf_7_18',
    ],
  },
  mutations: {
    SET_FILTER_SUBJECTS: (state, { subjects }) => {
      state.filter.subjects = [...subjects];
    },
    SET_FILTER_GRADES: (state, { grades }) => {
      state.filter.grades = grades;
    },
    FETCH_LESSONS: (state, payload) => {
      state.ids = [...new Set([...payload])];
    },
    SAVE_REQUEST: (state, { query, ids }) => {
      if (state.requests[query]) {
        state.requests[query] = [...state.requests[query], ...ids];
      } else {
        state.requests[query] = ids;
      }
    },
    FETCH_ASSIGN_LESSONS: (state, payload) => {
      state.tasks = [...new Set([...payload])];
    },
    REMOVE_ASSIGN_LESSON: (state, id) => {
      const idx = state.tasks.indexOf(id);
      if (idx !== -1) {
        state.tasks.splice(idx, 1);
      }
    },
    REMOVE_LESSON: (state, id) => {
      const idx = state.ids.indexOf(id);
      if (idx !== -1) {
        state.ids.splice(idx, 1);
      }
    },
    SET_PASSED_LESSONS: (state, payload) => {
      state.passedLessons = [...new Set(state.passedLessons.concat(payload))];
    },
    RESET_PASSED_LESSONS: (state) => {
      state.passedLessons = [];
    },
  },
  getters: {
    lessons(state, getters, rootState) {
      return state.ids.map((_id) => {
        const lesson = rootState.DB.lessons[_id];
        if (lesson) return lesson;
        return { _id, pending: true };
      });
    },
    tasksList(state, getters, rootState) {
      return state.tasks.map((_id) => rootState.DB.tasks[_id]);
    },
    passedLessons(state) {
      return state.passedLessons;
    },
  },
  actions: {
    setFilter({ commit }, { subjects, grades }) {
      if (subjects) {
        commit('SET_FILTER_SUBJECTS', { subjects });
      }
      if (grades) {
        commit('SET_FILTER_GRADES', { grades });
      }
    },
    async getList({ state, commit }, params = {}) {
      const { skip, ...restParams } = params;

      params.sort = { oldFormat: 1, idx: 1 };

      const query = objToQuery(restParams);

      /**
       * Проверяем, совершли ли мы такой заппрос,
       * если да то не делаем новый
       */
      const inState = state[query];
      if (inState && skip === 0) return Promise.resolve(inState);
      const oldLessons = HTTP.get('/crud/lesson/', { params }).then((response) => {
        if (response.data.models && VueCookies.get('guestQr')) {
          response.data.models = response.data.models.filter((el) => {
            let find = false;
            // eslint-disable-next-line
            for (const item of state.lessonGuestQr) {
              if (item === el.path) find = true;
            }
            return find;
          });
        }
        const { models } = response.data;
        // TODO прикрутить запрос на lessonRefactor
        if (models.length) {
          const ids = models.map((n) => n._id);
          commit('FETCH_LESSONS', ids);
          commit('SAVE_REQUEST', { query, ids });
          commit(
            'FETCH_MODELS',
            {
              type: 'lessons',
              payload: models,
            },
            { root: true }
          );

          return ids;
        }
        // TODO общий return
        return [];
      });

      const newLessons = HTTP.get('/api/constructor/lessons', { params }).then((response) => {
        if (response.data.models && VueCookies.get('guestQr')) {
          response.data.models = response.data.models.filter((el) => {
            let find = false;
            // eslint-disable-next-line
            for (const item of state.lessonGuestQr) {
              if (item === el.path) find = true;
            }
            return find;
          });
        }
        const { models } = response.data;
        // TODO прикрутить запрос на lessonRefactor
        if (models) {
          const ids = models.map((n) => n._id);
          commit('FETCH_LESSONS', ids);
          commit('SAVE_REQUEST', { query, ids });
          commit(
            'FETCH_MODELS',
            {
              type: 'lessons',
              payload: models,
            },
            { root: true }
          );

          return ids;
        }
        // TODO общий return
        return [];
      });
      const a = await newLessons;
      const b = await oldLessons;
      return [...a, ...b];
    },
    async updateLesson({ commit }, params) {
      const { data } = await HTTP.patch('/api/v2/lessons', params);
      if (data._id) {
        commit('FETCH_LESSONS', [data._id]);
        commit(
          'FETCH_MODELS',
          {
            type: 'lessons',
            payload: [data],
          },
          { root: true }
        );
      }
    },
    addLesson({ commit }, params) {
      HTTP.post('/crud/lesson/', { ...params }).then((response) => {
        if (response.data._id) {
          commit('FETCH_LESSONS', [response.data._id]);
          commit(
            'FETCH_MODELS',
            {
              type: 'lessons',
              payload: [response.data],
            },
            { root: true }
          );
        }
      });
    },
    fetchAssignLessons({ commit }, params) {
      return HTTP.get('/api/gethomeworks/', { params }).then((response) => {
        commit(
          'FETCH_ASSIGN_LESSONS',
          response.data.models.map((n) => n._id)
        );
        commit(
          'FETCH_MODELS',
          {
            type: 'tasks',
            payload: response.data.models,
          },
          { root: true }
        );
        commit('RESET_PASSED_LESSONS');
        return response.data;
      });
    },
    assignEljurLesson(context, params) {
      return HTTP.post('/api/homeworkeljurassign/', { ...params });
    },
    assignLesson({ commit }, params) {
      HTTP.post('/api/homeworkassign/', { ...params }).then((response) => {
        if (response.data._id) {
          commit(
            'FETCH_MODELS',
            {
              type: 'tasks',
              payload: [response.data],
            },
            { root: true }
          );
        }
      });
    },
    removeAssignLesson({ commit }, params) {
      const payload = {
        _id: params.lessonAssign,
      };
      HTTP.delete('/api/homeworkassign/', { data: { ...payload } })
        .then(() => {
          commit('REMOVE_ASSIGN_LESSON', params._id);
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
    getLesson(context, params) {
      return HTTP.get('/crud/lesson/', { ...params }).then((response) => response.data.models);
    },
    removeLesson({ commit }, params) {
      return HTTP.delete('/crud/lesson/', { data: { ...params } })
        .then(() => {
          commit('REMOVE_LESSON', params._id);
          commit('REMOVE_LIST_ITEM', { type: 'lessons', id: params._id }, { root: true });

          return params._id;
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
    fetchAssignStatuses({ commit }, params) {
      return HTTP.get('/api/assignstatus/', { params }).then((response) => {
        commit('SET_PASSED_LESSONS', response.data.assigned);
      });
    },
  },
};
export default lessons;

import { normalizeData } from '@/helpers/index';

export default {
  SET_LAYOUT(state, layout = 'BaseLayout') {
    state.layout = layout;
  },
  ADD_PENDING(state, ids) {
    ids.forEach((id) => {
      state.pending[id] = true;
    });
  },
  DELETE_STUDENT: (state, studentId) => {
    delete state.students[studentId];
  },
  DELETE_STUDENT_FROM_STUDYCLASS: (state, { studentId, studyClassId }) => {
    state.DB.classes[studyClassId].students = state.DB.classes[studyClassId].students.filter((id) => id !== studentId);
  },
  SET_AGREED(state, value) {
    state.isAgreed = value;
    state.DB.users[state.auth.user].agreed = value;
  },
  ADD_TO_STORE(state, { type, payload }) {
    state.DB[type] = { ...state.DB[type], ...payload };
  },
  FETCH_MODELS(state, { type, payload }) {
    let data = {};
    if (type === 'users') {
      data = normalizeData(
        payload.map((n) => {
          n.fullName = `${n.lastName} ${n.firstName}`;
          return n;
        })
      );
    } else {
      data = normalizeData(payload);
    }

    state.DB[type] = { ...state.DB[type], ...data };
  },
  FETCH_ACTIVITY(state, payload) {
    state.DB.activities = { ...state.DB.activities, ...payload };
  },
  SET_ANSWER(state, payload) {
    const { question, lesson, update } = payload;
    const currentExam = state.DB.activities[lesson];

    currentExam[question] = {
      ...currentExam[question],
      ...update,
    };
  },
  SET_REDIRECT(state, payload) {
    state.redirect = payload;
  },
  REMOVE_LIST_ITEM(state, { type, id }) {
    delete state.DB[type][id];
  },
  TOGGLE_FULL_SCREEN(state, isFullScreen) {
    state.isFullScreen = isFullScreen;
  },
  SET_FULL_SCREEN_FZ(state, size) {
    state.fullScreenFontSize = size;
  },
  UPDATE_ROUTER_SESSION(state, url) {
    state.routerSession = [url, ...state.routerSession].slice(0, state.routerSessionLimit);
  },
  UPDATE_MODELS_ITEM(state, { type, id, payload }) {
    state.DB[type][id] = { ...state.DB[type][id], ...payload };
  },
  SET_UPDATES_CLASS: (state, payload) => {
    state.DB.classes[payload._id] = { ...payload };
  },
  SET_MODELS: (state, { type, payload }) => {
    const data = {};
    if (type === 'subjects' || type === 'lessons') {
      // eslint-disable-next-line no-restricted-syntax
      for (const s of payload) {
        data[s._id] = s;
      }
    }
    state.DB[type] = { ...state.DB[type], ...data };
  },
  SET_GLOBAL_NOSCROLL(state, payload) {
    if (payload) {
      const root = document.documentElement;
      root.classList.add('noscroll');
    } else {
      const root = document.documentElement;
      root.classList.remove('noscroll');
    }
  },
  SET_WINDOW_WIDTH(state, windowWidth) {
    state.windowWidth = windowWidth;
  },
};

<template>
  <div class="modal">
    <div class="card">
      <div class="card__error">{{ showMessageByStatus }}</div>
      <div class="card__button" @click="returnToEducont">Вернутьcя на Educont</div>
      <div class="card__button" @click="closeWindow">{{ $t('Закрыть') }}</div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'ModalStatusUserEducont',
  data() {
    return {
      pending: true,
      statusMessage: {
        NOT_ACTIVE: 'Ваш аккаунт Educont был удален, поэтому доступ к контенту ограничен',
        NO_SCHOOL:
          'Чтобы получить доступ к контенту, укажите учебную информацию в личном кабинете Educont и дождитесь подверждения от общеобразовательного учреждения.',
        NOT_ACTUAL: 'Чтобы получить доступ к контенту, подтвердите учебную информацию в личном кабинете Educont',
        NOT_APPROVED: 'Доступ ограничен в связи с отклонением аккаунта Образовательной Организацией',
        NONE: 'Доступ ограничен. Дождитесь подверждения аккаунта образовательной организацией',
        GRADUATE: 'Доступ к контенту ограничен в связи с окончанием общеобразовательного учреждения',
      },
    };
  },
  props: {
    status: String,
  },
  computed: {
    ...mapGetters('authEducont', ['getStatus']),
    showMessageByStatus() {
      return this.statusMessage[this.status];
    },
  },
  methods: {
    ...mapMutations('authEducont', ['SET_STATUS']),
    returnToEducont() {
      window.location.href = 'https://sso.preprod.educont.ru/';
    },
    closeWindow() {
      this.SET_STATUS('ACTIVE');
    },
    goToLoginPage() {
      this.$router.replace('/');
    },
  },
  async mounted() {
    await this.$store.dispatch('auth/logout');
  },
};
</script>

<style lang="less" scoped>
.account-select {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal {
  position: fixed;
  background: rgba(0, 0, 0, 0.363);
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}
.card {
  width: 50%;
  height: 50%;
  margin: 30px auto;
  max-width: 600px;
  border-radius: 20px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(46, 57, 153, 0.658);
  gap: 20px;
  margin: 30px auto;
  &__button {
    background: rgb(94, 126, 211);
    color: white;
    padding: 20px 30px;
    border-radius: 10px;
    text-align: center;
    margin: 0;
    cursor: pointer;
    &:hover {
      background: rgb(66, 96, 179);
    }
  }
  &__error {
    background: rgba(219, 70, 70, 0.877);
    color: rgb(255, 255, 255);
    border-radius: 20px;
    padding: 10px 20px;
  }
}
</style>

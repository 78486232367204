<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.2071 3.04289C13.5976 3.43342 13.5976 4.06658 13.2071 4.45711L7.66421 10L13.2071 15.5429C13.5976 15.9334 13.5976 16.5666 13.2071 16.9571C12.8166 17.3476 12.1834 17.3476 11.7929 16.9571L5.54289 10.7071C5.15237 10.3166 5.15237 9.68342 5.54289 9.29289L11.7929 3.04289C12.1834 2.65237 12.8166 2.65237 13.2071 3.04289Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    viewBox: String,
    width: Number,
    height: Number,
  },
};
</script>

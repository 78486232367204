<template>
  <div class="static-layout">
    <StaticHeader :toggleSidebar="toggleSidebar" :sidebarIsOpen="sidebarIsOpen" />
    <div class="static-layout__content">
      <StaticBodyLayout>
        <router-view></router-view>
      </StaticBodyLayout>
    </div>
  </div>
</template>

<script>
import StaticHeader from '@/components/Static/StaticHeader';
import StaticBodyLayout from '@/components/Static/StaticBodyLayout';

export default {
  name: 'StaticLayout',
  components: {
    StaticHeader,
    StaticBodyLayout,
  },
  data() {
    return {
      sidebarIsOpen: false,
    };
  },
  methods: {
    swipeHandler(direction) {
      if (direction === 'left') this.sidebarIsOpen = true;
    },
    toggleSidebar() {
      this.sidebarIsOpen = !this.sidebarIsOpen;
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.static-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  height: 100vh;
  background-image: linear-gradient(rgba(12, 153, 255, 0.36) 0%, rgba(70, 144, 255, 0) 50%);

  &__content {
    flex: 1;
    overflow: hidden;
    padding-top: 1px;

    .container {
      height: 100%;
    }
  }
}
</style>

<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1035_6599)">
      <path
        d="M8 -6.99382e-07C12.4184 -3.13114e-07 16 3.5816 16 8C16 12.4184 12.4184 16 8 16C3.5816 16 3.13114e-07 12.4184 6.99382e-07 8C1.08565e-06 3.5816 3.5816 -1.08565e-06 8 -6.99382e-07ZM8 6C8.69036 6 9.25 5.44036 9.25 4.75C9.25 4.05964 8.69036 3.5 8 3.5C7.30964 3.5 6.75 4.05964 6.75 4.75C6.75 5.44036 7.30964 6 8 6ZM8 13C8.55229 13 9 12.5523 9 12L9 8.5C9 7.94772 8.55229 7.5 8 7.5C7.44772 7.5 7 7.94771 7 8.5L7 12C7 12.5523 7.44772 13 8 13Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1035_6599">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: ['width', 'height'],
};
</script>

<template>
  <div
    v-if="getCurrentInterface === 'old'"
    class="base-modal"
    :style="[applyStyles, { '--circleVisibility': isCircleVisible }]"
    aria-modal="true"
    role="dialog"
    aria-labelledby="base-modal__title"
    tabindex="1"
    :modal-id="modalName"
  >
    <div class="base-modal__close" @click.stop="close" v-if="closable" role="button" aria-label="Закрыть модальное окно">
      <base-icon glyph="#icon-close" width="20px" />
    </div>
    <div class="base-modal__content">
      <div>
        <div class="base-modal__head">
          <div id="base-modal__title">{{ title }}</div>
          <div v-if="subtitle" id="base-modal__subtitle">
            <router-link v-if="subtitle.link" :to="subtitle.link">
              {{ subtitle.name }}
              <base-icon glyph="#icon-arrow-link" width="18px" />
            </router-link>
            <span v-else>{{ subtitle.name }}</span>
          </div>
          <div class="base-modal__head-btn">
            <slot name="modal-head-btn"></slot>
          </div>
        </div>
        <div class="base-modal__body">
          <slot name="modal-body"></slot>
          <div class="base-modal__error">
            <slot name="modal-error"></slot>
          </div>
        </div>
        <div>
          <slot name="modal-footer"></slot>
        </div>
      </div>
      <div class="base-modal__aside">
        <slot name="modal-aside"></slot>
      </div>
    </div>
  </div>
  <div
    v-else
    class="base-modal-refactor"
    :style="[applyStyles, { '--circleVisibility': isCircleVisible }]"
    aria-modal="true"
    role="dialog"
    aria-labelledby="base-modal-refactor__title"
    tabindex="1"
  >
    <div class="base-modal-refactor__close" v-on:click="close" v-if="closable" role="button" aria-label="Закрыть модальное окно">
      <base-icon glyph="#icon-close" width="20px" />
    </div>
    <div class="base-modal-refactor__content">
      <div>
        <div class="base-modal-refactor__head">
          <div id="base-modal-refactor__title">{{ title }}</div>
          <div v-if="subtitle" id="base-modal-refactor__subtitle">
            <router-link v-if="subtitle.link" :to="subtitle.link">
              {{ subtitle.name }}
              <base-icon glyph="#icon-arrow-link" width="18px" />
            </router-link>
            <span v-else>{{ subtitle.name }}</span>
          </div>
          <div class="base-modal-refactor__head-btn">
            <slot name="modal-head-btn"></slot>
          </div>
        </div>
        <div class="base-modal-refactor__body">
          <slot name="modal-body"></slot>
          <div class="base-modal-refactor__error">
            <slot name="modal-error"></slot>
          </div>
        </div>
        <div>
          <slot name="modal-footer"></slot>
        </div>
      </div>
      <div class="base-modal-refactor__aside">
        <slot name="modal-aside"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BaseModal',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: Object,
    },
    width: {
      type: String,
      default: '',
    },
    closable: {
      type: Boolean,
      default: true,
    },
    showCircle: {
      type: Boolean,
      default: true,
    },
    modalName: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('auth', ['getCurrentInterface']),
    applyStyles() {
      return {
        width: this.width,
      };
    },
    isCircleVisible() {
      return this.showCircle ? 'visible' : 'hidden';
    },
  },
  methods: {
    close() {
      if (!this.modalName) {
        this.$emit('vuedals:close');
      } else {
        this.$vuedals.close(this.modalName);
      }
      this.$emit('onClose');
    },
  },
};
</script>
<style lang="less" scoped>
@import '~less_vars';

#base-modal-refactor__title {
  font-family: 'SF Pro Display';
}

.base-modal-refactor {
  display: block;
  z-index: 1;

  &__close {
    position: absolute;
    top: 0;
    left: 100%;
    padding: 10px;

    &:hover {
      cursor: pointer;
      background: @primary-color;
    }

    .base-icon {
      --primaryIconColor: #ffffff;
      --secondaryIconColor: #ffffff;
    }
  }

  &__content {
    background: @modal-bg;
    display: flex;
    flex-direction: row;

    > div {
      flex: auto;
      position: relative;
      max-width: 100%;
    }
  }

  &:before {
    //content: '';
    width: 245px;
    height: 245px;
    border: 25px solid @secondary-color;
    border-radius: 50%;
    position: absolute;
    top: -45px;
    left: -45px;
    z-index: -1;
    visibility: var(--circleVisibility);
  }

  &:after {
    //content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: 18px;
    height: 18px;
    border-top: 20px solid @secondary-color;
    border-right: 21px solid transparent;
    visibility: var(--circleVisibility);
  }

  &__head {
    font-size: @h2-fz;
    //font-weight: bold;
    padding: 45px 35px 10px 35px;
    position: relative;

    #base-modal__subtitle {
      font-size: @h4-fz;
      font-weight: bold;
      margin-top: 15px;
    }

    &-btn {
      position: absolute;
      top: 45px;
      right: 35px;
    }
  }

  &__body {
    padding: 5px 35px 45px 35px;

    img {
      max-width: 100%;
    }

    .ql-align-center {
      text-align: center;
    }

    .ql-align-left {
      text-align: left;
    }

    .ql-align-right {
      text-align: right;
    }

    .ql-align-justify {
      text-align: justify;
    }

    table {
      border-collapse: collapse;

      td {
        border: 1px solid #ccc;
        padding: 5px;
      }
    }
  }

  &__error:not(:empty) {
    margin-top: 20px;
    font-size: @base-fz;
    color: @secondary-color;
  }

  &__aside {
    padding: 30px;
    max-width: 37%;
    background: @bg-blue-light;
    position: relative;

    &:empty {
      display: none;
    }

    .multiselect {
      min-width: 100px;
    }
  }

  @media (max-width: 768px) {
    height: 100%;

    &:after,
    &:before {
      display: none;
    }

    &__head {
      font-size: @h3-fz;
      padding: 50px 15px 10px 15px;

      &-btn {
        position: unset;
        padding: 5px 0;
      }
    }

    &__body {
      padding: 5px 15px 45px 15px;
    }

    &__close {
      right: 0;
      left: unset;
      background: @primary-color;
      z-index: 3;
    }

    &__content {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
</style>
<style lang="less">
@import '~less_vars';

.base-modal {
  display: block;
  z-index: 1;

  &__close {
    position: absolute;
    top: 0;
    left: 100%;
    padding: 10px;

    &:hover {
      cursor: pointer;
      background: @primary-color;
    }

    .base-icon {
      --primaryIconColor: #ffffff;
      --secondaryIconColor: #ffffff;
    }
  }

  &__content {
    background: @modal-bg;
    display: flex;
    flex-direction: row;

    > div {
      flex: auto;
      position: relative;
      max-width: 100%;
    }
  }

  &:before {
    content: '';
    width: 245px;
    height: 245px;
    border: 25px solid @secondary-color;
    border-radius: 50%;
    position: absolute;
    top: -45px;
    left: -45px;
    z-index: -1;
    visibility: var(--circleVisibility);
  }

  &:after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: 18px;
    height: 18px;
    border-top: 20px solid @secondary-color;
    border-right: 21px solid transparent;
    visibility: var(--circleVisibility);
  }

  &__head {
    font-size: @h2-fz;
    font-weight: bold;
    padding: 45px 35px 10px 35px;
    position: relative;

    #base-modal__subtitle {
      font-size: @h4-fz;
      font-weight: bold;
      margin-top: 15px;
    }

    &-btn {
      position: absolute;
      top: 45px;
      right: 35px;
    }
  }

  &__body {
    padding: 5px 35px 45px 35px;

    img {
      max-width: 100%;
    }

    .ql-align-center {
      text-align: center;
    }

    .ql-align-left {
      text-align: left;
    }

    .ql-align-right {
      text-align: right;
    }

    .ql-align-justify {
      text-align: justify;
    }

    table {
      border-collapse: collapse;

      td {
        border: 1px solid #ccc;
        padding: 5px;
      }
    }
  }

  &__error:not(:empty) {
    margin-top: 20px;
    font-size: @base-fz;
    color: @secondary-color;
  }

  &__aside {
    padding: 30px;
    max-width: 37% !important;
    background: @bg-blue-light;
    position: relative;

    &:empty {
      display: none;
    }

    .multiselect {
      min-width: 100px;
    }
  }

  @media (max-width: 768px) {
    height: 100%;

    &:after,
    &:before {
      display: none;
    }

    &__head {
      font-size: @h3-fz;
      padding: 50px 15px 10px 15px;

      &-btn {
        position: unset;
        padding: 5px 0;
      }
    }

    &__body {
      padding: 5px 15px 45px 15px;
    }

    &__close {
      right: 0;
      left: unset;
      background: @primary-color;
      z-index: 3;
    }

    &__content {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
</style>

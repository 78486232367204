import BaseSvg from '@/components/Base/BaseSvg';
import BaseIcon from '@/components/Base/BaseIcon';
import BaseSpinner from '@/components/Base/BaseSpinner';
import BaseButton from '@/components/Base/BaseButton';
import BaseLabel from '@/components/Base/BaseLabel';
import BaseSearch from '@/components/Base/BaseSearch';
import BaseInput from '@/components/Base/BaseInput';
import BaseCheckbox from '@/components/Base/BaseCheckbox';
import BaseRadio from '@/components/Base/BaseRadio';
import BaseModal from '@/components/Base/BaseModal';
import BaseTag from '@/components/Base/BaseTag';
import BaseCollapsing from '@/components/Base/BaseCollapsing';
import BaseScroll from '@/components/Base/BaseScroll';
import BaseScrollTo from '@/components/Base/BaseScrollTo';
import BaseProgressbar from '@/components/Base/BaseProgressbar';
import BaseToggle from '@/components/Base/BaseToggle';
import BaseFooter from '@/components/Base/BaseFooter';
import BaseWysiwyg from '@/components/Base/BaseWysiwyg';
import GapInput from '@/components/Lesson/GapInput';
import GapInputBlocklog from '@/components/Lesson/GapInputBlocklog';
import RefactorBaseButton from '@/components/NewComponents/RefactorBaseButton';
import NewBaseCheckBox from '@/components/NewComponents/NewBase/NewBaseCheckBox';
import NewBaseTag from '@/components/Base/NewBaseTag';
import Vue from 'vue';

const index = {
  BaseSvg,
  BaseIcon,
  BaseSpinner,
  BaseButton,
  BaseLabel,
  BaseSearch,
  BaseInput,
  BaseCheckbox,
  BaseRadio,
  BaseModal,
  BaseTag,
  BaseCollapsing,
  BaseScroll,
  BaseScrollTo,
  BaseProgressbar,
  BaseToggle,
  BaseFooter,
  BaseWysiwyg,
  GapInput,
  GapInputBlocklog,
  RefactorBaseButton,
  NewBaseCheckBox,
  NewBaseTag,
};

function registrateBaseComponents() {
  Object.keys(index).forEach((name) => {
    Vue.component(name, index[name]);
  });
}

export default {
  registrateBaseComponents,
};

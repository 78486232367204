import { render, staticRenderFns } from "./AudioBtn.vue?vue&type=template&id=713cecba&"
import script from "./AudioBtn.vue?vue&type=script&lang=js&"
export * from "./AudioBtn.vue?vue&type=script&lang=js&"
import style0 from "./AudioBtn.vue?vue&type=style&index=0&id=713cecba&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <div class="base-scroll" :class="applyClasses" :style="applyHeight">
    <VuePerfectScrollbar
      ref="pfScroll"
      :style="applyStyles"
      :settings="settings"
      @ps-y-reach-end="reachEndHandle"
      @ps-scroll-y="scrollHandle"
    >
      <slot name="content" />
    </VuePerfectScrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { toBemModifer } from '@/helpers/index';

export default {
  name: 'BaseScroll',
  components: {
    VuePerfectScrollbar,
  },
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: 'auto',
    },
    maxHeight: {
      type: String,
      default: 'unset',
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'default',
    },
    variant: {
      type: String,
      default: 'vertical',
    },
    scrollOffset: {
      type: Number,
      default: 20,
    },
    scrollToBehavior: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      settings: {
        useBothWheelAxes: true,
        wheelPropagation: true,
      },
    };
  },
  computed: {
    applyStyles() {
      return {
        width: this.variant === 'vertical' ? `calc(${this.width} + ${this.scrollOffset}px)` : this.width,
        height: this.variant === 'horizontal' ? `calc(${this.height} + ${this.scrollOffset}px)` : this.height,
        maxHeight: this.variant === 'horizontal' ? `calc(${this.maxHeight} + ${this.scrollOffset}px)` : this.maxHeight,
      };
    },
    applyHeight() {
      return {
        height: this.variant === 'horizontal' ? `calc(${this.height} + ${this.scrollOffset}px)` : this.height,
        maxHeight: this.variant === 'horizontal' ? `calc(${this.maxHeight} + ${this.scrollOffset}px)` : this.maxHeight,
      };
    },
    applyClasses() {
      const { theme, variant } = this;
      const baseClass = 'base-scroll';
      return [...toBemModifer(theme, baseClass, 'theme'), ...toBemModifer(variant, baseClass, 'variant')];
    },
  },
  methods: {
    scrollHandle(e) {
      if (this.shadow) {
        e.target.parentNode.classList.add('base-scroll--shadow-start', 'base-scroll--shadow-end');
      }
      this.$emit('onScrollHandle', e.target.scrollTop);
    },
    reachStartHandle(e) {
      if (this.shadow) {
        e.target.parentNode.classList.remove('base-scroll--shadow-start');
      }
    },
    reachEndHandle(e) {
      if (this.shadow) {
        e.target.parentNode.classList.remove('base-scroll--shadow-end');
      }

      // TODO: сделать нормальный детект на скролл
      this.$emit('baseScrollReachEnd');
    },
    scrollTo(id) {
      const target = document.getElementById(id);

      if (!target) return;

      this.$refs.pfScroll.$el.scrollTop = target.offsetTop;
    },
  },
  mounted() {
    const ps = this.$el.getElementsByTagName('section')[0];
    if (this.shadow) {
      if (this.variant === 'vertical') {
        ps.addEventListener('ps-scroll-y', this.scrollHandle);
        ps.addEventListener('ps-y-reach-start', this.reachStartHandle);
        ps.addEventListener('ps-y-reach-end', this.reachEndHandle);
      } else if (this.variant === 'horizontal') {
        ps.addEventListener('ps-scroll-x', this.scrollHandle);
        ps.addEventListener('ps-x-reach-start', this.reachStartHandle);
        ps.addEventListener('ps-x-reach-end', this.reachEndHandle);
      }

      if (ps.scrollHeight > ps.clientHeight || ps.scrollWidth > ps.clientWidth) {
        this.$el.classList.add('base-scroll--shadow-end');
      }
    }

    if (this.scrollToBehavior) {
      this.$parent.$on(this.scrollToBehavior, (id) => this.scrollTo(id));
    }
  },
};
</script>

<style lang="less">
@import '~less_vars';

.base-scroll {
  position: relative;
  scroll-behavior: smooth;

  &:before,
  &:after {
    content: '';
    position: absolute;
    transition: opacity 0.1s linear;
    opacity: 0;
    z-index: 1;
    pointer-events: none;
  }

  &--shadow-start {
    &:before {
      opacity: 0.6 !important;
    }
  }
  &--shadow-end {
    &:after {
      opacity: 0.6 !important;
    }
  }

  .ps {
    &__scrollbar-x {
      height: 7px !important;
      top: 0;

      &-rail {
        height: 7px !important;
      }
    }

    &__rail-x {
      height: 7px !important;
    }

    &__thumb-x {
      height: 7px !important;
      top: 0;
    }

    &__scrollbar-y {
      width: 7px !important;
      left: 0;

      &-rail {
        width: 7px !important;
      }
    }

    &__rail-y {
      width: 7px !important;
    }

    &__thumb-y {
      width: 7px !important;
      left: 0;
    }
  }

  //themes
  &--theme-default {
    .ps {
      &__scrollbar-x,
      &__scrollbar-y {
        border-radius: 0 !important;
        background-color: @gray-disabled !important;
        opacity: 1 !important;

        &-rail {
          opacity: 1 !important;
          background-color: @gray-light-2 !important;
          border-radius: 0 !important;
        }
      }
      &__rail-x,
      &__rail-y {
        border-radius: 0 !important;
        background-color: @gray-light-2 !important;
        opacity: 1 !important;
      }
      &__thumb-x,
      &__thumb-y {
        border-radius: 0 !important;
        background-color: @gray-disabled !important;
        opacity: 1 !important;
      }
    }
  }

  &--theme-light {
    .ps {
      &__scrollbar-x,
      &__scrollbar-y {
        border-radius: 0 !important;
        background-color: @gray-light-2 !important;
        opacity: 1 !important;

        &-rail {
          opacity: 1 !important;
          background-color: @bg-color !important;
          border-radius: 0 !important;
        }
      }
    }
  }

  //variants
  &--variant-vertical {
    &:before,
    &:after {
      height: 40px;
    }

    &:before {
      top: 0;
      left: 0;
      right: 0;
      background-image: linear-gradient(@modal-bg 70%, transparent);
    }
    &:after {
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(to top, @modal-bg 70%, transparent);
    }

    & > .ps {
      padding-right: 20px;
    }
  }

  &--variant-horizontal {
    white-space: nowrap;

    &:before,
    &:after {
      width: 40px;
    }

    &:before {
      top: 0;
      left: 0;
      bottom: 0;
      background-image: linear-gradient(to right, @modal-bg 70%, transparent);
    }
    &:after {
      top: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(to left, @modal-bg 70%, transparent);
    }

    & > .ps {
      padding-bottom: 20px;
    }
  }
}

@media print {
  .base-scroll {
    &--shadow-start {
      &:before {
        opacity: 0 !important;
      }
    }
    &--shadow-end {
      &:after {
        opacity: 0 !important;
      }
    }
  }
  .ps {
    overflow: scroll !important;
  }
}
</style>

<template>
  <div class="empty-layout">
    <header class="base-header">
      <div class="base-header__inner">
        <div class="base-header__row">
          <div class="base-header__column base-header__column--slot-logo">
            <div class="base-header__logo">
              <img src="../assets/logo-white.svg" alt="Open School" />
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="empty-layout__content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyLayout',
};
</script>
<style lang="less">
@import '~less_vars';

.empty-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;

  &__content {
    flex: 1;
    overflow: hidden;
    padding-top: 5px;

    .container {
      height: 100%;
    }
  }

  &::after {
    content: '';
    display: block;
    width: 350px;
    height: 350px;
    border: 40px solid @secondary-color;
    background-color: #fff;
    position: fixed;
    border-radius: 50%;
    bottom: -85px;
    right: -85px;
    z-index: -1;
  }

  @media (max-width: 1200px) {
    &::after {
      display: none;
    }
  }
}
</style>

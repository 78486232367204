<template>
  <header class="base-header">
    <div class="base-header__inner">
      <div class="base-header__row">
        <div class="base-header__column base-header__column--slot-logo">
          <a v-if="getRole === 'guest'" class="base-header__logo">
            <img src="../../assets/logo-white.svg" alt="Open School" />
          </a>
          <router-link v-else to="/" class="base-header__logo">
            <BaseLogo :width="180" :height="75" :white="true" />
          </router-link>
        </div>
        <div class="base-header__column base-header__column--slot-userblock">
          <user-block v-if="user" :user="user" :pending="!user" />
        </div>
        <div v-if="getRole !== 'guest'" class="base-header__column base-header__column--slot-menu">
          <div class="base-header__locale-changer" v-if="isKzPlatform"><LocaleChanger /></div>
          <button
            class="base-header__burger"
            :aria-label="$t('Открыть меню')"
            :aria-expanded="sidebarIsOpen"
            aria-controls="sidebar"
            @click="openSidebar"
          >
            <BurgerCloseIcon v-if="isKzPlatform" :clicked="burgerIconWasClicked" status="burger" />
            <BurgerCloseIcon v-else v-show="!sidebarIsOpen" :clicked="burgerIconWasClicked" status="burger" />
          </button>
        </div>
      </div>
    </div>

    <div
      id="sidebar"
      tabindex="-1"
      aria-hidden="true"
      role="navigation"
      class="base-header__sidebar"
      :class="{ isOpen: sidebarIsOpen }"
    >
      <div class="base-header__sidebar--slot-nav">
        <base-scroll height="100%" variant="vertical" :scrollOffset="0" :shadow="true">
          <div slot="content">
            <BaseNav :links="getNavs" v-if="nav" :toggleSidebar="toggleSidebar" />
          </div>
        </base-scroll>
      </div>
      <button class="base-header__close" :aria-label="$t('Закрыть меню')" aria-controls="sidebar" @click="toggleSidebar">
        <BurgerCloseIcon v-show="sidebarIsOpen" status="close" />
      </button>
      <div class="base-header__sidebar--slot-user">
        <user-block v-if="user" :user="user" :pending="!user" :toggleSidebar="toggleSidebar" />

        <button v-if="isOpenSchoolEmploye" @click="openNewPlatform" style="color: var(--primary-color)">
          {{ $t('изменить дизайн') }}
        </button>

        <div class="base-header__sidebar__footer" v-if="user && !$cookies.get('guestSubject')">
          <router-link class="base-header__sidebar__profile" :to="{ name: 'profile' }" @click.native="toggleSidebar">
            {{ $t('Профиль') }}
          </router-link>

          <button class="base-header__sidebar__logout" :aria-label="$t('Выйти из профиля')" @click="logout">
            {{ $t('Выход') }}
          </button>
        </div>
      </div>
    </div>
    <div class="base-header__overlay" @click="toggleSidebar"></div>
    <global-notification />
  </header>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import UserBlock from '@/components/UserBlock';
import BaseNav from '@/components/Base/BaseNav';
import BaseLogo from '@/components/NewComponents/BaseLogo';
import BurgerCloseIcon from '@/components/BurgerCloseIcon';
import GlobalNotification from '@/components/GlobalNotification';
import LocaleChanger from '@/components/NewComponents/LocaleChanger';
import config from '@/../config';

export default {
  name: 'BaseHeader',
  components: {
    GlobalNotification,
    UserBlock,
    BaseNav,
    BaseLogo,
    BurgerCloseIcon,
    LocaleChanger,
  },
  props: {
    sidebarIsOpen: Boolean,
    toggleSidebar: Function,
  },
  data() {
    return {
      burgerIconWasClicked: false,
      windowLocationUrl: window.location.origin,
      config,
      interfaceState: false,
    };
  },
  computed: {
    ...mapState('navs', {
      nav: (state) => state.nav,
    }),
    ...mapGetters('auth', {
      user: 'me',
    }),
    ...mapState('auth', ['timeCodeAuth']),
    ...mapGetters('auth', ['getCurrentInterface']),
    ...mapGetters('navs', ['getRole']),
    ...mapGetters('platform', ['isKzPlatform']),
    getNavs() {
      let accessNavs = this.nav;
      const linksHide = ['Мои уроки'];

      if (this.isKzPlatform) {
        accessNavs = this.nav.filter((n) => n.kz === true);
      }

      return accessNavs.filter(({ label }) => !linksHide.includes(label));
    },
    actualTimeCodeAuth() {
      return this.timeCodeAuth || this.getCookie('timeCodeAuth');
    },
    getCookie() {
      return (name) => {
        const matches = document.cookie.match(
          new RegExp(`(?:^|; )${name.replace(/([\\.$?*|{}\\(\\)\\[\]\\\\/\\+^])/g, '\\$1')}=([^;]*)`)
        );
        return matches ? decodeURIComponent(matches[1]) : undefined;
      };
    },
    isOpenSchoolEmploye() {
      const { email } = this.user;
      if (email) {
        const [, domain] = email.split('@');
        const accessesEmails = [
          'maddclif24@gmail.com',
          'alymkulov.almambet@gmail.com',
          'admin@admin.kz',
          'dildaabisheva@gmail.com',
          'kiyassov@gmail.com',
        ];
        return (
          domain.includes('2035') || domain.includes('edca') || domain.includes('amansultan') || accessesEmails.includes(email)
        );
      }
      return false;
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapMutations('auth', ['SET_INTERFACE']),
    openSidebar() {
      this.burgerIconWasClicked = true;
      this.toggleSidebar();
    },
    openNewPlatform() {
      localStorage.setItem('isNewPlatform', 'true');
      window.location.href = `${config.FRONT_2_URL}/lessons`;
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.base-header {
  padding-top: 20px;
  margin-bottom: 12px;

  &__inner {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: @container-desktop;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;

    &::before {
      display: block;
      width: 576px;
      height: 576px;
      background-color: @primary-color;
      content: '';
      position: absolute;
      border-radius: 50%;
      left: -224px;
      top: -339px;
      z-index: -1;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
  }

  &__column {
    flex-grow: 1;
    flex-shrink: 0;

    &--slot-logo {
      margin-right: 135px;
      flex-basis: 158px;
      max-width: 158px;
      flex-grow: 0;
    }

    &--slot-userblock {
      flex: 1 1 auto;
    }

    &--slot-menu {
      margin-left: auto;
      flex: 0 0 auto;
      display: flex;
      align-items: center;
    }
  }

  &__page-name {
    font-size: 22px;
    line-height: 47px;
    color: #a0a9bc;
    margin-right: 20px;
  }

  &__logo {
    display: block;
    margin-top: -15px;
    margin-left: -15px;
    img {
      max-width: 100%;
    }
  }

  &__burger {
    padding: 10px;
    margin-right: -10px;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }

  &__close {
    position: absolute;
    width: 35px;
    height: 35px;
    right: 15px;
    top: 35px;
    cursor: pointer;
    border: none;
    background: transparent;
    outline: none;
    .base-icon {
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &__sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    max-width: 100%;
    background: #fff;
    min-width: 320px;
    z-index: 7;
    transform: translateX(100%);
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    display: flex;
    flex-direction: column;

    &.isOpen {
      transform: translateX(0);
      box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
    }

    &--slot-nav {
      overflow: hidden;
      margin: 30px 60px auto 30px;
      position: relative;
      height: 100%;

      .base-nav {
        flex-direction: column;
        align-items: normal;
        margin: 0;
        max-height: 100%;

        &__item {
          padding: 10px 0;
        }

        &__item:first-child {
          margin-right: 40px;
        }
      }
    }

    &--slot-user {
      padding: 20px 30px;

      .user-block {
        &__content {
          flex-direction: column;
        }
        &__data {
          width: 100%;
        }

        &__image {
          display: none;
        }

        &__name {
          font-size: @h3-fz;
          font-weight: 600;
          color: @base-color;
          margin-bottom: 15px;
          line-height: 1.5;
          max-width: initial;
        }

        &__logout {
          display: none;
        }
      }
    }

    &__profile {
      color: @primary-color;
      text-decoration: none;
      padding: 5px;
    }

    &__logout {
      color: @secondary-color;
      border: none;
      background: transparent;
      cursor: pointer;
    }

    &__footer {
      font-size: @base-fz;
      font-weight: 600;
      display: flex;
      border-top: 1px solid #d8e1e7;
      margin-right: -30px;
      padding-right: 30px;
      padding-top: 20px;
      margin-top: 20px;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__overlay {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.6);
  }

  &__sidebar.isOpen + &__overlay {
    display: block;
  }

  &__locale-changer {
    margin-right: 10px;
    margin-bottom: 2px;

    @media (max-width: @mobile-width) {
      margin-right: 2px;
    }
  }

  @media (max-width: @small-desktop-width) {
    padding-top: 11px;
    padding-bottom: 6px;
    margin-bottom: 0;

    &__row {
      align-items: center;
    }

    &__column {
      &--slot-userblock {
        margin-right: auto;
      }
    }

    &__logo {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
      line-height: 0;

      img {
        height: 45px;
      }
    }

    &__page-name {
      display: none;
    }
    &__close {
      top: 25px;
    }
  }

  @media (max-width: @small-width) {
    &__inner {
      &::before {
        left: -280px;
      }
    }
    &__column {
      &--slot-logo {
        margin-right: 75px;
      }
      &--slot-userblock {
        .user-block {
          &__image {
            border: 3px solid @secondary-color;
          }
          &__data {
            display: none;
          }
        }
      }
    }
    &__close {
      top: 17px;
    }
  }

  @media (max-width: @mobile-width) {
    &__inner {
      &::before {
        left: -350px;
      }
    }
    &__column {
      &--slot-logo {
        margin-right: 5px;
      }
    }
    &__close {
      top: 17px;
      right: 18px;
    }
  }
}

[data-platform='kz'] {
  .base-header {
    &__inner {
      &::before {
        display: block;
        width: 876px;
        height: 876px;
        background-color: @primary-color;
        content: '';
        position: absolute;
        border-radius: 50%;
        left: -570px;
        top: -620px;
        z-index: -1;
      }
    }
  }
}

@media print {
  .base-header {
    .user-block {
      display: none;
    }

    &__inner {
      &::before {
        // display: none;
        box-shadow: inset -700px 0 200px #4507ca;
      }
    }
    &__column {
      &--slot-logo {
        flex-basis: 118px;
        max-width: 118px;
      }
      &--slot-menu {
        display: none;
      }
    }

    &__logo img {
      //height: 20px;
      width: 75px;
    }
  }
}
</style>

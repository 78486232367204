<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.7684 9.44331L20.1428 10.0689L13.8867 3.81306L14.5123 3.18638C16.086 1.64988 19.3149 1.53497 20.8062 3.19901C22.4224 4.77698 22.3846 7.86534 20.7684 9.44331ZM5.91844 21.9544H3.11956C2.44782 21.9544 2 21.5125 2 20.8497V18.0879C2 16.6517 2.24362 15.4423 3.25122 14.448L12.0098 5.68945L18.2659 11.9456L9.50733 20.7041C8.49973 21.6984 7.37387 21.9544 5.91844 21.9544Z"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height', 'title', 'id'],
};
</script>

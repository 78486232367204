import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import Vue from 'vue';

function init() {
  Sentry.init({
    Vue,
    dsn: 'https://c04b4d478eca41c98dc0a53c8b017b09@o4504066567962624.ingest.sentry.io/4504066575564801',
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    release: `openschool_client@${process.env.PACKAGE_VERSION}`,
    attachStacktrace: true,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // tracesSampleRate: 0.2,
    tracingOptions: {
      trackComponents: true,
    },
  });
}

export default {
  init,
};

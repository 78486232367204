<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.792893 1.04289C1.18342 0.652369 1.81658 0.652369 2.20711 1.04289L8.45711 7.29289C8.84763 7.68342 8.84763 8.31658 8.45711 8.70711L2.20711 14.9571C1.81658 15.3476 1.18342 15.3476 0.792894 14.9571C0.40237 14.5666 0.402369 13.9334 0.792894 13.5429L6.33579 8L0.792893 2.45711C0.402369 2.06658 0.402369 1.43342 0.792893 1.04289Z"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height'],
};
</script>

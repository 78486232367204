import { HTTP } from '@/http';

import Vue from 'vue';
import Vuex from 'vuex';
import mutations from '@/store/mutations';
import getters from '@/store/getters';
import actions from '@/store/actions';
import journal from '@/store/modules/Journal';
import lesson from '@/store/modules/Lesson';
import lessons from '@/store/modules/Lessons';
import auth from '@/store/modules/Auth';
import classEditor from '@/store/modules/ClassEditor';
import students from '@/store/modules/Students';
import studyGroups from '@/store/modules/StudyGroups';
import studyClasses from '@/store/modules/StudyClasses';
import navs from '@/store/modules/Navs';
import teachers from '@/store/modules/Teachers';
import tags from '@/store/modules/Tags';
import subjects from '@/store/modules/Subjects';
import administrators from '@/store/modules/Administrators';
import assistants from '@/store/modules/Assistants';
import schools from '@/store/modules/Schools';
import regions from '@/store/modules/Regions';
import cities from '@/store/modules/Cities';
import partners from '@/store/modules/Partners';
import platform from '@/store/modules/Platform';

// eslint-disable-next-line import/no-cycle
import statistics from '@/store/modules/Statistics';
import municipalityStats from '@/store/modules/MunicipalityStats';
import homework from '@/store/modules/Homework';
import feedback from '@/store/modules/Feedback';
import teacherjournal from '@/store/modules/TeacherJournal';
import chat from '@/store/modules/Chat';
import news from '@/store/modules/News';
import teacherMaterials from '@/store/modules/TeacherMaterials';
import checklist from '@/store/modules/Checklist';
import authEducont from '@/store/modules/AuthEducont';
import help from '@/store/modules/Help';
// new
import lessonsV2 from '@/store/modules/newModules/Lessons';
import classNumbers from '@/store/modules/ClassNumbers';
import subjectsV2 from '@/store/modules/newModules/Subjects';
import homeworksV2 from '@/store/modules/Homeworks';
import lessonsCatalog from '@/store/modules/LessonsCatalog';
import amplitude from '@/store/modules/Amplitude';
import diary from '@/store/modules/Diary';
import newschools from '@/store/modules/NewSchools';
import newcity from '@/store/modules/NewCity';
import xlsxTeachers from '@/store/modules/XlsxTeachers';
import xlsxStudents from '@/store/modules/XlsxStudents';

Vue.use(Vuex);

const modules = {
  xlsxStudents,
  xlsxTeachers,
  newcity,
  newschools,
  amplitude,
  lessonsCatalog,
  journal,
  lesson: lesson(HTTP),
  lessons,
  navs,
  auth,
  classEditor,
  authEducont,
  students,
  studyGroups,
  studyClasses,
  teachers,
  tags,
  subjects,
  requests: {
    students: {},
  },
  administrators,
  assistants,
  schools,
  regions,
  cities,
  partners,
  statistics,
  municipalityStats,
  homework,
  feedback: feedback(HTTP),
  teacherjournal: teacherjournal(HTTP),
  chat: chat(HTTP),
  news: news(HTTP),
  teacherMaterials: teacherMaterials(HTTP),
  checklist: checklist(HTTP),
  help,
  // new
  lessonsV2,
  classNumbers,
  subjectsV2,
  platform,
  homeworksV2,
  diary,
};

// define initial state
const state = {
  layout: 'BaseLayout',
  redirect: null,
  isAgreed: true,
  DB: {
    classes: {},
    groups: {},
    users: {},
    students: {},
    lessons: {},
    activities: {},
    teachers: {},
    tags: {},
    tagGroups: {},
    subjects: {},
    subjectsAreas: {},
    subjectsThemes: {},
    tasks: {},
    administrators: {},
    assistants: {},
    schools: {},
    regions: {},
    cities: {},
    partners: {},
  },
  isFullScreen: false,
  fullScreenFontSize: 0,
  pending: {},
  routerSession: [],
  routerSessionLimit: 100,
  windowWidth: null,
};

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules,
});

export default store;

<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM13.8321 6.1679C14.2226 6.55842 14.2226 7.19158 13.8321 7.58211L11.4142 10L13.8321 12.4179C14.2226 12.8084 14.2226 13.4416 13.8321 13.8321C13.4416 14.2226 12.8084 14.2226 12.4179 13.8321L10 11.4142L7.58211 13.8321C7.19158 14.2226 6.55842 14.2226 6.1679 13.8321C5.77737 13.4416 5.77737 12.8084 6.1679 12.4179L8.58579 10L6.1679 7.58211C5.77737 7.19158 5.77737 6.55842 6.1679 6.16789C6.55842 5.77737 7.19159 5.77737 7.58211 6.16789L10 8.58579L12.4179 6.1679C12.8084 5.77737 13.4416 5.77737 13.8321 6.1679Z"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height'],
};
</script>

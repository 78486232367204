<template>
  <div v-if="isNewInterface" style="font-family: 'SF Pro Display' !important">
    <router-view></router-view>
  </div>
  <div class="mainpage-layout" v-else>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MainPageLayout',
  computed: {
    ...mapGetters('auth', ['getCurrentInterface']),
    isNewInterface() {
      return this.getCurrentInterface === 'new';
    },
  },
};
</script>

<style lang="less">
[data-platform='ru'] .mainpage-layout {
  background-image: linear-gradient(rgba(12, 153, 255, 0.36) 0%, rgba(70, 144, 255, 0) 50vh);
}
</style>

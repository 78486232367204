<template>
  <div class="user-block-new" :class="{ 'is-pending': !user }">
    <div class="user-block-new__content">
      <div v-if="showNotification" @click="$emit('initNtf')" class="user-block-new-alert">
        <div v-if="false" class="user-block-new-alert__img-wrap">
          <div class="user-block-new-alert-notification">
            <div class="user-block-new-alert-notification__count">3</div>
          </div>
          <img src="../../assets/refactor/user-alert.svg" alt="user alert" class="user-block-new-alert__img" />
        </div>
      </div>

      <div class="user-block-new__image" @click="handleMenuToggle">
        <div class="user-block-new__image-placeholder">
          <span>{{ initials }}</span>
        </div>
        <div v-if="user" class="user-block-new__photo" :style="{ backgroundImage: `url(${user.photo})` }"></div>
        <!-- <div class="user-block__edit" title="Загрузить фотографию" @click="openModal">
          <base-icon glyph="#icon-camera" width="18px" height="18px" />
        </div>-->
      </div>

      <div class="user-block-new__data" v-if="user">
        <div class="user-block-new__name" @click="handleMenuToggle">{{ nameLable }}</div>
        <div class="user-block-new__status" v-if="user.roles && user.roles.length === 1">
          {{ $t(roleTextsData[user.roles[0]]) }}
        </div>

        <div class="user-block-new__status" v-if="user.roles && user.roles.length > 1">
          <multiselect
            :value="{ role: role, label: $t(this.roleTextsData[role].toLowerCase()) }"
            @select="onSelect"
            :preselect-first="true"
            :searchable="false"
            :show-labels="false"
            :options="roleTexts"
            :maxHeight="350"
            label="label"
          />
        </div>
      </div>
    </div>

    <div class="user-block-new__menu user-menu-new" v-if="menuOpen">
      <router-link class="user-menu-new__item" :to="{ name: 'profile' }">{{ $t('профиль') }}</router-link>
      <!--      <LocaleChanger class="user-menu-new__item" />-->
      <a class="user-menu-new__item" href="#" v-if="isOpenSchoolEmploye" @click="toggleInterface">{{ $t('изменить дизайн') }}</a>
      <a href="#" class="user-menu-new__item user-menu-new__item--primary" @click.prevent="logout">
        {{ $t('выйти') }}
        <img class="logout-icon" src="@/assets/refactor/logout-arrow.svg" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import Multiselect from 'vue-multiselect';
import { setCookie } from '@/helpers/cookie';
import config from '@/../config';

// import LocaleChanger from '@/components/RefactorComponents/LocaleChanger';

export default {
  name: 'UserBlockNew',
  components: {
    Multiselect,
    // LocaleChanger,
  },
  props: {
    pending: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: null,
    },
    toggleSidebar: Function,
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  computed: {
    ...mapGetters('navs', ['getRole']),
    ...mapGetters('auth', ['getCurrentInterface']),
    ...mapState('navs', {
      roleTextsData: (state) => state.roleTexts,
      role: (state) => state.role,
    }),

    isVisibleSettings() {
      return this.getRole === 'teacher' || this.getRole === 'student' || this.getRole === 'subs' || this.getRole === 'content';
    },
    roleTexts() {
      return this.user.roles.map((role) => ({
        role,
        label: this.$t(this.roleTextsData[role].toLowerCase()),
      }));
    },
    initials() {
      if (!this.user) return null;
      const { firstName = '', lastName = '' } = this.user;
      return firstName.slice(0, 1) + lastName.slice(0, 1);
    },
    nameLable() {
      if (!this.user) return null;
      const { firstName = '', lastName = '', secondName = '' } = this.user;
      return `${lastName.slice(0, 9)} ${firstName.slice(0, 1)}. ${secondName.length ? `${secondName.slice(0, 1)}` : ''}`;
    },
    showNotification() {
      return this.getRole === 'student';
    },
    isOpenSchoolEmploye() {
      const { email } = this.user;
      if (email) {
        const [, domain] = email.split('@');
        const accessesEmails = [
          'maddclif24@gmail.com',
          'alymkulov.almambet@gmail.com',
          'admin@admin.kz',
          'dildaabisheva@gmail.com',
          'kiyassov@gmail.com',
        ];
        return (
          domain.includes('2035') || domain.includes('edca') || domain.includes('amansultan') || accessesEmails.includes(email)
        );
      }
      return false;
    },
  },

  methods: {
    ...mapActions('auth', ['logout']),
    ...mapMutations('auth', ['SET_INTERFACE']),
    ...mapActions('navs', {
      changeRole: 'changeRole',
    }),

    toggleInterface() {
      if (window.location.hostname === 'localhost') {
        window.location.href = `${config.FRONT_2_URL}/lessons`;
      } else {
        localStorage.setItem('isNewPlatform', 'true');
        setCookie('isNewPlatform', 'true');
        window.location.href = `${window.location.origin}/lessons`;
      }
    },

    onSelect(value) {
      this.changeRole(value.role);
    },

    profileNavigate() {
      if (this.toggleSidebar) this.toggleSidebar();
      this.$router.push({ name: 'profile' }).catch(() => {});
    },

    handleMenuToggle() {
      this.menuOpen = !this.menuOpen;
    },
    handleCloseClick(e) {
      if (this.menuOpen && !e.target.closest('.user-block__image')) {
        this.menuOpen = false;
        document.removeEventListener('click', this.handleCloseClick);
      }
    },
    handleMenuClose() {
      this.menuOpen = false;
    },
  },
  mounted() {
    this.$bus.$on('clickBaseLayout', this.handleMenuClose);
  },
  beforeDestroy() {
    this.$bus.$off('clickBaseLayout', this.handleMenuClose);
  },
};
</script>

<!--suppress LessResolvedByNameOnly -->
<style lang="less">
@import '~less_vars';
.user-block-new {
  position: relative;
  &__content {
    display: flex;
    flex-flow: row;
    align-items: center; // flex-start;
  }

  &__data {
    flex-direction: column;
    justify-content: center;
  }

  &__photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  &-alert {
    margin-right: 20px;
    position: relative;
    cursor: pointer;

    &-notification {
      top: -3px;
      right: -3px;
      position: absolute;
      display: inline-block;
      //background-color: @wrong-color;
      border-radius: 100%;
      line-height: 0;
      //padding: 3px;

      &__count {
        padding: 50% 0;
        margin: 0 5px 0 5px;
        color: white;
        font-size: 11px;
        letter-spacing: -0.1em;
      }
    }
    &__img {
      &-wrap {
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 39px;
        height: 39px;
        background-color: white;
      }
    }
  }

  &__name {
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;
    white-space: nowrap;
  }

  &__status {
    font-size: 12px;
    display: inline-block;
  }
  &__image {
    position: relative;
    flex: 0 0 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 7px;
    cursor: pointer;

    &-placeholder {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--userBlockColor);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      padding-left: 1px;
      color: #efeef1;
      text-transform: uppercase;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        border: 3px solid @gray-disabled;
        border-radius: 50%;
        border-top-color: @secondary-color;
        animation: base-spinner-rotation 0.7s infinite linear both;
        opacity: 0;
      }
    }

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    img {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  &.is-pending &__image-placeholder::before {
    opacity: 1;
  }

  .multiselect {
    width: unset !important;
    height: unset !important;
    min-height: fit-content;
    background: transparent;
    display: inline-block;
    vertical-align: top;
    margin: 0 15px 0 0;

    &--active {
      .multiselect__select {
        &::before {
          transform: rotate(180deg) translate(-10px, -7px);
        }
      }
    }

    &__content-wrapper {
      left: auto;
      top: 115%;
      right: -60px;
      border-radius: 8px;
      color: @base-color;
      max-height: 315px !important;
      overflow-x: hidden;
    }

    &__content {
      padding: 7px;
      display: none;
      @media (max-width: @mobile-width) {
        display: none;
      }
    }

    &__single {
      padding-left: 0;
      color: var(--headerTextColor);
      background-color: transparent;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      width: auto;
      text-overflow: ellipsis;
      white-space: normal;
      overflow: hidden;
      margin-bottom: 0;
      max-width: 70px;
    }

    &__option {
      padding: 5px 16px;
      min-height: 30px;
      color: @text-black;

      &:hover {
        color: @text-black;
        text-shadow: 0 0 0.5px black;
      }
    }

    &__element {
      border-top: 0 solid transparent !important;
    }

    &__select {
      padding: 0 0;
      height: 1px;
      width: 1px;

      &:before {
        position: absolute;
        color: @base-color;
        content: '';
        background: url('../../assets/refactor/botArrow.svg') no-repeat;
        border-color: transparent;
        border-width: 10px;
        margin-top: 0;
        right: -15px;
        top: -5px;
      }
    }

    &__tags {
      min-height: fit-content;
      text-align: left;
      background: transparent;
      padding: 0 0 0 0;
      width: auto;
    }
  }

  .erg {
    .multiselect__select {
      &::before {
        background: url('../../assets/refactor/botArrowWhite.svg') no-repeat !important;
      }
    }
  }

  .asm {
    .multiselect__select {
      &::before {
        background: url('../../assets/refactor/botArrowWhite.svg') no-repeat !important;
      }
    }
  }

  @media (max-width: 1200px) {
    /* &__image {
      flex: 0 0 36px;
      max-width: 36px;
      height: 36px;
      align-self: center;
    } */
  }

  @media (max-width: @mobile-width) {
    &-alert {
      display: none;
    }
    &__status {
      display: none;
    }
    &__name {
      display: none;
    }
    /* &__image {
      flex: 0 0 36px;
      max-width: 36px;
      height: 36px;
      align-self: center;

      &-placeholder {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
      }
    } */
  }
}
.user-menu-new {
  position: absolute;
  top: 100%;
  right: -15px;
  width: 152px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
  border: 1px solid var(--borderColor);
  margin-top: 8px;
  padding-top: 11px;
  padding-bottom: 11px;
  z-index: 1;

  &__item {
    display: block;
    padding: 5px 16px;
    color: @base-color;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: @primary-color;
    }

    &--primary {
      color: @primary-color !important;

      &:hover {
        text-decoration: underline;
      }
    }

    .logout-icon {
      margin-left: 7px;
    }
  }
  @media (max-width: @mobile-width) {
    left: -200%;
  }
}
</style>

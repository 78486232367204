import { HTTP } from '@/http';

import axios from 'axios';
import config from '../../../config';

const { STATS_API } = config;

const statistics = {
  namespaced: true,
  state: {
    statistic: {},
  },
  getters: {
    statistic: (state) => state.statistic,
  },
  mutations: {
    SET_STATISTIC: (state, payload) => {
      state.statistic = payload;
    },
  },
  actions: {
    async fetchUserStatistics({ commit }, params) {
      try {
        const { data, status, statusText } = await HTTP.get('/api/getuserstatistic/', { params });
        if (status !== 200) throw Error(statusText);
        commit('SET_STATISTIC', data.studentsResults);
      } catch (error) {
        console.error(error.message);
      }
    },
    async fetchStudentStatistics({ commit }, params) {
      try {
        const { data, status, statusText } = await HTTP.get('/api/getstudentstatistics/', { params });
        if (status !== 200) throw Error(statusText);
        commit('SET_STATISTIC', data.studentsResults);
      } catch (error) {
        console.error(error.message);
      }
    },
    async sendEvent({ rootGetters }, params) {
      try {
        const userData = rootGetters['auth/me'];
        const param = {
          ...params,
          userId: userData._id,
          currentRole: rootGetters['navs/getRole'],
          email: userData.email,
          attachedTo: userData.attachedTo,
        };
        if (!STATS_API) {
          console.log('Статистика не отправляется: Не указан адрес сервера');
        } else {
          await axios.post(`${STATS_API}/stats/event-receiver`, param);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
export default statistics;

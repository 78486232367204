import { HTTP } from '@/http';

const ClassNumbers = {
  namespaced: true,
  state: {
    classNumbers: [],
    requests: {},
  },
  mutations: {
    SET_CLASS_NUMBERS: (state, classNumbers) => {
      state.classNumbers = classNumbers;
    },
    SET_CACHE_DATA: (state, { query, data }) => {
      state.requests[JSON.stringify(query)] = data;
    },
  },
  getters: {
    getClassNumbers(state) {
      return state.classNumbers;
    },
  },
  actions: {
    async fetchClassNumbers({ commit, state }, payload) {
      try {
        let cacheData = state.requests.classNumbers;
        if (cacheData) return cacheData;
        const response = await HTTP.get('/api/v2/fetchclassnumbers', { params: payload });
        cacheData = commit('SET_CACHE_DATA', { query: 'classNumbers', data: response.data });
        return response.data;
      } catch (e) {
        console.log(e);
      }
      return null;
    },
  },
};
export default ClassNumbers;

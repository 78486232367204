<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12ZM17.0975 9.86861C17.488 9.47809 17.488 8.84492 17.0975 8.4544C16.707 8.06388 16.0738 8.06388 15.6833 8.4544L10.7134 13.4243L8.67769 11.3886C8.28717 10.998 7.654 10.998 7.26348 11.3886C6.87295 11.7791 6.87295 12.4123 7.26348 12.8028L10.0063 15.5456C10.1938 15.7331 10.4482 15.8385 10.7134 15.8385C10.9786 15.8385 11.233 15.7331 11.4205 15.5456L17.0975 9.86861Z"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height', 'title', 'id'],
};
</script>

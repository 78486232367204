<template>
  <PageIndexOld v-if="!isNewInterface && !isKzPlatform" />
  <RefactorPageIndex v-else />
</template>

<script>
import { mapGetters } from 'vuex';
import PageIndexOld from '@/views/PageIndexOld';
import RefactorPageIndex from '@/components/NewComponents/RefactorPageIndex';

export default {
  name: 'PageIndex',
  components: {
    RefactorPageIndex,
    PageIndexOld,
  },
  computed: {
    ...mapGetters('platform', ['isKzPlatform']),
    ...mapGetters('auth', ['getCurrentInterface']),

    isNewInterface() {
      return this.getCurrentInterface === 'new';
    },
  },
};
</script>

<style></style>

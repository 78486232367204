import i18n from '@/lang/i18n';

export default (date, format, tz) => {
  // eslint-disable-next-line no-underscore-dangle
  let _date = new Date(date || '');

  if (Number.isInteger(tz)) {
    _date = new Date(_date.getTime() + _date.getTimezoneOffset() * 60 * 1000 + tz * 60 * 60 * 1000);
  }

  if (_date.toString() === 'Invalid Date') return date;

  const months = [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря',
  ];
  const days = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];

  const parts = {
    YYYY: (x) => x.getFullYear(),
    YY: (x) => `${x.getFullYear()}`.substr(2, 2),
    MMM: (x) => i18n.t(months[x.getMonth()]),
    MM: (x) => x.getMonth() + 1,
    DDD: (x) => i18n.t(days[x.getDay()]),
    DD: (x) => x.getDate(),
    hh: (x) => x.getHours(),
    mm: (x) => x.getMinutes(),
    ss: (x) => x.getSeconds(),
  };

  return format
    .split(new RegExp(`(${Object.keys(parts).join('|')})+`))
    .map((x) => (parts[x] ? `${parts[x](_date)}`.padStart(2, '0') : x))
    .join('');
};

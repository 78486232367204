<template>
  <div>
    <div id="app" :class="rootClasses" :data-platform="platform" :data-theme="theme">
      <component :is="layout" />
    </div>
    <ModalStatusUserEducont :status="getStatus" v-if="getStatus !== 'ACTIVE' && getStatus !== 'APPROVED'" />
    <div class="toggle-theme" v-if="isTestPlatform() && isOpenMenu">
      <div class="toggle-theme__item" @click="changeTheme('erg')">ERG</div>
      <div class="toggle-theme__item" @click="changeTheme('alm')">ALMATY</div>
      <div class="toggle-theme__item" @click="changeTheme('aman')">AMAN</div>
      <div class="toggle-theme__item" @click="changeTheme('os')">OS</div>
      <div class="toggle-theme__item" @click="isOpenMenu = false">Закрыть</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SimpleLayout from '@/layouts/SimpleLayout';
import StaticLayout from '@/layouts/StaticLayout';
import MainPageLayout from '@/components/MainPage/MainPageLayout';
import ModernLayout from '@/layouts/ModernLayout';
import EmptyLayout from '@/layouts/EmptyLayout';
import ModalStatusUserEducont from '@/components/Modal/ModalStatusUserEducont';
import LoginLayout from '@/layouts/LoginLayout';
import CleanLayout from '@/layouts/NewLayouts/CleanLayout';
import _ from 'lodash';
import { setCookie } from '@/helpers/cookie';
import { getCookie } from '@/helpers';
import BaseLayout from '@/layouts/BaseLayout';

export default {
  name: 'App',
  data() {
    return {
      isSendUserSource: false,
      isOpenMenu: true,
    };
  },
  components: {
    SimpleLayout,
    StaticLayout,
    MainPageLayout,
    ModernLayout,
    EmptyLayout,
    ModalStatusUserEducont,
    LoginLayout,
    BaseLayout,
    CleanLayout,
  },
  methods: {
    ...mapActions('amplitude', ['sendUserSource']),
    isTestPlatform() {
      const domen = window.origin;
      const domensTest = ['localhost', 'preprod'];
      return domensTest.some((domenTest) => domen.includes(domenTest));
    },
    changeTheme(name) {
      this.$store.commit('platform/SET_PLATFORM_THEME', name);
    },
    debounceOnResize: _.debounce(function debounceAction() {
      this.$store.commit('SET_WINDOW_WIDTH', window.innerWidth);
    }, 500),
    setTypePlatformCookie() {
      // кука для определения какой из фронтов показать
      const typePlatformInStorage = localStorage.getItem('isNewPlatform');
      const typePlatformInCookie = getCookie('isNewPlatform');
      if (typePlatformInStorage && !typePlatformInCookie) {
        setCookie('isNewPlatform', 'true', '1h');
        window.location.reload();
      }
      if (typePlatformInStorage && typePlatformInCookie) {
        setCookie('isNewPlatform', 'true', '1h');
      }
    },
  },
  computed: {
    ...mapGetters('auth', { user: 'me' }),
    ...mapGetters('authEducont', ['getStatus']),
    ...mapGetters('platform', ['isErgPlatform', 'isAlmatyPlatform', 'isKzPlatform', 'getPlatformType']),
    layout() {
      if (window.location.href.includes('new-lesson')) return 'CleanLayout';
      if (this.$route.meta.layout) {
        return this.$route.meta.layout;
      }
      return 'BaseLayout';
    },
    isFullScreen() {
      return this.$store.state.isFullScreen;
    },
    fullScreenFontSize() {
      return this.$store.state.fullScreenFontSize;
    },
    rootClasses() {
      const classes = {};
      if (this.isFullScreen) {
        classes['on-full-screen'] = true;
      }
      if (this.fullScreenFontSize) {
        classes[`fullscreen-fz-${this.fullScreenFontSize}`] = true;
      }
      return classes;
    },
    platform() {
      if (this.isKzPlatform) return 'kz';
      return 'ru';
    },
    theme() {
      return this.getPlatformType;
    },
  },
  created() {
    this.setTypePlatformCookie();
  },
  mounted() {
    this.$store.commit('SET_WINDOW_WIDTH', window.innerWidth);
    this.$nextTick(() => {
      window.addEventListener('resize', this.debounceOnResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.debounceOnResize);
  },
  updated() {
    if (this.user && !this.isSendUserSource) {
      this.sendUserSource({ userId: this.user._id, source: document.referrer });
      this.isSendUserSource = true;
    }
  },
};
</script>

<style lang="less">
@import '~less_vars';
@import '~./less/common.less';

.toggle-theme {
  position: fixed;
  top: 80px;
  right: 0;
  z-index: 10;

  &__item {
    padding: 5px 10px;
    background: #7373de;
    color: white;
    cursor: pointer;
    margin-top: 3px;
  }
}
.plyr__controls__item.plyr__progress__container {
  flex-grow: 1;
}

// https://github.com/redxtech/vue-plyr/issues/50
// Бага с либой, она прячет регулятор громкости
// ишью заведено уже, но пока не поправили,
// делать два форка, ради одной строчки в css чот в падлу...
.plyr .plyr__controls .plyr__volume {
  display: flex !important;
}
</style>

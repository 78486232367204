<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.41071 7.375H2.46429C1.37944 7.375 0.5 6.49556 0.5 5.41071V2.46429C0.5 1.37944 1.37944 0.5 2.46429 0.5H5.41071C6.49556 0.5 7.375 1.37944 7.375 2.46429V5.41071C7.375 6.49556 6.49556 7.375 5.41071 7.375ZM5.41071 15.7087H2.46429C1.37944 15.7087 0.5 14.8293 0.5 13.7445V10.798C0.5 9.71318 1.37944 8.83374 2.46429 8.83374H5.41071C6.49556 8.83374 7.375 9.71318 7.375 10.798V13.7445C7.375 14.8293 6.49556 15.7087 5.41071 15.7087ZM10.7968 15.7087H13.7432C14.8281 15.7087 15.7075 14.8293 15.7075 13.7445V10.798C15.7075 9.71318 14.8281 8.83374 13.7432 8.83374H10.7968C9.71196 8.83374 8.83252 9.71318 8.83252 10.798V13.7445C8.83252 14.8293 9.71196 15.7087 10.7968 15.7087ZM13.7432 7.375H10.7968C9.71196 7.375 8.83252 6.49556 8.83252 5.41071V2.46429C8.83252 1.37944 9.71196 0.5 10.7968 0.5H13.7432C14.8281 0.5 15.7075 1.37944 15.7075 2.46429V5.41071C15.7075 6.49556 14.8281 7.375 13.7432 7.375Z"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height', 'title', 'id'],
};
</script>

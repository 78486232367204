const diary = {
  namespaced: true,
  state: {
    homework: {
      deadlineToday: [],
      inProgress: [],
      notStarted: [],
    },
    subjectsFilter: [],
  },
};

export default diary;

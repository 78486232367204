import { HTTP } from '@/http';
import { objToQuery } from '@/helpers/index';

const assistants = {
  namespaced: true,
  state: {
    ids: [],
    requests: {},
  },
  mutations: {
    FETCH_ASSISTANTS: (state, payload) => {
      state.ids = [...new Set([...state.ids, ...payload])];
    },
    SAVE_REQUEST: (state, payload) => {
      state.requests[payload] = true;
    },
    REMOVE: (state, id) => {
      state.ids.splice(state.ids.indexOf(id), 1);
    },
  },
  getters: {
    list(state, getters, rootState) {
      return state.ids.map((id) => rootState.DB.assistants[id]);
    },
  },
  actions: {
    fetchAssistatns({ state, commit }, payload) {
      const params = { ...payload, roles: { $in: ['assistant'] } };
      const query = objToQuery(params);
      if (query && !state.requests[query]) {
        return HTTP.get('/crud/user/', { params }).then((response) => {
          if (response.data.models) {
            const data = response.data.models.map((n) => {
              n.fullName = `${n.lastName} ${n.firstName} ${n.secondName}`;
              return n;
            });
            commit(
              'FETCH_ASSISTANTS',
              response.data.models.map((n) => n._id)
            );
            commit(
              'FETCH_MODELS',
              {
                type: 'assistants',
                payload: response.data.models,
              },
              { root: true }
            );
            commit('SAVE_REQUEST', query);
            return data;
          }
          return null;
        });
      }
      return null;
    },
    addAssistants({ commit }, payload) {
      const params = Array.isArray(payload) ? payload : [payload];
      if (!params.length) return null;

      params.forEach((assistant) => {
        if (!assistant.roles) {
          assistant.roles = [];
        }
      });

      return HTTP.post('/crud/user/', [...params])
        .then((response) => {
          const { created = [], updated = [] } = response.data;
          const list = [...created, ...updated];

          if (list && list.length > 0) {
            list.forEach((assistant) => {
              const { firstName, lastName, secondName } = assistant;
              assistant.fullName = `${lastName} ${firstName} ${secondName}`;
            });

            const assistantsList = [];
            const usersList = [];

            list.forEach((a) => {
              a.roles.includes('assistant') ? assistantsList.push(a) : usersList.push(a);
            });

            if (assistantsList.length) {
              commit(
                'FETCH_ASSISTANTS',
                assistantsList.map((a) => a._id)
              );
              commit(
                'FETCH_MODELS',
                {
                  type: 'assistants',
                  payload: list,
                },
                { root: true }
              );
            }
            if (usersList.length) {
              usersList.forEach((user) => {
                commit('REMOVE', user._id);
                commit(
                  'REMOVE_LIST_ITEM',
                  {
                    type: 'assistants',
                    id: user._id,
                  },
                  { root: true }
                );
              });
            }
          }
          return list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeAssistant({ commit }, params) {
      HTTP.delete('/crud/user/', { data: params }).then((response) => {
        if (response.data.removed) {
          const id = params._id;
          commit('REMOVE', id);
          commit(
            'REMOVE_LIST_ITEM',
            {
              type: 'assistants',
              id,
            },
            { root: true }
          );
        }
      });
    },
  },
};

export default assistants;

<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.65818 5.0992L7.27212 2.28836C7.99434 1.72663 9.00566 1.72663 9.72788 2.28836L13.3418 5.0992C14.0726 5.66757 14.5 6.54149 14.5 7.46726V12.6667C14.5 13.7712 13.6046 14.6667 12.5 14.6667H10.3333C10.0572 14.6667 9.83333 14.4428 9.83333 14.1667V10.5C9.83333 10.2239 9.60948 10 9.33333 10H7.66667C7.39052 10 7.16667 10.2239 7.16667 10.5V14.1667C7.16667 14.4428 6.94281 14.6667 6.66667 14.6667H4.5C3.39543 14.6667 2.5 13.7712 2.5 12.6667V7.46726C2.5 6.54149 2.92742 5.66757 3.65818 5.0992Z"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height'],
};
</script>

export default {
  profile: 'Профиль',
  logout: 'Выйти',
  subjects: {
    russianHistory: 'История России',
    algebra: 'Алгебра',
    geometry: 'Геометрия',
    physics: 'Физика',
    literature: 'Литература',
    foreignLanguage: 'Иностранный язык',
    socialStudies: 'Обществознание',
    informaticsAndTechnologies: 'Информатика и ИКТ',
    geography: 'География',
    theWorldHistory: 'Всемирная история',
    biology: 'Биология',
    chemistry: 'Химия',
    maths: 'Математика',
    history: 'История',
    russianLanguage: 'Русский язык',
    informatics: 'Информатика',
    englishLanguage: 'Английский язык',
    simulators: 'Симуляторы',
    tatarLanguage: 'Татарский язык',
  },
  time: 'Время',
  today: 'Сегодня',
  week: 'Неделя',
  month: 'Месяц',
  day: 'День',
  january: 'Январь',
  february: 'Февраль',
  march: 'Март',
  april: 'Апрель',
  may: 'Май',
  june: 'Июнь',
  july: 'Июль',
  august: 'Август',
  september: 'Сентябрь',
  october: 'Октябрь',
  november: 'Ноябрь',
  december: 'Декабрь',
  kz: 'Қазақ',
  ru: 'Русский',
  pageHelpOld: {
    for: 'Руководство для',
    teacher: 'учителя',
    student: 'ученика',
    head: 'администратора',
  },
  LessonFirstSlide: {
    tasks: '0 заданий | {n} задание | {n} задания | {n} заданий',
    minutes: '0 минут | {n} минута | {n} минуты | {n} минут',
    attempts: '0 попыток | {n} попытка | {n} попытки | {n} попыток',
  },
};

const chat = () => ({
  namespaced: true,
  state: {
    unreadMessages: 3,
    chats: [
      {
        id: 1,
        participants: [
          {
            userId: 1,
            name: 'Алексей Сидоров',
          },
        ],
        messages: [
          {
            message:
              'Спасибо! Получил Ваш файлик, всё здорово, ознакомился. Думаю можно продолжать в том же духе. Когда планируете выполнить второе задание?',
            userId: 1,
            date: new Date(),
            delivered: true,
            read: true,
          },
          {
            message: 'Я как раз его закончил, прикрепил файл. Можете проверить',
            userId: 1,
            date: new Date(),
            delivered: true,
            read: true,
          },
        ],
      },
      {
        id: 2,
        participants: [
          {
            userId: 3,
            name: 'Николай Борисович Иванов',
          },
        ],
        messages: [],
      },
      {
        id: 3,
        participants: [
          {
            userId: 4,
            name: 'Олег Николаевич',
          },
        ],
        messages: [],
      },
      {
        id: 4,
        participants: [
          {
            userId: 5,
            name: 'Николай Борисович Троцкий',
          },
        ],
        messages: [],
      },
    ],
    currentChat: {
      id: 1,
      participants: [
        {
          userId: 1,
          name: 'Алексей Сидоров',
        },
      ],
      messages: [
        {
          message:
            'Спасибо! Получил Ваш файлик, всё здорово, ознакомился. Думаю можно продолжать в том же духе. Когда планируете выполнить второе задание?',
          userId: 1,
          userName: 'Алексей Сидоров',
          date: new Date(),
          delivered: true,
          read: true,
        },
        {
          message: 'Я как раз его закончил, прикрепил файл. Можете проверить',
          userId: '5b62f9d862ec763e60541a0e',
          userName: 'Александр',
          date: new Date(),
          delivered: true,
          read: true,
        },
      ],
    },
  },
  getters: {
    unreadMessages: (state) => state.unreadMessages,
    chats: ({ chats }) => chats,
    currentChat: ({ currentChat }) => currentChat,
  },
  mutations: {},
  actions: {},
});

export default chat;

export default (HTTP) => ({
  namespaced: true,
  state: {
    checklist: [],
    pending: null,
  },
  getters: {
    checklist: ({ checklist }) => checklist,
  },
  mutations: {
    SET_CHECKLIST(state, payload) {
      state.checklist = payload;
    },
  },
  actions: {
    async getChecklist({ commit }) {
      const response = await HTTP.get('/api/getchecklist/?group=intro');
      commit('SET_CHECKLIST', response.data.data);
    },
  },
});

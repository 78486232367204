/**
 * Возвращает массив бем-нейминг классов
 * @param {string[]} names - Массив строк или строка для модификаторов. e.q ['default', 'dark'] or 'default'
 * @param {string} suffix - суффикс модификатора e.q theme
 * @param {string} baseClass - базовый класс e.q base-button
 * @return {Array} - e.q ['base-button--theme-default', 'base-button--theme-dark']
 */
function toBemModifer(names, baseClass, suffix = 'unset') {
  let arr = [];
  if (names) {
    arr = Array.isArray(names) ? names : [names];
  }

  if (!arr.length) return [];
  return arr.map((el) => `${baseClass}--${suffix}-${el}`);
}

const compareArrsWithPrimitives = (a1, a2) => {
  const compare = a1.filter((i) => !a2.includes(i)).concat(a2.filter((i) => !a1.includes(i)));
  return compare;
};

/**
 * Вернет объект из стора, если есть или заглушку
 * @param {object} state
 * @param {string} property
 * @param {string} _id
 * @return {object}
 */
function getOrFetch(state, property, _id) {
  if (state.DB[property][_id]) {
    return {
      _id,
      pending: false,
      ...state.DB[property][_id],
    };
  }
  return {
    _id,
    pending: true,
  };
}

function normalizeData(input) {
  const result = {};
  input.forEach((n) => {
    result[n._id] = n;
  });
  return result;
}

function objToQuery(obj) {
  if (!obj || typeof obj !== 'object') {
    console.error('query mast be an Object. recieve: ', obj);
    return null;
  }

  return encodeURIComponent(JSON.stringify(obj));
}

/**
 *
 * @param {String} tpl - кусок сырой разметки с гапами
 * @param {*} data - объект который дажо мапнуть
 * @returns String - Вернет строку с замапаными данными
 */
export const gapReplacer = (tpl, data) => {
  // eslint-disable-next-line no-useless-escape
  const re = /\#\{([^}]*)\}/g;
  let match;
  // eslint-disable-next-line no-cond-assign
  while ((match = re.exec(tpl))) {
    const replacedString = data[match[1]] || 'значение не определено';
    tpl = tpl.replace(match[0], replacedString); // eslint-disable-line no-param-reassign
    re.lastIndex = 0;
  }
  return tpl;
};

export const isFullScreen = () => document.fullscreen || document.webkitIsFullScreen || document.mozFullScreen || false;

/**
 * Toggle fullscreen function who work with webkit and firefox.
 * @param {Object} event
 */
export const toggleFullScreen = (event) => {
  let element = document.body;
  if (event instanceof HTMLElement) {
    element = event;
  }
  const isFull = document.fullscreen || document.webkitIsFullScreen || document.mozFullScreen || false;

  element.requestFullScreen =
    element.requestFullScreen ||
    element.webkitRequestFullScreen ||
    element.mozRequestFullScreen ||
    function func() {
      return false;
    };
  document.cancelFullScreen =
    document.cancelFullScreen ||
    document.webkitCancelFullScreen ||
    document.mozCancelFullScreen ||
    function func() {
      return false;
    };

  isFull ? document.cancelFullScreen() : element.requestFullScreen();
};

// countFormatter(count, ['найдена', 'найдено', 'найдены']);
export const countFormatter = (number, titles) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
};

/**
 * Проигрывает кусочек видоса
 * @param {HTMLElement} video
 * @param {number[]} range
 * @param {number} interval
 */
function playVideoRange(video, [timeStart, timeEnd], interval = 100) {
  let timerId = video.getAttribute('data-timer-id');
  if (!timerId) {
    timerId = `video-timer-${Date.now()}`;
    video.setAttribute('data-timer-id', timerId);
  } else {
    clearTimeout(window[timerId]);
  }
  video.pause();
  video.currentTime = timeStart / 1000;

  function checkTime() {
    if (video.currentTime >= timeEnd / 1000) {
      video.pause();
      video.currentTime = timeEnd / 1000;
    } else {
      clearTimeout(window[timerId]);
      window[timerId] = setTimeout(checkTime, interval);
    }
  }

  const playPromise = video.play();

  // @see https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
  if (playPromise !== 'undefined') {
    playPromise
      .then(() => {
        window[timerId] = setTimeout(checkTime, interval);
      })
      .catch(() => {
        clearTimeout(window[timerId]);
      });
  } else {
    window[timerId] = setTimeout(checkTime, interval);
  }
  // Если вдруг рендж больше длины видео
  video.addEventListener('ended', () => {
    clearTimeout(window[timerId]);
  });
}

/**
 * Returns an array with arrays of the given size.
 *
 * @param myArray {Array} Array to split
 * @param chunkSize {Integer} Size of every group
 */
function chunkArray(myArray, chunkSize) {
  const results = [];

  while (myArray.length) {
    results.push(myArray.splice(0, chunkSize));
  }

  return results;
}

/**
 * Функция транслита
 *
 * @param text {string} Текст для перевода
 */
function transliterate(text) {
  return text
    .replace(/\u0401/g, 'YO')
    .replace(/\u0419/g, 'I')
    .replace(/\u0426/g, 'TS')
    .replace(/\u0423/g, 'U')
    .replace(/\u041A/g, 'K')
    .replace(/\u0415/g, 'E')
    .replace(/\u041D/g, 'N')
    .replace(/\u0413/g, 'G')
    .replace(/\u0428/g, 'SH')
    .replace(/\u0429/g, 'SCH')
    .replace(/\u0417/g, 'Z')
    .replace(/\u0425/g, 'H')
    .replace(/\u042A/g, '')
    .replace(/\u0451/g, 'yo')
    .replace(/\u0439/g, 'i')
    .replace(/\u0446/g, 'ts')
    .replace(/\u0443/g, 'u')
    .replace(/\u043A/g, 'k')
    .replace(/\u0435/g, 'e')
    .replace(/\u043D/g, 'n')
    .replace(/\u0433/g, 'g')
    .replace(/\u0448/g, 'sh')
    .replace(/\u0449/g, 'sch')
    .replace(/\u0437/g, 'z')
    .replace(/\u0445/g, 'h')
    .replace(/\u044A/g, "'")
    .replace(/\u0424/g, 'F')
    .replace(/\u042B/g, 'I')
    .replace(/\u0412/g, 'V')
    .replace(/\u0410/g, 'a')
    .replace(/\u041F/g, 'P')
    .replace(/\u0420/g, 'R')
    .replace(/\u041E/g, 'O')
    .replace(/\u041B/g, 'L')
    .replace(/\u0414/g, 'D')
    .replace(/\u0416/g, 'ZH')
    .replace(/\u042D/g, 'E')
    .replace(/\u0444/g, 'f')
    .replace(/\u044B/g, 'i')
    .replace(/\u0432/g, 'v')
    .replace(/\u0430/g, 'a')
    .replace(/\u043F/g, 'p')
    .replace(/\u0440/g, 'r')
    .replace(/\u043E/g, 'o')
    .replace(/\u043B/g, 'l')
    .replace(/\u0434/g, 'd')
    .replace(/\u0436/g, 'zh')
    .replace(/\u044D/g, 'e')
    .replace(/\u042F/g, 'Ya')
    .replace(/\u0427/g, 'CH')
    .replace(/\u0421/g, 'S')
    .replace(/\u041C/g, 'M')
    .replace(/\u0418/g, 'I')
    .replace(/\u0422/g, 'T')
    .replace(/\u042C/g, "'")
    .replace(/\u0411/g, 'B')
    .replace(/\u042E/g, 'YU')
    .replace(/\u044F/g, 'ya')
    .replace(/\u0447/g, 'ch')
    .replace(/\u0441/g, 's')
    .replace(/\u043C/g, 'm')
    .replace(/\u0438/g, 'i')
    .replace(/\u0442/g, 't')
    .replace(/\u044C/g, "'")
    .replace(/\u0431/g, 'b')
    .replace(/\u044E/g, 'yu');
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
}

export {
  compareArrsWithPrimitives,
  toBemModifer,
  getOrFetch,
  normalizeData,
  objToQuery,
  playVideoRange,
  chunkArray,
  transliterate,
  getCookie,
};

import { HTTP } from '@/http';

const LessonsCatalog = {
  namespaced: true,
  state: {
    subjAreas: [],
    subjThemes: [],
    subjects: [],
    requests: {
      subjects: {},
      subjAreas: {},
      subjThemes: {},
    },
  },
  mutations: {
    SET_SUBJECT_AREAS: (state, subjAreas) => {
      state.subjAreas = subjAreas;
    },
    SET_SUBJECT_THEMES: (state, subjThemes) => {
      state.subjThemes = subjThemes;
    },
    SET_SUBJECTS: (state, subjects) => {
      state.subjects = subjects;
    },
    SET_CACHE_DATA: (state, { type, query, data }) => {
      state.requests[type][JSON.stringify(query)] = data;
    },
  },
  getters: {
    getSubjAreas(state) {
      return state.subjAreas;
    },
    getSubjThemes(state) {
      return state.subjThemes;
    },
    getSubjects(state) {
      return state.subjects;
    },
  },
  actions: {
    async fetchSubjectAreas({ commit, state }, payload) {
      try {
        const cacheData = state.requests.subjAreas[JSON.stringify(payload)];
        if (cacheData) {
          return cacheData;
        }
        const response = await HTTP.get('/api/v2/fetchsubjectareas', { params: payload });
        commit('SET_CACHE_DATA', { type: 'subjAreas', query: payload, data: response.data });
        commit('SET_MODELS', { type: 'subjectsAreas', payload: response.data }, { root: true });
        return response.data;
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async fetchSubjectThemes({ state, commit }, payload) {
      try {
        const cacheData = state.requests.subjThemes[JSON.stringify(payload)];
        if (cacheData) {
          return cacheData;
        }
        const response = await HTTP.get('/api/v2/subject-themes', { params: payload });
        commit('SET_CACHE_DATA', { type: 'subjThemes', query: payload, data: response.data });
        return response.data;
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async fetchSubjects({ commit, state }, payload) {
      try {
        const cacheData = state.requests.subjects[JSON.stringify(payload)];
        if (cacheData) return cacheData;
        const response = await HTTP.get('/api/v2/fetchsubjects', { params: payload });
        commit('SET_CACHE_DATA', { type: 'subjects', query: payload, data: response.data });
        commit('SET_MODELS', { type: 'subjects', payload: response.data }, { root: true });
        return response.data;
      } catch (e) {
        console.log(e);
      }
      return null;
    },
  },
};
export default LessonsCatalog;

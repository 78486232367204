<template>
  <div class="main-subjects">
    <div class="main-subjects__title">{{ $t('Предметы') }}</div>
    <div class="main-subjects__subtitle">{{ $t('Доступные предметы') }}</div>
    <div class="main-subjects__list is-two-row">
      <main-subjects-item v-for="subjectItem in availableSubjects" :key="subjectItem.type" :subject="subjectItem">
      </main-subjects-item>
    </div>
    <div class="main-subjects__subtitle is-gray">{{ $t('Предметы в разработке') }}</div>
    <div class="main-subjects__list">
      <main-subjects-item v-for="subjectItem in unavailableSubjects" :key="subjectItem.type" :subject="subjectItem">
      </main-subjects-item>
    </div>
  </div>
</template>

<script>
import MainSubjectsItem from '@/components/MainSubjectsItem';

export default {
  name: 'MainSubjects',
  components: {
    MainSubjectsItem,
  },
  data() {
    return {
      subjects: [
        {
          title: 'Математика',
          subtitle: '5–6 класс',
          isAvailable: true,
          type: 'is-math',
        },
        {
          title: 'История России',
          subtitle: '6–11 класс',
          isAvailable: true,
          type: 'is-russian-history',
        },
        {
          title: 'Алгебра',
          subtitle: '7–11 класс',
          isAvailable: true,
          type: 'is-algebra',
        },
        {
          title: 'Геометрия',
          subtitle: '7–11 класс',
          isAvailable: true,
          type: 'is-geometry',
        },
        {
          title: 'Физика',
          subtitle: '7–11 класс',
          isAvailable: true,
          type: 'is-physics',
        },
        {
          title: 'Химия',
          subtitle: '8–11 класс',
          isAvailable: true,
          type: 'is-chemistry',
        },
        {
          title: 'Русский язык',
          subtitle: '1–11 класс',
          isAvailable: false,
          type: 'is-russian',
        },
        {
          title: 'Литература',
          subtitle: '1–11 класс',
          isAvailable: false,
          type: 'is-literature',
        },
        {
          title: 'Иностранный язык',
          subtitle: '1–11 класс',
          isAvailable: false,
          type: 'is-english',
        },
        {
          title: 'Всемирная история',
          subtitle: '5–11 класc',
          isAvailable: false,
          type: 'is-world-history',
        },
        {
          title: 'Биология',
          subtitle: '5–11 класс',
          isAvailable: false,
          type: 'is-biology',
        },
        {
          title: 'Обществознание',
          subtitle: '5–11 класс',
          isAvailable: false,
          type: 'is-social',
        },
        {
          title: 'Информатика и ИКТ',
          subtitle: '5–11 класс',
          isAvailable: false,
          type: 'is-it',
        },
        {
          title: 'География',
          subtitle: '6–11 класс',
          isAvailable: false,
          type: 'is-geography',
        },
      ],
    };
  },
  computed: {
    availableSubjects() {
      return this.subjects.filter((item) => item.isAvailable === true);
    },

    unavailableSubjects() {
      return this.subjects.filter((item) => item.isAvailable === false);
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';
.main-subjects {
  position: relative;

  &::before {
    display: block;
    content: '';
    position: absolute;
    width: 183px;
    height: 183px;
    border-radius: 50%;
    border: 20px solid #fc005c;
    top: 285px;
    left: -99px;
    z-index: -1;
  }

  &::after {
    display: block;
    position: absolute;
    content: '';
    width: 616px;
    height: 550px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url(../../assets/teacher-bg@2x.png);
    top: -45px;
    right: -91px;
    z-index: -1;
  }

  &__title {
    font-weight: bold;
    font-size: 50px;
    letter-spacing: 0.025em;
    color: #ffffff;
    margin-bottom: 40px;
  }

  &__subtitle {
    font-weight: bold;
    font-size: @subheader-fz;
    line-height: 23px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 47px;
    padding-left: 40px;
    position: relative;

    &.is-gray {
      color: rgba(176, 176, 176, 0.5);
    }

    &::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 5px solid @pink-color;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 3px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 27px;
    justify-content: space-between;

    &.is-two-row {
      width: 66%;
    }
  }

  &__list::after {
    content: '';
    flex: 0 31.8%;
  }

  @media (max-width: 768px) {
    &::before {
      display: none;
    }

    &__list {
      justify-content: space-between;

      &.is-two-row {
        width: 100%;
      }
    }

    &__list::after {
      content: '';
      flex: 0 48.5%;
    }
  }

  @media (max-width: 480px) {
    &::after {
      display: none;
    }

    &__title {
      margin-bottom: 20px;
      font-size: 35px;
    }

    &__subtitle {
      margin-bottom: 20px;
    }

    &__list::after {
      display: none;
    }
  }
}
</style>

<template>
  <div>
    <form action="#">
      <a
        role="button"
        class="auth-btn"
        :key="12"
        aria-label="Авторизация через Цифровой образовательный контент"
        :href="`${window.location.origin}/auth/api/educont/startsso`"
      >
        Цифровой образовательный контент
      </a>
      <a
        v-for="service in getServices"
        role="button"
        class="auth-btn"
        :key="service.oauth"
        :aria-label="`Авторизация через ${service.name}`"
        :href="service.oauth"
        >{{ service.name }}</a
      >
      <div v-show="showError" class="form-error">
        <p>Ошибка авторизации. Неверный код</p>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import config from '../../../config';

export default {
  name: 'LoginByService',
  data() {
    return {
      config,
      windowLocationUrl: window.location.origin,
    };
  },
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters('auth', ['getServices']),
    showError() {
      return this.error && this.error.type === 'code';
    },
  },
  async mounted() {
    this.$store.dispatch('auth/getOAuthServices');
  },
};
</script>

<style lang="less" scoped>
@import '~less_vars';
form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.auth-btn {
  flex-basis: 100%;
  padding: 20px 36px;
  background-color: @surface-accent;
  color: @text-white;
  font-weight: bold;
  border-radius: 16px;
  text-align: center;
  text-decoration: none;
}
.auth-btn:hover {
  background-color: @surface-secondary;
}
</style>

import { HTTP } from '@/http';

const lessons = {
  namespaced: true,
  state: {
    lessons: [],
    requests: {},
    navigationMode: 'list',
    favoriteLessons: [],
  },
  mutations: {
    PUSH_LESSONS: (state, data) => {
      state.lessons.push(...data);
    },
    CLEAR_LESSONS: (state) => {
      state.lessons = [];
    },
    SET_CACHE_DATA: (state, { query, data }) => {
      state.requests[JSON.stringify(query)] = data;
    },
  },
  getters: {
    getLessons(state) {
      return state.lessons;
    },
  },
  actions: {
    async fetchLessons({ commit, state }, payload) {
      try {
        let cacheData = state.requests[JSON.stringify(payload)];
        if (cacheData) return cacheData;
        const response = await HTTP.get('/api/v2/fetchlessons', { params: payload });
        cacheData = commit('SET_CACHE_DATA', { query: payload, data: response.data });
        commit('SET_MODELS', { type: 'lessons', payload: response.data.data }, { root: true });
        return response.data;
      } catch (e) {
        console.log(e);
      }
      return null;
    },
  },
};
export default lessons;

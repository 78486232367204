<!-- eslint-disable max-len -->
<template>
  <div class="main-goals">
    <div class="main-goals__img"></div>
    <div class="main-goals__title">{{ $t('Наша цель') }}</div>
    <div class="main-goals__text">
      {{
        $t(
          'дать учителям единую и простую в & nbsp;использовании платформу, которая предоставит все возможности электронного обучения XXI века'
        )
      }}
    </div>
    <BaseScrollTo href="#subscribe" className="main-goals__button" :offset="150" :duration="1000">
      {{ $t('Оформить подписку') }}</BaseScrollTo
    >
  </div>
</template>

<script>
export default {
  name: 'MainGoals',
  components: {},
};
</script>

<style lang="less">
@import '~less_vars';

.main-goals {
  &__title {
    font-weight: bold;
    font-size: 50px;
    color: #ffffff;
    margin-bottom: 16px;
    padding-top: 70px;
    letter-spacing: 0.025em;
  }

  &__text {
    font-weight: bold;
    font-size: 20px;
    line-height: normal;
    color: #ffffff;
    margin-bottom: 50px;
    width: 40%;
  }

  &__button {
    display: inline-block;
    font-weight: bold;
    border-radius: 5px;
    background-color: white;
    color: @pink-color;
    padding: 20px 38px;
    text-align: center;
    text-decoration: none;
  }

  &__img {
    display: block;
    position: absolute;
    width: 763px;
    height: 449px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url(../../assets/mb-goals@2x.png);
    right: -6px;
    top: 0;
  }

  @media (max-width: 1200px) {
    &__img {
      right: calc(763px - 100%);
    }
  }

  @media (max-width: 768px) {
    &__title {
      color: @secondary-color;
      margin-bottom: 40px;
    }

    &__text {
      width: 100%;
      color: #000;
      background-color: #fff;
      padding: 20px 20px;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.14);
      border-radius: 12px;
      font-size: 19px;
      margin-bottom: 25px;

      &::first-letter {
        text-transform: capitalize;
      }
    }

    &__button {
      width: 100%;
      display: inline-block;
      background-color: @primary-color;
      color: #fff;
    }

    &__img {
      display: none;
    }
  }

  @media (max-width: 480px) {
    &__title {
      margin-bottom: 25px;
      font-size: 35px;
    }
  }
}
</style>

/* eslint-disable max-len */
<template>
  <div class="form">
    <form action="#" @submit.prevent="submitHandler">
      <label class="form__label" for="email">{{ $t('Логин') }}</label>
      <refactor-base-input
        type="text"
        required
        id="email"
        name="email"
        @input="inputHandler"
        :placeholder="$t('Введите свой email')"
      ></refactor-base-input>
      <label class="form__label" for="password">{{ $t('Пароль') }}</label>
      <refactor-base-input
        type="password"
        required
        id="password"
        name="password"
        @input="inputHandler"
        :placeholder="$t('Введите свой пароль')"
      ></refactor-base-input>
      <div class="form__wrapper">
        <refactor-base-checkbox v-model="form.memorize">{{ $t('Запомнить меня') }}</refactor-base-checkbox>
        <router-link tag="div" class="form__forgot-password" :to="{ name: 'loginbyemail' }">
          {{ $t('Забыли пароль?') }}
        </router-link>
      </div>
      <refactor-base-button>{{ $t('Войти') }}</refactor-base-button>
      <div v-show="showError" class="form-error">
        <p>{{ $t('Неверный логин или пароль') }}</p>
      </div>
    </form>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import RefactorBaseInput from '@/components/NewComponents/RefactorBaseInput';
import RefactorBaseCheckbox from '@/components/NewComponents/RefactorBaseCheckbox';

export default {
  name: 'RefactorLoginByPass',
  components: { RefactorBaseCheckbox, RefactorBaseInput },
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
        memorize: true,
      },
    };
  },
  computed: {
    showError() {
      return this.error && this.error.type === 'login';
    },
  },
  methods: {
    ...mapActions('auth', ['authByLogin']),
    inputHandler(e) {
      const name = e.target.getAttribute('name');
      this.form[name] = e.target.value;
    },
    submitHandler() {
      const { email, password, memorize } = this.form;
      if (email && password) {
        this.authByLogin({ email, password, memorize });
      }
    },
  },
};
</script>

<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.00027 3.69967L3.00002 15.8233C3.00001 16.5497 3.39381 17.2189 4.02876 17.5717L11.029 21.4606C11.6331 21.7961 12.3675 21.7961 12.9716 21.4605L19.9713 17.5717C20.6062 17.2189 21 16.5497 21 15.8234V3.69968C21 2.9372 20.1809 2.45523 19.5144 2.82552L14.0292 5.87283C13.3943 6.22557 13.0005 6.89481 13.0005 7.62115V13.9999C13.0005 14.5522 12.5528 14.9999 12.0005 14.9999C11.4482 14.9999 11.0005 14.5522 11.0005 13.9999V7.62165C11.0005 6.89532 10.6067 6.22609 9.97179 5.87335L4.48593 2.82554C3.8194 2.45524 3.00029 2.93719 3.00027 3.69967Z"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height', 'title', 'id'],
};
</script>

<template>
  <component
    class="base-btn"
    :is="tag"
    :to="to"
    :href="href"
    :class="applyClasses"
    :disabled="disabled"
    :type="type"
    @click="$emit('click', $event)"
    ><slot>Кнопка</slot>
  </component>
</template>

<script>
import { toBemModifer } from '@/helpers/index';

export default {
  name: 'BaseButton',
  props: {
    tag: {
      type: String,
      default: 'button',
    },
    to: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    theme: {
      type: [String, Array],
      default: 'default',
    },
    size: {
      type: String,
      default: null,
    },
    variant: {
      type: [String, Array],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pending: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: String,
    },
    directionalRight: {
      type: Boolean,
      default: false,
    },
    directionalLeft: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    applyClasses() {
      const { pending, disabled, size, theme, variant, fontSize, directionalRight, directionalLeft } = this;
      const baseClass = 'base-btn';
      const result = [
        ...toBemModifer(theme, baseClass, 'theme'),
        ...toBemModifer(size, baseClass, 'size'),
        ...toBemModifer(variant, baseClass, 'variant'),
        ...toBemModifer(fontSize, baseClass, 'fz'),
      ];
      if (disabled) result.push('is-disabled');
      if (pending) result.push('is-pending');
      if (directionalRight) result.push(`${baseClass}--directional-right`);
      if (directionalLeft) result.push(`${baseClass}--directional-left`);

      return result;
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';
.base-btn {
  height: @btn-height;
  display: inline-block;
  background-color: @gray;
  text-decoration: none;
  outline: none;
  user-select: none;
  vertical-align: middle;
  line-height: @btn-height;
  padding: 0 35px;
  border: none;
  border-radius: 3px;
  font-family: @secondary-font;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    padding: 0 15px;
  }

  &:active {
    transform: translateY(2px);
  }

  .base-icon {
    vertical-align: initial;
    margin-right: 5px;
  }

  &.is-disabled {
    opacity: 0.4;
  }

  // themes
  &--theme-default {
    background-color: @primary-color;
    color: @button-text-color;
    --primaryIconColor: #fff;
    --secondaryIconColor: #fff;

    &:hover {
      background-color: @darken-primary-color;
    }
  }

  &--theme-disabled {
    background-color: @gray-disabled;
    color: @button-text-color;
  }

  &--theme-invert {
    background-color: @button-text-color;
    color: @primary-color;
    border: 1px solid @primary-color;
  }

  &--theme-invert-1 {
    background-color: @button-text-color;
    color: @secondary-color;
  }

  &--theme-invert-2 {
    background-color: @button-text-color;
    color: @primary-color;
  }

  &--theme-secondary {
    background-color: @secondary-color;
    transition: background 0.3s;
    color: @button-text-color;
    font-size: @base-fz;
    --primaryIconColor: #fff;
    --secondaryIconColor: #fff;

    &:hover {
      background-color: @darken-secondary-color;
    }
  }

  // variants
  &--variant-toggle {
    border-radius: 0;
    height: @btn-height-toggle;
    line-height: @btn-height-toggle;
    padding: 0 20px;
    font-weight: 500;

    &:active {
      transform: none;
    }
  }

  &--variant-arrow {
    position: relative;
    overflow: visible;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      bottom: 0;
      border-left: 10px solid @primary-color;
      border-top: 21px solid transparent;
      border-bottom: 21px solid transparent;
    }

    .base-icon {
      vertical-align: text-top !important;
    }

    @media (max-width: 768px) {
      &:after {
        border-left: 7px solid @primary-color;
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
      }
    }
  }

  &--variant-arrow-reverse {
    position: relative;
    overflow: visible;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      border-right: 10px solid @primary-color;
      border-top: 21px solid transparent;
      border-bottom: 21px solid transparent;
    }
  }

  &--variant-with-icon {
    position: relative;
    padding-left: 45px;
    padding-right: 25px;

    .base-icon {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
    @media (max-width: 768px) {
      padding: 0 15px 0 35px;

      .base-icon {
        left: 10px;
      }
    }
  }

  &--variant-only-icon {
    width: 42px;
    height: 32px;
    padding: 0;
    position: relative;

    .base-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &--variant-block {
    display: block;
    width: 100%;
    text-align: center;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &--variant-min {
    padding: 0 20px;
  }

  &--variant-rounded {
    border-radius: 13px;
  }

  // sizes
  &--size-large {
    line-height: @btn-height-large;
    height: @btn-height-large;
    font-size: @bigger-fz;
    font-weight: 500;
    @media (max-width: 768px) {
      font-size: @base-fz;
      height: @btn-height;
      line-height: @btn-height;
    }
  }

  // font-sizes
  &--fz-base {
    font-size: @base-fz;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  &--directional-right {
    clip-path: polygon(0% 0%, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0% 100%);
    padding-right: 40px;
  }

  &--directional-left {
    clip-path: polygon(10px 0%, 100% 0%, 100% 100%, 10px 100%, 0% 50%);
    padding-left: 40px;
  }
}
</style>

import VueCookies from 'vue-cookies';
import { HTTP } from '@/http';
import { objToQuery } from '@/helpers';

const subjects = {
  namespaced: true,
  state: {
    ids: [],
    requests: {},
    areas: [],
    links: [],
    themes: [],
    tempTheme: {},

    guestQrSubject: {
      'Татарский язык': [1, 2, 3],
      // eslint-disable-next-line
      'География': [5, 6, 7],
      // eslint-disable-next-line
      'Английский язык': [3, 4],
      // eslint-disable-next-line
      'Математика': [5, 6, 7],
      // eslint-disable-next-line
      'Информатика': [7],
    },
  },
  mutations: {
    FETCH_SUBJECTS: (state, payload) => {
      state.ids = [...new Set([...state.ids, ...payload])];
    },
    FETCH_AREAS: (state, payload) => {
      state.areas = [...new Set([...state.areas, ...payload])];
    },
    FETCH_LINKS: (state, payload) => {
      state.links = [...new Set([...state.links, ...payload])];
    },
    FETCH_THEMES: (state, payload) => {
      state.themes = [...new Set([...state.themes, ...payload])];
    },
    CREATE_THEME: (state, payload) => {
      state.tempTheme = payload;
    },
    SAVE_REQUEST: (state, { ids, query }) => {
      state.requests[query] = ids;
    },
    REMOVE_SUBJECT: (state, id) => {
      state.ids.splice(state.ids.indexOf(id), 1);
    },
    REMOVE_AREA: (state, id) => {
      state.areas.splice(state.areas.indexOf(id), 1);
    },
    REMOVE_LINK: (state, id) => {
      state.links.splice(state.links.indexOf(id), 1);
    },
    REMOVE_THEME: (state, id) => {
      state.themes.splice(state.themes.indexOf(id), 1);
    },
  },
  getters: {
    getSubjects(state, getters, rootState) {
      return state.ids.map((id) => rootState.DB.subjects[id]);
    },
    getSubjectsLinks(state, getters, rootState) {
      return state.links.map((id) => rootState.DB.subjectsLinks[id]);
    },
    getSubjectsAreas(state, getters, rootState) {
      return state.areas.map((id) => rootState.DB.subjectsAreas[id]);
    },
    getSubjectsThemes(state, getters, rootState) {
      return state.themes.map((id) => rootState.DB.subjectsThemes[id]);
    },
  },
  actions: {
    fetchSubjects({ commit, state }, params) {
      return HTTP.get('/crud/subject/', { params }).then((response) => {
        if (response.data.models && VueCookies.get('guestQr')) {
          response.data.models = response.data.models.filter((sb) => {
            let element = null;
            // eslint-disable-next-line
            for (const item of Object.keys(state.guestQrSubject)) {
              // eslint-disable-next-line
              if (item === sb.name) element = sb;
            }
            return element;
          });
        }
        if (response.data.models && VueCookies.get('guestSubject')) {
          response.data.models = response.data.models.filter(
            (sb) =>
              sb.name === VueCookies.get('guestSubject') ||
              (VueCookies.get('guestSubject') === 'Алгебра' && sb.name === 'Математика')
          );
        }
        commit(
          'FETCH_SUBJECTS',
          response.data.models.map((n) => n._id)
        );
        commit(
          'FETCH_MODELS',
          {
            type: 'subjects',
            payload: response.data.models,
          },
          { root: true }
        );
        return response.data.models;
      });
    },
    fetchSubjectsAreas({ commit }, params) {
      return HTTP.get('/crud/subjectarea/', { params }).then((response) => {
        commit(
          'FETCH_AREAS',
          response.data.models.map((n) => n._id)
        );
        commit(
          'FETCH_MODELS',
          {
            type: 'subjectsAreas',
            payload: response.data.models,
          },
          { root: true }
        );
        return response.data.models;
      });
    },
    fetchSubjectsLinks({ commit }, params) {
      return HTTP.get('/crud/subjectlink/', { params }).then((response) => {
        commit(
          'FETCH_LINKS',
          response.data.models.map((n) => n._id)
        );
        commit(
          'FETCH_MODELS',
          {
            type: 'subjectsLinks',
            payload: response.data.models,
          },
          { root: true }
        );
        return response.data.models;
      });
    },
    fetchSubjectsThemes({ state, commit }, params = {}) {
      const query = objToQuery(params);
      const inState = state.requests[query];

      if (inState) {
        return Promise.resolve(inState);
      }
      commit('SAVE_REQUEST', { ids: [], query });

      return HTTP.get('/crud/subjecttheme/', { params })
        .then((response) => {
          const { models } = response.data;
          const ids = models.map((n) => n._id);
          commit('SAVE_REQUEST', { ids, query });
          commit('FETCH_THEMES', ids);
          commit(
            'FETCH_MODELS',
            {
              type: 'subjectsThemes',
              payload: models,
            },
            { root: true }
          );
          return ids;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addSubjects({ commit }, params) {
      return HTTP.post('/crud/subject/', { ...params })
        .then((response) => {
          if (response.data) {
            commit('FETCH_SUBJECTS', [response.data._id]);
            commit(
              'FETCH_MODELS',
              {
                type: 'subjects',
                payload: [response.data],
              },
              { root: true }
            );
            return response.data;
          }
          return null;
        })
        .catch((error) => error);
    },
    removeSubject({ commit }, params) {
      HTTP.delete('/crud/subject/', { data: params }).then((response) => {
        if (response.data.removed) {
          const id = params._id;
          commit('REMOVE_SUBJECT', id);
          commit('REMOVE_LIST_ITEM', { type: 'subjects', id }, { root: true });
        }
      });
    },
    addSubjectsArea({ commit }, params) {
      return HTTP.post('/crud/subjectarea/', { ...params })
        .then((response) => {
          if (response.data) {
            commit('FETCH_AREAS', [response.data._id]);
            commit(
              'FETCH_MODELS',
              {
                type: 'subjectsAreas',
                payload: [response.data],
              },
              { root: true }
            );
            return response.data;
          }
          return null;
        })
        .catch((error) => error);
    },
    removeSubjectArea({ commit }, params) {
      HTTP.delete('/crud/subjectarea/', { data: params }).then((response) => {
        if (response.data.removed) {
          const id = params._id;
          commit('REMOVE_AREA', id);
          commit('REMOVE_LIST_ITEM', { type: 'subjectsAreas', id }, { root: true });
        }
      });
    },
    addSubjectsLink({ commit }, params) {
      return HTTP.post('/crud/subjectlink/', { ...params })
        .then((response) => {
          if (response.data) {
            commit('FETCH_LINKS', [response.data._id]);
            commit(
              'FETCH_MODELS',
              {
                type: 'subjectsLinks',
                payload: [response.data],
              },
              { root: true }
            );
            return response.data;
          }
          return null;
        })
        .catch((error) => error);
    },
    removeSubjectLink({ commit }, params) {
      HTTP.delete('/crud/subjectlink/', { data: params }).then((response) => {
        if (response.data.removed) {
          const id = params._id;
          commit('REMOVE_LINK', id);
          commit('REMOVE_LIST_ITEM', { type: 'subjectsLinks', id }, { root: true });
        }
      });
    },
    addSubjectsTheme({ commit }, params) {
      return HTTP.post('/crud/subjecttheme/', { ...params }).then((response) => {
        if (response.data._id) {
          commit('FETCH_THEMES', [response.data._id]);
          commit('CREATE_THEME', response.data);
          commit(
            'FETCH_MODELS',
            {
              type: 'subjectsThemes',
              payload: [response.data],
            },
            { root: true }
          );
          return response.data;
        }
        return null;
      });
    },
    removeSubjectTheme({ commit }, params) {
      HTTP.delete('/crud/subjecttheme/', { data: params }).then((response) => {
        if (response.data.removed) {
          const id = params._id;
          commit('REMOVE_THEME', id);
          commit('REMOVE_LIST_ITEM', { type: 'subjectsThemes', id }, { root: true });
        }
      });
    },
    fetchSubjectsThemesAndLessons(context, params = {}) {
      return HTTP.get('/api/lessonsearch/', { params })
        .then((response) => response.data)
        .catch((error) => {
          console.log(error);
        });
    },
  },
};

export default subjects;

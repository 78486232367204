<template>
  <div class="static-header">
    <div class="static-header__inner">
      <div class="static-header__row">
        <div class="static-header__column static-header__column--slot-logo">
          <router-link to="/" class="static-header__logo">
            <BaseLogo class="show-medium-and-up" :width="180" :height="75" :white="true" />
            <BaseLogo class="show-medium-and-down" :width="70" :isMobile="true" />
          </router-link>
        </div>
        <div class="static-header__column static-header__column--slot-contacts">
          <a class="static-header__phone" :href="`tel:${getContactInfo.phoneNumberFull}`">
            {{ getContactInfo.phoneCountryCode }}
            <span class="static-header__phone-accent">{{ getContactInfo.phoneAreaCode }}</span>
            {{ getContactInfo.phoneNumber }}
          </a>
          <br />
          <a class="static-header__email" :href="`mailto:${getContactInfo.emailFull}`">
            {{ getContactInfo.emailUser }}<span class="static-header__email-accent">@</span>{{ getContactInfo.emailDomain }}
          </a>
        </div>
        <div class="static-header__column static-header__column--slot-menu">
          <div class="static-header__locale-changer" v-if="isKzPlatform"><LocaleChanger /></div>
          <button
            class="static-header__burger"
            aria-label="Открыть меню"
            :aria-expanded="sidebarIsOpen"
            aria-controls="sidebar"
            @click="toggleSidebar"
          >
            <base-icon glyph="#icon-burger" width="26px" height="22px" class="static-header__burger-icon" />
          </button>
        </div>
      </div>
    </div>
    <div
      id="sidebar"
      class="static-header__sidebar"
      tabindex="-1"
      :class="{ isOpen: sidebarIsOpen }"
      aria-hidden="true"
      role="navigation"
    >
      <button class="static-header__close" aria-label="Закрыть меню" aria-controls="sidebar" @click="toggleSidebar">
        <base-icon glyph="#icon-close" width="30px" height="30px" />
      </button>
      <div class="static-header__sidebar--slot-nav">
        <base-scroll height="100%" variant="vertical" :scrollOffset="0" :shadow="true">
          <div slot="content">
            <StaticLinks :links="nav" :toggleSidebar="toggleSidebar" />
          </div>
        </base-scroll>
      </div>
    </div>
    <div class="static-header__overlay" @click="toggleSidebar"></div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import StaticLinks from '@/components/Static/StaticLinks';
import BaseLogo from '@/components/NewComponents/BaseLogo';
import LocaleChanger from '@/components/NewComponents/LocaleChanger';

export default {
  name: 'StaticHeader',
  components: {
    BaseLogo,
    StaticLinks,
    LocaleChanger,
  },
  props: {
    sidebarIsOpen: Boolean,
    toggleSidebar: Function,
  },
  computed: {
    ...mapState('navs', { nav: (state) => state.nav }),
    ...mapGetters('platform', ['isKzPlatform', 'isErgPlatform', 'isAlmatyPlatform', 'getContactInfo']),
  },
};
</script>

<style lang="less">
@import '~less_vars';

.static-header {
  &__inner {
    padding-top: 20px;
    width: 1310px;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto 22px;
    display: block;
    position: relative;
    z-index: 1;

    &::before {
      display: block;
      width: 876px;
      height: 876px;
      background-color: #4507ca;
      content: '';
      position: absolute;
      border-radius: 50%;
      left: -570px;
      top: -620px;
      z-index: -1;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
  }

  &__column {
    flex-grow: 1;
    flex-shrink: 0;

    &--slot-logo {
      margin-right: 135px;
      flex-basis: 158px;
      max-width: 158px;
      flex-grow: 0;
    }

    &--slot-contacts {
      width: 185px;
      flex: 1 1 auto;
    }

    &--slot-menu {
      flex: 0 0 auto;
      margin-top: 5px;
      display: flex;
      align-items: center;
    }
  }

  &__logo {
    display: block;
    margin-top: -15px;
    margin-left: -15px;
    height: 70px;

    img {
      max-width: 100%;
    }
  }

  &__phone {
    font-weight: 600;
    font-size: 24px;
    color: @base-color;
    text-decoration: none;
    display: inline-block;

    &-accent {
      color: @secondary-color;
    }
  }

  &__email {
    font-weight: 600;
    color: @primary-color;
    text-decoration: none;
    margin-top: 5px;
    display: inline-block;

    &-accent {
      color: @secondary-color;
    }
  }

  &__page-name {
    font-size: 22px;
    line-height: 47px;
    margin-right: 20px;
  }

  &__locale-changer {
    margin-right: 10px;

    @media (max-width: @mobile-width) {
      margin-right: 2px;
    }
  }

  &__burger {
    padding: 10px;
    margin-right: -10px;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }

  &__close {
    position: absolute;
    width: 35px;
    height: 35px;
    right: 15px;
    top: 35px;
    border: none;
    background: transparent;

    .base-icon {
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &__sidebar {
    position: fixed;
    top: 0;
    right: 0;
    max-width: 100%;
    background: #fff;
    min-width: 320px;
    padding: 30px 60px 0 30px;
    height: 100vh;
    z-index: 5;
    transform: translateX(100%);
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

    &.isOpen {
      transform: translateX(0);
      box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
    }
    &--slot-nav {
      height: 100%;

      .base-nav {
        flex-direction: column;
        align-items: normal;
        margin: 0;

        &__item {
          padding: 10px 0;
        }

        &__item:first-child {
          margin-right: 40px;
        }
      }
    }

    &--slot-user {
      padding: 20px 0;
      position: absolute;
      bottom: 0;

      .user-block {
        &__content {
          flex-direction: column;
        }

        &__data {
          width: 100%;
        }

        &__image {
          width: 60px;
          max-width: 60px;
          flex: 0 0 60px;
          height: 60px;
          margin-bottom: 15px;
        }

        &__name {
          font-size: @h4-fz;
          font-weight: 600;
          color: @base-color;
          margin-bottom: 15px;
          line-height: 1.5;
          max-width: initial;
        }
      }
    }

    &__profile {
      color: @primary-color;
      font-size: @base-fz;
      font-weight: 600;
      text-decoration: none;
      display: inline-block;
      line-height: 2;
      margin: 15px 0;
    }

    &__logout {
      color: @secondary-color;
      font-size: @base-fz;
      font-weight: 600;

      span {
        display: inline-block;
        cursor: pointer;
      }
    }
  }

  &__overlay {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.6);
  }

  &__sidebar.isOpen + &__overlay {
    display: block;
  }

  @media (max-width: 1200px) {
    padding-top: 11px;
    padding-bottom: 6px;
    margin-bottom: 0;

    &__inner {
      margin: 0 auto 15px;
      padding-top: 8px;
    }

    &__column {
      &--slot-userblock {
        margin-right: auto;
      }
      &--slot-menu {
        margin-top: 0;
      }
    }

    &__logo {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
      line-height: 0;

      img {
        height: 45px;
      }
    }
  }

  @media (max-width: 991px) {
    &__row {
      align-items: center;
    }
    &__page-name {
      display: none;
    }
  }

  &__column--slot-contacts {
    margin-right: 30px;
    text-align: right;
  }

  @media (max-width: @small-width) {
    &__column {
      &--slot-logo {
        margin-right: 25px;
      }
    }
  }

  @media (max-width: 550px) {
    &__inner {
      padding-top: 1px;
      margin: 0;

      &::before {
        left: -225px;
        top: -285px;
        width: 400px;
        height: 400px;
      }
    }

    &__column {
      &--slot-logo {
        flex-basis: 50px;
      }

      &--slot-contacts {
        margin-top: 10px;
      }

      &--slot-menu {
        margin-top: 10px;
      }
    }

    &__phone {
      font-size: 18px;
    }

    &__email {
      font-size: 12px;
    }

    &__row {
      align-items: flex-start;
    }
  }

  @media (max-width: 390px) {
    &__inner {
      &::before {
        left: -320px;
        top: -220px;
      }
    }
  }
}

[data-platform='kz'] {
  .static-header {
    &__inner {
      &::before {
        display: block;
        width: 876px;
        height: 876px;
        background-color: @primary-color;
        content: '';
        position: absolute;
        border-radius: 50%;
        left: -570px;
        top: -620px;
        z-index: -1;
      }

      @media (max-width: 550px) {
        &::before {
          left: -275px;
          top: -285px;
          width: 400px;
          height: 400px;
        }
      }
    }

    &__column--slot-logo {
      @media (max-width: 550px) {
        margin-right: 10px;
      }
    }
  }
}
</style>

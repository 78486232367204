<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.1639 6.7526C19.5767 7.11952 19.6138 7.75159 19.2469 8.16437L11.2469 17.1644C11.0579 17.3771 10.7872 17.4991 10.5026 17.5C10.218 17.5009 9.94659 17.3805 9.75623 17.169L5.25623 12.169C4.88677 11.7585 4.92005 11.1262 5.33056 10.7567C5.74107 10.3873 6.37336 10.4205 6.74281 10.831L10.4949 15L17.7521 6.83565C18.119 6.42286 18.7511 6.38568 19.1639 6.7526Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: Number,
    height: Number,
  },
};
</script>

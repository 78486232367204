import axios from 'axios';
import Qs from 'qs';

// eslint-disable-next-line import/prefer-default-export
export const HTTP = axios.create({
  headers: {},
  paramsSerializer(params) {
    return Qs.stringify(params, { arrayFormat: 'brackets' });
  },
  withCredentials: true,
});

<template>
  <div class="mobile-menu">
    <div class="mobile-menu__top">
      <div class="mobile-menu__close-btn" @click="closeMenu">
        <BaseSvg class="close-btn" :width="32" :height="32" iconName="CloseMenu" />
      </div>
      <div class="mobile-menu__user-info">
        <router-link class="base-header__sidebar-profile" to="/profile">
          <div class="mobile-menu__name">
            <div class="mobile-menu__ava">
              <div v-if="me.photo" class="mobile-menu__ava-photo" :style="{ backgroundImage: `url(${me.photo})` }"></div>
              <span v-else class="mobile-menu__ava-initials">{{ initials }}</span>
            </div>
            {{ getFio }}
            <BaseSvg class="mobile" :width="24" :height="24" iconName="Arrow" />
          </div>
        </router-link>
        <div class="mobile-menu__role-container">
          <div @click="handleMenuToggle" class="mobile-menu__role">
            <p>{{ getRoleName }}</p>
            <BaseSvg class="mobile arrow-down" :width="24" :height="24" iconName="Arrow" />
          </div>
          <template v-if="menuOpen">
            <div class="mobile-menu__roles" v-for="role in roleTexts" @click="onSelect(role.role)" :key="getUniqKey(role)">
              {{ role.label }}
            </div>
          </template>
        </div>
      </div>
      <div class="mobile-menu__navs-items">
        <div class="mobile-menu__navs-item" v-for="navItem of sortNavs" :key="navItem.label">
          <div class="mobile-menu__navs-img-block">
            <img :src="require(`@/assets/refactor/header/${getImgName(navItem.label)}`)" alt="Уроки" />
          </div>
          <router-link class="base-header__sidebar-profile" :to="navItem.path">
            <p @click="closeMenu">{{ navItem.label }}</p>
          </router-link>
        </div>
        <button v-if="isOpenSchoolEmploye" @click="toggleInterface">Изменить дизайн</button>
      </div>
    </div>
    <div class="mobile-menu__exit-btn">
      <BaseSvg class="mobile" :width="14" :height="14" iconName="CloseSmall" />
      <p @click="logout">Выйти из профиля</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import _ from 'lodash';

export default {
  name: 'MobileMenu',
  data() {
    return {
      roleNames: {
        teacher: 'Учитель',
        student: 'Ученик',
      },
      labelIcons: {
        Каталог: 'menu_item_catalog.png',
        Уроки: 'menu_item_catalog.png',
        Задания: 'works.png',
        Мониторинг: 'monitoring.png',
        Статистика: 'monitoring.png',
        Помощь: 'help.png',
        Документация: 'docs.png',
        'Методический раздел': 'metodics.png',
        'Знакомство с платформой': 'onboarding.png',
        'Задать вопрос': 'help.png',
        'Тренажер ФГ': 'trainingfg.png',
      },
      menuOpen: false,
    };
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapMutations('auth', ['SET_INTERFACE']),
    ...mapActions('navs', ['changeRole']),
    getUniqKey() {
      return _.uniqueId();
    },
    closeMenu() {
      this.$emit('closeMenu');
    },
    getImgName(label) {
      return this.labelIcons[label] || 'metodics.png';
    },
    toggleInterface() {
      const currentInterface = this.getCurrentInterface;
      const newInterface = currentInterface === 'old' ? 'new' : 'old';
      this.SET_INTERFACE(newInterface);
    },
    onSelect(role) {
      this.changeRole(role);
    },
    handleMenuToggle() {
      this.menuOpen = !this.menuOpen;
    },
  },
  computed: {
    ...mapGetters('auth', ['me', 'getCurrentInterface']),
    ...mapState('navs', {
      nav: (state) => state.nav,
      roleTextsData: (state) => state.roleTexts,
      role: (state) => state.role,
    }),
    initials() {
      if (!this.me) return null;
      const { firstName = '', lastName = '' } = this.me;
      return firstName.slice(0, 1) + lastName.slice(0, 1);
    },
    roleTexts() {
      return this.me.roles.map((role) => ({
        role,
        label: this.$t(this.roleTextsData[role].toLowerCase()),
      }));
    },
    isOpenSchoolEmploye() {
      const { email } = this.me;
      if (email) {
        const [, domain] = email.split('@');
        const accessesEmails = [
          'maddclif24@gmail.com',
          'alymkulov.almambet@gmail.com',
          'admin@admin.kz',
          'dildaabisheva@gmail.com',
          'kiyassov@gmail.com',
        ];
        return (
          domain.includes('2035') || domain.includes('edca') || domain.includes('amansultan') || accessesEmails.includes(email)
        );
      }
      return false;
    },
    sortNavs() {
      return this.nav.filter((item) => {
        return item.label !== 'Личный кабинет';
      });
    },
    getFio() {
      const user = this.me;
      let str = '';
      if (user.lastName) str += user.lastName || '';
      if (user.firstName) str += `.${user.firstName.slice(0, 1)}` || '';
      if (user.secondName) str += `.${user.secondName.slice(0, 1)}` || '';
      return str;
    },
    getRoleName() {
      return this.roleTextsData[this.role] || 'Роль не определена';
    },
  },
};
</script>

<style lang="less" scoped>
@import '~less_vars';

.close-btn {
  margin-left: auto;
}
.arrow-down {
  transform: rotate(90deg);
}
a {
  text-decoration: none;
  color: @text-black;
  cursor: pointer;
}
.mobile-menu {
  background: @surface-light-accent-bg;
  position: fixed;
  width: 360px;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__ava {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    color: #efeef1;
  }
  &__ava-photo {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 100%;
    height: 100%;
    border-radius: 50px;
  }
  &__navs-img-block {
    display: flex;
    width: 20px;
    flex-direction: column;
    align-items: center;
  }
  &__top {
    margin-bottom: 20px;
    overflow-y: scroll;
  }
  &__exit-btn {
    display: flex;
    gap: 14px;
    font-family: @body-3-accent_font-family;
    font-style: @body-3-accent_font-style;
    font-weight: @body-3-accent_font-weight;
    font-size: @body-3-accent_font-size;
    line-height: @body-3-accent_line-height;
    letter-spacing: @body-3-accent_letter-spacing;
    align-items: center;
    margin-left: 16px;
  }
  &__role-container {
    padding-bottom: 16px;
    border-bottom: 1px solid @surface-divider;
  }
  &__navs-items {
    padding: 10px 12px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: @surface-white;
    margin-top: 16px;
  }
  &__navs-item {
    display: flex;
    gap: 20px;
    font-family: @body-2-accent_font-family;
    font-style: @body-2-accent_font-style;
    font-weight: @body-2-accent_font-weight;
    font-size: @body-2-accent_font-size;
    line-height: @body-2-accent_line-height;
  }
  &__user-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    border-radius: 8px;
    margin-top: 30px;
    background: @surface-white;
    font-family: @body-1-accent_font-family;
    font-style: @body-1-accent_font-style;
    font-weight: @body-1-accent_font-weight;
    font-size: @body-1-accent_font-size;
    line-height: @body-1-accent_line-height;
  }
  &__role {
    padding: 10px 12px;
    border-radius: 8px;
    background: @surface-white;
    align-items: center;
    font-family: @body-3-accent_font-family;
    font-style: @body-3-accent_font-style;
    font-weight: @body-3-accent_font-weight;
    font-size: @body-3-accent_font-size;
    line-height: @body-3-accent_line-height;
    display: flex;
    justify-content: space-between;
  }
  &__roles {
    background: @surface-white;
    padding: 5px 12px;
  }
  &__close-btn {
    display: flex;
    justify-content: center;

    svg {
      fill: @icon-black;
    }
  }
  @media (max-width: @mobile-width) {
    width: 100%;
  }
}
</style>

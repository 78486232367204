<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" :viewBox="viewBox" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.71429 8H2.28571C1.02335 8 0 6.97665 0 5.71429V2.28571C0 1.02335 1.02335 0 2.28571 0H5.71429C6.97665 0 8 1.02335 8 2.28571V5.71429C8 6.97665 6.97665 8 5.71429 8ZM5.71429 18H2.28571C1.02335 18 0 16.9767 0 15.7143V12.2857C0 11.0233 1.02335 10 2.28571 10H5.71429C6.97665 10 8 11.0233 8 12.2857V15.7143C8 16.9767 6.97665 18 5.71429 18ZM12.2857 18H15.7143C16.9767 18 18 16.9767 18 15.7143V12.2857C18 11.0233 16.9767 10 15.7143 10H12.2857C11.0233 10 10 11.0233 10 12.2857V15.7143C10 16.9767 11.0233 18 12.2857 18ZM15.7143 8H12.2857C11.0233 8 10 6.97665 10 5.71429V2.28571C10 1.02335 11.0233 0 12.2857 0H15.7143C16.9767 0 18 1.02335 18 2.28571V5.71429C18 6.97665 16.9767 8 15.7143 8Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    viewBox: String,
    width: Number,
    height: Number,
  },
};
</script>

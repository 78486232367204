<template>
  <span class="image-component__wrp" @click.stop="handleImageClick">
    <img class="image-component" :src="src" ref="image" />
    <span class="image-component__zoom" @click.stop="handleImageClick"></span>
  </span>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import ModalImage from '@/components/Modal/ModalImage';

export default {
  name: 'ImageComponent',
  data() {
    return {
      isMobile,
      imageWidth: null,
      imageNaturalWidth: null,
    };
  },
  props: {
    src: String,
  },
  methods: {
    handleImageClick() {
      this.$emit('vuedals:new', {
        name: 'ModalImage',
        component: ModalImage,
        props: {
          image: this.src,
        },
        dismissable: false,
        size: 'xxl',
      });
    },
  },
  computed: {},
  mounted() {
    const image = new Image();
    const vm = this;
    // eslint-disable-next-line func-names
    image.onload = function () {
      if (!vm.$refs.image) {
        return;
      }
      const { width, naturalWidth } = vm.$refs.image;
      vm.imageWidth = width;
      vm.imageNaturalWidth = naturalWidth;
    };
    image.src = this.src;
  },
};
</script>

<style lang="less">
.image-component {
  border-radius: 8px;

  &__wrp {
    display: inline;
    position: relative;
    cursor: pointer;

    &:hover {
      .image-component__zoom {
        opacity: 0.8;
      }
    }
  }
  &__zoom {
    background: url('../../assets/ico-img-zoom.svg') no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 5px;
    bottom: 5px;
    cursor: pointer;
    transition: 0.2s;
    opacity: 0;
    &:hover {
      opacity: 1 !important;
    }
  }
}
</style>

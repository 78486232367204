// файл с казахскими переводами должен называться kk.js
// чтобы удобно было накатывать локале по названию, например: moment.locale(Language.current());
const files = require.context('.', false, /\.js$/);
const messages = {};

files.keys().forEach((key) => {
  if (key === './index.js') return;
  messages[key.replace(/(\.\/|\.js)/g, '')] = files(key).default;
});

const languages = Object.keys(messages);

export { messages, languages };

<template>
  <div class="modal">
    <div class="card">
      <div class="card__message"><p>Связать данный аккаунт с аккаунтом Educont?</p></div>
      <base-spinner size="40px" v-if="pending" />
      <div class="card__button-container" v-if="!pending">
        <div class="card__button" @click="bindEduUserWithOsUser">Связать аккаунт</div>
        <div class="card__button" @click="closeModal">Не связывать</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pending: false,
      windowLocationUrl: window.location.origin,
    };
  },
  methods: {
    deleteCookies() {
      const hostName = window.location.hostname;
      this.$cookies.remove('bindingAccount', null, hostName);
      this.$cookies.remove('accessToken', null, hostName);
    },
    async bindEduUserWithOsUser() {
      try {
        this.pending = true;
        const accessToken = this.$cookies.get('accessToken');
        const idCurrentUser = this.$store.state.auth.user;
        const res = await this.$store.dispatch('authEducont/continueWithActiveAccount', { idOs: idCurrentUser, accessToken });
        this.deleteCookies();
        this.$toast.open({
          message: res.data.message,
          type: 'success',
        });
        this.pending = false;
        this.closeModal();
      } catch (e) {
        this.closeModal();
        this.$toast.open({
          message: 'Произошла ошибка при привязке пользователя',
          type: 'error',
        });
      }
    },
    closeModal() {
      this.deleteCookies();
      this.$emit('notBind');
    },
  },
};
</script>

<style lang="less" scoped>
.account-select {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal {
  position: fixed;
  background: rgba(0, 0, 0, 0.363);
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}
.card {
  width: 50%;
  height: 50%;
  margin: 30px auto;
  max-width: 600px;
  border-radius: 20px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(46, 57, 153, 0.658);
  gap: 20px;
  margin: 30px auto;
  &__button {
    background: rgb(94, 126, 211);
    color: white;
    padding: 20px 30px;
    border-radius: 10px;
    text-align: center;
    margin: 0;
    cursor: pointer;
    &:hover {
      background: rgb(66, 96, 179);
    }
  }
  &__message {
    background: rgba(12, 143, 36, 0.877);
    color: rgb(255, 255, 255);
    border-radius: 20px;
    padding: 10px 20px;
  }
  &__button-container {
    display: flex;
    gap: 20px;
  }
}
</style>

<template>
  <div>
    <img :height="height" :width="width" :src="imgSource" alt="logo" :class="{ 'no-colors': bw }" />
  </div>
</template>

<script>
/* eslint-disable global-require,import/no-dynamic-require */
import { mapGetters } from 'vuex';

export default {
  name: 'BaseLogo',

  props: {
    bw: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false },
    white: { type: Boolean, default: false },
    width: { type: Number, default: 130 },
    height: { type: Number, default: 55 },
  },
  computed: {
    ...mapGetters('platform', ['getPlatformType']),

    imgSource() {
      const platformType = this.getPlatformType;
      if (this.isMobile) return require(`../../assets/platforms/${platformType}/logo-mobile.svg`);
      if (this.white) return require(`../../assets/platforms/${platformType}/logo-white.svg`);
      return require(`../../assets/platforms/${platformType}/logo.svg`);
    },
  },
};
</script>

<style lang="less" scoped>
.no-colors {
  filter: grayscale(150%) brightness(70%);
}

img {
  width: 100%;
}
</style>

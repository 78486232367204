<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.8834 14.9519L31.8929 15.9425L21.9874 6.03735L22.9779
                  5.0451C25.4697 2.61231 30.5821 2.43037 32.9433 5.0651C35.5023
                  7.56354 35.4424 12.4535 32.8834 14.9519ZM9.37095
                  34.7612H4.93938C3.8758 34.7612 3.16675 34.0615
                  3.16675 33.012V28.6392C3.16675 26.3653 3.55248
                  24.4502 5.14785 22.876L19.0156 9.0083L28.9211
                  18.9138L15.0534 32.7815C13.458 34.3558 11.6754
                  34.7612 9.37095 34.7612Z"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height'],
};
</script>

<template>
  <div class="main-login">
    <div class="main-login__slider">
      <div class="main-login__slider-heading">{{ $t('Открытая школа') }}&nbsp;—</div>
      <div class="main-login__slider-text">
        {{ $t('это онлайн - платформа с новым') }}
        <br />{{ $t('форматом школьных уроков') }}
      </div>
      <BaseScrollTo href="#subscribe" class="main-login__slider-button" :offset="150" :duration="1000"
        >{{ $t('Оформить подписку') }}
      </BaseScrollTo>
      <router-link to="/edu/programs/webinars/" class="main-login__slider-button edu" role="button"
        >{{ $t('Записаться на обучение') }}
      </router-link>
    </div>
    <div class="main-login__form">
      <login-form :pending="pending" :error="error"></login-form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LoginForm from '@/components/LoginForm/LoginForm';

export default {
  name: 'MainAuth',
  components: {
    LoginForm,
  },
  computed: {
    ...mapState('auth', {
      pending: (state) => state.pending,
      error: (state) => state.error,
    }),
  },
};
</script>

<style lang="less">
@import '~less_vars';

.main-login {
  display: flex;
  justify-content: flex-end;
  color: #000;
  margin-top: 120px;

  &__form {
    min-height: 467px;
    max-width: 505px;
  }

  &__slider {
    padding-top: 23px;
    width: 35%;
    z-index: 2;

    &-heading {
      font-weight: bold;
      font-size: 34px;
      line-height: 120%;
      margin-bottom: 13px;
    }

    &-text {
      font-size: 20px;
      margin-bottom: 33px;
      line-height: normal;
    }

    &-button {
      display: inline-block;
      font-weight: bold;
      border-radius: 5px;
      background-color: @pink-color;
      color: white;
      padding: 20px 52px;
      text-align: center;
      text-decoration: none;

      &.edu {
        margin-top: 21px;
        padding: 20px 36px;
        background-color: @primary-color;
      }
    }
  }

  @media (max-width: 1200px) {
    &__slider {
      width: 30%;
      margin-right: 30px;

      &-text {
        br {
          display: none;
        }
      }
    }
  }

  @media (max-width: 991px) {
    &__slider {
      &-button,
      &-button.edu {
        padding: 20px 15px;
        width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 0;
    position: relative;
    z-index: 3;

    &__slider {
      display: none; //TODO: do we need it here?

      width: 45%;
      padding-top: 10px;
    }

    &__form {
      width: 100%;
    }
  }
}
</style>

<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.34074 2.34074C3.90123 0.780247 5.78765 0 8 0C10.2123 0 12.0988 0.780247 13.6593 2.34074C15.2198 3.90123 16 5.78765 16 8C16 10.2123 15.2198 12.0988 13.6593 13.6593C12.0988 15.2198 10.2123 16 8 16C5.78765 16 3.90123 15.2198 2.34074 13.6593C0.780247 12.0988 0 10.2123 0 8C0 5.78765 0.780247 3.90123 2.34074 2.34074ZM3.17037 12.8593C4.51358 14.1827 6.12346 14.8444 8 14.8444C9.87654 14.8444 11.4765 14.1827 12.8 12.8593C14.1432 11.516 14.8148 9.8963 14.8148 8C14.8148 6.1037 14.1432 4.49383 12.8 3.17037C11.4765 1.82716 9.87654 1.15555 8 1.15555C6.12346 1.15555 4.51358 1.82716 3.17037 3.17037C1.84691 4.49383 1.18519 6.1037 1.18519 8C1.18519 9.8963 1.84691 11.516 3.17037 12.8593ZM8.02963 5.12593C7.25926 5.12593 6.60741 5.40247 6.07407 5.95555C5.54074 6.48889 5.27407 7.17037 5.27407 8C5.27407 8.82963 5.54074 9.51111 6.07407 10.0444C6.60741 10.5778 7.25926 10.8444 8.02963 10.8444C8.58272 10.8444 9.07654 10.6963 9.51111 10.4C9.94568 10.1037 10.2617 9.70864 10.4593 9.21481H11.9407C11.7037 10.1037 11.2198 10.8247 10.4889 11.3778C9.77778 11.9111 8.95802 12.1778 8.02963 12.1778C6.8642 12.1778 5.87654 11.7827 5.06667 10.9926C4.27654 10.2025 3.88148 9.20494 3.88148 8C3.88148 6.79506 4.27654 5.79753 5.06667 5.00741C5.85679 4.19753 6.84444 3.79259 8.02963 3.79259C8.95802 3.79259 9.77778 4.06914 10.4889 4.62222C11.2198 5.15555 11.7037 5.86667 11.9407 6.75556H10.4593C10.2617 6.26173 9.94568 5.86667 9.51111 5.57037C9.07654 5.27407 8.58272 5.12593 8.02963 5.12593Z"
      fill="#676D7A"
    />
  </svg>
</template>

<script>
export default {
  props: {
    viewBox: String,
    width: Number,
    height: Number,
  },
};
</script>

<style lang="less" scoped></style>

<template>
  <div @click="handleBaseLayout">
    <div class="base-layout">
      <base-header :toggleSidebar="toggleSidebar" :sidebarIsOpen="sidebarIsOpen"></base-header>
      <div class="base-layout__content">
        <router-view></router-view>
      </div>
    </div>
    <ModalBindEducontUser v-if="bindModalOpen" @notBind="notBind" />
    <ModalTermsOfUse v-if="!isAgreed && isModalOpen" @closeTermsModal="closeTermsModal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseHeader from '@/components/Base/BaseHeader';
import ModalBindEducontUser from '@/components/Modal/ModalBindEducontUser';
import ModalTermsOfUse from '@/components/Modal/ModalTermsOfUse';

export default {
  name: 'BaseLayout',
  components: {
    BaseHeader,
    ModalBindEducontUser,
    ModalTermsOfUse,
  },
  data() {
    return {
      sidebarIsOpen: false,
      statusUserEducont: true,
      bindModalOpen: false,
      isModalOpen: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['me', 'isNewInterface']),
    ...mapGetters('authEducont', ['getStatus']),
    isAgreed() {
      return this.$store.getters.getAgreed;
    },
    currentRoute() {
      return this.$route.name;
    },
    inPageLesson() {
      const pageLessonRoutes = ['lesson', 'lessonSlides'];
      return pageLessonRoutes.includes(this.currentRoute);
    },
  },
  methods: {
    closeTermsModal() {
      this.isModalOpen = false;
    },
    async notBind() {
      this.bindModalOpen = false;
    },
    handleBaseLayout({ target }) {
      if (!target.classList.contains('user-block-new__photo') && !target.classList.contains('user-block-new__name')) {
        this.$bus.$emit('clickBaseLayout');
      }
    },
    swipeHandler(direction) {
      if (direction === 'left') this.sidebarIsOpen = true;
    },
    toggleSidebar() {
      this.sidebarIsOpen = !this.sidebarIsOpen;
    },
  },
  mounted() {
    const bindingAccount = this.$cookies.get('bindingAccount');
    const accessToken = this.$cookies.get('accessToken');
    const idCurrentUser = this.$store.state.auth.user;
    if (accessToken && bindingAccount && idCurrentUser) this.bindModalOpen = true;
  },
};
</script>

<style lang="less">
@import '~less_vars';

.base-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;

  &__content {
    flex: 1;
    overflow: hidden;
    padding-top: 5px;

    .container {
      height: 100%;
    }
  }

  &::after {
    content: '';
    display: block;
    width: 350px;
    height: 350px;
    border: 40px solid @secondary-color;
    background-color: #fff;
    position: fixed;
    border-radius: 50%;
    bottom: -85px;
    right: -85px;
    z-index: -1;
  }

  @media (max-width: 1200px) {
    &::after {
      display: none;
    }
  }
}

[data-platform='kz'] {
  .base-layout {
    &::after {
      display: none;
    }
  }
}

@media print {
  .base-layout {
    //height: auto;
    //min-height: 100vh;

    &__content {
      margin: 0 -5px;
      padding: 0 5px;
      background-color: #fff;
    }

    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>

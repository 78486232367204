<template>
  <div class="base-label" @click="$emit('click')">
    <base-icon
      class="base-label__icon"
      :glyph="icon"
      :width="iconWidth"
      :height="iconHeight"
      :isBrand="true"
      v-if="icon"
      :style="{ minWidth: iconWidth }"
    >
    </base-icon>
    <div class="base-label__text" :style="textStyle">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'BaseLabel',
  props: {
    text: {
      type: [String, Number],
      required: true,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconWidth: {
      type: String,
      default: '20px',
    },
    iconHeight: {
      type: String,
      default: '20px',
    },
    nowrap: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    textStyle() {
      const styles = {};
      if (this.nowrap) {
        styles.whiteSpace = 'nowrap';
      }
      return styles;
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.base-label {
  max-width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__text {
    font-size: @small-fz;
    line-height: @medium-lh;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__icon {
    margin: 5px 5px 5px 0;
  }
}
</style>

<template>
  <nav class="base-nav" v-if="links.length">
    <div class="base-nav__item" v-for="(item, index) in links" :key="index" @click="sendEventToAmplitude(item)">
      <router-link v-if="isInsidePlatform(item.path)" class="base-nav__link" :to="item.path" @click.native="toggleSidebar">
        {{ $t(item.label) }}
      </router-link>
      <a v-else class="base-nav__link" :href="item.path" target="blank">{{ $t(item.label) }}</a>
    </div>
  </nav>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'BaseNav',
  props: {
    links: Array,
    toggleSidebar: Function,
  },
  methods: {
    ...mapActions('auth', ['logout']),
    isInsidePlatform(url) {
      if (url.includes('/login?timeCodeAuth=')) return false;
      return true;
    },
    sendEventToAmplitude(navItem) {
      const eventName = 'clickOnMenuItem';
      const eventProperties = {
        itemName: navItem.path.replace(/\//g, ''),
      };
      this.$store.dispatch('amplitude/sendEvent', { eventName, eventProperties });
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.base-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: -20px;
  flex-wrap: wrap;

  &__item {
    padding-left: 20px;
    padding-right: 20px;
    line-height: 2;
    font-family: @secondary-font;

    .router-link-active {
      color: @gray-disabled;
      cursor: none;
      pointer-events: none;
    }
  }

  @media (max-width: 1300px) {
    &__item {
      padding-left: 0;
    }
  }

  &__link {
    font-size: @bigger-fz;
    line-height: 26px;
    font-weight: 500;
    text-decoration: none;
    color: @primary-color;
    cursor: pointer;
  }
}
</style>

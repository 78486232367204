<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.1142 10.6665C15.1556 9.62515 16.8441 9.62515 17.8855 10.6665L24.9426 17.7237C25.4633 18.2444 25.4633 19.0887 24.9426 19.6094C24.4219 20.1301 23.5777 20.1301 23.057 19.6094L15.9998 12.5522L8.94265 19.6094C8.42195 20.1301 7.57773 20.1301 7.05703 19.6094C6.53633 19.0887 6.53633 18.2444 7.05703 17.7237L14.1142 10.6665Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    viewBox: String,
    width: Number,
    height: Number,
  },
};
</script>

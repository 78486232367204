import { HTTP } from '@/http';

const homework = {
  namespaced: true,
  state: {
    lessonsDates: {},
    pending: null,
  },
  getters: {
    lessonsDates: (state) => state.lessonsDates,
    pending: ({ pending }) => pending,
  },
  mutations: {
    SET_LESSONSDATES(state, lessonsDates) {
      state.lessonsDates = lessonsDates;
    },
    SET_PENDING(state, value) {
      state.pending = value;
    },
  },
  actions: {
    async homeworkEdit({ commit, rootState }, params) {
      commit('lessons/REMOVE_ASSIGN_LESSON', params._id, { root: true });
      return HTTP.post('/api/homeworkedit/', { ...params }).then((response) => {
        const assignLessons = [...rootState.lessons.tasks, ...response.data.models.map((n) => n._id)];
        commit(
          'REMOVE_LIST_ITEM',
          {
            type: 'tasks',
            id: params._id,
          },
          { root: true }
        );
        commit(
          'FETCH_MODELS',
          {
            type: 'tasks',
            payload: response.data.models,
          },
          { root: true }
        );
        commit('lessons/FETCH_ASSIGN_LESSONS', assignLessons, { root: true });
      });
    },
    async getAssignedHomeworks({ commit }, params) {
      try {
        commit('SET_PENDING', true);
        const {
          data: { lessonsDates },
        } = await HTTP.get('/api/getassignedhomeworks/', { params });
        commit('SET_LESSONSDATES', lessonsDates);
        commit('SET_PENDING', false);
      } catch (e) {
        console.error('get homeworks error: ', e);
      }
    },
    async createUnfinishedHomework(empty, params) {
      try {
        await HTTP.patch('/api/v2/unfinished-homework', params);
      } catch (e) {
        console.error('create unfinishedHomework error: ', e);
      }
    },
    async deleteUnfinishedHomework(empty, params) {
      try {
        await HTTP.delete('/api/v2/unfinished-homework', { data: params });
      } catch (e) {
        console.error('delete unfinishedHomework error: ', e);
      }
    },
  },
};
export default homework;

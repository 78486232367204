<template>
  <div v-if="false">
    <form action="#" @submit.prevent="submitHandler">
      <base-input placeholder="Email" type="text" required @input="setEmail"></base-input>
      <base-checkbox v-model="form.memorize">Запомнить меня</base-checkbox>
      <base-button size="large">Получить ссылку</base-button>

      <div v-show="showError" class="form-error">
        <span v-if="error">{{ error.msg }}</span>
      </div>
    </form>
    <vuedal />
  </div>
  <div v-else>
    <refactor-login-by-email />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { Component as Vuedal } from 'vuedals';
import ModalConfirm from '@/components/Modal/ModalConfirm';
import RefactorLoginByEmail from '@/components/NewComponents/RefactorLoginByEmail';

export default {
  name: 'LoginByEmail',
  components: {
    RefactorLoginByEmail,
    Vuedal,
  },
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        memorize: true,
        email: null,
        redirect: '/',
      },
    };
  },
  computed: {
    showError() {
      return this.error && this.error.type === 'email';
    },
  },
  methods: {
    ...mapActions('auth', ['authByEmail']),
    async submitHandler() {
      const { memorize, email, redirect } = this.form;
      if (email && redirect) {
        const res = await this.authByEmail({ memorize, email, redirect });
        if (res) {
          this.$emit('vuedals:new', {
            name: 'modalConfirm',
            component: ModalConfirm,
            dismissable: false,
            props: {
              title: 'Вам послано письмо с ссылкой на вход в систему',
              showCancel: false,
              handleConfirm: () => {
                this.$emit('vuedals:close');
              },
              confirmText: this.$t('Закрыть'),
            },
            size: 'md',
          });
        }
      }
    },
    setEmail(e) {
      this.form.email = e.target.value;
    },
  },
};
</script>

<!--eslint-disable max-len-->
<template>
  <svg width="24" height="26" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0V12H12C5.37258 12 0 6.62742 0 0Z" />
  </svg>
</template>

<script>
export default {
  props: {
    viewBox: String,
    width: Number,
    height: Number,
  },
};
</script>

// noinspection JSUnusedGlobalSymbols
import VueCookies from 'vue-cookies';
import { HTTP } from '@/http';
import router from '@/router';
import config from '../../../config';

const actualTimeCodeAuth = window.localStorage.getItem('timecodeauth') || VueCookies.get('timeCodeAuth');
const authPisaUrl = `${config.PISA_URL}/login?timeCodeAuth=${actualTimeCodeAuth}&redirect=${config.PISA_URL}/exercises-catalog`;

const navList = {
  head: [
    {
      path: '/principal/teachers/',
      label: 'Учителя',
      kz: true,
    },
    {
      path: '/journal/',
      label: 'Классы и ученики',
      kz: true,
    },
    {
      path: '/help/',
      label: 'Помощь',
      kz: true,
    },
    {
      path: '/docs/',
      label: 'Документация',
      kz: true,
    },
    {
      path: '/edu/',
      label: 'Методический раздел',
      kz: false,
    },
    {
      path: '/feedback/',
      label: 'Задать вопрос',
      kz: true,
    },
    {
      path: authPisaUrl,
      label: 'Тренажер ФГ',
      kz: true,
    },
  ],
  assistant: [
    {
      path: '/principal/teachers/',
      label: 'Учителя',
      kz: true,
    },
    {
      path: '/journal/',
      label: 'Классы и ученики',
      kz: true,
    },
    {
      path: '/help/',
      label: 'Помощь',
      kz: true,
    },
    {
      path: '/docs/',
      label: 'Документация',
      kz: false,
    },
    {
      path: '/edu/',
      label: 'Методический раздел',
      kz: false,
    },
    {
      path: '/feedback/',
      label: 'Задать вопрос',
      kz: true,
    },
    {
      path: authPisaUrl,
      label: 'Тренажер ФГ',
      kz: true,
    },
    {
      path: '/stats/',
      label: 'Статистика',
      kz: true,
    },
  ],
  teacher: [
    {
      path: '/dashboard/',
      label: 'Личный кабинет',
      kz: true,
    },
    {
      path: '/lessons/',
      label: 'Каталог',
      kz: true,
    },
    {
      path: '/journal/',
      label: 'Классы и группы',
      kz: true,
    },
    {
      path: '/tasks/',
      label: 'Задания',
      kz: true,
    },
    {
      path: '/progress/',
      label: 'Мониторинг',
      kz: true,
    },
    // {
    //   path: '/chart/',
    //   label: 'Статистика',
    // },
    {
      path: '/help/',
      label: 'Помощь',
      kz: true,
    },
    {
      path: '/docs/',
      label: 'Документация',
      kz: false,
    },
    {
      path: '/edu/',
      label: 'Методический раздел',
      kz: false,
    },
    {
      path: '/intro/',
      label: 'Знакомство с платформой',
      kz: true,
    },
    {
      path: '/feedback/',
      label: 'Задать вопрос',
      kz: true,
    },
    {
      path: authPisaUrl,
      label: 'Тренажер ФГ',
      kz: true,
    },
  ],
  student: [
    {
      path: '/diary/',
      label: 'Задания',
      kz: true,
    },
    {
      path: '/lessons/',
      label: 'Уроки',
      kz: true,
    },
    {
      path: '/progress/',
      label: 'Статистика',
      kz: true,
    },
    {
      path: '/help/',
      label: 'Помощь',
      kz: true,
    },
    {
      path: '/docs/',
      label: 'Документация',
      kz: false,
    },
    {
      path: '/feedback/',
      label: 'Задать вопрос',
      kz: true,
    },
    {
      path: authPisaUrl,
      label: 'Тренажер ФГ',
      kz: true,
    },
  ],
  super: [
    {
      path: '/administrators/',
      label: 'Администраторы',
      kz: true,
    },
    {
      path: '/impersonate/',
      label: 'Отладка пользователей',
      kz: true,
    },
    {
      path: '/stats/',
      label: 'Статистика',
      kz: true,
    },
    {
      path: '/partners/',
      label: 'Партнеры',
      kz: true,
    },
    {
      path: '/help/',
      label: 'Помощь',
      kz: true,
    },
    {
      path: '/docs/',
      label: 'Документация',
      kz: true,
    },
    {
      path: '/edu/',
      label: 'Методический раздел',
      kz: true,
    },
    {
      path: '/feedback/',
      label: 'Задать вопрос',
      kz: true,
    },
    {
      path: authPisaUrl,
      label: 'Тренажер ФГ',
      kz: true,
    },
  ],
  subs: [
    {
      path: '/schools/',
      label: 'Список школ',
      kz: true,
    },
    {
      path: '/regions/',
      label: 'Субъекты РФ',
      kz: true,
    },
    {
      path: '/help/',
      label: 'Помощь',
      kz: true,
    },
    {
      path: '/docs/',
      label: 'Документация',
      kz: true,
    },
    {
      path: '/edu/',
      label: 'Методический раздел',
      kz: false,
    },
    {
      path: '/feedback/',
      label: 'Задать вопрос',
      kz: true,
    },
    {
      path: authPisaUrl,
      label: 'Тренажер ФГ',
      kz: true,
    },
    // {
    //   path: '/chart',
    //   label: 'Статистика'
    // }
  ],
  content: [
    {
      path: '/lessons/',
      label: 'Уроки',
      kz: true,
    },
    {
      path: '/subjects/',
      label: 'Настройки',
      kz: true,
    },
    {
      path: '/subjectslinks/',
      label: 'Ссылки в фильтре',
      kz: true,
    },
    {
      path: '/tags/',
      label: 'Теги',
      kz: true,
    },
    {
      path: '/reviews/',
      label: 'Оценки уроков',
      kz: true,
    },
    {
      path: '/help/',
      label: 'Помощь',
      kz: true,
    },
    {
      path: '/docs/',
      label: 'Документация',
      kz: true,
    },
    {
      path: '/edu/',
      label: 'Методический раздел',
      kz: false,
    },
    {
      path: '/feedback/',
      label: 'Задать вопрос',
      kz: true,
    },
    {
      path: authPisaUrl,
      label: 'Тренажер ФГ',
      kz: true,
    },
  ],
  notAuthorized: [
    // { path: '/prices/', label: 'Тарифы и Цены', target: '_self', kz: true },
    // { path: '/help/', label: 'Помощь', target: '_self' },
    { path: '/docs/', label: 'Документация', target: '_self', kz: true },
    { path: '/edu/', label: 'Методический раздел', target: '_self', kz: false },
    { path: '/feedback/', label: 'Задать вопрос', target: '_self', kz: true },
  ],
  landing: [
    { path: '/', label: 'Главная', kz: true },
    { path: '/edu/', label: 'Методика', kz: false },
    // { path: '/prices/', label: 'Тарифы и Цены', kz: true },
    { path: '/docs/', label: 'Документация', kz: true },
    { path: '/help/', label: 'Помощь', kz: true },
  ],
  municipal: [
    { path: '/stats/', label: 'Статистика', kz: true },
    {
      path: '/help/',
      label: 'Помощь',
      kz: true,
    },
  ],
  guest: [
    {
      path: '/lessons/',
      label: 'Уроки',
      kz: true,
    },
  ],
};

const defaultState = {
  role: '',
  navLanding: navList.landing,
  nav: navList.notAuthorized,
  roleTexts: {
    head: 'Завуч',
    teacher: 'Учитель',
    assistant: 'Администратор школы',
    student: 'Ученик',
    super: 'Главный администратор',
    subs: 'Ответственный за регион',
    content: 'Администратор контента',
    regional: 'Региональный представитель',
    municipal: 'Муниципалитет',
    guest: 'Гость',
  },
};

const navs = {
  namespaced: true,
  state: defaultState,
  getters: {
    getRole(state) {
      return state.role;
    },
  },
  mutations: {
    SET_CURRENT_ROLE: (state, role) => {
      state.role = role;
    },
    SET_NAVS: (state) => {
      state.nav = state.role ? navList[state.role] : navList.notAuthorized;
    },
  },
  actions: {
    setRole({ commit }, payload) {
      commit('SET_CURRENT_ROLE', payload);
      commit('SET_NAVS');
    },
    changeRole({ dispatch }, payload) {
      dispatch('setRole', payload);
      return HTTP.post('/api/setRole/', { role: payload }).then((response) => {
        const result = response.data;
        if (result) {
          const { role } = result;
          if (!result.error && role) {
            window.localStorage.setItem('role', role);
          }
          if (!payload.noRefresh) {
            router.push('/');
          }
          dispatch('amplitude/refreshRoleUserProperty', null, { root: true });
          return response.data;
        }
        return null;
      });
    },
  },
};

export default navs;

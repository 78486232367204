const ENV = process.env;

module.exports = {
  MOODLE_URL: ENV.VUE_APP_MOODLE_URL,
  FILESERVER_URL: ENV.VUE_APP_FILESERVER_URL,
  STATS_API: ENV.VUE_APP_STATS_API,
  FRONT_2_URL: ENV.VUE_APP_FRONT_2_URL,
  PISA_URL: ENV.VUE_APP_PISA_URL,
  YANDEX_METRICA_COUNTER: ENV.VUE_APP_MOODLE_URL,
};

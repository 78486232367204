<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 16 16" fill="red" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_762_4362)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 11C2 11.5523 2.44772 12 3 12H4V5C4 3.89543 4.89543 3 6 3H10C10 2.44771 9.55229 2 9 2H3C2.44772 2 2 2.44771 2 3V11ZM12 3H14C15.1046 3 16 3.89543 16 5V14C16 15.1046 15.1046 16 14 16H6C4.89543 16 4 15.1046 4 14L3 14C1.34315 14 0 12.6569 0 11V3C0 1.34315 1.34315 0 3 0H9C10.6569 0 12 1.34314 12 3ZM7.60078 5.54517C7.0485 5.54517 6.60078 5.99288 6.60078 6.54517C6.60078 7.09745 7.0485 7.54517 7.60078 7.54517H12.4008C12.9531 7.54517 13.4008 7.09745 13.4008 6.54517C13.4008 5.99288 12.9531 5.54517 12.4008 5.54517H7.60078ZM6.60078 10.0914C6.60078 9.53911 7.0485 9.09139 7.60078 9.09139H12.4008C12.9531 9.09139 13.4008 9.53911 13.4008 10.0914C13.4008 10.6437 12.9531 11.0914 12.4008 11.0914H7.60078C7.0485 11.0914 6.60078 10.6437 6.60078 10.0914Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_762_4362">
        <rect :width="width" :height="height" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: Number,
    height: Number,
  },
};
</script>

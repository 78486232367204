<template>
  <div v-if="!isNewInterface && !isKzPlatform">
    <form action="#" @submit.prevent="submitHandler">
      <base-input width="100%" :placeholder="$t('Код')" type="text" required @input="setCode"></base-input>
      <base-checkbox v-model="memorize">{{ $t('Запомнить меня') }}</base-checkbox>
      <base-button size="large">{{ $t('Войти') }}</base-button>
      <div v-show="showError" class="form-error">
        <p>{{ $t('Ошибка авторизации.Неверный код') }}</p>
      </div>
    </form>
  </div>
  <div v-else>
    <refactor-login-by-code />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import RefactorLoginByCode from '@/components/NewComponents/RefactorLoginByCode';

export default {
  name: 'LoginByCode',
  components: { RefactorLoginByCode },
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      memorize: true,
      code: null,
      redirect: '/',
    };
  },
  computed: {
    ...mapGetters('auth', ['getCurrentInterface']),
    ...mapGetters('platform', ['isKzPlatform']),

    isNewInterface() {
      return this.getCurrentInterface === 'new';
    },
    showError() {
      return this.error && this.error.type === 'code';
    },
  },
  methods: {
    ...mapActions('auth', ['authByCode']),
    submitHandler() {
      const { redirect, code, memorize } = this;
      if (redirect && code) {
        this.authByCode({ redirect, code, memorize });
      }
    },
    setCode(e) {
      this.code = e.target.value;
    },
  },
};
</script>

<template>
  <input
    :class="applyClasses"
    :type="type"
    :placeholder="placeholder"
    :title="placeholder"
    :style="applyStyles"
    :name="name"
    :value="value"
    :required="required"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    @blur="$emit('blur', $event)"
    @keyup.enter="$emit('enter', $event)"
  />
</template>
<script>
import { toBemModifer } from '@/helpers/index';

export default {
  name: 'RefactorBaseInput',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    theme: {
      type: [String, Array],
      default: 'default',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
    },
    name: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    applyClasses() {
      const { size, theme, variant } = this;
      const baseClass = 'base-input';
      return [
        baseClass,
        ...toBemModifer(theme, baseClass, 'theme'),
        ...toBemModifer(size, baseClass, 'size'),
        ...toBemModifer(variant, baseClass, 'variant'),
      ];
    },
    applyStyles() {
      return {
        width: this.width,
        height: this.height,
      };
    },
  },
};
</script>

<style lang="less" scoped>
@import '~less_vars';

.base-input {
  height: @input-height;
  background-color: @bg-color;
  border: 1px solid #e7dcfd;
  border-radius: 8px;
  padding: 0 16px;
  max-width: 100%;
  transition: border 0.3s;
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;

  &:focus {
    outline-color: @refactor-primary-color;
    outline-width: 1px;
  }

  &::placeholder {
    color: @gray-dark;
    font-weight: 100;
  }

  &.error {
    border-color: @secondary-color !important;

    &:focus {
      outline-color: @secondary-color !important;
    }
  }

  &--theme {
    &-rounded-12 {
      border-radius: 12px;
    }
    &-rounded-20 {
      border-radius: 20px;
    }
    &-borderless {
      border: unset;
    }
  }
}
</style>

<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 8C2 9.10457 2.89543 10 4 10H6.58579L4.29289 12.2929C3.90237 12.6834 3.90237 13.3166 4.29289 13.7071C4.68342 14.0976 5.31658 14.0976 5.70711 13.7071L7 12.4142V13C7 13.5523 7.44772 14 8 14C8.55228 14 9 13.5523 9 13V12.4142L10.2929 13.7071C10.6834 14.0976 11.3166 14.0976 11.7071 13.7071C12.0976 13.3166 12.0976 12.6834 11.7071 12.2929L9.41421 10H12C13.1046 10 14 9.10457 14 8V4C14 2.89543 13.1046 2 12 2H4C2.89543 2 2 2.89543 2 4V8Z"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height'],
};
</script>

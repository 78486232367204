const typeMap = {
  video: 'VideoWidget',
  text: 'TextWidget',
  gallery: 'SliderWidget',
  Matching: 'QuestionWidget',
  OneOfMany: 'QuestionWidget',
  FormulaOne: 'QuestionWidget',
  ManyOfMany: 'QuestionWidget',
  FormulaMany: 'QuestionWidget',
  KeyboardGap: 'QuestionWidget',
  MouseGap: 'QuestionWidget',
  Choice: 'QuestionWidget',
  CombinationLock: 'QuestionWidget',
  Algorithm: 'QuestionWidget',
  SelectGap: 'QuestionWidget',
  SplitAtGroups: 'QuestionWidget',
  Sequence: 'QuestionWidget',
  Morphemes: 'QuestionWidget',
  Syntactic: 'QuestionWidget',
  Highlighting: 'QuestionWidget',
};
const questionsMap = {
  questionMulti: 'QuestionCheckList',
  questionRadio: 'QuestionRadioList',
  questionConformity: 'QuestionConformity',
  questionFillGaps: 'QuestionGap',
  questionFillGapsDrag: 'QuestionGapDrag',
  questionChoice: 'QuestionChoiceList',
  questionLock: 'QuestionLock',
  questionAlgorithm: 'QuestionAlgorithmList',
  questionSelectGap: 'QuestionSelectGap',
  questionSplitAtGroups: 'QuestionGrouping',
  questionSequence: 'QuestionSequence',
  questionMorphemes: 'QuestionMorphemes',
  questionSyntactic: 'QuestionSyntactic',
  questionHighlighting: 'QuestionHighlighting',
};
const questionTitles = {
  OneOfMany: 'Выберите один правильный вариант ответа',
  ManyOfMany: 'Выберите несколько правильных вариантов ответов',
  FormulaOne: 'Выберите один правильный вариант ответа',
  FormulaMany: 'Выберите несколько правильных вариантов ответов',
  Matching: 'Сопоставьте предложенные варианты',
  KeyboardGap: 'Заполните пропуски',
  MouseGap: 'Заполните пропуски',
  Choice: 'Согласитесь или опровергните высказывания',
  CombinationLock: 'Кодовый замок',
  Algorithm: 'Постройте алгоритм решения задачи, выставляя карточки в правильной последовательности',
  SelectGap: 'Заполните пропуски, выбрав ответ из предложенных',
  SplitAtGroups: 'Распределите карточки по группам',
  Sequence: 'Распределите карточки по порядку',
  Morphemes: 'Разберите слова по составу',
  Syntactic: 'Разберите предложения по членам',
  Highlighting: 'Выберите слова',
};

function delay(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function mapVideoFiles(files) {
  return files.map((link) => ({
    src: `${link}`,
    format: link.match(/\.[0-9a-z]+$/i) && link.match(/\.[0-9a-z]+$/i)[0].replace('.', ''),
  }));
}

function prepareGapTaskText(data) {
  let index = 0;
  let { parsedQuestion } = data;
  const { current } = data;
  while (parsedQuestion.includes('$value')) {
    const value = current && current[`var${index}`] ? current[`var${index}`] : '';

    if (value) {
      parsedQuestion = parsedQuestion.replace(`$valuevar${index}`, value);
    } else {
      parsedQuestion = parsedQuestion.replace(`$valuevar${index}`, '');
    }

    index += 1;

    // safety
    if (index > 1000) {
      break;
    }
  }
  return parsedQuestion;
}

function mapGapInputs(data, locked) {
  if (data) {
    // katex handler
    const katexRe = /<span class="enclosing(.*?)" data-varinfo="(.*?)"><\/span>/gi;
    // eslint-disable-next-line no-param-reassign
    data = data.replace(katexRe, `#{$2}`, 'gi');

    const re = /#{([^|}]*)([^}]*)}/gi;

    if (data.includes('blocklog_type_iframe')) {
      const projName = data.split('?project=')[1].split('&')[0]; // &solution
      return data.replace(
        re,
        `<gap-input-blocklog name="$1" :locked="${locked}" defaultValue="$value$1" projectName="${projName}"/>`,
        'gm'
      );
    }
    return data.replace(re, `<gap-input gapClass="$2" name="$1" :locked="${locked}" defaultValue="$value$1"/>`, 'gm');
  }
  return '';
}

function mapGapDragComponents(data) {
  if (data) {
    // katex handler
    const katexRe = /<span class="enclosing(.*?)" data-varinfo="(.*?)"><\/span>/gi;
    // eslint-disable-next-line no-param-reassign
    data = data.replace(katexRe, `#{$2}`, 'gi');
    // eslint-disable-next-line no-useless-escape
    const re = /#{([^|}]*)([^}]*)}/gi;
    // TODO вынести это в отдельный компонент, как инпут, чтобы не передавать дата-нейм
    return data.replace(re, `<span class="question-gap-drag__container empty" data-name="$1"></span>`, 'gm');
  }
  return '';
}

function mapActivity(data) {
  const result = {};
  let questionOrder = 0;

  data.forEach((n, index) => {
    if (n) {
      const act = n;

      act.component = typeMap[n.type] || 'UnknownWidget';
      act.isTask = false;

      if (['OneOfMany', 'FormulaOne'].includes(n.type)) {
        act.isTask = true;
        act.questionOrder = questionOrder;
        questionOrder += 1;
        act.questionComponent = questionsMap.questionRadio;
      }
      if (['FormulaMany', 'ManyOfMany'].includes(n.type)) {
        act.isTask = true;
        act.questionOrder = questionOrder;
        questionOrder += 1;
        act.questionComponent = questionsMap.questionMulti;
      }
      if (n.type === 'Matching') {
        act.isTask = true;
        act.questionOrder = questionOrder;
        questionOrder += 1;
        act.questionComponent = questionsMap.questionConformity;
      }
      if (n.type === 'Choice') {
        act.isTask = true;
        act.questionOrder = questionOrder;
        questionOrder += 1;
        act.questionComponent = questionsMap.questionChoice;
      }

      if (n.type === 'video') act.sources = mapVideoFiles(n.links);

      if (n.type === 'KeyboardGap') {
        act.isTask = true;
        act.questionOrder = questionOrder;
        questionOrder += 1;
        act.parsedQuestion = mapGapInputs(n.question, n.locked);
        act.parsedQuestion = prepareGapTaskText(n);

        if (n.question.includes('blocklog_type_iframe')) {
          // не выставляем id решения на iframe с внутренней кнопкой для самопроверки (ex. inslide_exersice_type_iframe)
          if (!n.question.includes('classwork_checking_iframe')) {
            act.parsedQuestion = n.parsedQuestion.replace('{{pract_ex_id}}', `${n.practice}`);
          } else {
            act.parsedQuestion = n.parsedQuestion.replace('{{pract_ex_id}}', `undefined`);
          }

          // скрипт для graphengine вычисления высоты math_geometry_type_iframe на платформе
          // навешиваем его при релоаде math_geometry_type_iframe, релоад происходит при ресайзе
          if (n.question.includes('math_geometry_type_iframe')) {
            act.parsedQuestion = n.parsedQuestion.replace(
              '{{onload_iframe_script}}',
              'let intRes = 0;\n' +
                "document.querySelectorAll('.math_geometry_type_iframe__meter').forEach(el => {\n" +
                '    const w = parseInt(el.clientWidth);\n' +
                '    if (w) {\n' +
                '      intRes = w;\n' +
                '    }\n' +
                "}); const stringMes = 'intRes: '; " +
                "this.style.height = (((intRes * (this.dataset.infoHeightPx || 600)) / (this.dataset.infoWidthPx || 800)) || 500) + 'px'; " +
                "this.height = (((intRes * (this.dataset.infoHeightPx || 600)) / (this.dataset.infoWidthPx || 800)) || 500) + 'px';"
            );
          }
        }

        act.questionComponent = questionsMap.questionFillGaps;
      }

      if (n.type === 'MouseGap') {
        act.isTask = true;
        act.questionOrder = questionOrder;
        questionOrder += 1;
        act.parsedQuestion = mapGapDragComponents(n.question);
        act.questionComponent = questionsMap.questionFillGapsDrag;
      }

      if (n.type === 'CombinationLock') {
        act.isTask = true;
        act.questionOrder = questionOrder;
        questionOrder += 1;
        act.questionComponent = questionsMap.questionLock;
      }

      if (n.type === 'Algorithm') {
        act.isTask = true;
        act.questionOrder = questionOrder;
        questionOrder += 1;
        act.questionComponent = questionsMap.questionAlgorithm;
      }

      if (n.type === 'SelectGap') {
        act.isTask = true;
        act.questionOrder = questionOrder;
        questionOrder += 1;
        act.questionComponent = questionsMap.questionSelectGap;
      }

      if (n.type === 'SplitAtGroups') {
        act.isTask = true;
        act.questionOrder = questionOrder;
        questionOrder += 1;
        act.questionComponent = questionsMap.questionSplitAtGroups;
      }
      if (n.type === 'Sequence') {
        act.isTask = true;
        act.questionOrder = questionOrder;
        questionOrder += 1;
        act.questionComponent = questionsMap.questionSequence;
      }
      if (n.type === 'Morphemes') {
        act.isTask = true;
        act.questionOrder = questionOrder;
        questionOrder += 1;
        act.questionComponent = questionsMap.questionMorphemes;
      }
      if (n.type === 'Syntactic') {
        act.isTask = true;
        act.questionOrder = questionOrder;
        questionOrder += 1;
        act.questionComponent = questionsMap.questionSyntactic;
      }
      if (n.type === 'Highlighting') {
        act.isTask = true;
        act.questionOrder = questionOrder;
        questionOrder += 1;
        act.questionComponent = questionsMap.questionHighlighting;
      }

      result[index] = act;
    }
  });
  return { result, questionOrder };
}

const lesson = (HTTP) => ({
  namespaced: true,
  state: {
    id: null,
    activity: null,
    activityPending: false,
    pending: false,
    questionCount: 0,
    status: null,
    activityMod: 'all',
    requestCounter: 0,
    selectedBlocks: [],
  },
  mutations: {
    SET_LESSON: (state, payload) => {
      state.id = payload.id;
    },
    SET_SELECTED_BLOCKS: (state, payload) => {
      state.selectedBlocks = payload;
    },
    SET_ACTIVITY_PENDING: (state) => {
      state.activityPending = false;
    },
    SET_PENDING: (state, flag) => {
      state.pending = flag;
    },
    SET_QUESTION_COUNT: (state, count) => {
      state.questionCount = count;
    },
    SET_STATUS: (state, status) => {
      state.status = status;
    },
    SET_ACTIVITY_MOD: (state, value) => {
      state.activityMod = value;
    },
    INCREASE_REQUESTCOUNTER: (state) => {
      state.requestCounter += 1;
    },
    RESET_REQUESTCOUNTER: (state) => {
      state.requestCounter = 0;
    },
  },
  getters: {
    lesson(state, getters, rootState) {
      return {
        meta: rootState.DB.lessons[state.id],
        activity: rootState.DB.activities[state.id],
        questions: state.questionCount,
        status: state.status,
        selectedBlocks: state.selectedBlocks,
      };
    },
    selectedBlocks(state) {
      return state.selectedBlocks;
    },
    questionTitlesList() {
      return Object.keys(questionTitles).map((n) => ({
        type: n,
        label: questionTitles[n],
      }));
    },
  },
  actions: {
    async getActivity({ commit, state, rootState }, { id, activityMod = 'filtered' }) {
      commit('SET_ACTIVITY_PENDING', true);
      if (rootState.DB.activities[id] && state.activityMod === activityMod) {
        commit('SET_ACTIVITY_PENDING', false);
      } else {
        const params = { _id: id };
        const route = activityMod === 'all' ? '/api/getallblocks/' : '/api/getnewlesson/';
        return HTTP.get(route, { params }).then((response) => {
          const newAct = {};
          const { result, questionOrder } = mapActivity(response.data.lesson);
          newAct[id] = result;
          commit('FETCH_ACTIVITY', newAct, { root: true });
          commit('SET_QUESTION_COUNT', questionOrder);
          commit('SET_ACTIVITY_PENDING', false);
          commit('SET_ACTIVITY_MOD', activityMod);
        });
      }
      return null;
    },

    getSelectedBlocks(context, id) {
      const params = { lesson: id };
      return HTTP.get('/api/blockslist/', { params }).then((response) => response.data.lbc);
    },

    async getAllBlocks({ commit }) {
      return HTTP.get('/api/blockslistrefactor/').then((response) => {
        if (response.data.result) {
          commit('SET_SELECTED_BLOCKS', response.data.result);
        }
      });
    },

    saveSelectedBlocks(context, params) {
      HTTP.post('/api/blockon/', { ...params }).then(() => {});
    },

    async checkStatus({ commit }, id) {
      const params = { lesson: id };
      return HTTP.get('/api/lessonstatus/', { params }).then((response) => {
        if (response.data.status) {
          commit('SET_STATUS', response.data.status);
        }
      });
    },

    async getLesson({ commit, rootState, dispatch }, params) {
      if (params && params._id) {
        const { _id: id, activityMod } = params;
        const savedLesson = rootState.DB.lessons[id];
        if (savedLesson) {
          commit('SET_LESSON', {
            id: savedLesson._id,
          });
          await dispatch('getActivity', { id, activityMod });
          await dispatch('checkStatus', id);

          return savedLesson;
        }
      }
      commit('SET_PENDING', true);

      return HTTP.get('/crud/lesson/', { params }).then((result) => {
        const { models } = result.data;

        if (models && models.length) {
          commit('SET_LESSON', {
            id: models[0]._id,
            activity: models[0].activity,
          });
          commit(
            'FETCH_MODELS',
            {
              type: 'lessons',
              payload: result.data.models,
            },
            {
              root: true,
            }
          );
          dispatch('getActivity', { id: models[0]._id });
          commit('SET_PENDING', false);

          return models[0];
        }
        return null;
      });
    },

    giveMeLesson({ commit }, params) {
      return HTTP.get('/crud/lesson/', { params }).then((result) => {
        const { models } = result.data;

        if (models && models.length) {
          commit('SET_LESSON', {
            id: models[0]._id,
            activity: models[0].activity,
          });
          commit(
            'FETCH_MODELS',
            {
              type: 'lessons',
              payload: result.data.models,
            },
            {
              root: true,
            }
          );
        }
        return models[0];
      });
    },
    // eslint-disable-next-line consistent-return
    async setAnswer({ state, commit, dispatch }, params) {
      try {
        if (state.pending && state.requestCounter < 30) {
          await delay(100);
          commit('INCREASE_REQUESTCOUNTER');
          return dispatch('setAnswer', params);
        }
        if (state.pending && state.requestCounter >= 30) {
          commit('RESET_REQUESTCOUNTER');
          throw new Error('pending to set');
        }
        commit('SET_PENDING', true);
        const { data } = await HTTP.post('/api/setanswer/', { ...params });
        if (data && data.error) {
          commit('SET_PENDING', false);
          commit('RESET_REQUESTCOUNTER');
          throw new Error(data.error);
        }
        commit('SET_PENDING', false);
        commit('RESET_REQUESTCOUNTER');
        return data;
      } catch (e) {
        if (e.message !== 'pending to set') {
          commit('SET_PENDING', false);
          commit('RESET_REQUESTCOUNTER');
        }
        console.error('setAnswer error: ', e.message);
      }
    },
    // eslint-disable-next-line consistent-return
    async sendHomework({ commit, state, dispatch }, params) {
      try {
        if (state.pending && state.requestCounter < 30) {
          commit('INCREASE_REQUESTCOUNTER');
          await delay(100);
          return dispatch('sendHomework', params);
        }
        if (state.pending && state.requestCounter >= 30) {
          commit('RESET_REQUESTCOUNTER');
          throw new Error('pending to send');
        }
        commit('SET_PENDING', true);
        const { data } = await HTTP.post('/api/setexercise/', { ...params });
        if (data && data.error) {
          throw new Error(data.error);
        }
        commit('SET_PENDING', false);
        commit('RESET_REQUESTCOUNTER');
        return data;
      } catch (e) {
        if (e.message !== 'pending to send') {
          commit('SET_PENDING', false);
          commit('RESET_REQUESTCOUNTER');
        }
        console.error('sendHomework error: ', e.message);
      }
    },
    // eslint-disable-next-line consistent-return
    async checkAnswer({ commit, state, dispatch }, params) {
      try {
        if (state.pending && state.requestCounter < 30) {
          commit('INCREASE_REQUESTCOUNTER');
          await delay(100);
          return dispatch('checkAnswer', params);
        }
        if (state.pending && state.requestCounter >= 30) {
          commit('RESET_REQUESTCOUNTER');
          throw new Error('pending to check');
        }
        commit('SET_PENDING', true);
        const { data } = await HTTP.get('/api/checkanswer/', { params });
        if (data && data.error) {
          throw new Error(data.error);
        }
        commit('SET_PENDING', false);
        commit('RESET_REQUESTCOUNTER');
        return data;
      } catch (e) {
        if (e.message !== 'pending to check') {
          commit('SET_PENDING', false);
          commit('RESET_REQUESTCOUNTER');
        }
        console.error('checkAnswer error: ', e.message);
      }
    },
    // eslint-disable-next-line consistent-return
    async lockLesson({ commit, state, dispatch }, params) {
      try {
        if (state.pending && state.requestCounter < 30) {
          commit('INCREASE_REQUESTCOUNTER');
          await delay(100);
          return dispatch('lockLesson', params);
        }
        if (state.pending && state.requestCounter >= 30) {
          commit('RESET_REQUESTCOUNTER');
          throw new Error('pending to lockLesson');
        }
        commit('SET_PENDING', true);

        const { data } = await HTTP.post('/api/lockhomework/', { ...params });
        if (data && data.error) {
          throw new Error(data.error);
        }
        commit('SET_PENDING', false);
        commit('RESET_REQUESTCOUNTER');
        return data;
      } catch (e) {
        if (e.message !== 'pending to lockLesson') {
          commit('SET_PENDING', false);
          commit('RESET_REQUESTCOUNTER');
        }
        console.error('checkAnswer error: ', e.message);
      }
    },
    async unLockLesson(context, params) {
      const { data } = await HTTP.post('/api/unlockhomework/', {
        ...params,
      }).catch((e) => console.error('unLockLesson error: ', e.message));
      return data;
    },
    getStudentResults(ctx, params) {
      if (!params || !params.lesson || !params.student) {
        return Promise.reject(new Error('lesson and student is required'));
      }
      return new Promise((resolve, reject) => {
        HTTP.get('/api/studentresult/', { params })
          .then((response) => {
            if (Array.isArray(response.data)) {
              return resolve(response.data);
            }
            reject(new TypeError('response.data is not Array'));
            return null;
          })
          .catch((error) => reject(error));
      });
    },
  },
});

export default lesson;

import { HTTP } from '@/http';

const homeworks = {
  namespaced: true,
  state: {
    unfinishedHomeworks: [],
  },
  getters: {
    getUnfinishedHomeworks(state) {
      return state.unfinishedHomeworks;
    },
  },
  actions: {
    async updateLeadtime(context, { homeworkId, leadTime }) {
      try {
        const { data } = await HTTP.patch(`/api/v2/${homeworkId}/homework-leadtime`, { leadTime });
        return data;
      } catch (e) {
        console.log(e);
      }
      return null;
    },
  },
};
export default homeworks;

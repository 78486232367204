import VueCookies from 'vue-cookies';
import { HTTP } from '@/http';

const classLiters = 'абвгдеёжзийклмнопрстуфхцчшщыэюя'.split('');
const guestClasses = VueCookies.get('guestClasses');

const studyClasses = {
  namespaced: true,
  state: {
    ids: [],
    isLoaded: false,
    defaultClassLiters: classLiters,
    newClassStudents: [],
    defaultClassNumbers: guestClasses
      ? guestClasses.toString().split(',')
      : ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
  },
  getters: {
    numbers(state) {
      return state.defaultClassNumbers;
    },
    liters(state) {
      return state.defaultClassLiters;
    },
    list(state, getters, rootState) {
      return state.ids.map((id) => {
        const item = rootState.DB.classes[id];
        if (item) return item;
        return { _id: id, pending: true };
      });
    },
    classesMap(state, getters) {
      const classMap = {};
      getters.list.forEach((n) => {
        if (classMap[n.number]) {
          classMap[n.number].push(n.litera);
        } else {
          classMap[n.number] = [n.litera];
        }
      });
      return classMap;
    },
    newAdded(state, getters, rootState) {
      return state.newClassStudents.map((id) => rootState.DB.students[id]);
    },
  },
  mutations: {
    FETCH_CLASSES: (state, payload) => {
      state.ids = [...new Set([...state.ids, ...payload])];
    },
    SET_LOADED: (state, payload) => {
      state.isLoaded = payload;
    },
    ADD_STUDENT_TO_NEW_CLASS: (state, payload) => {
      if (Array.isArray(payload)) {
        state.newClassStudents = [...new Set([...state.newClassStudents, ...payload])];
      } else {
        state.newClassStudents = [...new Set([...state.newClassStudents, payload])];
      }
    },
    REMOVE_CLASS: (state, id) => {
      const idx = state.ids.indexOf(id);
      if (idx >= 0) {
        state.ids.splice(idx, 1);
      }
    },
    REMOVE_STUDENT_FROM_NEW_CLASS: (state, id) => {
      const students = state.newClassStudents;
      const idx = students.indexOf(id);

      if (idx !== -1) {
        students.splice(idx, 1);
      }
    },
    DROP_NEW_CLASS: (state) => {
      state.newClassStudents = [];
    },
  },
  actions: {
    dropNewClass({ commit }) {
      commit('DROP_NEW_CLASS');
    },
    addToNewClass({ commit }, id) {
      commit('ADD_STUDENT_TO_NEW_CLASS', id);
    },
    removeFromNewClass({ commit }, id) {
      commit('REMOVE_STUDENT_FROM_NEW_CLASS', id);
    },
    transferStudent(context, payload) {
      return HTTP.post('/api/transferstudent/', payload);
    },
    createClass({ commit, rootState }, payload) {
      const params = Array.isArray(payload) ? payload : [payload];

      if (params.length === 0) {
        return null;
      }

      params.forEach((c) => {
        c.school = rootState.DB.users[rootState.auth.user].school;
      });

      return HTTP.post('/crud/studyclass/', [...params])
        .then((response) => {
          const { created = [], updated = [] } = response.data;
          if ((created && created.length > 0) || (updated && updated.length)) {
            const classes = [...created, ...updated];

            commit(
              'FETCH_CLASSES',
              classes.map((c) => c._id)
            );
            commit('DROP_NEW_CLASS');
            commit(
              'FETCH_MODELS',
              {
                type: 'classes',
                payload: classes,
              },
              { root: true }
            );
            return classes;
          }
          return null;
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
    refetchClasses({ commit, dispatch }) {
      commit('SET_LOADED', false);
      dispatch('fetchClasses');
    },
    removeClass({ commit }, _id) {
      return HTTP.post('/api/deleteclass/', { studyClass: _id })
        .then((response) => {
          commit('REMOVE_CLASS', _id);
          commit(
            'REMOVE_LIST_ITEM',
            {
              type: 'classes',
              ID: _id,
            },
            { root: true }
          );
          return response.data;
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
    fetchClasses({ state, commit }, params) {
      if (!params && state.isLoaded) return null;
      return HTTP.get('/crud/studyclass/', { params })
        .then((response) => {
          const data = response.data.models;
          if (!params) {
            commit('SET_LOADED', true);
          }
          commit(
            'FETCH_CLASSES',
            data.filter((n) => state.defaultClassNumbers.includes(n.number)).map((n) => n._id)
          );
          commit(
            'FETCH_MODELS',
            {
              type: 'classes',
              payload: data,
            },
            { root: true }
          );
          return { success: data && data.length > 0 };
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
    fetchClass(context, params) {
      return HTTP.get(`/api/exam/studyclass/${params.id}`, { params })
        .then((resp) => {
          return resp.data;
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
    removeFromClass({ commit }, payload) {
      return HTTP.post('/api/deletestudent/', payload)
        .then((response) => {
          commit('SET_UPDATES_CLASS', response.data.studyClass, { root: true });
          commit(
            'REMOVE_LIST_ITEM',
            {
              type: 'users',
              ID: payload.student,
            },
            { root: true }
          );
          return response.data;
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
  },
};

export default studyClasses;

<template>
  <footer class="container base-footer" :class="classes">
    <div class="base-footer__inner">
      <div class="base-footer__copyright">2022 — {{ $t('Все права защищены') }}</div>
      <div class="base-footer__contacts">
        <a href="mailto:support@2035school.ru" class="base-footer__contacts-email">support@2035school.ru</a>
        <a href="tel:88007757672" class="base-footer__contacts-phone">8 800 775-76-72</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'BaseFooter',
  props: {
    classes: String,
  },
};
</script>

<style lang="less">
@import '~less_vars';

.base-footer {
  margin-top: 20px;
  padding-bottom: 30px;

  &__inner {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
  }

  &__copyright {
    font-family: @futura-demi-font;
    font-size: 18px;
    line-height: 1.33;
    color: black;
  }

  &__contacts {
    &-email,
    &-phone {
      font-family: @futura-demi-font;
      font-size: 18px;
      line-height: 1.33;
      text-decoration: none;
    }

    &-email {
      color: @primary-color;
      margin-right: 55px;
    }

    &-phone {
      color: black;
    }
  }

  @media (max-width: 480px) {
    &__inner {
      flex-direction: column;
    }

    &__copyright {
      margin-bottom: 20px;
    }

    &__contacts {
      display: flex;
      flex-direction: column;

      &-email {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}
</style>

const feedback = (HTTP) => ({
  namespaced: true,
  state: {
    pending: null,
  },
  getters: {
    pending: (state) => state.pending,
  },
  mutations: {
    SET_PENDING: (state, payload) => {
      state.pending = payload;
    },
  },
  actions: {
    // eslint-disable-next-line consistent-return
    async sendFeedback(context, params) {
      try {
        const { data } = await HTTP.post('/api/feedback/', params);
        if (data && data.error) {
          throw new Error(data.error);
        }
        return data;
      } catch (e) {
        console.error('send feedback error: ', e.message);
      }
    },
    // eslint-disable-next-line consistent-return
    async sendFeedback2(context, params) {
      try {
        const { data } = await HTTP.post('/api/feedback2/', params);
        if (data && data.error) {
          throw new Error(data.error);
        }
        return data;
      } catch (e) {
        console.error('send feedback2 error: ', e.message);
      }
    },
    // eslint-disable-next-line consistent-return
    async sendQuestion(context, params) {
      try {
        const { data } = await HTTP.post('/api/sendquestion/', params);
        if (data && data.error) {
          throw new Error(data.error);
        }
        return data;
      } catch (e) {
        console.error('send question error: ', e.message);
      }
    },
    // eslint-disable-next-line consistent-return
    async requestAdvice(context, params) {
      try {
        const { data } = await HTTP.post('/api/requestadvice/', params);
        if (data && data.error) {
          throw new Error(data.error);
        }
        return data;
      } catch (e) {
        console.error('send question error: ', e.message);
      }
    },
  },
});
export default feedback;

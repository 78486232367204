<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 5C8 3.34315 9.34315 2 11 2H13C14.6569 2 16 3.34315 16 5L18 5C18.5523 5 19 5.44772 19 6C19 6.55229 18.5523 7 18 7L15 7L9.00099 7L9 7L8.99901 7L6 7C5.44772 7 5 6.55228 5 6C5 5.44771 5.44772 5 6 5L8 5ZM13 4C13.5523 4 14 4.44772 14 5H10C10 4.44772 10.4477 4 11 4H13ZM7 8C6.44772 8 6 8.44772 6 9V20C6 21.1046 6.89543 22 8 22H16C17.1046 22 18 21.1046 18 20V9C18 8.44772 17.5523 8 17 8H7Z"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height'],
};
</script>

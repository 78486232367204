<template>
  <div class="container" :class="{ 'container_white-bg': viewUnfinishedBlock() }">
    <header class="header">
      <router-link to="/" class="header__logo">
        <BaseLogo class="desktop header__logo-desktop" :width="140" :height="48" />
        <BaseLogo class="mobile header__logo-mobile" :width="70" :isMobile="true" />
      </router-link>

      <div class="header__navs">
        <router-link class="header__navitem" :to="item.path" v-for="(item, idx) in showedLinks" :key="idx">
          {{ $t(item.label) }}
        </router-link>

        <div
          class="header__navitem"
          :class="{ 'header__navitem_other-links-opened': otherLinksOpened }"
          @click="openOtherLinks"
          v-if="otherLinks && otherLinks.length"
        >
          {{ $t('Прочее') }}
          <div class="header__navs-dropdown" v-show="otherLinksOpened">
            <router-link class="header__navitem" :to="item.path" v-for="(item, idx) in otherLinks" :key="idx">
              {{ $t(item.label) }}
            </router-link>
            <a class="header__navitem" v-if="hasMoodleAccess" href="#" @click="moodle">
              {{ $t('Вход в СДО') }}
            </a>
            <a class="header__navitem" v-if="isMoodleAdmin" href="/moodle-users">
              {{ $t('Администрирование СДО') }}
            </a>
          </div>
        </div>
      </div>

      <div class="header__control" v-if="false">
        <multiselect
          v-if="userIsTeacher && user"
          @select="setCurrentClass"
          :value="initialCurrentClass"
          :options="studyClassesList"
          label="label"
          :searchable="false"
          :show-labels="false"
          :allowEmpty="false"
          :placeholder="$t('Выберите класс')"
        >
          <template slot="noOptions">
            <span>{{ $t('Список пуст') }}</span>
          </template>
        </multiselect>
      </div>
      <div class="header__page-name mobile">
        {{ $t(getPageName) }}
      </div>
      <div class="header__user">
        <user-block
          v-if="user"
          :user="user"
          :photo="user && user.photo"
          :roles="user && user.roles"
          :name="user && `${user.firstName} ${user.lastName}`"
          :pending="!user"
          @initNtf="toggleNotification"
        />
      </div>
      <div class="mobile burger" @click="openMobileMenu">
        <BaseSvg :width="19" :height="19" iconName="Burger" />
      </div>

      <transition name="mobile-menu">
        <MobileMenu v-if="isOpenMobileMenu" @closeMenu="closeMenu" />
      </transition>

      <NotificationFeed v-if="showNotification" :show="showNtf" />
    </header>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
import axios from 'axios';
import UserBlock from '@/components/NewComponents/NewUserBlock';
import NotificationFeed from '@/components/NewComponents/NotificationFeed';
import BaseLogo from '@/components/NewComponents/BaseLogo';
import MobileMenu from '@/components/NewComponents/Header/MobileMenu';
import config from '../../../config';

export default {
  name: 'BaseHeaderNew',
  components: {
    MobileMenu,
    UserBlock,
    Multiselect,
    NotificationFeed,
    BaseLogo,
  },
  data() {
    return {
      showNtf: false,
      otherLinksOpened: false,
      selectedClass: null,
      hasMoodleAccess: false,
      isMoodleAdmin: false,
      pageNames: {
        lessons: 'Каталог уроков',
        diary: 'Задания',
        progress: 'Статистика',
        'my-lessons': 'Мои уроки',
        help: 'Помощь',
        docs: 'Документация',
        tasks: 'Задания',
        classes: 'Классы и группы',
        schools: 'Список школ',
        dashboard: 'Личный кабинет',
        profile: 'Профиль',
        teachers: 'Учителя',
      },
      config,
      token: localStorage.getItem('os_token'),
      isOpenMobileMenu: false,
    };
  },

  created() {
    const vm = this;
    document.addEventListener('click', (e) => {
      const { target } = e;
      if (!target.closest('.ntf-feed__content') && !target.closest('.user-block-new-alert__img-wrap')) {
        vm.showNtf = false;
      }
    });
  },

  mounted() {
    this.getStorage();
    this.checkMoodleAccess();
    this.isThisUserMoodleAdmin();
  },

  computed: {
    ...mapGetters('auth', { user: 'me' }),
    ...mapGetters('navs', ['getRole']),
    ...mapGetters('studyClasses', { studyClasses: 'list' }),
    ...mapState('navs', { nav: (state) => state.nav }),
    ...mapGetters('homeworksV2', ['getUnfinishedHomeworks']),
    getPageName() {
      return this.pageNames[this.$route.name] || '';
    },
    showedLinks() {
      const endIndex = this.getRole === 'teacher' ? 3 : 2;
      return this.nav.length > 3 ? this.nav.slice(0, endIndex) : this.nav;
    },
    otherLinks() {
      const teacherLinksHide = ['Мониторинг', 'Знакомство с платформой', 'Тренажер ФГ'];
      const studentLinksHide = ['Статистика', 'Тренажер ФГ'];
      if (this.getRole === 'teacher') {
        return this.nav.slice(3).filter(({ label }) => !teacherLinksHide.includes(label));
      }
      if (this.getRole === 'student') {
        return this.nav.slice(2).filter(({ label }) => !studentLinksHide.includes(label));
      }
      return this.nav.length > 3 ? this.nav.slice(3) : null;
    },
    studyClassesList() {
      const res = this.studyClasses
        .filter((item) => this.user.favoriteClasses.includes(item._id))
        .sort((a, b) => a.number - b.number)
        .map((item) => {
          item.label = this.$t(`${item.number}${item.litera} класс}`);
          return item;
        });

      res.push({ _id: '0', label: this.$t('Классы и группы') });

      return res;
    },

    initialCurrentClass() {
      return this.studyClassesList.filter((c) => c._id === this.user.currentStudyClass);
    },

    userIsTeacher() {
      return this.getRole === 'teacher';
    },

    showNotification() {
      return this.getRole === 'student';
    },
  },
  methods: {
    ...mapActions('studyClasses', ['setNewCurrentClass']),
    viewUnfinishedBlock() {
      return this.getRole === 'student' && this.$route.name === 'lessons' && this.getUnfinishedHomeworks.length;
    },
    closeMenu() {
      this.isOpenMobileMenu = false;
    },
    openMobileMenu() {
      this.isOpenMobileMenu = true;
    },
    toggleNotification() {
      this.showNtf = !this.showNtf;
    },
    async moodle() {
      const token = localStorage.getItem('os_token');
      const header = `Bearer ${token}`;
      const url = await axios.get('/api/info', {
        headers: { Authorization: header },
      });
      window.location.replace(url.data);
    },
    async getStorage() {
      if (document.referrer === `${config.MOODLE_URL}/`) {
        await this.moodle();
      }
    },
    openOtherLinks() {
      this.otherLinksOpened = !this.otherLinksOpened;
      if (this.otherLinksOpened) {
        this.$nextTick(() => {
          document.addEventListener('click', this.handleCloseClick, true);
        });
      }
    },
    handleCloseClick(e) {
      if (this.otherLinksOpened && !e.target.closest('.header__navitem')) {
        this.otherLinksOpened = false;
        document.removeEventListener('click', this.handleCloseClick);
      }
    },
    setCurrentClass(currentClass) {
      if (currentClass._id === '0' && currentClass.label === 'Классы и группы') {
        this.$router.push({ path: '/journal' }).catch(() => {});
        return;
      }
      this.setNewCurrentClass({ newStudyClassId: currentClass._id, userId: this.user._id });
    },
    async checkMoodleAccess() {
      /* const token = localStorage.getItem('os_token');
      const header = `Bearer ${token}`;
      const res = await axios.get('/checkmoodleaccess', {
        headers: { Authorization: header },
      }); */
      if (localStorage.getItem('role') !== 'student') {
        this.hasMoodleAccess = true;
      }
    },
    async isThisUserMoodleAdmin() {
      const result = await axios.get('/api/getMoodleUsers', {
        headers: { Authorization: `Bearer ${localStorage.getItem('os_token')}` },
      });
      if (result.code !== 403) {
        this.isMoodleAdmin = true;
      } else {
        this.isMoodleAdmin = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import '~less_vars';
.burger {
  margin-right: 25px;

  svg {
    fill: @icon-black;
  }
}
.container {
  @media (max-width: @medium-width) {
    padding: 0;
  }
  &_white-bg {
    background: @background-white-bg;
  }
}

.header {
  margin: 0 auto;
  position: relative;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: @surface-white;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  box-shadow: @shadow-header-box-shadow;
  z-index: 5;
  &__top {
    overflow: auto;
  }
  &__page-name {
    font-family: @headline-6_font-family;
    font-style: @headline-6_font-style;
    font-weight: @headline-6_font-weight;
    font-size: @headline-6_font-size;
    line-height: @headline-6_line-height;
  }
  &__logo {
    .desktop {
      margin-left: 24px;
      margin-bottom: 3px;
    }
  }

  &__navs {
    position: relative;
    max-width: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    gap: 32px;
    @media (max-width: @medium-width) {
      display: none;
    }
  }

  &__navitem {
    position: relative;
    display: inline-block;
    text-decoration: none;
    transition: color 0.3s;
    white-space: nowrap;
    cursor: pointer;
    padding: 23px 0;
    color: @text-black;
    font-family: @body-2-accent_font-family;
    font-style: @body-2-accent_font-style;
    font-weight: @body-2-accent_font-weight;
    font-size: @body-2-accent_font-size;
    line-height: @body-2-accent_line-height;
    letter-spacing: @body-2-accent_letter-spacing;

    &_other-links-opened {
      color: @text-secondary;
    }

    &:hover {
      color: @text-secondary;
    }
    &.router-link-active {
      color: @text-accent;
      border-bottom: 2px solid @surface-accent;
      padding-bottom: 21px;
    }
  }

  &__navs-dropdown {
    position: absolute;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    top: 100%;
    left: -45%;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
    padding-top: 8px;
    padding-bottom: 8px;
    z-index: 9999;

    .header__navitem {
      padding: 12px 32px;
      border-bottom: 0;

      &:hover {
        color: @text-secondary;
      }
      &.router-link-active {
        color: @primary-color;
      }
    }
  }

  &__user {
    margin-right: 20px;
    max-width: 200px;
    @media (max-width: @medium-width) {
      display: none;
    }
  }

  &__logo-mobile {
    padding-left: 10px;
    width: 60px;
  }

  &__logo-desktop {
    width: 140px;
  }
}

.mobile {
  display: none;

  @media (max-width: @medium-width) {
    display: block;
  }
}

.desktop {
  display: block;

  @media (max-width: @medium-width) {
    display: none;
  }
}
</style>

<style lang="less">
@import '~less_vars';

.user-block-new__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: @body-2-accent_font-family;
  font-style: @body-2-accent_font-style;
  font-weight: @body-2-accent_font-weight;
  font-size: @body-2-accent_font-size;
  line-height: @body-2-accent_line-height;
  color: @text-black;
}

.header__control {
  .multiselect {
    min-width: 179px;
    height: 37px;
    background: #f5f5f7;
    border-radius: 8px;
    color: @surface-accent;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    padding: 8px 8px 8px 16px;

    &--active {
      .multiselect__select {
        &::before {
          transform: rotate(180deg) translate(-10px, -7px);
        }
      }
    }

    &__option {
      padding: 5px 16px;
      min-height: 30px;

      &:hover {
        background: @background-light-accent-bg !important;
        color: @base-color !important;
        font-weight: 500 !important;
      }
      &--highlight,
      &--selected {
        background: @bg-color !important;
        color: @base-color !important;
        font-weight: 500 !important;
      }
    }

    &__element {
      border-top: 0 solid transparent !important;
    }

    &__content-wrapper {
      top: 100%;
      border-radius: 8px;
    }

    &__content {
      padding: 7px;
    }

    &__single {
      margin-bottom: unset !important;
      vertical-align: middle;
      text-align: left;
      background-color: transparent;
    }

    &__placeholder {
      margin-bottom: unset;
    }

    &__tags {
      min-height: unset;
      padding: 0 25px 0 0 !important;
      background-color: transparent;
      border: unset;
      text-align: left;
    }

    &__select {
      padding: 4px 21px 4px 8px;
      width: 45px;

      &:before {
        position: absolute;
        color: @base-color;
        content: '';
        background: url('../../assets/botArrow.svg') no-repeat;
        border-color: transparent;
        border-width: 10px;
        right: 15px;
        top: 5px;
        margin: 0;
        transform: rotate(0deg);
      }
    }
  }
}

.mobile-menu-enter,
.mobile-menu-leave-to {
  transform: translateX(100%);
}

.mobile-menu-enter-active,
.mobile-menu-leave-active {
  transition: transform 0.5s ease;
}
</style>

<template>
  <base-modal class="modal-image" :show-circle="false">
    <div slot="modal-body">
      <div class="modal-image__image">
        <img :src="image" />
      </div>
    </div>
  </base-modal>
</template>

<script>
export default {
  name: 'ModalImage',
  props: {
    image: String,
  },
  methods: {},
};
</script>

}
<style lang="less">
.modal-image {
  z-index: 3000;
  img {
    width: 100%;
    max-height: 70vh;
    object-fit: contain;
  }

  &__image {
    text-align: center;
  }
}
</style>

<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="28" height="28" rx="14" fill="#F5F5F7" />
    <g clip-path="url(#clip0_1649_62715)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.79289 8.79289C9.18342 8.40237 9.81658 8.40237 10.2071 8.79289L14 12.5858L17.7929 8.79289C18.1834 8.40237 18.8166 8.40237 19.2071 8.79289C19.5976 9.18342 19.5976 9.81658 19.2071 10.2071L15.4142 14L19.2071 17.7929C19.5976 18.1834 19.5976 18.8166 19.2071 19.2071C18.8166 19.5976 18.1834 19.5976 17.7929 19.2071L14 15.4142L10.2071 19.2071C9.81658 19.5976 9.18342 19.5976 8.79289 19.2071C8.40237 18.8166 8.40237 18.1834 8.79289 17.7929L12.5858 14L8.79289 10.2071C8.40237 9.81658 8.40237 9.18342 8.79289 8.79289Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1649_62715">
        <rect width="12" height="12" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: ['width', 'height'],
};
</script>

<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.0762 2.67749C11.4178 1.8562 12.5813 1.8562 12.9229 2.67749L15.0725 7.84583C15.2165 8.19206 15.5421 8.42863 15.9159 8.4586L21.4956 8.90591C22.3822 8.977 22.7417 10.0835 22.0662 10.6622L17.8151 14.3037C17.5303 14.5476 17.4059 14.9304 17.4929 15.2952L18.7917 20.74C18.9981 21.6052 18.0569 22.2891 17.2978 21.8254L12.5208 18.9077C12.2008 18.7122 11.7983 18.7122 11.4783 18.9077L6.70133 21.8254C5.94223 22.2891 5.00098 21.6052 5.20737 20.74L6.50615 15.2952C6.59316 14.9304 6.46878 14.5476 6.184 14.3037L1.93289 10.6622C1.25735 10.0835 1.61688 8.977 2.50353 8.90591L8.08318 8.4586C8.45697 8.42863 8.78258 8.19206 8.92659 7.84583L11.0762 2.67749Z"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height', 'title', 'id'],
};
</script>

<template>
  <div class="static-body-layout">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'StaticBodyLayout',
};
</script>

<style lang="less">
@import '~less_vars';

.static-body-layout {
  width: 1310px;
  max-width: 100%;
  margin: 0 auto;
  z-index: 1;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;

  @media (max-width: @medium-width) {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>

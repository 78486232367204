<template>
  <div class="main-formats">
    <div class="main-formats__title">{{ $t('Интерактивные образовательные форматы') }}</div>
    <div class="main-formats__list">
      <div class="main-formats__item">
        <div class="main-formats__item-inner">
          <img
            src="../../assets/img-formats-ready-lessons.png"
            alt=""
            class="main-formats__item-inner-image"
            srcset="../../assets/img-formats-ready-lessons@2x.png 2x"
          />
          <div class="main-formats__item-inner-text">
            <div class="main-formats__item-inner-heading">{{ $t('Готовые уроки') }}</div>
            <div class="main-formats__item-inner-desc">
              {{ $t('Назначайте готовые уроки ученикам, отсутствовавшим на занятиях') }}
            </div>
          </div>
        </div>
        <div class="main-formats__item-inner">
          <img
            src="../../assets/img-formats-tests.png"
            alt=""
            class="main-formats__item-inner-image"
            srcset="../../assets/img-formats-tests@2x.png 2x"
          />
          <div class="main-formats__item-inner-text">
            <div class="main-formats__item-inner-heading">{{ $t('Тесты') }}</div>
            <div class="main-formats__item-inner-desc">{{ $t('Автоматически проверяйте усвоение полученных знаний') }}</div>
          </div>
        </div>
      </div>
      <div class="main-formats__item">
        <div class="main-formats__item-inner">
          <img
            src="../../assets/img-formats-individual.png"
            alt=""
            class="main-formats__item-inner-image"
            srcset="../../assets/img-formats-individual@2x.png 2x"
          />
          <div class="main-formats__item-inner-text">
            <div class="main-formats__item-inner-heading">{{ $t('Индивидуальные задания') }}</div>
            <div class="main-formats__item-inner-desc">
              {{ $t('Назначайте задания исходя из уровня подготовки каждого ученика') }}
            </div>
          </div>
        </div>
        <div class="main-formats__item-inner">
          <img
            src="../../assets/img-formats-interactive.png"
            alt=""
            class="main-formats__item-inner-image"
            srcset="../../assets/img-formats-interactive@2x.png 2x"
          />
          <div class="main-formats__item-inner-text">
            <div class="main-formats__item-inner-heading">{{ $t('Интерактивные задания') }}</div>
            <div class="main-formats__item-inner-desc">
              {{ $t('Добавляйте интерактивности во время уроков в классе') }}
            </div>
          </div>
        </div>
      </div>
      <div class="main-formats__item">
        <div class="main-formats__item-inner">
          <img
            src="../../assets/img-formats-video.png"
            alt=""
            class="main-formats__item-inner-image"
            srcset="../../assets/img-formats-video@2x.png 2x"
          />
          <div class="main-formats__item-inner-text">
            <div class="main-formats__item-inner-heading">{{ $t('Обучающие видеоролики') }}</div>
            <div class="main-formats__item-inner-desc">
              {{ $t('Видео объяснит то, что не в состоянии показать картинка в учебнике') }}
            </div>
          </div>
        </div>
        <div class="main-formats__item-inner">
          <img
            src="../../assets/img-formats-exercise.png"
            alt=""
            class="main-formats__item-inner-image"
            srcset="../../assets/img-formats-exercise@2x.png 2x"
          />
          <div class="main-formats__item-inner-text">
            <div class="main-formats__item-inner-heading">{{ $t('Тренажеры') }}</div>
            <div class="main-formats__item-inner-desc">
              {{ $t('Процесс закрепления материала наконец - то стал действительно увлекательным') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainFormats',
  components: {},
};
</script>

<style lang="less">
@import '~less_vars';

.main-formats {
  &__title {
    font-weight: bold;
    font-size: 50px;
    letter-spacing: 0.025em;
    color: #fc2767;
    width: 50%;
    margin-bottom: 10px;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
  }

  &__item {
    width: 30%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    &:nth-child(1) {
      margin-top: 150px;
    }

    &:nth-child(3) {
      margin-top: -160px;
    }

    &-inner {
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.14);
      border-radius: 8px;
      margin-bottom: 65px;

      &:last-child {
        margin-bottom: 0;
      }

      &-image {
        display: block;
        width: 100%;
        border-bottom: 1px solid #c4c4c4;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &-text {
        background: #ffffff;
        padding: 30px 40px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        min-height: 220px;
      }

      &-heading {
        font-weight: bold;
        font-size: @subheader-fz;
        line-height: 28px;
        color: #000000;
        margin-bottom: 20px;
      }

      &-desc {
        font-size: @bigger-fz;
        line-height: 25px;
        color: #7e7e7e;
      }
    }
  }

  @media (max-width: 768px) {
    &__title {
      width: 100%;
      margin-bottom: 40px;
    }

    &__list {
      padding: 0;
    }

    &__item {
      width: 32%;

      &-inner {
        margin-bottom: 25px;

        &-text {
          padding: 10px 20px;
        }

        &-heading {
          font-size: 20px;
        }

        &-text {
          font-size: 15px;
        }
      }

      &:nth-child(n) {
        margin-top: 0;
      }
    }
  }

  @media (max-width: 480px) {
    &__title {
      margin-bottom: 25px;
      font-size: 35px;
    }

    &__list {
      flex-direction: column;
    }

    &__item {
      width: 100%;
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }

      &-inner {
        &-text {
          min-height: initial;

          &-heading {
            font-size: 23px;
          }

          &-desc {
            font-size: 20px;
          }
        }
      }
    }
  }
}
</style>

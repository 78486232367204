<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.20711 1.04289C8.59763 1.43342 8.59763 2.06658 8.20711 2.45711L2.66421 8L8.20711 13.5429C8.59763 13.9334 8.59763 14.5666 8.20711 14.9571C7.81658 15.3476 7.18342 15.3476 6.79289 14.9571L0.542893 8.70711C0.152369 8.31658 0.152369 7.68342 0.542893 7.29289L6.79289 1.04289C7.18342 0.652369 7.81658 0.652369 8.20711 1.04289Z"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height'],
};
</script>

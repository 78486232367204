import { HTTP } from '@/http';

const schools = {
  namespaced: true,
  state: {
    ids: [],
    requests: {},
    count: -1,
    amounts: {},
  },
  getters: {
    list(state, getters, rootState) {
      return state.ids.map((id) => rootState.DB.schools[id]);
    },
  },
  mutations: {
    SAVE_REQUESTS: (state, payload) => {
      state.requests[payload] = true;
    },
    DROP_REQUESTS: (state) => {
      state.requests = {};
    },
    SAVE_LIST: (state, payload) => {
      state.ids = [...new Set([...state.ids, ...payload])];
    },
    REMOVE: (state, id) => {
      state.ids.splice(state.ids.indexOf(id), 1);
    },
    SAVE_COUNT: (state, count) => {
      state.count = count;
    },
    SAVE_AMOUNTS: (state, payload) => {
      state.amounts[payload.schoolId] = payload.amounts;
    },
  },
  actions: {
    fetchSchools({ commit }, params = {}) {
      return HTTP.get('/crud/school/', { params }).then((response) => {
        const data = response.data.models;
        const ids = data.map((school) => school._id);

        commit('SAVE_LIST', ids);

        commit(
          'FETCH_MODELS',
          {
            type: 'schools',
            payload: data,
          },
          { root: true }
        );

        return data;
      });
    },
    // Только прикрепленные к юзеру школы
    // Если есть params.user то у этого юзера
    // Если нет, то у текущего в сессии
    getSchoolsByUser({ commit }, params = {}) {
      return HTTP.get('/api/school/', { params }).then((response) => {
        const data = response.data.models;
        const ids = data.map((school) => school._id);

        commit('SAVE_LIST', ids);

        commit(
          'FETCH_MODELS',
          {
            type: 'schools',
            payload: data,
          },
          { root: true }
        );

        return data;
      });
    },
    // Возвращает неприкрепленные школы
    getNotAttachSchools({ commit }, params = {}) {
      return HTTP.get('/api/noattchschools/', { params }).then((response) => {
        const data = response.data.schools;
        const ids = data.map((school) => school._id);

        commit('SAVE_LIST', ids);

        commit(
          'FETCH_MODELS',
          {
            type: 'schools',
            payload: data,
          },
          { root: true }
        );

        return data;
      });
    },
    fetchCount({ commit }, params = {}) {
      return HTTP.get('/crud/school/count/', { params }).then((response) => {
        const { count } = response.data;
        if (count) {
          commit('SAVE_COUNT', count);
          return count;
        }
        return -1;
      });
    },
    addSchools({ commit }, payload) {
      const params = Array.isArray(payload) ? payload : [payload];

      return HTTP.post('/crud/school/', [...params])
        .then((response) => {
          const { created = [], updated = [] } = response.data;
          if ((created && created.length > 0) || (updated && updated.length)) {
            const list = [...created, ...updated];
            commit(
              'SAVE_LIST',
              list.map((school) => school._id)
            );
            commit(
              'FETCH_MODELS',
              {
                type: 'schools',
                payload: list,
              },
              { root: true }
            );
            return list;
          }
          return [];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async removeSchool({ commit, dispatch }, params) {
      const ids = Array.isArray(params) ? params : [params];

      try {
        const result = await dispatch('deleteModel', { model: 'school', options: { data: ids } }, { root: true });
        if (result.data.removed > 0) {
          ids.forEach((id) => commit('REMOVE', id));
        }
        return result;
      } catch (err) {
        console.log('err', err);
      }
      return null;
    },
    getSchoolPeopleAmount({ state, commit }, schoolId, forceUpdate = false) {
      const {
        amounts: { [schoolId]: data },
      } = state;
      if (!forceUpdate && data) {
        return Promise.resolve(state.amounts[schoolId]);
      }
      return Promise.all([
        HTTP.get('/crud/user/count/', {
          params: { school: schoolId, roles: { $in: ['teacher'] } },
        }),
        HTTP.get('/crud/user/count/', {
          params: { school: schoolId, roles: { $in: ['student'] } },
        }),
      ]).then((response) => {
        if (response) {
          const amounts = {
            teachersAmount: response[0].data.count,
            studentsAmount: response[1].data.count,
          };
          commit('SAVE_AMOUNTS', {
            schoolId,
            amounts,
          });
          return amounts;
        }
        return null;
      });
    },
  },
};

export default schools;

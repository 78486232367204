import { HTTP } from '@/http';

const tags = {
  namespaced: true,
  state: {
    ids: [],
    requests: [],
    idsGroups: [],
    tempTag: {},
  },
  mutations: {
    FETCH_TAGS: (state, payload) => {
      state.ids = [...new Set([...state.ids, ...payload])];
    },
    CREATE_TAG: (state, payload) => {
      state.tempTag = payload;
    },
    REMOVE_TAG: (state, id) => {
      const idx = state.ids.indexOf(id);
      if (idx !== -1) {
        state.ids.splice(idx, 1);
      }
    },
    FETCH_GROUPS: (state, payload) => {
      state.idsGroups = [...new Set([...state.idsGroups, ...payload])];
    },
    REMOVE_GROUP: (state, id) => {
      const idx = state.idsGroups.indexOf(id);
      if (idx !== -1) {
        state.idsGroups.splice(idx, 1);
      }
    },
  },
  getters: {
    list(state, getters, rootState) {
      return state.ids.map((id) => rootState.DB.tags[id]);
    },
    groups(state, getters, rootState) {
      return state.idsGroups.map((id) => rootState.DB.tagGroups[id]);
    },
  },
  actions: {
    fetchTags({ commit }) {
      return HTTP.get('/crud/tag/').then((response) => {
        commit(
          'FETCH_TAGS',
          response.data.models.map((n) => n._id)
        );
        commit(
          'FETCH_MODELS',
          {
            type: 'tags',
            payload: response.data.models,
          },
          { root: true }
        );
      });
    },
    addTag({ commit }, params) {
      HTTP.post('/crud/tag/', { ...params }).then((response) => {
        if (response.data._id) {
          commit('FETCH_TAGS', [response.data._id]);
          commit('CREATE_TAG', response.data);
          commit(
            'FETCH_MODELS',
            {
              type: 'tags',
              payload: [response.data],
            },
            { root: true }
          );
        }
      });
    },
    removeTag({ commit }, params) {
      HTTP.delete('/crud/tag/', { data: { ...params } })
        .then(() => {
          commit('REMOVE_TAG', params._id);
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
    fetchGroups({ commit }) {
      return HTTP.get('/crud/taggroup/').then((response) => {
        commit(
          'FETCH_GROUPS',
          response.data.models.map((n) => n._id)
        );
        commit(
          'FETCH_MODELS',
          {
            type: 'tagGroups',
            payload: response.data.models,
          },
          { root: true }
        );
      });
    },
    addGroup({ commit }, params) {
      HTTP.post('/crud/taggroup/', { ...params }).then((response) => {
        if (response.data._id) {
          commit('FETCH_GROUPS', [response.data._id]);
          commit(
            'FETCH_MODELS',
            {
              type: 'tagGroups',
              payload: [response.data],
            },
            { root: true }
          );
        }
      });
    },
    removeGroup({ commit }, params) {
      HTTP.delete('/crud/taggroup/', { data: { ...params } })
        .then(() => {
          commit('REMOVE_GROUP', params._id);
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
    reorderTags({ commit }, params) {
      HTTP.post('/api/changetagidx/', { ...params }).then((response) => {
        commit(
          'FETCH_TAGS',
          response.data.tags.map((n) => n._id)
        );
        commit(
          'FETCH_MODELS',
          {
            type: 'tags',
            payload: response.data.tags,
          },
          { root: true }
        );
      });
    },
  },
};

export default tags;

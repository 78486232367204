<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9994 22.9996C10.4965 22.9995 9.02836 22.5478 7.78527 21.7033C6.54217 20.8588 5.58146 19.6602 5.02771 18.2631C4.47395 16.866 4.35269 15.3348 4.67964 13.8679C5.0066 12.4011 5.76669 11.0663 6.86136 10.0366C8.06307 8.90562 10.8316 6.96397 11.0383 2.98023C11.0689 2.38985 11.7387 1.99738 12.2179 2.34365C16.9826 5.787 19.2242 9.334 15.1434 14.2511C14.7452 14.731 15.0725 15.4718 15.6674 15.2845C16.3502 15.0695 17.1745 14.6398 18.184 13.7805C18.5755 13.4473 19.198 13.5896 19.3182 14.0895C19.4281 14.546 19.4994 15.0174 19.4994 15.4996C19.4994 17.4887 18.7092 19.3964 17.3027 20.8029C15.8961 22.2094 13.9885 22.9996 11.9994 22.9996Z"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height'],
};
</script>

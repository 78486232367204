let flag = false;

// Blocklog iframe messages to Openschool
// Присылает результат проверки решения
// blocklog_type_iframe класс iframe механик внутреннего ОШ производства
export default function iframeMessageListener() {
  if (flag === false) {
    window.addEventListener('message', (myEvent) => {
      let msg = myEvent.data;
      if (msg) {
        msg += '';
        if (msg.includes('message___blocklog_type_iframe')) {
          // message___blocklog_type_iframe___*******___***___423759
          const arr = msg.split('___');
          const projName = arr[2];
          const res = arr[3];
          const blocklogInputs = document.querySelectorAll('.gap-input_blocklog');
          blocklogInputs.forEach((blInp) => {
            if (blInp.dataset.projectName === projName) {
              blInp.innerText = res;
              blInp.click();
            }
          });
        }
      }
    });
    flag = true;
  }
}

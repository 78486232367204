import i18n from '@/lang/i18n';
import { languages } from '@/lang';

export default class Language {
  static getAll() {
    return languages;
  }

  static current() {
    return i18n.locale;
  }

  static isKz() {
    return i18n.locale === 'kk';
  }

  static getDomain() {
    const hostSplit = window.location.hostname.split('.');
    if (hostSplit.length >= 2) {
      return hostSplit.slice(hostSplit.length - 2, hostSplit.length).join('.');
    }
    return hostSplit[0];
  }

  static change(lang) {
    if (i18n.locale === lang) {
      return;
    }

    i18n.locale = lang;
    const domain = this.getDomain() || window.location.hostname;
    document.cookie = `locale=${lang}; domain=${domain}; path=/`;
  }

  static isCurrent(lang) {
    return i18n.locale === lang;
  }
}

<template>
  <div v-if="currentRole === 'teacher' && show" class="global-notification">
    <base-svg class="global-notification__svg" iconName="ExclamationPointIcon" :height="16" />
    <span class="global-notification__text">
      Нам нужна ваша помощь. Помогите платформе «Открытая школа» стать лучше. Пройдите важный опрос. Это займет не более 4 минут.
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSdBBedTgxtnKvZ52I0p8efWtBr2z3136q5mUKcMAFfHbUzQFQ/viewform?usp=sf_link"
        >Ссылка на опрос.</a
      >
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'GlobalNotification',
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters('navs', { currentRole: 'getRole' }),
  },
};
</script>

<style lang="less" scoped>
.global-notification {
  background-color: #fb0;
  background-clip: content-box;
  border-radius: 12px;
  height: auto;
  margin: auto;
  max-width: 1280px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    max-width: 768px;
  }
  @media (max-width: 480px) {
    max-width: 480px;
  }

  &__svg {
    margin: 0 10px 0 16px;
  }

  &__text {
    margin: 5px 0;
  }
}
</style>

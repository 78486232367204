import Vue from 'vue';
import Vuedals from 'vuedals';
import Vue2TouchEvents from 'vue2-touch-events';
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';
import { Vue2Dragula } from 'vue2-dragula';
import VueScrollactive from 'vue-scrollactive';
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import 'moment/locale/ru';

import DateFilter from '@/helpers/dateFilter';
import PercentFilter from '@/helpers/percentFilter';
import NumberEndingFilter from '@/helpers/numberEndingFilter';

import router from '@/router';
import store from './store/index';
import App from './App';
import i18n from './lang/i18n';
import sentry from './service/sentry';
import baseComponents from './components';
import yaMetrica from './service/yaMetrica';
import routerMiddleware from './router/routerMiddleware';

Vue.use(require('vue-cookies'));

sentry.init();
baseComponents.registrateBaseComponents();
yaMetrica.init();
routerMiddleware.init();

Vue.config.productionTip = false;

Vue.use(Vuedals);
Vue.use(Vue2Dragula);
Vue.use(Vue2TouchEvents);
Vue.use(VueScrollactive);
Vue.use(VuePlyr);
Vue.use(ToastPlugin, {
  duration: 3000,
  position: 'top',
});

Vue.filter('date', DateFilter);
Vue.filter('percent', PercentFilter);
Vue.filter('numberEnding', NumberEndingFilter);

const EventBus = new Vue();
Object.defineProperties(Vue.prototype, {
  $bus: {
    get() {
      return EventBus;
    },
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

<template>
  <div>
    <div class="new-base-check-box" :class="isChecked ? 'checked' : 'not-checked'">
      <svg v-if="isChecked" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.99771 0.252601C10.4105 0.619519 10.4477 1.25159 10.0808 1.66437L4.74742 7.66437C4.55837
            7.87706 4.28766 7.99913 4.0031 8.00001C3.71853 8.00088 3.44708 7.88049 3.25671 7.66898L0.256714
            4.33564C-0.112745 3.92513 -0.0794662 3.29284 0.331044 2.92338C0.741554 2.55392 1.37384 2.5872
            1.7433 2.99771L3.99538 5.50002L8.58593 0.335647C8.95285 -0.0771359 9.58492 -0.114317 9.99771
            0.252601Z"
          fill="white"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewBaseCheckBox',
  props: {
    isChecked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //
    };
  },
};
</script>

<style lang="less" scoped>
@import '~less_vars';

.checked {
  background: @surface-accent;
  border: 2px solid @surface-accent;
}

.not-checked {
  background-color: inherit;
  border: 2px solid @surface-light-gray;
}

.new-base-check-box {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  display: flex;
  padding-top: 4.5px;
  padding-left: 3.3px;
}
</style>

const XlsxStudents = {
  namespaced: true,
  state: {
    teachers: [],
  },
  mutations: {
    UPDATE_STUDENTS: (state, subjAreas) => {
      state.subjAreas = subjAreas;
    },
    DELETE_STUDENT: (state, idx) => {
      state.teachers.splice(idx + 1, 1);
    },
    SET_STUDENTS: (state, teachers) => {
      state.teachers = teachers;
    },
    CHANGE_STUDENT_FIELD: (state, { idx, typeField, value }) => {
      if (typeField === 'email') {
        console.log(idx, typeField, value);
        state.teachers[idx].email.value = value;
        // TODO: допилить логику проверки на дубликат в бд
        state.teachers[idx].email.isDublicateCurrent = false;
        state.teachers[idx].email.isDublicateInDb = false;
      }
      if (typeField === 'phone') {
        state.teachers[idx].phone = value;
      }
      if (typeField === 'firstName') {
        state.teachers[idx].firstName = value;
      }
      if (typeField === 'lastName') {
        state.teachers[idx].lastName = value;
      }
      if (typeField === 'secondName') {
        state.teachers[idx].secondName = value;
      }
      if (typeField === 'subjects') {
        state.teachers[idx].subjects = value;
      }
    },
  },
  getters: {
    getStudents(state) {
      return state.teachers;
    },
  },
  actions: {},
};
export default XlsxStudents;

<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.24902 1.75049C8.24902 2.30277 8.69674 2.75049 9.24902 2.75049H11.8348L8.54192 6.04338C8.15139 6.43391 8.15139 7.06707 8.54192 7.4576C8.93244 7.84812 9.56561 7.84812 9.95613 7.45759L13.249 4.1647V6.75049C13.249 7.30277 13.6967 7.75049 14.249 7.75049C14.8013 7.75049 15.249 7.30277 15.249 6.75049V1.75049C15.249 1.1982 14.8013 0.750488 14.249 0.750488H9.24902C8.69674 0.750488 8.24902 1.1982 8.24902 1.75049ZM2.74902 9.25049C2.74902 8.6982 2.30131 8.25049 1.74902 8.25049C1.19674 8.25049 0.749023 8.6982 0.749023 9.25049V14.2505C0.749023 14.8028 1.19674 15.2505 1.74902 15.2505H6.74902C7.30131 15.2505 7.74902 14.8028 7.74902 14.2505C7.74902 13.6982 7.30131 13.2505 6.74902 13.2505H4.16324L7.45613 9.95759C7.84665 9.56707 7.84665 8.9339 7.45613 8.54338C7.06561 8.15286 6.43244 8.15286 6.04192 8.54338L2.74902 11.8363V9.25049Z"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height'],
};
</script>

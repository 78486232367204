<template>
  <div class="user-block" :class="{ 'is-pending': !user }">
    <div class="user-block__content">
      <div class="user-block__image" @click="profileNavigate">
        <div class="user-block__image-placeholder">
          <span>{{ initials }}</span>
        </div>
        <div v-if="user" class="user-block__photo" :style="{ backgroundImage: `url(${user.photo})` }"></div>
      </div>

      <div class="user-block__data" v-if="user">
        <div class="user-block__data-row">
          <router-link
            v-if="getRole !== 'guest'"
            class="user-block__name"
            :to="{ name: 'profile' }"
            @click.native="toggleSidebar || null"
          >
            {{ user.fullName }}
          </router-link>
          <a v-if="getRole === 'guest'" class="user-block__name">{{ user.fullName }}</a>
          <div class="user-block__logout" @click="logout">
            <base-icon glyph="#icon-log-out" :title="$t('Выйти')" width="18px" height="18px" />
          </div>
        </div>
        <div class="user-block__data-row">
          <div class="user-block__status" v-if="user.roles && user.roles.length === 1">
            {{ $t(roleTextsData[user.roles[0]]) }}
          </div>
          <div class="user-block__status" v-if="user.roles && user.roles.length > 1">
            <multiselect
              :value="{ role: role, label: this.$t(roleTextsData[role]) }"
              @select="onSelect"
              :preselect-first="true"
              :searchable="false"
              :show-labels="false"
              :options="roleTexts"
              label="label"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';

export default {
  name: 'UserBlock',
  components: {
    Multiselect,
  },
  props: {
    pending: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: null,
    },
    toggleSidebar: Function,
  },
  computed: {
    ...mapState('navs', {
      roleTextsData: (state) => state.roleTexts,
      role: (state) => state.role,
    }),
    ...mapGetters('navs', ['getRole']),

    isVisibleSettings() {
      return this.getRole === 'teacher' || this.getRole === 'student' || this.getRole === 'subs' || this.getRole === 'content';
    },
    roleTexts() {
      return this.user.roles.map((role) => ({
        role,
        label: this.$t(this.roleTextsData[role]),
      }));
    },
    initials() {
      if (!this.user) return null;
      const { firstName = '', lastName = '' } = this.user;
      return firstName.slice(0, 1) + lastName.slice(0, 1);
    },
  },
  methods: {
    ...mapActions('navs', {
      changeRole: 'changeRole',
    }),
    ...mapActions('auth', ['logout']),

    onSelect(value) {
      this.changeRole(value.role);
    },

    profileNavigate() {
      if (this.getRole === 'guest') return;
      if (this.toggleSidebar) this.toggleSidebar();
      this.$router.push({ name: 'profile' });
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';
.user-block {
  &__content {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  &__logout {
    width: 28px;
    height: 28px;
    cursor: pointer;
    border-radius: 3px;
    display: inline-block;
    margin-left: 10px;
    position: relative;
    vertical-align: middle;

    &:hover {
      background-color: @primary-color;
      --primaryIconColor: #fff;
      --secondaryIconColor: #fff;
    }

    & > .base-icon {
      position: absolute;
      transition: fill 0.3s;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }

  &__name {
    display: inline-block;
    font-weight: 600;
    font-size: @base-fz;
    line-height: 20px;
    color: @primary-color;
    max-width: 200px;
    text-decoration: unset;
    vertical-align: middle;

    &.router-link-active {
      color: @gray-disabled;
    }
  }
  &__status > .multiselect > .multiselect__tags > .multiselect__single {
    background: unset;
  }

  &__status {
    font-size: @base-fz;
    line-height: 20px;
    color: @gray-dark;
    display: inline-block;
  }

  &__data {
    position: relative;
    padding-right: 42px;
  }

  &__image {
    position: relative;
    flex: 0 0 52px;
    max-width: 52px;
    height: 52px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    cursor: pointer;

    &-placeholder {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: @gray-disabled;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: @bigger-fz;
      font-weight: bold;
      color: @gray-light-2;
      text-transform: uppercase;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        border: 3px solid @gray-disabled;
        border-radius: 50%;
        border-top-color: @secondary-color;
        animation: base-spinner-rotation 0.7s infinite linear both;
        opacity: 0;
      }
    }

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    img {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  &.is-pending &__image-placeholder::before {
    opacity: 1;
  }

  .multiselect {
    min-width: 80px;
    min-height: unset;
    background: transparent;

    &__content-wrapper {
      left: 0;
      right: unset !important;
    }

    &__single {
      padding-left: 0;
    }

    &__select,
    &__tags {
      height: 30px;
      min-height: unset;
      text-align: left;
      background: transparent;
    }
    &__tags {
      padding: 5px 30px 0 0 !important;
    }
  }

  @media (max-width: 1200px) {
    &__status {
      font-size: 14px;
      line-height: 18px;
    }
    &__name {
      font-size: 14px;
    }
    &__image {
      flex: 0 0 47px;
      max-width: 47px;
      height: 47px;
      align-self: center;
    }
  }
}
</style>

<template>
  <component :is="parseText" v-on="this.$listeners" />
</template>

<script>
import AudioBtn from '@/components/Lesson/AudioBtn';
import ImageComponent from '@/components/Lesson/ImageComponent';

export default {
  name: 'BaseWysiwyg',
  props: {
    text: String,
    replaceImages: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    parseText() {
      let template = '<div v-on="this.$listeners"></div>';
      if (this.text) {
        let renderedText = this.text
          .replace(/<audio class="ql-audio" controls="true"/g, '<AudioBtn')
          .replace(/<\/audio>/g, '</AudioBtn>');

        if (this.replaceImages) {
          renderedText = renderedText.replace(/<img(.*?)src="(.*?)"(.*?)>/gi, '<ImageComponent $1 src="$2"/>');
        }

        template = `<div v-on="this.$listeners">${renderedText}</div>`;
      }

      return {
        components: { AudioBtn, ImageComponent },
        template,
      };
    },
  },
};
</script>

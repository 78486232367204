<template>
  <div
    class="gap-input gap-input_blocklog"
    :data-project-name="projectName"
    :contenteditable="!locked"
    @click="handler"
    style="visibility: hidden"
  >
    {{ val }}
  </div>
</template>

<script>
import iframeMessageListener from '@/helpers/iframeMessageListener';

export default {
  name: 'GapInputBlocklog',
  data() {
    return {
      val: '',
    };
  },
  props: {
    name: String,
    defaultValue: String,
    projectName: String,
    locked: Boolean,
  },
  methods: {
    handler(e) {
      const { name } = this;
      // emit with VAL from 'innerText' - из iframe писать в html и делать клик
      this.$parent.$emit('gapChange', { val: e.target.innerText, name });
    },
  },
  mounted() {
    this.val = this.defaultValue.trim();
    iframeMessageListener();
  },
};
</script>

<style lang="less"></style>

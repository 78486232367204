<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.99927 2.00049C8.55155 2.00049 8.99927 2.4482 8.99927 3.00049L8.99927 7.00049H12.9993C13.5516 7.00049 13.9993 7.4482 13.9993 8.00049C13.9993 8.55277 13.5516 9.00049 12.9993 9.00049H8.99927L8.99927 13.0005C8.99927 13.5528 8.55155 14.0005 7.99927 14.0005C7.44698 14.0005 6.99927 13.5528 6.99927 13.0005L6.99927 9.00049H2.99927C2.44698 9.00049 1.99927 8.55277 1.99927 8.00049C1.99927 7.4482 2.44698 7.00049 2.99927 7.00049H6.99927L6.99927 3.00049C6.99927 2.4482 7.44698 2.00049 7.99927 2.00049Z"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height'],
};
</script>

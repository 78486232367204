<!--eslint-disable max-len-->
<template>
  <svg width="24" height="26" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.375 2.49963C3.33947 2.49963 2.5 3.3391 2.5 4.37463C2.5 5.41017 3.33947 6.24963 4.375 6.24963C5.41053 6.24963 6.25 5.41017 6.25 4.37463C6.25 3.3391 5.41053 2.49963 4.375 2.49963ZM4.375 8.12463C3.33947 8.12463 2.5 8.9641 2.5 9.99963C2.5 11.0352 3.33947 11.8746 4.375 11.8746C5.41053 11.8746 6.25 11.0352 6.25 9.99963C6.25 8.9641 5.41053 8.12463 4.375 8.12463ZM2.5 15.6246C2.5 14.5891 3.33947 13.7496 4.375 13.7496C5.41053 13.7496 6.25 14.5891 6.25 15.6246C6.25 16.6602 5.41053 17.4996 4.375 17.4996C3.33947 17.4996 2.5 16.6602 2.5 15.6246ZM8.75 3.12463C8.05964 3.12463 7.5 3.68428 7.5 4.37463C7.5 5.06499 8.05964 5.62463 8.75 5.62463H16.25C16.9404 5.62463 17.5 5.06499 17.5 4.37463C17.5 3.68428 16.9404 3.12463 16.25 3.12463H8.75ZM7.5 9.99963C7.5 9.30928 8.05964 8.74963 8.75 8.74963H16.25C16.9404 8.74963 17.5 9.30928 17.5 9.99963C17.5 10.69 16.9404 11.2496 16.25 11.2496H8.75C8.05964 11.2496 7.5 10.69 7.5 9.99963ZM8.75 14.3746C8.05964 14.3746 7.5 14.9343 7.5 15.6246C7.5 16.315 8.05964 16.8746 8.75 16.8746H16.25C16.9404 16.8746 17.5 16.315 17.5 15.6246C17.5 14.9343 16.9404 14.3746 16.25 14.3746H8.75Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    viewBox: String,
    width: Number,
    height: Number,
  },
};
</script>

<template>
  <div class="main-footer">
    <div class="main-footer__logo">
      <a href="/" class="main-footer__logo-link">
        <img class="main-footer__logo-image" src="../../assets/logo-white.svg" alt="2035school" />
      </a>
      <div class="main-footer__logo-text">© {{ currentYear }} — {{ $t('Все права защищены') }}</div>
    </div>
    <div class="main-footer__menu">
      <div class="main-footer__menu-col">
        <div class="main-footer__menu-heading">О нас</div>
        <a href="/feedback" class="main-footer__menu-link">{{ $t('Задать вопрос') }}</a>
        <a href="/help" class="main-footer__menu-link">{{ $t('Помощь') }}</a>
        <a href="/edu" class="main-footer__menu-link">{{ $t('Методический раздел') }}</a>
      </div>
      <div class="main-footer__menu-col">
        <div class="main-footer__menu-heading">{{ $t('Контакты') }}</div>
        <a href="mailto:support@2035school.ru" class="main-footer__menu-link">support<span class="at">@</span>2035school.ru</a>
        <a href="tel:+78007757672" class="main-footer__menu-link is-phone">8 <span class="code">800</span> 775-76-72</a>
      </div>
      <!-- <div class="main-footer__menu-col is-socials">
        <div class="main-footer__menu-heading">Социальные сети</div>
        <div class="main-footer__menu-links">
          <a href="#" class="main-footer__menu-link is-fb"><img src="../../assets/fb.svg" alt=""></a>
          <a href="#" class="main-footer__menu-link is-tg"><img src="../../assets/tg.svg" alt=""></a>
          <a href="#" class="main-footer__menu-link is-ig"><img src="../../assets/ig.svg" alt=""></a>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainFooter',
  components: {},
  computed: {
    currentYear() {
      const date = new Date();
      return date.getFullYear();
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.main-footer {
  position: relative;
  display: flex;

  &::before {
    display: block;
    content: url(../../assets/circle-footer.svg);
    position: absolute;
    left: -259px;
    top: -137px;
    z-index: -1;
  }

  &__logo {
    margin-right: 197px;

    &-image {
      display: block;
      width: 214px;
      margin-bottom: 33px;
    }

    &-text {
      font-weight: bold;
      font-size: 15px;
      line-height: 26px;
      color: #ffffff;
      opacity: 0.5;
    }
  }

  &__menu {
    padding-top: 33px;
    display: flex;
    justify-content: space-between;
    border-top: 2px solid #e9f2f9;

    a {
      text-decoration: none;
      transition: color 0.2s ease;
      color: @primary-color;

      &:visited {
        color: @primary-color;
      }

      &:hover {
        color: @secondary-color;
        transition: color 0.2s ease-out;
      }
    }

    &-links {
      display: flex;
    }

    &-col {
      display: flex;
      flex-direction: column;
      margin-right: 32px;

      &:last-child {
        margin-right: 0;
      }
    }

    &-heading {
      font-weight: bold;
      font-size: 17px;
      line-height: 26px;
      color: #000000;
      margin-bottom: 28px;
    }

    &-link {
      margin-bottom: 22px;
      font-weight: bold;

      &:last-child {
        margin-bottom: 0;
      }

      span.at {
        color: @secondary-color;
      }

      &.is-phone {
        color: #000;

        span.code {
          color: @secondary-color;
        }
      }

      &.is-fb,
      &.is-tg {
        margin-right: 10px;
      }
    }
  }

  @media (max-width: 768px) {
    &::before {
      left: -350px;
      top: -160px;
    }

    &__logo {
      margin-right: 85px;
    }
  }

  @media (max-width: 480px) {
    margin-bottom: 25px;
    &::before {
      display: none;
    }

    &__logo {
      display: none;
    }

    &__menu {
      width: 100%;
      justify-content: space-between;
    }
  }
}
</style>

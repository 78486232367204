const news = (HTTP) => ({
  namespaced: true,
  state: {
    pending: null,
    news: [],
    shortNews: [],
    newsItem: null,
  },
  getters: {
    news: (state) => state.news,
    pending: ({ pending }) => pending,
    shortNews: ({ shortNews }) => shortNews,
    newsItem: (state) => state.newsItem,
  },
  mutations: {
    SAVE_SHORTNEWS(state, value) {
      state.shortNews = value;
    },
    SAVE_NEWS(state, value) {
      state.news = value;
    },
    SET_PENDING(state, value) {
      state.pending = value;
    },
    SET_NEWSITEM(state, value) {
      state.newsItem = value;
    },
  },
  actions: {
    // eslint-disable-next-line consistent-return
    async getShortNews({ commit }) {
      try {
        commit('SET_PENDING', true);
        const { data } = await HTTP.get('/api/getshortnews/');
        if (data && data.error) {
          throw new Error(data.error);
        }
        commit('SAVE_SHORTNEWS', data);
        commit('SET_PENDING', false);
      } catch (e) {
        console.error('getShortNews error: ', e.message);
      }
    },
    // eslint-disable-next-line consistent-return
    async getNews({ commit }) {
      try {
        commit('SET_PENDING', true);
        const { data } = await HTTP.get('/api/getnews/');
        if (data && data.error) {
          throw new Error(data.error);
        }
        commit('SAVE_NEWS', data);
        commit('SET_PENDING', false);
      } catch (e) {
        console.error('getNews error: ', e.message);
      }
    },
    // eslint-disable-next-line consistent-return
    async getNewsItem({ commit }, id) {
      try {
        commit('SET_PENDING', true);
        const { data } = await HTTP.get(`/api/getnewsitem/`, { params: { id } });
        if (data && data.error) {
          throw new Error(data.error);
        }
        commit('SET_NEWSITEM', data);
        commit('SET_PENDING', false);
      } catch (e) {
        console.error('getNews error: ', e.message);
      }
    },
  },
});

export default news;

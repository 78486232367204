<!-- eslint-disable max-len -->
<template>
  <div class="main-reviews">
    <div class="main-reviews__title">{{ $t('Отзывы') }}</div>
    <div class="main-reviews__photo1">
      <img
        class="main-reviews__photo1-image"
        src="../../assets/img-reviews-teacher-1.png"
        alt=""
        srcset="../../assets/img-reviews-teacher-1@2x.png 2x"
      />
    </div>
    <swiper :options="swiperOption" class="swiper-container" ref="carousel">
      <swiper-slide>
        <div class="main-reviews__review">
          <div class="main-reviews__review-photo">
            <img
              class="main-reviews__review-photo-image"
              src="../../assets/img-reviews-teacher-main-1.png"
              alt=""
              srcset="../../assets/img-reviews-teacher-main-1@2x.png 2x"
            />
          </div>
          <div class="main-reviews__review-text">
            {{
              $t(
                '«Материалы по истории соответствуют историко-культурному стандарту, кодификатору ЕГЭ, а также линейной системе преподавания истории. Помимо видеороликов, к каждому уроку есть задания на закрепление материала, работу с анализом исторических источников, картами и изображениями»'
              )
            }}
          </div>
          <div class="main-reviews__review-name">Анастасия Сухарева</div>
          <div class="main-reviews__review-position">{{ $t('учитель истории ГБОУ Школа №554') }}</div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="main-reviews__review">
          <div class="main-reviews__review-photo">
            <img
              class="main-reviews__review-photo-image"
              src="../../assets/img-reviews-teacher-main-2.png"
              alt=""
              srcset="../../assets/img-reviews-teacher-main-2@2x.png 2x"
            />
          </div>
          <div class="main-reviews__review-text">
            {{
              $t(
                '«Курс по физике содержит практически полностью все разделы, изучаемые в средней и старшей школе. Включенные уроки полезны для обучающихся и педагогов. Каждый урок достаточно компактный и легко может быть встроен в урок в классе либо полностью, либо частично. Также в каждом уроке имеются интерактивные упражнения на закрепление изученного материала»'
              )
            }}
          </div>
          <div class="main-reviews__review-name">Роман Колтунов</div>
          <div class="main-reviews__review-position">{{ $t('учитель физики ГБОУ СОШ № 444') }}</div>
        </div>
      </swiper-slide>

      <div class="main-reviews__navigation-prev" slot="button-prev"></div>

      <div class="main-reviews__navigation-next" slot="button-next"></div>
    </swiper>
    <div class="main-reviews__photo2">
      <img
        class="main-reviews__photo2-image"
        src="../../assets/img-reviews-teacher-2.png"
        alt=""
        srcset="../../assets/img-reviews-teacher-2@2x.png 2x"
      />
    </div>
    <div class="main-reviews__photo3">
      <img
        class="main-reviews__photo3-image"
        src="../../assets/img-reviews-teacher-3.png"
        alt=""
        srcset="../../assets/img-reviews-teacher-3@2x.png 2x"
      />
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';

export default {
  name: 'MainReviews',
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOption: {
        navigation: {
          nextEl: '.main-reviews__navigation-next',
          prevEl: '.main-reviews__navigation-prev',
        },
        loop: true,
      },
      activeSlide: 0,
    };
  },
  methods: {},
};
</script>

<style lang="less">
@import '~less_vars';

.main-reviews {
  position: relative;
  padding-bottom: 10px;

  .swiper-container {
    overflow: initial;
    margin-bottom: 40px;
  }

  &__title {
    display: none;
    font-weight: bold;
    font-size: 50px;
    color: @secondary-color;
    margin-bottom: 40px;
  }

  &__review {
    padding: 0 300px;
    text-align: center;

    &-photo {
      background: url(../../assets/bg-bubble.svg) no-repeat 50% 0;
      height: 226px;
    }

    &-text {
      font-weight: bold;
      font-size: @bigger-fz;
      line-height: 27px;
      color: #ffffff;
      margin-bottom: 27px;
    }

    &-name {
      font-weight: bold;
      font-size: 15px;
      line-height: 22px;
      color: #ffffff;
      padding: 16px 60px;
      border: 3px dotted rgba(255, 255, 255, 0.3);
      border-radius: 90px;
      display: inline-block;
      margin-bottom: 16px;
    }

    &-position {
      font-weight: bold;
      font-size: 13px;
      line-height: 19px;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  &__navigation {
    &-prev {
      cursor: pointer;
      display: inline-block;
      background: #fff url(../../assets/arr-prev.svg) no-repeat 47%;
      width: 58px;
      height: 58px;
      border-radius: 50%;
      box-shadow: 0px 15px 20px rgba(188, 0, 68, 0.4);
      position: absolute;
      margin-top: 40px;
      left: 50%;
      margin-left: -70px;
    }

    &-next {
      cursor: pointer;
      display: inline-block;
      background: #fff url(../../assets/arr-next.svg) no-repeat 53%;
      width: 58px;
      height: 58px;
      border-radius: 50%;
      box-shadow: 0px 15px 20px rgba(188, 0, 68, 0.4);
      position: absolute;
      margin-top: 40px;
      left: 50%;
      margin-left: 5px;
    }
  }

  &__photo1 {
    position: absolute;
    top: 30px;
    left: 202px;

    img {
      border-radius: 50%;
      display: block;
    }
  }

  &__photo2 {
    position: absolute;
    bottom: 34px;
    left: 314px;

    img {
      border-radius: 50%;
      display: block;
    }
  }

  &__photo3 {
    position: absolute;
    bottom: -2px;
    right: 231px;

    img {
      border-radius: 50%;
      display: block;
    }
  }

  @media (max-width: 1200px) {
    margin-top: -250px;

    &__review {
      padding: 0 200px;
    }
    &__photo1 {
      left: 190px;
      top: 30px;
    }

    &__photo2 {
      left: 170px;
    }

    &__photo3 {
      right: 251px;
    }
  }

  @media (max-width: 768px) {
    margin-top: 0;

    .swiper-container {
      background-color: @primary-color;
      padding: 20px 0px 100px;
      border-radius: 12px;
    }

    &__photo1,
    &__photo2,
    &__photo3 {
      display: none;
    }

    &__title {
      display: block;
    }

    &__review {
      position: relative;
      padding: 0;
      &-text {
        padding: 0 20px;
      }
    }

    &__navigation {
      position: absolute;
      left: 41.5%;
      bottom: 15px;

      &-next,
      &-prev {
        margin-top: 20px;
        box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.4);
      }
    }
  }

  @media (max-width: 480px) {
    &__title {
      margin-bottom: 25px;
      font-size: 35px;
    }

    &__navigation {
      left: 50%;
      margin-left: -63px;
    }
  }
}
</style>

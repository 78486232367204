import { render, staticRenderFns } from "./NewUserBlock.vue?vue&type=template&id=3c5f84c1&"
import script from "./NewUserBlock.vue?vue&type=script&lang=js&"
export * from "./NewUserBlock.vue?vue&type=script&lang=js&"
import style0 from "./NewUserBlock.vue?vue&type=style&index=0&id=3c5f84c1&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
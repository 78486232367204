<!-- eslint-disable max-len -->
<template>
  <div class="main-organization">
    <div class="main-organization__title">{{ $t('Организация обучения') }}</div>
    <div class="main-organization__item is-comp">
      <div class="main-organization__item-textblock">
        <div class="main-organization__item-heading">{{ $t('В компьютерном классе') }}</div>
        <div class="main-organization__item-text">
          {{
            $t(
              'Проводите уроки в компьютерном классе и за его пределами с использованием мобильных классов или личных устройств учеников (BYOD)'
            )
          }}
        </div>
      </div>
      <img src="../../assets/ico-organisation-computer.svg" alt="" class="main-organization__item-image" />
    </div>
    <div class="main-organization__item is-self">
      <div class="main-organization__item-textblock">
        <div class="main-organization__item-heading">{{ $t('Самостоятельное изучение') }}</div>
        <div class="main-organization__item-text">
          {{
            $t(
              'Виртуальные симуляторы, разноуровневые материалы, практические задачи позволят Вам организовать внеурочную деятельность интересно, разнообразно и практикоориентированно'
            )
          }}
        </div>
      </div>
      <img src="../../assets/ico-organisation-self-learning.svg" alt="" class="main-organization__item-image" />
    </div>
    <div class="main-organization__item is-inverted">
      <div class="main-organization__item-textblock">
        <div class="main-organization__item-heading">{{ $t('Перевернутый класс') }}</div>
        <div class="main-organization__item-text">
          {{
            $t(
              'Перенесите изучение нового материала на дом, а на уроках устраивайте увлекательные исследования, игры и закрепление материала'
            )
          }}
        </div>
      </div>
      <img src="../../assets/ico-organisation-reverse-class.svg" alt="" class="main-organization__item-image" />
    </div>
    <div class="main-organization__item is-workspace">
      <div class="main-organization__item-textblock">
        <div class="main-organization__item-heading">{{ $t('Смена рабочих зон') }}</div>
        <div class="main-organization__item-text">
          {{
            $t(
              'Использование платформы позволяет организовать на уроке зону онлайн-работы с платформой, эффективно сочетать традиционные подходы при изучении нового материала и технологии электронного обучения'
            )
          }}
        </div>
      </div>
      <img src="../../assets/ico-organisation-zone-change.svg" alt="" class="main-organization__item-image" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainOrganization',
  components: {},
};
</script>

<style lang="less">
@import '~less_vars';

.main-organization {
  position: relative;

  &__title {
    font-weight: bold;
    font-size: 50px;
    text-align: center;
    letter-spacing: 0.025em;
    color: #4507ca;
    margin-bottom: 66px;
  }

  &__item {
    position: relative;

    &.is-comp {
      width: 24%;
      margin-left: 590px;
      margin-bottom: 81px;

      .main-organization__item-image {
        top: 0;
        left: -160px;
        box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
      }
    }

    &.is-self {
      width: 27%;
      margin-left: 335px;
      margin-bottom: 91px;

      .main-organization__item-image {
        top: 0;
        left: -160px;
        box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
      }
    }

    &.is-inverted {
      width: 22%;
      margin-left: 424px;

      .main-organization__item-image {
        top: 0;
        left: -160px;
        box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
      }
    }

    &.is-workspace {
      width: 26%;
      margin-top: -28px;
      margin-left: 890px;

      .main-organization__item-image {
        top: 0;
        left: -160px;
        box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
      }
    }

    &-heading {
      font-weight: bold;
      font-size: @subheader-fz;
      line-height: 29px;
      color: #000000;
      margin-bottom: 20px;
    }

    &-text {
      font-size: @bigger-fz;
      line-height: 1.5;
      color: #7e7e7e;
    }

    &-image {
      border-radius: 50%;
      position: absolute;
    }
  }

  @media (max-width: 1200px) {
    &__item {
      &.is-comp {
        margin-left: 450px;
      }

      &.is-self {
        margin-left: 300px;
      }

      &.is-inverted {
        margin-left: 435px;
      }

      &.is-workspace {
        margin-top: -85px;
        margin-left: 850px;
      }
    }
  }

  @media (max-width: 768px) {
    &__title {
      margin-bottom: 40px;
      text-align: left;
      color: @secondary-color;
    }

    &__item {
      width: 100%;
      background-color: #fff;
      padding: 20px 20px;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.14);
      border-radius: 12px;
      margin-bottom: 25px;
      min-height: 190px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;

      &-textblock {
        width: 75%;
      }

      &-image {
        position: initial;
        margin-right: 20px;
      }

      &.is-comp {
        width: 100%;
        margin-left: 0;
        margin-bottom: 25px;
      }

      &.is-self {
        width: 100%;
        margin-left: 0;
        margin-bottom: 25px;
      }

      &.is-inverted {
        width: 100%;
        margin-left: 0;
        margin-bottom: 25px;
      }

      &.is-workspace {
        width: 100%;
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 25px;
      }
    }
  }

  @media (max-width: 480px) {
    &__title {
      margin-bottom: 25px;
      font-size: 35px;
    }

    &__item {
      &-heading {
        font-size: 20px;
      }

      &-text {
        font-size: 16px;
      }
    }
  }
}
</style>

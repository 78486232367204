import { HTTP } from '@/http';

const administrators = {
  namespaced: true,
  state: {
    ids: [],
  },
  mutations: {
    FETCH_ADMINISTRATORS: (state, payload) => {
      state.ids = [...new Set([...state.ids, ...payload])];
    },
    REMOVE_ADMINISTRATOR: (state, id) => {
      const idx = state.ids.indexOf(id);
      if (idx !== -1) {
        state.ids.splice(idx, 1);
      }
    },
  },
  getters: {
    list(state, getters, rootState) {
      return state.ids.map((id) => rootState.DB.administrators[id]);
    },
  },
  actions: {
    fetchAdministrators({ commit }, payload = {}) {
      const params = payload.roles ? payload : { ...payload, roles: { $in: ['subs', 'content'] } };

      return HTTP.get('/crud/user/', { params }).then((response) => {
        const data = response.data.models.map((n) => {
          n.fullName = `${n.lastName} ${n.firstName} ${n.secondName}`;
          return n;
        });
        commit(
          'FETCH_ADMINISTRATORS',
          data.map((n) => n._id)
        );
        commit(
          'FETCH_MODELS',
          {
            type: 'administrators',
            payload: data,
          },
          { root: true }
        );
      });
    },
    addAdministrator({ commit }, params) {
      params.part = ['administrator'];
      return HTTP.post('/crud/user/', { ...params })
        .then((response) => {
          const admin = response.data;

          if (admin._id) {
            const { firstName, lastName, secondName } = admin;
            admin.fullName = `${lastName} ${firstName} ${secondName}`;

            commit('FETCH_ADMINISTRATORS', [admin._id]);
            commit(
              'FETCH_MODELS',
              {
                type: 'administrators',
                payload: [admin],
              },
              { root: true }
            );
          }

          return admin;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeAdministrator({ commit }, params) {
      HTTP.delete('/crud/user/', { data: params }).then((response) => {
        if (response.data.removed) {
          const id = params._id;
          commit('REMOVE_ADMINISTRATOR', id);
          commit(
            'REMOVE_LIST_ITEM',
            {
              type: 'administrators',
              id,
            },
            { root: true }
          );
        }
      });
    },
  },
};

export default administrators;

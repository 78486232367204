export default {
  layout(state) {
    return state.layout;
  },
  students(state) {
    const { students } = state.DB;
    return Object.keys(students).map((n) => students[n]);
  },
  getAgreed(state) {
    return state.isAgreed;
  },
  getWinwdowWidth() {
    return document.documentElement.clientWidth;
  },
  isMobile(state) {
    return state.windowWidth <= 450;
  },
};

const classEditor = {
  namespaced: true,
  requests: {},
  state: {
    litera: null,
    number: null,
    search: null,
    filter: {
      litera: null,
      number: null,
    },
  },
  mutations: {
    SET_SEARCH: (state, payload) => {
      state.search = payload;
    },
    SET_FILTER: (state, payload) => {
      state.filter = { ...state.filter, ...payload };
    },
  },
  actions: {
    fetchStudents() {},
  },
  getters: {
    getStudents(state, getters, rootState) {
      const data = rootState.DB.users;
      let users = Object.keys(data).map((n) => rootState.DB.users[n]);
      const { filter, search } = state;

      if (filter.litera) {
        users = users.filter((n) => n.litera === filter.litera);
      }

      if (filter.number) {
        users = users.filter((n) => n.number === filter.number);
      }

      if (search) {
        const re = new RegExp(search, 'i');
        users = users.filter((n) => n.fullName.search(re) >= 0);
      }

      return users;
    },
  },
};

export default classEditor;

<!--eslint-disable max-len-->
<template>
  <svg width="90" height="48" viewBox="0 0 76 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <linearGradient id="linear-gradient">
      <stop offset="0%" stop-color="#ffffff" stop-opacity="0" />
      <stop offset="5%" stop-color="#ffffff" stop-opacity="0.2" />
      <stop offset="10%" stop-color="#ffffff" stop-opacity="0.3" />
      <stop offset="15%" stop-color="#ffffff" stop-opacity="0.3" />
      <stop offset="20%" stop-color="#ffffff" stop-opacity="0.4" />
      <stop offset="25%" stop-color="#ffffff" stop-opacity="0.8" />
      <stop offset="30%" stop-color="#ffffff" stop-opacity="0.9" />
      <stop offset="40%" stop-color="#ffffff" stop-opacity="1" />
      <stop offset="50%" stop-color="#ffffff" stop-opacity="1" />
      <stop offset="60%" stop-color="white" stop-opacity="1" />
      <stop offset="100%" stop-color="white" />
    </linearGradient>
    <linearGradient id="linear-gradient2">
      <stop offset="0%" stop-color="var(--background-light-accent-bg)" stop-opacity="0" />
      <stop offset="5%" stop-color="var(--background-light-accent-bg)" stop-opacity="0.2" />
      <stop offset="10%" stop-color="var(--background-light-accent-bg)" stop-opacity="0.3" />
      <stop offset="15%" stop-color="var(--background-light-accent-bg)" stop-opacity="0.3" />
      <stop offset="20%" stop-color="var(--background-light-accent-bg)" stop-opacity="0.4" />
      <stop offset="25%" stop-color="var(--background-light-accent-bg)" stop-opacity="0.8" />
      <stop offset="30%" stop-color="var(--background-light-accent-bg)" stop-opacity="0.9" />
      <stop offset="40%" stop-color="var(--background-light-accent-bg)" stop-opacity="1" />
      <stop offset="50%" stop-color="var(--background-light-accent-bg)" stop-opacity="1" />
      <stop offset="60%" stop-color="var(--background-light-accent-bg)" stop-opacity="1" />
      <stop offset="100%" stop-color="var(--background-light-accent-bg)" />
    </linearGradient>
    <path
      :fill="gradient"
      d="M0 0H14.4C20.5626 0 26.4891 2.3706 30.9517 6.62069L59.0483 33.3793C63.5109 37.6294 69.4374 40 75.6 40H76H0V0Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: Number,
    height: Number,
    gradient: String,
  },
};
</script>

import { HTTP } from '@/http';

const lessonsCatalog = {
  namespaced: true,
  state: {
    filter: {
      subject: null,
      classNumber: null,
      subjectArea: null,
      tags: null,
      group: null,

      // параметры для фильтрации в редизайне
      subjects: [],
      classNumbers: [],
      quarters: [],
      visibilityLessons: 'visible',
      search: '',
      // по умолчанию сортировка по дате выбора
      sort: 'byDateOfSelection',
    },
    methodicSubjects: {
      // География
      '5ebeb669d32e948db41a3e7a': ['5', '6'],
      // Татарский язык
      '5f45126fc58c99a974c593c7': ['1', '2', '3'],
      // Английский язык
      '5d67c1f435c620756ea46845': ['2', '3', '4', '5', '6'],
      // Русский язык
      '5d5ff932e7de8572c81b05cc': ['2', '3', '4'],
      // Математика
      '5b76c3f64ec1cd3415d7d910': ['2', '3', '4', '5'],
      // Алгебра
      '5b76c3f64ec1cd3415d7d91a': ['7'],
    },
    lessonsVisibilityOptions: [
      { title: 'Все', value: 'all' },
      { title: 'Видимые', value: 'visible' },
      { title: 'Скрытые', value: 'hide' },
    ],
    subjectGrades: [],
    requests: {},
    viewMode: 'cards',
    pending: true,
    totalPage: 0,
    page: 1,
    limit: 18,
  },
  mutations: {
    SET_FILTER: (state, { name, value }) => {
      state.filter[name] = value;
    },

    CLEAR_FILTERS: (state) => {
      Object.entries(state.filter).forEach(([key]) => {
        if (key === 'visibilityLessons') {
          state.filter[key] = 'visible';
          return;
        }
        state.filter[key] = null;
      });
    },
    SET_SUBJECT_GRADES: (state, data) => {
      state.subjectGrades = data;
    },
    SET_CACHE_DATA: (state, { query, data }) => {
      state.requests[JSON.stringify(query)] = data;
    },
    SET_PENDING: (state, data) => {
      state.pending = data;
    },
  },
  getters: {
    getPage(state) {
      return state.page;
    },
    getFilter(state) {
      return state.filter;
    },
    getSubjectGrades(state) {
      return state.subjectGrades;
    },
    getMethodicSubjects(state) {
      return state.methodicSubjects;
    },
    getAllVisibilityOptions(state) {
      return state.lessonsVisibilityOptions;
    },
    getVisibilityOption(state) {
      return state.lessonsVisibilityOptions.find(({ value }) => value === state.filter.visibilityLessons);
    },
  },
  actions: {
    async fetchSubjectGrades({ commit, state }, payload) {
      try {
        let cacheData = state.requests[JSON.stringify(payload)];
        if (cacheData) return cacheData;
        const response = await HTTP.get('/api/v2/fetch-subject-grade', { params: payload });
        cacheData = commit('SET_CACHE_DATA', { query: payload, data: response.data });
        return response.data;
      } catch (e) {
        console.log(e);
      }
      return null;
    },
  },
};
export default lessonsCatalog;

<template>
  <button :class="applyClasses" :disabled="disabled" @click="$emit('click', $event)">
    <slot>Кнопка</slot>
  </button>
</template>

<script>
export default {
  name: 'RefactorBaseButton',
  props: {
    theme: {
      type: [String, Array],
      default: 'primary',
    },
    size: {
      type: String,
      default: 'medium',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      baseClass: 'ref-base-btn',
    };
  },
  methods: {
    generateBemClassName(baseClass, suffix, modifiers) {
      let arr = [];
      if (modifiers) {
        arr = Array.isArray(modifiers) ? modifiers : [modifiers];
      }

      if (!arr.length) return [];
      return arr.map((modifier) => `${baseClass}__${suffix}_${modifier}`);
    },
  },
  computed: {
    applyClasses() {
      const { pending, disabled, size, theme } = this;
      let result = [this.baseClass, ...this.generateBemClassName(this.baseClass, 'size', size)];

      if (disabled) {
        result.push('disabled');
        result = [...result, ...this.generateBemClassName(this.baseClass, 'theme', `${theme}-disabled`)];
      } else {
        result = [...result, ...this.generateBemClassName(this.baseClass, 'theme', theme)];
      }
      if (pending) result.push('is-pending');

      return result;
    },
  },
};
</script>

<style lang="less" scoped>
@import '~less_vars';

.ref-base-btn {
  display: inline-block;
  outline: none;
  user-select: none;
  vertical-align: middle;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:active {
    transform: translateY(2px);
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  // themes
  &__theme {
    &_primary {
      background-color: @surface-accent;
      color: @text-white;

      &:hover {
        background-color: @surface-secondary;
      }
      &:active {
        background-color: @surface-accent;
      }
      &:focus-visible {
        background-color: @surface-secondary;
        border: 3px solid @surface-accent;
      }

      &-disabled {
        background-color: @surface-light-gray-bg;
        color: @text-light-gray;
      }
    }

    &_secondary {
      background-color: @surface-light-gray-bg;
      color: @text-dark-gray;

      &:hover {
        background-color: @surface-dark-accent-bg;
        color: @text-accent;
      }
      &:active {
        background-color: @surface-secondary;
        color: @text-white;
      }
      &:focus-visible {
        background-color: @surface-dark-accent-bg;
        border: 3px solid @surface-accent;
        color: @text-accent;
      }

      &-disabled {
        background-color: @surface-light-gray-bg;
        color: @text-light-gray;
      }
    }

    &_ghost {
      background-color: transparent;
      color: @text-dark-gray;

      &:hover {
        background-color: @surface-light-gray-bg;
        color: @text-black;
      }
      &:active {
        background-color: @surface-divider;
        color: @text-black;
      }
      &:focus-visible {
        background-color: @surface-light-gray-bg;
        border: 3px solid @surface-accent;
        color: @text-black;
      }

      &-disabled {
        color: @text-light-gray;
      }
    }
  }

  // sizes
  &__size {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;

    &_large {
      height: 64px;
      padding: 16px 24px;
      border-radius: 16px;

      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.01em;
    }
    &_medium {
      padding: 12px 16px;
      height: 48px;
      border-radius: 12px;

      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.01em;
    }
    &_small {
      padding: 8px 12px;
      height: 40px;
      border-radius: 8px;

      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.02em;
    }
  }
}
</style>

import * as Sentry from '@sentry/vue';
import VueCookies from 'vue-cookies';
import Vue from 'vue';
import moment from 'moment';
import router from '@/router';
import { HTTP } from '@/http';

const defaultState = {
  token: null,
  memorize: true,
  pending: false,
  user: null,
  error: null,
  hasEmail: false,
  index: 0,
  services: null,
  timeCodeAuth: localStorage.getItem('timecodeauth'),
  interface: localStorage.getItem('interface'),
};

const auth = {
  namespaced: true,
  state: defaultState,
  getters: {
    getToken(state) {
      return state.token;
    },
    isPending(state) {
      return state.pending;
    },
    me(state, getters, rootState) {
      return rootState.DB.users[state.user];
    },
    getServices(state) {
      return state.services;
    },
    getCurrentInterface(state) {
      return state.interface || 'old';
    },
    isNewInterface(state, getters) {
      return getters.getCurrentInterface === 'new';
    },
  },
  mutations: {
    SET_TOKEN: (state, { token, memorize }) => {
      state.token = token;
      HTTP.defaults.headers.common.Authorization = token;
      if (memorize) {
        localStorage.setItem('os_token', token);
      }
    },
    SET_TIMECODE: (state, timeCodeAuth) => {
      state.timeCodeAuth = timeCodeAuth;
      localStorage.setItem('timecodeauth', timeCodeAuth);
    },
    REMOVE_TOKEN: (state) => {
      state.token = null;
      const saved = localStorage.getItem('os_token');
      if (saved) localStorage.removeItem('os_token');

      state.timeCodeAuth = null;
      localStorage.removeItem('timecodeauth');
    },
    SET_PENDING: (state, pending) => {
      state.pending = pending;
    },
    SET_USER: (state, id) => {
      state.user = id;
    },
    SET_ERROR: (state, error) => {
      state.error = error;
    },
    SET_EMAIL_SEND: (state, flag) => {
      state.hasEmail = flag;
    },
    SET_SERVICES: (state, data) => {
      state.services = data;
    },
    // это нужно для переключения интерфейса
    SET_INTERFACE: (state, data) => {
      state.interface = data;
      localStorage.setItem('interface', data);
    },
  },
  actions: {
    async checkAccessSchool({ dispatch }, user) {
      if (user.roles.includes('super')) {
        return true;
      }

      const userPartToCheck = ['teacher', 'student'];
      if (userPartToCheck.includes(user.currentRole)) {
        if (!user.school) {
          Vue.$toast.open({
            message: 'Отсутствует школа',
            type: 'error',
          });
          return false;
        }

        const endDate = user?.subs?.endDate;
        const userHasActiveSubs = endDate && moment().isBefore(moment(endDate));

        if (!endDate) {
          const access = await dispatch('getAccess', {
            school: user.school,
          });

          if (access) {
            return true;
          }
        }

        if (userHasActiveSubs) {
          return true;
        }

        return false;
      }
      return true;
    },
    setUser({ commit }, userData) {
      Sentry.setUser({ id: userData._id, ...userData });
      commit('SET_USER', userData._id);
      commit('SET_PENDING', false);
      commit(
        'FETCH_MODELS',
        {
          type: 'users',
          payload: [userData],
        },
        { root: true }
      );
    },
    getAndSaveRole({ dispatch }, userData) {
      // TODO: удаляю так как ломает открытие урока из нового диза
      // let role = window.localStorage.getItem('role');
      let role = null;
      if (!role && userData.currentRole) {
        role = userData.currentRole;
      }
      if (!userData.roles.includes(role)) {
        role = userData.roles[0];
      }
      dispatch('navs/setRole', role, { root: true });

      if (!userData.currentRole || userData.currentRole !== role) {
        dispatch('navs/changeRole', role, { root: true, noRefresh: true });
      }
    },
    async getStatusSubsEducontUser({ dispatch, commit }) {
      try {
        const status = await dispatch('authEducont/fetchStatusUser', {}, { root: true });
        if (status && status !== 'ACTIVE') {
          await commit('SET_PENDING', false, { root: true });
          return false;
        }
        return true;
      } catch (e) {
        Vue.$toast.open({
          message: 'Не смог получить статус пользователя(едуконт)',
          type: 'error',
        });
      }
      return null;
    },
    async isIntro(user) {
      return user.isIntroViewed;
    },
    sendEventToStats({ dispatch }) {
      dispatch(
        'statistics/sendEvent',
        {
          eventType: 'openPlatform',
        },
        { root: true }
      );
    },
    async getMe({ commit, dispatch, rootState, state }, path) {
      try {
        commit('SET_PENDING', true);

        const result = await HTTP.get('/api/me/');
        const userData = result.data;

        const isActiveEducont = await dispatch('getStatusSubsEducontUser');
        if (!isActiveEducont) return;

        if (!userData._id) {
          commit('SET_PENDING', false);
          await router.push({ path: '/login/' });
          return;
        }
        dispatch('setUser', userData);
        dispatch('sendEventToStats');
        dispatch('amplitude/initAmplitude', null, { root: true });
        dispatch('getAndSaveRole', userData);
        commit('SET_AGREED', userData.agreed, { root: true });

        const isActiveSubs = await dispatch('checkAccessSchool', userData);
        if (!isActiveSubs) {
          commit('SET_PENDING', false);
          commit('REMOVE_TOKEN');
          commit('REMOVE_LIST_ITEM', { type: 'users', id: state.user }, { root: true });
          dispatch('navs/setRole', null, { root: true });
          await router.push('/accessclosed');
          return;
        }

        const redirect = rootState.redirect || path;
        if (!dispatch('isIntro', userData)) {
          await router.push('/intro');
          return;
        }

        if (redirect) {
          commit('SET_REDIRECT', null, { root: true });
          await router.push(redirect);
        }
        // eslint-disable-next-line
        return userData;
      } catch (e) {
        console.error('err', e);
        commit('SET_PENDING', false);
      }
    },
    authError({ commit }, error) {
      commit('SET_PENDING', false);
      commit('SET_ERROR', error);
    },
    async getOAuthServices({ state, commit }) {
      if (state.services) return;
      const res = await HTTP.get('/api/oauth2/services');
      commit('SET_SERVICES', res.data);
    },
    async getAccess({ commit }, params) {
      try {
        commit('SET_PENDING', true);
        const { data } = await HTTP.get('/api/access/', { params });
        commit('SET_PENDING', false);
        return data.access;
      } catch (e) {
        commit('SET_PENDING', false);
        console.error(e);
      }
      return null;
    },
    fetchExamerToken() {
      return HTTP.get('/api/getExamerToken/')
        .then((result) => result.data.token)
        .catch(() => {
          console.error('Error retrieving examer token');
        });
    },
    // eslint-disable-next-line consistent-return
    async authByCode({ dispatch, commit }, payload) {
      try {
        commit('SET_PENDING', true);
        commit('SET_ERROR', {});
        const { memorize } = payload;
        const result = await HTTP.post(`/auth/api/codelogin/`, payload);
        const { token } = result.data;
        if (token) {
          commit('SET_TOKEN', { token, memorize });
          dispatch('getMe', '/');
        } else {
          dispatch('authError', { type: 'code' });
        }
        return result.data;
      } catch (e) {
        dispatch('authError', {
          type: 'code',
          msg: '',
        });
        return { error: 'Ошибка при авторизации' };
      }
    },
    // eslint-disable-next-line consistent-return
    async authByLogin({ dispatch, commit }, payload) {
      try {
        commit('SET_PENDING', true);
        const { memorize } = payload;
        const result = await HTTP.post(`/auth/api/login/`, payload);
        const { token, timeCodeAuth } = result.data;
        if (token) {
          commit('SET_TOKEN', { token, memorize });
          if (timeCodeAuth !== undefined) {
            commit('SET_TIMECODE', timeCodeAuth);
          }
          dispatch('getMe', '/');
        } else {
          dispatch('authError', {
            type: 'login',
            msg: '',
          });
        }
        return result.data;
      } catch (e) {
        dispatch('authError', {
          type: 'common',
          msg: 'Ошибка авторизации. Пожалуйста, попробуйте позже',
        });
        return { error: 'Ошибка при авторизации' };
      }
    },
    authByEmail({ dispatch, commit }, payload) {
      commit('SET_PENDING', true);
      commit('SET_EMAIL_SEND', false);
      return HTTP.post(`/auth/api/api/generatemail/`, payload)
        .then((result) => {
          commit('SET_PENDING', false);
          if (result.data.error) {
            dispatch('authError', {
              type: 'email',
              msg: result.data.error,
            });
          } else {
            commit('SET_EMAIL_SEND', false);
            return result.data;
          }
          return null;
        })
        .catch(() => {
          dispatch('authError', {
            type: 'common',
            msg: 'Ошибка авторизации. Пожалуйста, попробуйте позже',
          });
        });
    },
    authByExpiredEmailInvite({ dispatch, commit }, payload) {
      commit('SET_PENDING', true);
      commit('SET_EMAIL_SEND', false);
      return HTTP.post(`/auth/api/api/generateinvitebyexpiredinvite/`, payload)
        .then((result) => {
          commit('SET_PENDING', false);
          if (result.data.error) {
            dispatch('authError', {
              type: 'email',
              msg: result.data.error,
            });
            return result;
          }
          commit('SET_EMAIL_SEND', false);
          return result;
        })
        .catch(() => {
          dispatch('authError', {
            type: 'common',
            msg: 'Ошибка авторизации. Пожалуйста, попробуйте позже',
          });
        });
    },
    authByAdmin({ dispatch, commit }, payload) {
      commit('SET_PENDING', true);
      commit('SET_ERROR', {});
      HTTP.post(`/auth/api/loginAdm/`, payload)
        .then((result) => {
          const { token } = result.data;
          if (token) {
            commit('SET_TOKEN', { token, memorize: true });
            dispatch('getMe', '/');
          } else {
            dispatch('authError', {
              type: 'login',
              msg: result.data.error,
            });
          }
        })
        .catch(() => {
          dispatch('authError', {
            type: 'common',
            msg: '404 Not Found',
          });
        });
    },
    checkPassword(funcs, payload) {
      return HTTP.post(`/auth/api/login/`, payload).then((response) => {
        const { token } = response.data;
        if (token) {
          return token;
        }
        return null;
      });
    },
    setMe({ commit, getters }, payload) {
      commit(
        'FETCH_MODELS',
        {
          type: 'users',
          payload: [{ ...getters.me, ...payload }],
        },
        { root: true }
      );
    },
    /**
     * @deprecated use editProfile.
     */
    updateMe({ commit }, payload) {
      return HTTP.post('/crud/user/', { ...payload }).then((response) => {
        if (!response.data.error) {
          commit(
            'FETCH_MODELS',
            {
              type: 'users',
              payload: [response.data],
            },
            { root: true }
          );
          return response.data;
        }
        return null;
      });
    },
    editProfile({ dispatch }, payload) {
      return HTTP.post('/api/profile/', { ...payload }).then((response) => {
        if (!response.data.error) {
          dispatch('setMe', response.data);
        }
        return response.data;
      });
    },
    setLogin({ dispatch }, payload) {
      return HTTP.post(`/auth/api/api/setlogin/`, payload).then((response) => {
        const result = response.data;
        if (result && !result.error) {
          dispatch('setMe', response.data);
        }
        return result;
      });
    },
    setPassword({ dispatch }, password) {
      return HTTP.post(`/auth/api/api/setpassword/`, { password }).then((response) => {
        const result = response.data;
        if (result && !result.error) {
          dispatch('setMe', response.data);
        }
        return result;
      });
    },
    setEmail({ dispatch }, email) {
      return HTTP.post(`/auth/api/api/setemail/`, { email }).then((response) => {
        const result = response.data;
        if (result && !result.error) {
          dispatch('setMe', response.data);
        }
        return result;
      });
    },
    async logout({ commit, dispatch }) {
      await HTTP.post(`/auth/api/logout/`);
      localStorage.clear();
      VueCookies.remove('guestSubject');
      VueCookies.remove('guestQr');
      VueCookies.remove('guestClasses');
      dispatch('navs/setRole', '', { root: true });

      commit('SET_USER', null);
      if (!window.location.href.includes('login')) router.replace('/login/');
    },
    setAgreed() {
      HTTP.post('/api/useragree/').catch((err) => {
        console.error('err', err);
      });
    },
    async setIntroViewed({ commit, state }) {
      await HTTP.post('/api/setIntroViewed/').catch((err) => {
        console.error('err', err);
      });

      commit(
        'UPDATE_MODELS_ITEM',
        {
          type: 'users',
          id: state.user,
          payload: { isIntroViewed: true },
        },
        { root: true }
      );
    },
  },
};

export default auth;

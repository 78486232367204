<template>
  <div class="base-search">
    <div class="base-search__icon">
      <base-icon glyph="#icon-search" width="100%" height="20px"></base-icon>
    </div>
    <input
      class="base-search__field"
      type="text"
      :value="value"
      :placeholder="$t(placeholder)"
      @input="$emit('input', $event.target.value)"
    />
    <div class="base-search__icon base-search__icon-close" v-if="value" @click="$emit('input', '')">
      <base-icon glyph="#icon-close" width="18px" height="21px"></base-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSearch',
  props: {
    value: {
      default: '',
    },
    search: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Найти',
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.base-search {
  display: flex;
  align-items: center;

  &__icon {
    width: 18px;
    height: 18px;

    &-close {
      cursor: pointer;

      .base-icon {
        --primaryIconColor: @gray-light-3;
        --secondaryIconColor: @gray-light-3;

        &:hover {
          --primaryIconColor: @primary-color;
          --secondaryIconColor: @secondary-color;
        }
      }
    }
  }

  &__field {
    padding: 1px 10px;
    flex: 1;
    overflow: hidden;
    border: 0;
    outline: 0;
    color: @base-color;
    font-size: @base-fz;
    line-height: @base-lh;
    background-color: transparent;

    &::placeholder {
      color: @gray-dark;
    }
  }
}
</style>

<template>
  <div class="main-page">
    <div class="main-page__container">
      <div class="main-page__header">
        <!-- <main-header /> -->
        <StaticHeader :toggleSidebar="toggleSidebar" :sidebarIsOpen="sidebarIsOpen" class="is-main" />
      </div>
      <div class="main-page__login">
        <main-auth />
      </div>

      <!-- <div class="main-page__big-circle-winners">
        <main-contest-winners />
      </div> -->

      <div class="main-page__big-circle-1">
        <div class="main-page__subjects">
          <main-subjects />
        </div>
        <div class="main-page__authority">
          <main-authority />
        </div>
        <div class="main-page__goals">
          <main-goals />
        </div>
      </div>

      <div class="main-page__advantages">
        <main-advantages />
      </div>

      <div class="main-page__big-circle-2">
        <div class="main-page__formats">
          <main-formats />
        </div>
        <div class="main-page__organization">
          <main-organization />
        </div>
        <div class="main-page__reviews">
          <main-reviews />
        </div>
      </div>

      <div class="main-page__partners">
        <main-partners />
      </div>
      <div class="main-page__feedback">
        <main-feedback />
      </div>
      <div class="main-page__footer">
        <main-footer />
      </div>
    </div>
  </div>
</template>

<script>
import MainAuth from '@/components/MainPage/MainAuth';
import MainSubjects from '@/components/MainPage/MainSubjects';
import MainAuthority from '@/components/MainPage/MainAuthority';
import MainGoals from '@/components/MainPage/MainGoals';
import MainAdvantages from '@/components/MainPage/MainAdvantages';
import MainFormats from '@/components/MainPage/MainFormats';
import MainOrganization from '@/components/MainPage/MainOrganization';
import MainReviews from '@/components/MainPage/MainReviews';
import MainPartners from '@/components/MainPage/MainPartners';
import MainFeedback from '@/components/MainPage/MainFeedback';
import MainFooter from '@/components/MainPage/MainFooter';
import StaticHeader from '@/components/Static/StaticHeader';

export default {
  name: 'PageIndexOld',
  components: {
    MainAuth,
    MainSubjects,
    MainAuthority,
    MainGoals,
    MainAdvantages,
    MainFormats,
    MainOrganization,
    MainReviews,
    MainPartners,
    MainFeedback,
    MainFooter,
    StaticHeader,
  },
  data() {
    return {
      sidebarIsOpen: false,
    };
  },
  methods: {
    toggleInterface() {
      const currentInterface = this.getCurrentInterface;
      const newInterface = currentInterface === 'old' ? 'new' : 'old';
      this.SET_INTERFACE(newInterface);
    },
    toggleSidebar() {
      this.sidebarIsOpen = !this.sidebarIsOpen;
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.main-page {
  overflow: hidden;
  // TODO: replace circles with something else :/

  &__container {
    width: 95%;
    margin: 0 auto;
    max-width: 1280px;
  }

  &__big-circle-1 {
    position: relative;
    margin-bottom: 275px;

    &::before {
      display: block;
      content: url(../assets/circle-1-n.svg);
      position: absolute;
      left: -258px;
      top: -130px;
      z-index: -2;
    }
  }

  &__big-circle-2 {
    position: relative;
    margin-bottom: 242px;

    &::before {
      display: block;
      content: url(../assets/circle-2-n.svg);
      position: absolute;
      left: -260px;
      top: 105px;
      z-index: -2;
    }
  }

  // &__big-circle-winners {
  //   position: relative;
  //   margin-bottom: 350px;

  //   &::before {
  //     display: block;
  //     content: '';
  //     background: url(../assets/bg-circle-winners.svg);
  //     background-size: cover;
  //     background-repeat: no-repeat;
  //     width: 1900px;
  //     height: 1900px;
  //     position: absolute;
  //     left: -740px;
  //     top: -200px;
  //     z-index: -2;
  //   }
  // }

  &__login {
    position: relative;
    margin-bottom: 151px;

    &::before {
      display: block;
      position: absolute;
      content: '';
      width: 480px;
      height: 480px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url(../assets/boy@2x.png);
      left: -97px;
    }
  }

  &__subjects {
    margin-bottom: 84px;
  }

  &__authority {
    margin-bottom: 215px;
  }

  &__goals {
    position: relative;
  }

  &__advantages {
    margin-bottom: 386px;
  }

  &__formats {
    margin-bottom: 58px;
  }

  &__organization {
    position: relative;
    margin-bottom: 150px;

    &::before {
      display: block;
      position: absolute;
      content: '';
      width: 1500px;
      height: 1500px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url(../assets/bg-org@2x.png);
      right: -454px;
      top: -332px;
      z-index: -1;
    }
  }

  &__partners {
    margin-bottom: 100px;
  }

  &__feedback {
    position: relative;
    margin-bottom: 185px;

    &::before {
      display: block;
      content: url(../assets/circle-3.svg);
      position: absolute;
      left: -260px;
      top: 16px;
      z-index: -2;
    }
  }

  &__footer {
    margin-bottom: 40px;
  }

  @media (max-width: 1200px) {
    &__big-circle-1 {
      margin-bottom: 180px;
    }

    &__big-circle-2 {
      margin-bottom: 170px;
    }

    // &__big-circle-winners {
    //   &::before {
    //     top: -140px;
    //     width: 2050px;
    //     height: 2050px;
    //   }
    // }
    &__login {
      margin-bottom: 75px;

      &::before {
        content: '';
        background-image: url(../assets/boy@2x.png);
        background-size: 80%;
        width: 500px;
        height: 500px;
        background-repeat: no-repeat;
        left: -128px;
        top: 9px;
        z-index: 0;
      }
    }

    &__goals {
      &::before {
        right: -35px;
      }
    }

    &__organization {
      margin-bottom: 300px;
    }
  }

  @media (max-width: 1100px) {
    &__login {
      &::before {
        left: -100px;
        opacity: 0.35;
      }
    }
  }

  @media (max-width: 768px) {
    &__login {
      margin-bottom: 175px;

      &::before {
        display: none;
      }
    }

    &__big-circle-1 {
      margin-bottom: 60px;
    }

    &__big-circle-2 {
      margin-bottom: 50px;

      &::before {
        left: -700px;
        top: 450px;
      }
    }

    // &__big-circle-winners {
    //   &::before {
    //     left: -900px;
    //     width: 2680px;
    //     height: 2680px;
    //   }
    // }
    &__authority {
      margin-bottom: 50px;
    }

    &__goals {
      &::before {
        display: none;
      }
    }

    &__advantages {
      margin-bottom: 50px;
    }

    &__formats {
      margin-bottom: 50px;
    }

    &__organization {
      margin-bottom: 50px;

      &::before {
        display: none;
      }
    }

    &__partners {
      margin-bottom: 50px;
    }

    &__feedback {
      margin-bottom: 50px;

      &::before {
        display: none;
      }
    }
  }

  @media (max-width: 480px) {
    /* &__big-circle-1 {

     }*/
    &__big-circle-2 {
      margin-bottom: 0;

      &::before {
        top: 425px;
      }
    }

    // &__big-circle-winners {
    //   margin-bottom: 250px;
    //   &::before {
    //     top: -60px;
    //     width: 2250px;
    //     height: 2250px;
    //     left: -1050px;
    //   }
    // }
    &__login {
      margin-bottom: 150px;
    }

    &__subjects {
      margin-bottom: 30px;
    }

    &__authority {
      margin-bottom: 30px;
    }

    &__goals {
      margin-bottom: 30px;
    }

    &__advantages {
      margin-bottom: 30px;
    }

    &__formats {
      margin-bottom: 30px;
    }

    &__organization {
      margin-bottom: 30px;
    }

    &__reviews {
      margin-bottom: 30px;
    }

    &__partners {
      margin-bottom: 30px;
    }
  }

  // @media (max-width: 400px) {
  //   &__big-circle-winners {
  //     &::before {
  //       width: 2520px;
  //       height: 2520px;
  //     }
  //   }
  // }
}
</style>

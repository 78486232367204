<template>
  <div class="modern-layout">
    <base-header v-if="!isNewInterface" :toggleSidebar="toggleSidebar" :sidebarIsOpen="sidebarIsOpen" />
    <new-base-header v-else />
    <div class="modern-layout__content">
      <router-view></router-view>
    </div>
    <ModalStatusUserEducont :status="getStatus" v-if="getStatus !== 'ACTIVE'" />
    <ModalTermsOfUse v-if="!isAgreed && isModalOpen" @closeTermsModal="closeTermsModal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseHeader from '@/components/Base/BaseHeader';
import ModalStatusUserEducont from '@/components/Modal/ModalStatusUserEducont';
import NewBaseHeader from '@/components/NewComponents/Header';
import ModalTermsOfUse from '@/components/Modal/ModalTermsOfUse';

export default {
  name: 'ModernLayout',
  components: {
    BaseHeader,
    ModalStatusUserEducont,
    NewBaseHeader,
    ModalTermsOfUse,
  },
  data() {
    return {
      sidebarIsOpen: false,
      isModalOpen: true,
    };
  },
  computed: {
    ...mapGetters('authEducont', ['getStatus']),
    ...mapGetters('auth', ['getCurrentInterface']),
    isAgreed() {
      return this.$store.getters.getAgreed;
    },
    isNewInterface() {
      return this.getCurrentInterface === 'new';
    },
  },
  methods: {
    closeTermsModal() {
      this.isModalOpen = false;
    },
    swipeHandler() {
      // if (direction === 'left') this.sidebarIsOpen = true;
    },
    toggleSidebar() {
      this.sidebarIsOpen = !this.sidebarIsOpen;
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.modern-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: @background-light-accent-bg;
  z-index: 0;

  &__header {
    z-index: 99;
  }
  &__content {
    flex: 1;
    padding-top: 5px;

    .container {
      height: 100%;
    }
  }
}
</style>

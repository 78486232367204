<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.79994 5.19994L6.99994 7.99994L11.1859 2.13994C11.5774 1.59138 12.4225 1.59138 12.8139 2.13994L16.9999 7.99994L21.1999 5.19994C21.8935 4.73854 22.8448 5.32273 22.7469 6.14994L21.2075 19.2337C21.0889 20.2409 20.2353 20.9999 19.2212 20.9999H4.77819C3.76399 20.9999 2.91035 20.2408 2.79188 19.2336L1.25294 6.14894C1.1561 5.32217 2.10683 4.73895 2.79994 5.19994ZM11.9999 14.9999C13.0893 14.9999 13.9999 14.0893 13.9999 12.9999C13.9999 11.9106 13.0893 10.9999 11.9999 10.9999C10.9106 10.9999 9.99994 11.9106 9.99994 12.9999C9.99994 14.0893 10.9106 14.9999 11.9999 14.9999Z"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height'],
};
</script>

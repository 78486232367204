<template>
  <div
    class="gap-input"
    :class="parsedClass"
    :contenteditable="!locked"
    @keydown.enter.prevent="onKeydown"
    @blur="handler"
    :varnum="varNum"
  >
    {{ val }}
  </div>
</template>

<script>
export default {
  name: 'GapInput',
  data() {
    return {
      val: '',
    };
  },
  props: {
    name: String,
    defaultValue: String,
    locked: Boolean,
    gapClass: String,
  },
  computed: {
    parsedClass() {
      if (this.gapClass) {
        const positionLine = this.gapClass.indexOf('|');
        if (positionLine > -1) {
          const classModifier = this.gapClass.substring(positionLine + 1, this.gapClass.length);
          return `gap-input--${classModifier}`;
        }
      }
      return '';
    },
    varNum() {
      return parseInt(this.name.substring(3, this.name.length), 10);
    },
  },
  methods: {
    onKeydown(e) {
      if (e) e.preventDefault();
    },
    handler(e) {
      const { name } = this;
      this.$parent.$emit('gapChange', { val: e.target.innerText, name });
    },
  },
  mounted() {
    this.val = this.defaultValue.trim();
    this.$parent.$emit('gapCreated', this);
  },
};
</script>

<style lang="less">
@import '~less_vars';
.gap-input {
  display: inline-block;
  max-width: 100%;
  min-width: 2em;
  height: 1.4em;
  line-height: 1.15em;
  background-color: rgb(248 248 254);
  border: 2px solid rgb(196 192 243);
  border-radius: 5px;
  padding: 0 0.3em;
  margin-top: -2px;
  margin-left: 1px;
  margin-right: 1px;
  transition: border 0.3s;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;

  &:focus {
    outline: none;
    border: 2px solid @primary-color;
  }

  &--short {
    min-width: 1.4em;
  }

  &--long {
    min-width: 5em;
  }

  &--variant-correct {
    border: 2px solid @surface-success;
  }
  &--variant-wrong {
    border: 2px solid @surface-error;
  }
}
</style>

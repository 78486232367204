<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 16 16">
    <g clip-path="url(#clip0_762_4057)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 1C4.89543 1 4 1.89543 4 3H12C12 1.89543 11.1046 1 10 1H6ZM2 6C2 4.89543 2.89543 4 4 4H12C13.1046 4 14 4.89543 14 6H2ZM0 9C0 7.89543 0.895431 7 2 7H14C15.1046 7 16 7.89543 16 9V13C16 14.1046 15.1046 15 14 15H2C0.895431 15 0 14.1046 0 13V9Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_762_4057">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: Number,
    height: Number,
  },
};
</script>

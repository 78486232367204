import axios from 'axios';
import { HTTP } from '@/http';
import config from '../../../config';

const { STATS_API } = config;

const statistics = {
  namespaced: true,
  state: {
    schools: [],
    schoolsTotalCount: 0,
    teachers: [],
    students: [],
    studyClass: [],
    schoolFilterYear: null,
    schoolFilterDateFrom: null,
    schoolFilterDateTo: null,
    schoolFilterValues: [],
    schoolFilterOptions: [],
    page: 1,
    cityName: '',
  },
  getters: {
    getSchools: (state) => state.schools,
    getStudyClass: (state) => state.studyClass,
    getSchoolsTotalCount: (state) => state.schoolsTotalCount,
    getTeachers: (state) => state.teachers,
    getStudents: (state) => state.students,
    getSchoolFilterYear: (state) => state.schoolFilterYear,
    getSchoolFilterDateFrom: (state) => state.schoolFilterDateFrom,
    getSchoolFilterDateTo: (state) => state.schoolFilterDateTo,
    getSchoolFilterValues: (state) => state.schoolFilterValues,
    getSchoolFilterOptions: (state) => state.schoolFilterOptions,
    getPage: (state) => state.page,
    getYearFilterOptions() {
      const startYear = 2020;
      const currentYear = new Date().getFullYear();
      const yearOptions = [];
      for (let year = currentYear; year >= startYear; year--) {
        yearOptions.push(`${year} - ${year + 1}`);
      }
      return yearOptions;
    },
    getCityName: (state) => state.cityName,
  },
  mutations: {
    SET_SCHOOLS: (state, payload) => {
      state.schools = payload;
    },
    SET_SCHOOLS_TOTAL_COUNT: (state, payload) => {
      state.schoolsTotalCount = payload;
    },
    SET_TEACHERS: (state, payload) => {
      state.teachers = payload;
    },
    SET_STUDENTS: (state, payload) => {
      state.students = payload;
    },
    SET_STUDY_CLASS: (state, payload) => {
      state.studyClass = payload;
    },
    SET_SCHOOL_FILTER_YEAR: (state, payload) => {
      state.schoolFilterYear = payload;
    },
    SET_SCHOOL_FILTER_DATE_FROM: (state, payload) => {
      state.schoolFilterDateFrom = payload;
    },
    SET_SCHOOL_FILTER_DATE_TO: (state, payload) => {
      state.schoolFilterDateTo = payload;
    },
    SET_SCHOOL_FILTER_VALUES: (state, payload) => {
      state.schoolFilterValues = payload;
    },
    SET_SCHOOL_FILTER_OPTIONS: (state, payload) => {
      state.schoolFilterOptions = payload;
    },
    SET_PAGE: (state, payload) => {
      state.page = payload;
    },
    SET_CITY_NAME: (state, payload) => {
      state.cityName = payload;
    },
  },
  actions: {
    async fetchStudyClasses(empty, params) {
      try {
        const { data } = await axios.get(`${STATS_API}/stats/municipal/stydyclasses/`, { params });
        return data;
      } catch (error) {
        console.error(error.message);
      }
      return null;
    },
    async fetchTotalCountStudents(empty, params) {
      try {
        const { data } = await axios.get(`${STATS_API}/stats/municipal/students/count`, { params });
        return data;
      } catch (error) {
        console.error(error.message);
      }
      return null;
    },
    async fetchStatsBySchools(empty, params) {
      try {
        const { data } = await axios.get(`${STATS_API}/stats/municipal/schools/`, { params });
        return data.data;
      } catch (error) {
        console.error(error.message);
      }
      return null;
    },
    async fetchSchools(empty, params) {
      try {
        const { data } = await axios.get(`${STATS_API}/schools/`, { params });
        return data;
      } catch (error) {
        console.error(error.message);
      }
      return null;
    },
    async fetchTeachers(empty, params) {
      try {
        const { data } = await axios.get(`${STATS_API}/stats/municipal/teachers/`, { params });
        return data.data;
      } catch (error) {
        console.error(error.message);
      }
      return null;
    },
    async fetchStudents(empty, params) {
      try {
        const { data } = await axios.get(`${STATS_API}/stats/municipal/students/`, { params });
        return data.data;
      } catch (error) {
        console.error(error.message);
      }
      return null;
    },
    async fetchCityName(empty, params) {
      try {
        const { data } = await HTTP.get('/api/fetchcity/', { params });
        return data.name;
      } catch (error) {
        console.error('fetchCityName error', error.message);
      }
      return '';
    },
  },
};
export default statistics;

<template>
  <div class="base-progressbar">
    <span class="base-progressbar__bar" :style="`width: ${progress}%`"></span>
  </div>
</template>

<script>
export default {
  name: 'BaseProgressbar',
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    all: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    progress() {
      if (this.all === 0) return 0;
      return (this.amount / this.all) * 100;
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.base-progressbar {
  width: 100%;
  height: 3px;
  background: #c9c9c9;
  position: relative;
  border-radius: 3px;
  overflow: hidden;

  &__bar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: @primary-color;
  }
}
</style>

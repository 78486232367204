<template>
  <span class="base-tag" :class="applyClasses" @click="$emit('click', $event)">
    <slot>Тег</slot>
  </span>
</template>

<script>
import { toBemModifer } from '@/helpers/index';

export default {
  name: 'BaseTag',
  props: {
    theme: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
  },
  computed: {
    applyClasses() {
      const { theme, size } = this;
      const baseClass = 'base-tag';
      return [...toBemModifer(theme, baseClass, 'theme'), ...toBemModifer(size, baseClass, 'size')];
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.base-tag {
  height: 17px;
  border-radius: 20px;
  font-size: 11px;
  margin-right: 5px;
  padding: 0 10px;
  line-height: 16px;
  display: inline-block;

  &--size-md {
    font-size: @medium-fz;
    height: 19px;
    line-height: 18px;
  }

  &--size-lg {
    font-size: @base-fz;
    height: 42px;
    line-height: 42px;
    border-radius: 4px;
    padding: 0 15px;
  }

  &--theme-default {
    background-color: @primary-color;
    color: @button-text-color;
  }

  &--theme-pink {
    background-color: @pink-color;
    color: @button-text-color;
  }

  &--theme-orange {
    background-color: @orange-color;
    color: @button-text-color;
  }

  &--theme-carrot {
    background-color: @carrot-color;
    color: @button-text-color;
  }

  &--theme-yellow {
    background-color: @yellow-color;
    color: #303030;
  }

  &--theme-lime {
    background-color: @lime-color;
    color: #303030;
  }

  &--theme-green {
    background-color: @green-color;
    color: @button-text-color;
  }

  &--theme-cyan {
    background-color: @cyan-color;
    color: #303030;
  }

  &--theme-sky-blue {
    background-color: @sky-blue-color;
    color: @button-text-color;
  }

  &--theme-blue {
    background-color: @blue-color;
    color: @button-text-color;
  }

  &--theme-dark-blue {
    background-color: @dark-blue-color;
    color: @button-text-color;
  }

  &--theme-white {
    background: #ffffff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    color: @primary-color;

    &:hover {
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
    }
  }
}
</style>

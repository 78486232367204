<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.375 0.499573C1.33947 0.499573 0.5 1.33904 0.5 2.37457C0.5 3.41011 1.33947 4.24957 2.375 4.24957C3.41053 4.24957 4.25 3.41011 4.25 2.37457C4.25 1.33904 3.41053 0.499573 2.375 0.499573ZM2.375 6.12457C1.33947 6.12457 0.5 6.96404 0.5 7.99957C0.5 9.03511 1.33947 9.87457 2.375 9.87457C3.41053 9.87457 4.25 9.03511 4.25 7.99957C4.25 6.96404 3.41053 6.12457 2.375 6.12457ZM0.5 13.6246C0.5 12.589 1.33947 11.7496 2.375 11.7496C3.41053 11.7496 4.25 12.589 4.25 13.6246C4.25 14.6601 3.41053 15.4996 2.375 15.4996C1.33947 15.4996 0.5 14.6601 0.5 13.6246ZM6.75 1.12457C6.05964 1.12457 5.5 1.68422 5.5 2.37457C5.5 3.06493 6.05964 3.62457 6.75 3.62457H14.25C14.9404 3.62457 15.5 3.06493 15.5 2.37457C15.5 1.68422 14.9404 1.12457 14.25 1.12457H6.75ZM5.5 7.99957C5.5 7.30922 6.05964 6.74957 6.75 6.74957H14.25C14.9404 6.74957 15.5 7.30922 15.5 7.99957C15.5 8.68993 14.9404 9.24957 14.25 9.24957H6.75C6.05964 9.24957 5.5 8.68993 5.5 7.99957ZM6.75 12.3746C6.05964 12.3746 5.5 12.9342 5.5 13.6246C5.5 14.3149 6.05964 14.8746 6.75 14.8746H14.25C14.9404 14.8746 15.5 14.3149 15.5 13.6246C15.5 12.9342 14.9404 12.3746 14.25 12.3746H6.75Z"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height'],
};
</script>

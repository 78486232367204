<template>
  <span
    class="base-icon"
    :class="applyClasses"
    role="presentation"
    :title="title"
    tabindex="-1"
    :style="iconStyle"
    @click="$emit('click', $event)"
  >
    <svg class="base-icon__svg" :view-box.camel="viewBox">
      <use xmlns:xlink="http://www.w3.org/1999/xlink" :xlink:href="glyph"></use>
    </svg>
  </span>
</template>
<script>
import { toBemModifer } from '@/helpers/index';

export default {
  name: 'BaseIcon',
  props: {
    glyph: {
      type: String,
      required: true,
    },
    width: {
      default: '100%',
      type: String,
    },
    height: {
      type: String,
    },
    isBrand: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    variant: {
      type: [String, Array],
      default: null,
    },
  },
  computed: {
    viewBox() {
      return this.isBrand ? '0 0 24 24' : null;
    },
    iconStyle() {
      return {
        width: this.width,
        height: this.height || this.width,
      };
    },
    iconClass() {
      return this.glyph.replace('#', '');
    },
    applyClasses() {
      const { variant } = this;
      const baseClass = 'base-icon';
      const result = [...toBemModifer(variant, baseClass, 'variant')];
      return result.concat(this.iconClass);
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.base-icon {
  position: relative;
  display: inline-block;
  vertical-align: top;
  outline: none;

  &__svg {
    position: absolute;
    outline: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: currentColor;
  }

  &--variant-hover {
    --primaryIconColor: @gray-light-3;
    --secondaryIconColor: @gray-light-3;

    &:hover {
      --primaryIconColor: @primary-color;
      --secondaryIconColor: @secondary-color;
    }
  }
  &--variant-white {
    --primaryIconColor: #fff;
    --secondaryIconColor: #fff;
  }
}
</style>

<template>
  <div class="refactor-login-form">
    <div class="refactor-login-form__tabs" role="tablist">
      <router-link
        role="tab"
        class="refactor-login-form__tab"
        v-for="link in filteredTabs"
        :active="activeRouteName === link.name"
        :class="{ 'is-active': activeRouteName === link.name }"
        :to="{ name: link.name }"
        :key="`login-link-${link.name}`"
        :aria-label="`Способ авторизации ${link.title}`"
      >
        <div class="refactor-login-form__tab__title">{{ $t(link.title) }}</div>
      </router-link>
    </div>

    <div class="refactor-login-form__body">
      <div class="refactor-login-form__inner">
        <div class="refactor-login-form__title">{{ $t(title) }}</div>
        <div class="refactor-login-form__content">
          <div class="refactor-login-form__spinner" v-show="pending">
            <base-spinner size="60px" />
          </div>
          <router-view :error="error" :screenWidth="screenWidth"></router-view>
          <div class="refactor-login-form__terms-of-use">
            {{ $t('Нажимая кнопку "Войти" я принимаю') }}
            <a href="#" aria-label="Читать пользовательское соглашение" role="button" @click="openModal">
              {{ $t('пользовательское соглашение') }}
            </a>
            <div v-if="isKzPlatform" class="change-language">
              <p>{{ $t('Язык') }}:</p>
              <LocaleChanger />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ModalTermsOfUse from '@/components/Modal/ModalTermsOfUse';
import LocaleChanger from '@/components/NewComponents/LocaleChanger';

export default {
  name: 'RefactorLoginForm',
  components: {
    LocaleChanger,
  },
  props: {
    pending: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object,
      default: null,
    },
    screenWidth: {
      type: Number,
    },
  },
  data() {
    return {
      tabs: [
        {
          name: 'loginbypass',
          mobileTitle: this.$t('По почте'),
          desktopTitle: this.$t('По почте'),
          title: this.$t('С паролем'),
          text: this.$t('Я задал пароль'),
          onMobile: true,
        },
        // {
        //   name: 'loginbyemail',
        //   mobileTitle:'Без пароля'
        //   desktopTitle:'Без пароля'
        //   title:'Без пароля'
        //   text:'Меня пригласили'
        //   onMobile: false,
        // },
        {
          name: 'loginbycode',
          title: this.$t('По коду'),
          mobileTitle: this.$t('По коду'),
          desktopTitle: this.$t('По коду'),
          text: this.$t('Выдан учителем'),
          onMobile: true,
        },
        {
          name: 'loginbyservice',
          mobileTitle: this.$t('Электронный дневник'),
          desktopTitle: this.$t('Электронный дневник'),
          title: this.$t('Электронный дневник'),
          onMobile: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['getCurrentInterface']),
    ...mapGetters('platform', ['isKzPlatform']),

    title() {
      const route = this.$route.path;
      if (route === '/login/email') {
        return 'Забыли пароль';
      }
      return 'Авторизация';
    },
    activeRouteName() {
      return this.$route.name;
    },
    filteredTabs() {
      let res = this.tabs.slice();
      if (this.screenWidth > 450) {
        res.forEach((element) => {
          element.title = element.desktopTitle;
        });
        return res;
      }
      res = res.filter((tab) => tab.onMobile === true);
      res.forEach((element) => {
        element.title = element.mobileTitle;
      });
      return res;
    },
  },
  methods: {
    ...mapMutations('auth', ['SET_INTERFACE']),
    openModal() {
      this.$emit('vuedals:new', {
        name: 'ModalTermsOfUse',
        component: ModalTermsOfUse,
        dismissable: false,
        size: 'lg',
      });
    },
    toggleInterface() {
      const currentInterface = this.getCurrentInterface;
      const newInterface = currentInterface === 'old' ? 'new' : 'old';
      this.SET_INTERFACE(newInterface);
    },
  },
  updated() {},
};
</script>

<style lang="less">
@import '~less_vars';
.refactor-login-form {
  font-family: @ibm-plex-sans;
  display: flex;
  flex-direction: column;
  row-gap: 48px;
  height: 100%;
  max-width: 100vw;

  &__body {
    position: relative;
  }

  &__title {
    font-weight: 600;
    font-size: 32px;
    line-height: 41.6px;
    color: @base-color;
    margin-bottom: 20px;
  }

  &__terms-of-use {
    color: @base-color;
    opacity: 0.6;
    font-size: 14px;
    margin-top: 24px;
    @media (max-width: @mobile-width) {
      margin-top: 32px;
      margin-bottom: 32px;
      font-size: 12px;
      line-height: 16px;
    }

    a {
      color: @primary-color;
      cursor: pointer;
      transition: color 0.3s;
      text-decoration: none;

      &:hover {
        color: @primary-color;
      }
    }
  }

  &__tabs {
    display: flex;
    justify-content: flex-start;
    column-gap: 16px;
  }

  &__tab {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    justify-content: center;
    text-decoration: unset;
    transition: all 0.2s ease;
    cursor: pointer;
    background-color: @background-white-bg;
    border: 1px solid @background-white-bg;
    box-sizing: border-box;
    border-radius: 12px;
    row-gap: 3px;

    &__title {
      font-size: 16px;
      color: @base-color;
      font-weight: 500;
      line-height: 20.8px;
      opacity: 0.6;
      transition: inherit;
    }

    &__text {
      font-size: 14px;
      color: @base-color;
      font-weight: 400;
      line-height: 18px;
      opacity: 0.6;
      transition: inherit;
      white-space: nowrap;
    }

    &.is-active {
      background: @surface-accent;
      border: 1px solid @surface-accent;

      .refactor-login-form__tab__title {
        color: #fff;
        opacity: 1;
      }

      .refactor-login-form__tab__text {
        color: #fff;
        opacity: 1;
      }
    }
  }

  &__spinner {
    position: absolute;
    top: 0;
    z-index: 2;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: fade(#fff, 80%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .change-language {
    font-size: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 20px;
  }

  @media (max-width: @medium-width) {
    min-width: 100%;
    max-width: 100%;
    &__title {
      font-size: 38px;
      line-height: 40px;
      margin-bottom: 30px;
    }
    &__tabs {
      row-gap: 10px;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 0 50px 0 50px;
    }

    &__tab {
      flex: 1;
      padding: 13px;
      min-width: 136px;
      &__title {
        align-self: center;
      }
      &__text {
        align-self: center;
      }
    }

    &__inner {
      padding: 15px 10% 50px;
    }
  }

  @media (max-width: @mobile-width) {
    row-gap: 30px;
    &__inner {
      padding: 0 0 0 0;
    }
    &__title {
      font-size: 26px;
      line-height: 34px;
      margin-bottom: 16px;
    }
    &__tabs {
      row-gap: 10px;
      flex-wrap: wrap;
      padding: unset;
    }
    &__tab {
      &__text {
        display: none;
      }
    }
  }
}

.form {
  &__label {
    display: block;
    font-size: 16px;
    line-height: 21px;
    color: @base-color;
    font-weight: 500;
    margin-bottom: 8px;
  }

  button {
    margin-top: 42px;
    min-width: 178px;
    @media (max-width: @mobile-width) {
      width: 100%;
    }
  }

  input {
    margin-bottom: 24px;
    padding: 12px 10px;
    &::placeholder {
      color: @base-color;
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      font-style: normal;
      opacity: 0.6;
      @media (max-width: @mobile-width) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    @media (max-width: @mobile-width) {
      margin-bottom: 12px;
    }
  }

  &__input-recover {
    margin-bottom: unset !important;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__forgot-password {
    margin-top: -2px;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 21px;
    text-decoration-line: underline;
    cursor: pointer;

    @media (max-width: @mobile-width) {
      margin-top: 12px;
      font-size: 14px;
      line-height: 18px;
    }
  }

  .base-checkbox {
    @media (max-width: @mobile-width) {
      margin-top: 12px;
    }
  }

  .base-checkbox__box {
    background-color: @secondary-color !important;
    border: 2.66667px solid @secondary-color;
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    border-radius: 5.33333px !important;
    margin: 0 8px 0 0;

    box-sizing: border-box;
    border-radius: 5.33333px;
    @media (max-width: @mobile-width) {
      border-radius: 5.33333px !important;
    }
  }
  .base-checkbox__tick {
    width: 12px;
    height: 10px;
    top: 2.4px;
    right: 1.15px;

    @media (max-width: @mobile-width) {
      top: 2.4px;
      right: 1.15px;
    }
  }
  .base-checkbox__text {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    @media (max-width: @mobile-width) {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
</style>

import { HTTP } from '@/http';
import { objToQuery } from '@/helpers/index';

const regions = {
  namespaced: true,
  state: {
    ids: [],
    requests: {},
  },
  getters: {
    list(state, getters, rootState) {
      return state.ids.map((id) => rootState.DB.regions[id]);
    },
  },
  mutations: {
    SAVE_REQUESTS: (state, payload) => {
      state.requests[payload] = true;
    },
    DROP_REQUESTS: (state) => {
      state.requests = {};
    },
    SAVE_LIST: (state, payload) => {
      state.ids = [...new Set([...state.ids, ...payload])];
    },
    REMOVE: (state, id) => {
      state.ids.splice(state.ids.indexOf(id), 1);
    },
  },
  actions: {
    fetchRegions({ state, commit }, params = {}) {
      const query = objToQuery(params);

      if (!query || state.requests[query]) return null;

      return HTTP.get('/crud/region/', { params }).then((response) => {
        const data = response.data.models;
        const ids = data.map((region) => region._id);

        commit('SAVE_LIST', ids);

        commit('SAVE_REQUESTS', query);

        commit(
          'FETCH_MODELS',
          {
            type: 'regions',
            payload: data,
          },
          { root: true }
        );
        return data;
      });
    },
    fetchRegionsExtended({ commit }) {
      return HTTP.get('/api/getregions/').then((response) => {
        const data = response.data.regions;
        const ids = data.map((region) => region._id);
        commit('SAVE_LIST', ids);
        commit(
          'FETCH_MODELS',
          {
            type: 'regions',
            payload: data,
          },
          { root: true }
        );
        return data;
      });
    },
    addRegions({ commit }, payload) {
      const params = Array.isArray(payload) ? payload : [payload];

      return HTTP.post('/crud/region/', [...params])
        .then((response) => {
          const { created = [], updated = [] } = response.data;
          if ((created && created.length > 0) || (updated && updated.length)) {
            const list = [...created, ...updated];
            commit(
              'SAVE_LIST',
              list.map((school) => school._id)
            );
            commit(
              'FETCH_MODELS',
              {
                type: 'regions',
                payload: list,
              },
              { root: true }
            );
            return list;
          }
          return [];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async removeSchool({ commit, dispatch }, params) {
      const ids = !Array.isArray(params) ? params : [params];

      try {
        const result = await dispatch('deleteModel', { model: 'regions', options: { data: ids } }, { root: true });
        if (result.data.removed > 0) {
          ids.forEach((id) => commit('REMOVE', id));
        }
        return result;
      } catch (err) {
        console.log('err', err);
      }
      return null;
    },
  },
};

export default regions;

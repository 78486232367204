<template>
  <div class="main-feedback" id="subscribe">
    <img src="../../assets/circle-logo-feedback.svg" alt="" class="main-feedback__logo" />
    <div class="main-feedback__title">{{ $t('Узнайте как подключиться к «Открытой школе»') }}</div>
    <form-feedback class="is-main" />
  </div>
</template>

<script>
import FormFeedback from '@/components/FormFeedback';

export default {
  name: 'MainFeedback',
  components: {
    FormFeedback,
  },
};
</script>

<style lang="less">
@import '~less_vars';

.main-feedback {
  position: relative;
  width: 48%;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0 3px 40px rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  &__logo {
    position: absolute;
    left: 34%;
    top: -12.5%;
  }

  &__title {
    padding-top: 115px;
    font-weight: bold;
    font-size: 36px;
    line-height: 1.2;
    text-align: center;
    color: #000000;
    margin-bottom: 30px;
  }

  @media (max-width: 768px) {
    width: 100%;

    &__logo {
      display: none;
    }

    &__title {
      padding-top: 30px;
    }
  }

  @media (max-width: 480px) {
    &__title {
      font-size: 30px;
    }
  }
}
</style>

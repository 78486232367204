<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 1.33325C0 0.780967 0.447715 0.333252 1 0.333252H25C25.5523 0.333252 26 0.780967 26 1.33325C26 1.88554 25.5523 2.33325 25 2.33325H1C0.447715 2.33325 0 1.88554 0 1.33325ZM0 7.99992C0 7.44763 0.447715 6.99992 1 6.99992H25C25.5523 6.99992 26 7.44763 26 7.99992C26 8.5522 25.5523 8.99992 25 8.99992H1C0.447715 8.99992 0 8.5522 0 7.99992ZM1 13.6666C0.447715 13.6666 0 14.1143 0 14.6666C0 15.2189 0.447715 15.6666 1 15.6666H25C25.5523 15.6666 26 15.2189 26 14.6666C26 14.1143 25.5523 13.6666 25 13.6666H1Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    viewBox: String,
    width: Number,
    height: Number,
  },
};
</script>

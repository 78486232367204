import { HTTP } from '@/http';

const teachers = {
  namespaced: true,
  state: {
    ids: [],
    requests: {},
    subjects: [],
  },
  getters: {
    list(state, getters, rootState) {
      return state.ids.map((id) => rootState.DB.teachers[id]);
    },
  },
  mutations: {
    SAVE_REQUESTS: (state, payload) => {
      state.requests[payload] = true;
    },
    DROP_REQUESTS: (state) => {
      state.requests = {};
    },
    SAVE_LIST: (state, payload) => {
      state.ids = [...new Set([...state.ids, ...payload])];
    },
    FETCH_SUBJECTS: (state, paload, rootState) => {
      state.subjects = [...new Set(rootState.teachers.map((teacher) => teacher.subject))];
    },
    ADD: (state, id) => {
      state.ids.push(id);
    },
    REMOVE: (state, id) => {
      state.ids.splice(state.ids.indexOf(id), 1);
    },
  },
  actions: {
    async checkTeachersXlsx(empty, payload) {
      try {
        const { data } = await HTTP.post(`/api/v2/xlsx-teachers/`, payload);
        return data;
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async createTeachers(empty, payload) {
      try {
        const data = await HTTP.post('/api/v2/users', payload);
        return data;
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async deleteTeachers(empty, params) {
      try {
        params.role = 'teacher';
        const data = await HTTP.delete('/api/v2/users', { params });
        return data;
      } catch (e) {
        console.log(e);
        throw Error();
      }
    },
    fetchTeachers({ commit }, payload = {}) {
      const params = { ...payload, roles: { $in: ['teacher'] } };

      return HTTP.get('/crud/user/', { params }).then((response) => {
        const data = response.data.models.map((n) => {
          n.fullName = `${n.lastName} ${n.firstName} ${n.secondName}`;
          return n;
        });
        const ids = data.map((n) => n._id);
        commit('SAVE_LIST', ids);

        commit(
          'FETCH_MODELS',
          {
            type: 'teachers',
            payload: data,
          },
          { root: true }
        );
        return data;
      });
    },
    fetchCount(context, payload = {}) {
      const params = { ...payload, roles: { $in: ['teacher'] } };
      return HTTP.get('/crud/user/count/', { params }).then((response) => {
        const { count } = response.data;
        if (count) return count;
        return null;
      });
    },
    addTeachers({ commit, dispatch, rootState }, payload) {
      let params = payload;
      if (!Array.isArray(payload)) {
        params = [payload];
      }

      params.forEach((teacher) => {
        if (!teacher.roles || !teacher.roles.length) teacher.roles = ['teacher'];
        teacher.part = 'teacher';
        teacher.school = rootState.DB.users[rootState.auth.user].school;
      });

      return HTTP.post('/crud/user/', [...params])
        .then((response) => {
          const { created = [], updated = [] } = response.data;
          if ((created && created.length > 0) || (updated && updated.length)) {
            const list = [...created, ...updated];

            list.forEach((teacher) => {
              const { firstName, lastName, secondName } = teacher;
              teacher.fullName = `${lastName} ${firstName} ${secondName}`;

              const tData = params.find((t) => teacher.fullName === `${t.lastName} ${t.firstName} ${t.secondName}`);

              if (tData.classId) {
                dispatch('studyClasses/createClass', { teacher: teacher._id, _id: tData.classId }, { root: true });
              }
            });

            commit(
              'SAVE_LIST',
              list.map((t) => t._id)
            );
            commit(
              'FETCH_MODELS',
              {
                type: 'teachers',
                payload: list,
              },
              { root: true }
            );
            return list;
          }
          return [];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeTeacher({ commit, rootState }, params) {
      params.role = 'teacher';
      HTTP.delete('/crud/user/', { data: params }).then((response) => {
        if (response.data.removed) {
          const id = params._id;
          commit('REMOVE', id);
          commit('REMOVE_LIST_ITEM', { type: 'teachers', id }, { root: true });

          const roleIndex = rootState.DB.users[id].roles.indexOf('teacher');
          if (roleIndex) {
            rootState.DB.users[id].roles.splice(roleIndex, 1);
          }
        }
      });
    },
    sendInvite(context, params) {
      return HTTP.post('/api/inviteuserbyemail/', params)
        .then((response) => response.data)
        .catch((error) => {
          console.log(error);
        });
    },
  },
};

export default teachers;

import { HTTP } from '@/http';
import { objToQuery } from '@/helpers/index';

const cities = {
  namespaced: true,
  state: {
    ids: [],
    requests: {},
  },
  getters: {
    list(state, getters, rootState) {
      return state.ids.map((id) => rootState.DB.cities[id]);
    },
  },
  mutations: {
    SAVE_REQUESTS: (state, payload) => {
      state.requests[payload] = true;
    },
    DROP_REQUESTS: (state) => {
      state.requests = {};
    },
    SAVE_LIST: (state, payload) => {
      state.ids = [...new Set([...state.ids, ...payload])];
    },
    REMOVE: (state, id) => {
      state.ids.splice(state.ids.indexOf(id), 1);
    },
  },
  actions: {
    fetchCities({ state, commit }, params = {}) {
      const query = objToQuery(params);

      if (!query || state.requests[query]) return null;

      return HTTP.get('/crud/city/', { params }).then((response) => {
        const data = response.data.models;
        const ids = data.map((city) => city._id);

        commit('SAVE_LIST', ids);

        commit('SAVE_REQUESTS', query);

        commit(
          'FETCH_MODELS',
          {
            type: 'cities',
            payload: data,
          },
          { root: true }
        );
        return data;
      });
    },
    fetchCitiesExtended({ commit }, params) {
      return HTTP.get('/api/getcities/', { params }).then((response) => {
        const data = response.data.cities;
        const ids = data.map((city) => city._id);
        commit('SAVE_LIST', ids);
        commit(
          'FETCH_MODELS',
          {
            type: 'cities',
            payload: data,
          },
          { root: true }
        );
        return data;
      });
    },
    addCities({ commit }, payload) {
      const params = Array.isArray(payload) ? payload : [payload];

      return HTTP.post('/crud/city/', [...params])
        .then((response) => {
          const { created = [], updated = [] } = response.data;
          if ((created && created.length > 0) || (updated && updated.length)) {
            const list = [...created, ...updated];
            commit(
              'SAVE_LIST',
              list.map((city) => city._id)
            );
            commit(
              'FETCH_MODELS',
              {
                type: 'cities',
                payload: list,
              },
              { root: true }
            );
            return list;
          }
          return [];
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};

export default cities;

<template>
  <div class="main-advantages">
    <div class="main-advantages__title">{{ $t('Наши преимущества') }}</div>
    <div class="main-advantages__logo">
      <img class="main-advantages__logo-image" src="../../assets/bg-advantages-circle-logo.svg" alt="" />
    </div>
    <div class="main-advantages__advantage is-help">
      <div class="main-advantages__advantage-textblock">
        <div class="main-advantages__advantage-heading">{{ $t('Помощь учителю') }}</div>
        <div class="main-advantages__advantage-text">
          {{ $t('Создан, чтобы избавить учителя от рутинных задач и помочь сделать работу в классе интересней и эффективней') }}
        </div>
      </div>
      <img src="../../assets/ico-advantages-help.svg" alt="" class="main-advantages__advantage-image" />
    </div>
    <div class="main-advantages__advantage is-convenience">
      <div class="main-advantages__advantage-textblock">
        <div class="main-advantages__advantage-heading">{{ $t('Удобство') }}</div>
        <div class="main-advantages__advantage-text">
          {{ $t('Доступно всегда и везде. Место и время обучения выбираете вы') }}
        </div>
      </div>
      <img src="../../assets/ico-advantages-comfort.svg" alt="" class="main-advantages__advantage-image" />
    </div>
    <div class="main-advantages__advantage is-accessibility">
      <div class="main-advantages__advantage-textblock">
        <div class="main-advantages__advantage-heading">{{ $t('Доступность') }}</div>
        <div class="main-advantages__advantage-text">{{ $t('Делает образование доступным для всех') }}</div>
      </div>
      <img src="../../assets/ico-advantages-access.svg" alt="" class="main-advantages__advantage-image" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainAdvantages',
  components: {},
};
</script>

<style lang="less">
@import '~less_vars';

.main-advantages {
  text-align: center;
  position: relative;

  &__title {
    font-weight: bold;
    font-size: 50px;
    color: #4507ca;
    margin-bottom: 228px;
    letter-spacing: 0.025em;
  }

  &__logo {
    position: relative;

    &::before {
      display: block;
      position: absolute;
      content: '';
      width: 830px;
      height: 830px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url(../../assets/bg-advantages-dotted@2x.png);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }

  &__advantage {
    text-align: left;
    position: absolute;

    &.is-help {
      left: 172px;
      top: 181px;
      width: 37%;

      .main-advantages__advantage-image {
        top: 172px;
        left: 99px;
        box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
      }
    }

    &.is-convenience {
      right: 143px;
      top: 304px;
      width: 24%;

      .main-advantages__advantage-image {
        top: -161px;
        left: -25px;
        box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
      }
    }

    &.is-accessibility {
      right: 291px;
      top: 665px;
      width: 24%;

      .main-advantages__advantage-image {
        top: -18px;
        left: -161px;
        box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
      }
    }

    &-heading {
      font-weight: bold;
      font-size: @subheader-fz;
      line-height: 31px;
      color: #000000;
      margin-bottom: 12px;
    }

    &-text {
      font-size: @bigger-fz;
      line-height: 28px;
      color: #7e7e7e;
    }

    &-image {
      border-radius: 50%;
      position: absolute;
    }
  }

  @media (max-width: 1200px) {
    &__advantage {
      &.is-help {
        left: 100px;
      }

      &.is-convenience {
        right: 100px;
      }

      &.is-accessibility {
        top: 650px;
        right: 250px;
      }
    }
  }

  @media (max-width: 768px) {
    &__title {
      color: #fff;
      text-align: left;
      margin-bottom: 40px;
    }

    &__logo {
      display: none;
    }

    &__advantage {
      position: initial;
      width: 100%;
      color: #000;
      background-color: #fff;
      padding: 20px 20px;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.14);
      border-radius: 12px;
      margin-bottom: 25px;
      min-height: 170px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;

      &-textblock {
        width: 75%;
      }

      &-image {
        margin-right: 20px;
        position: initial;
      }

      &.is-help,
      &.is-convenience,
      &.is-accessibility {
        width: 100%;
      }

      &.is-help {
        width: 100%;
      }

      &.is-convenience {
        width: 100%;
      }

      &.is-accessibility {
        width: 100%;
      }
    }
  }

  @media (max-width: 480px) {
    &__title {
      color: @secondary-color;
      margin-bottom: 25px;
      font-size: 35px;
    }

    &__advantage {
      &-heading {
        font-size: 20px;
      }

      &-text {
        font-size: 17px;
      }
    }
  }
}
</style>

<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_185_16068)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 8C16 3.5816 12.4184 0 8 0C3.5816 0 0 3.5816 0 8C0 12.4184 3.5816 16 8 16C12.4184 16 16 12.4184 16 8ZM8.70711 3.29289C8.31658 2.90237 7.68342 2.90237 7.29289 3.29289L3.79289 6.79289C3.40237 7.18342 3.40237 7.81658 3.79289 8.20711C4.18342 8.59763 4.81658 8.59763 5.20711 8.20711L7 6.41421V12C7 12.5523 7.44772 13 8 13C8.55229 13 9 12.5523 9 12V6.41421L10.7929 8.20711C11.1834 8.59763 11.8166 8.59763 12.2071 8.20711C12.5976 7.81658 12.5976 7.18342 12.2071 6.79289L8.70711 3.29289Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_185_16068">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: ['width', 'height'],
};
</script>

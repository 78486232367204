<template>
  <component
    class="base-svg"
    :class="applyClasses"
    :title="iconName"
    :id="iconName"
    :width="width"
    :height="height"
    :is="iconName"
  />
</template>

<script>
import { toBemModifer } from '@/helpers';
import FilterIcon from '@/components/Icons/FilterIcon';
import Burger from '@/components/Icons/header/Burger';
import CloseMenu from '@/components/Icons/header/CloseMenu';
import Arrow from '@/components/Icons/header/Arrow';
import CloseSmall from '@/components/Icons/header/CloseSmall';
import ThreeDotsUnfinishCards from '@/components/Icons/header/ThreeDotsUnfinishCards';
import ArrowLeft from '@/components/Icons/ArrowLeft';
import ArrowRight from '@/components/Icons/ArrowRight';
import OpenFullScreen from '@/components/Icons/OpenFullScreen';
import CardIcon from '../Icons/CardIcon';
import ListIcon from '../Icons/ListIcon';
import PlusIcon from '../Icons/PlusIcon';
import MinusIcon from '../Icons/MinusIcon';
import toggleSearchList from '../Icons/ToggleSearchList';
import toggleSearchCart from '../Icons/ToggleSearchCart';
import Book from '../Icons/Book';
import CardCornerLeft from '../Icons/CardCornerLeft';
import CardCornerRight from '../Icons/CardCornerRight';
import StarCardIcon from '../Icons/StarCardIcon';
import ArrowToggleBtn from '../Icons/ArrowToggleBtn';
import ArrowSliderBtn from '../Icons/ArrowSliderBtn';
import HeartButton from '../Icons/HeartButton';
import PenButton from '../Icons/PenButton';
import LightningIcon from '../Icons/LightningIcon';
import ChevronRightGreyIcon from '../Icons/ChevronRightGreyIcon';
import ChevronRightBlueIcon from '../Icons/ChevronRightBlueIcon';
import ExclamationPointIcon from '../Icons/ExclamationPointIcon';
import Success from '../Icons/Success';
import IconStar from '../Icons/IconStar';
import Copyright from '../Icons/Copyright';
import ByCards from '../Icons/ByCards';
import ByList from '../Icons/ByList';
import CrossInCircle from '../Icons/CrossInCircle';
import CrossInCircle2 from '../Icons/CrossInCircle2';
import Search from '../Icons/Search';
import Trash from '../Icons/Trash';
import Pencil from '../Icons/Pencil';
import Info from '../Icons/Info';
import Class from '../Icons/Class';
import Home from '../Icons/Home';
import Slides from '../Icons/Slides';
import ChevronDown from '../Icons/ChevronDown';
import Message from '../Icons/Message';
import Fire from '../Icons/Fire';
import Crown from '../Icons/Crown';
import Document from '../Icons/Document';
import ArrowCircleUp from '../Icons/ArrowCircleUp';
import Check from '../Icons/Check';
import PencilFat from '../Icons/PencilFat';
import Plus from '../Icons/Plus';
import ChevronUp from '../Icons/ChevronUp';

export default {
  name: 'BaseSvg',
  components: {
    ThreeDotsUnfinishCards,
    CloseSmall,
    CloseMenu,
    Arrow,
    Burger,
    StarCardIcon,
    CardCornerLeft,
    CardCornerRight,
    CardIcon,
    ListIcon,
    PlusIcon,
    MinusIcon,
    toggleSearchList,
    toggleSearchCart,
    Book,
    ArrowToggleBtn,
    ArrowSliderBtn,
    HeartButton,
    PenButton,
    FilterIcon,
    LightningIcon,
    ChevronRightGreyIcon,
    ChevronRightBlueIcon,
    ExclamationPointIcon,
    Copyright,
    Success,
    IconStar,
    ByCards,
    ByList,
    CrossInCircle,
    CrossInCircle2,
    Search,
    Trash,
    Pencil,
    Info,
    ArrowLeft,
    ArrowRight,
    OpenFullScreen,
    Class,
    Home,
    Slides,
    ChevronDown,
    Message,
    Fire,
    Crown,
    Document,
    ArrowCircleUp,
    Check,
    PencilFat,
    Plus,
    ChevronUp,
  },

  props: {
    iconName: String,
    width: Number,
    height: Number,
    theme: String,
  },

  computed: {
    applyClasses() {
      const baseClass = 'base-svg';
      const { theme } = this;

      return [...toBemModifer(theme, baseClass, 'theme')];
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.base-svg {
  flex-shrink: 0;
  fill: white;

  svg {
    fill: white;
  }

  &--theme {
    &-secondary {
      fill: var(--secondaryColor);
    }

    &-white {
      fill: #ffffff;
    }

    &-blue {
      fill: #ffffff;
      stroke: #4507ca;
    }

    &-blue-hover {
      fill: #4507ca;
    }

    &-gray {
      fill: #7a7a7a;
    }

    &-gray-hover {
      fill: #ffffff;
      stroke: #7a7a7a;
    }

    &-darkgray {
      fill: #ffffff;
      stroke: #3c3f47;
    }
  }
}
</style>

<template>
  <nav class="static-links" v-if="links.length" role="menu">
    <div
      class="static-links__item"
      role="menuitem"
      v-for="(item, index) in links"
      @click="sendEventToAmplitude(item)"
      :key="index"
    >
      <router-link
        class="static-links__link"
        @click.native="toggleSidebar"
        :to="item.path"
        :target="item.target"
        aria-hidden="true"
        >{{ $t(item.label) }}</router-link
      >
    </div>
  </nav>
</template>

<script>
export default {
  name: 'PromoLinks',
  props: {
    links: Array,
    toggleSidebar: Function,
  },
  methods: {
    sendEventToAmplitude(navItem) {
      const eventName = 'clickOnMenuItem';
      const eventProperties = {
        itemName: navItem.path.replace(/\//g, ''),
      };
      this.$store.dispatch('amplitude/sendEvent', { eventName, eventProperties });
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.static-links {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__item {
    font-family: @secondary-font;
    font-style: normal;
    font-weight: 500;
    padding: 0;
    margin: 15px 0;

    .router-link-active {
      color: @gray-disabled;
      cursor: none;
      pointer-events: none;
    }
  }

  &__link {
    font-size: @bigger-fz;
    line-height: 26px;
    text-decoration: none;
    color: @primary-color;
    cursor: pointer;
    transition: color 0.2s ease;

    &:hover {
      color: @secondary-color;
    }
  }

  @media (max-width: @small-width) {
    display: block;
    padding: 15px;
    margin-right: 0;
  }
}
</style>

<template>
  <a :href="href" :class="className" :offset="offset" :duration="duration" role="button" @click.prevent="scrollTo(href)">
    <slot>Кнопка</slot>
  </a>
</template>

<script>
export default {
  name: 'BaseScrollTo',
  props: {
    href: {
      type: String,
      default: null,
    },
    className: {
      type: String,
      default: null,
    },
    offset: {
      type: Number,
      default: 20,
    },
    scrollOffset: {
      type: Number,
      default: null,
    },
    duration: {
      type: Number,
      default: 600,
    },
  },
  methods: {
    scrollTo(targetSelector) {
      return new Promise((resolve) => {
        const target = document.getElementById(targetSelector.substr(1));
        const targetDistanceFromTop = this.getOffsetTop(target);
        const startingY = window.pageYOffset;
        const difference = targetDistanceFromTop - startingY;

        // cubic in-out
        const easing = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
        let start = null;

        const step = (timestamp) => {
          if (!start) start = timestamp;

          let progress = timestamp - start;
          let progressPercentage = progress / this.duration;

          if (progress >= this.duration) progress = this.duration;
          if (progressPercentage >= 1) progressPercentage = 1;

          const offset = this.scrollOffset || this.offset;
          const perTick = startingY + easing(progressPercentage) * (difference - offset);

          window.scrollTo(0, perTick);

          if (progress < this.duration) {
            window.requestAnimationFrame(step);
          } else {
            resolve(() => false);
          }
        };

        window.requestAnimationFrame(step);
      });
    },
    getOffsetTop(element) {
      let yPosition = 0;
      let nextElement = element;

      while (nextElement) {
        yPosition += nextElement.offsetTop;
        nextElement = nextElement.offsetParent;
      }
      return yPosition;
    },
  },
};
</script>

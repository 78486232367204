const teacherjournal = (HTTP) => ({
  namespaced: true,
  state: {
    status: 'studentsProgress',
    pending: null,
    tabs: [
      { status: 'studentsProgress', name: 'Успеваемость учеников' },
      // { status: 'myClasses', name: 'Мои классы' },
      // { status: 'myLessons', name: 'Мои уроки' },
    ],
    classes: [
      { name: 'Олимпиадники', subject: 'информатика', count: 12 },
      { name: 'Олимпиадники', subject: 'информатика', count: 12 },
      { name: 'Олимпиадники', subject: 'информатика', count: 12 },
      { name: 'Олимпиадники', subject: 'информатика', count: 12 },
      { name: 'Олимпиадники', subject: 'информатика', count: 22 },
      { name: 'Олимпиадники', subject: 'информатика', count: 22 },
      { name: 'Олимпиадники', subject: 'информатика', count: 22 },
      { name: 'Олимпиадники', subject: 'информатика', count: 22 },
      { name: 'Олимпиадники', subject: 'информатика', count: 8 },
      { name: 'Олимпиадники', subject: 'информатика', count: 8 },
      { name: 'Олимпиадники', subject: 'информатика', count: 8 },
      { name: 'Олимпиадники', subject: 'информатика', count: 8 },
    ],
    lessons: [
      {
        img: '',
        subjectName: 'Физика',
        subjectColor: 'green',
        description: 'Преломление света. Закон преломления света. Показатель преломления.',
        rating: 5,
        views: 1000,
      },
      {
        img: '',
        subjectName: 'Английский',
        subjectColor: 'blue',
        description: 'Актуальные вопросы современного преподавания английского языка в основной школе',
        rating: 5,
        views: 1000,
      },
      {
        img: '',
        subjectName: 'Физика',
        subjectColor: 'blue',
        description: 'Преломление света. Закон преломления света. Показатель преломления.',
        rating: 5,
        views: 1000,
      },
      {
        img: '',
        subjectName: 'Физика',
        subjectColor: 'green',
        description: 'Преломление света. Закон преломления света. Показатель преломления.',
        rating: 5,
        views: 1000,
      },
      {
        img: '',
        subjectName: 'Английский',
        subjectColor: 'blue',
        description: 'Актуальные вопросы современного преподавания английского языка в основной школе',
        rating: 5,
        views: 1000,
      },
      {
        img: '',
        subjectName: 'Физика',
        subjectColor: 'blue',
        description: 'Преломление света. Закон преломления света. Показатель преломления.',
        rating: 5,
        views: 1000,
      },
      {
        img: '',
        subjectName: 'Физика',
        subjectColor: 'green',
        description: 'Преломление света. Закон преломления света. Показатель преломления.',
        rating: 5,
        views: 1000,
      },
      {
        img: '',
        subjectName: 'Английский',
        subjectColor: 'blue',
        description: 'Актуальные вопросы современного преподавания английского языка в основной школе',
        rating: 5,
        views: 1000,
      },
    ],
    statistics: null,
    studentsWithFinishedHW: {
      text: 'Процент учеников, выполнивших домашнее задание',
      percent: null,
      color: '#fc2767',
    },
    averagePercentHWAnswers: {
      text: 'Средний процент верных ответов по назначенным заданиям',
      percent: null,
      color: 'rgb(69, 7, 202)',
    },
  },
  getters: {
    status(state) {
      return state.status;
    },
    tabs(state) {
      return state.tabs;
    },
    classes(state) {
      return state.classes;
    },
    lessons(state) {
      return state.lessons;
    },
    statistics(state) {
      return state.statistics;
    },
    pending(state) {
      return state.pending;
    },
    studentsWithFinishedHW: ({ studentsWithFinishedHW }) => studentsWithFinishedHW,
    averagePercentHWAnswers: ({ averagePercentHWAnswers }) => averagePercentHWAnswers,
  },
  mutations: {
    SET_STATUS(state, payload) {
      state.status = payload;
    },
    TOGGLE_GROUP_VISABILITY(state, index) {
      state.statistics[index].visible = !state.statistics[index].visible;
    },
    SET_PENDING(state, value) {
      state.pending = value;
    },
    SET_STATISTICS(state, value) {
      state.statistics = value.map((gr) => ({
        ...gr,
        visible: !!gr.visible,
      }));
    },
    SET_STUDENTS_WITH_FINISHED_HW({ studentsWithFinishedHW }, payload) {
      studentsWithFinishedHW.percent = payload.finishedHWStudentCount / payload.allStudentsCount;
    },
    SET_AVERAGE_PERCENT_HW_ANSWERS({ averagePercentHWAnswers }, payload) {
      averagePercentHWAnswers.percent = payload;
    },
  },
  actions: {
    async getStudentsProgressStatistics({ commit }, params) {
      try {
        commit('SET_PENDING', true);
        const { data } = await HTTP.get('/api/getstudentprogressstatistics/', { params });
        if (data && data.error) {
          throw new Error(data.error);
        }
        const { lessonStatistics, allStudentsCount, finishedHWStudentCount, averagePercentHWAnswers } = data;
        commit('SET_STATISTICS', lessonStatistics);
        commit('SET_STUDENTS_WITH_FINISHED_HW', { allStudentsCount, finishedHWStudentCount });
        commit('SET_AVERAGE_PERCENT_HW_ANSWERS', averagePercentHWAnswers);
        commit('SET_PENDING', false);
      } catch (e) {
        console.error('getStudentsProgressStatistics error: ', e.message);
      }
    },
  },
});

export default teacherjournal;

<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="width" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2" clip-path="url(#clip0_407_48870)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.0007 5.99976L14.5007 6.49976L9.50073 1.49996L10.0007 0.9991C11.2585 -0.228899 13.8391 -0.320738 15.0309 1.00919C16.3226 2.27033 16.2924 4.73861 15.0007 5.99976ZM3.13243 15.999H0.895502C0.35864 15.999 0.000732422 15.6459 0.000732422 15.1161V12.9088C0.000732422 11.761 0.19544 10.7944 1.00073 9.99976L8.00073 2.99976L13.0007 7.99976L6.00073 14.9998C5.19544 15.7944 4.29563 15.999 3.13243 15.999Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_407_48870">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: ['width', 'height'],
};
</script>

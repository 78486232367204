import { HTTP } from '@/http';

const NewSchools = {
  namespaced: true,
  actions: {
    async fetchSchools(empty, payload) {
      try {
        const response = await HTTP.get('/api/v2/schools', { params: payload });
        return response.data;
      } catch (e) {
        console.log(e);
      }
      return null;
    },
  },
};
export default NewSchools;

<template>
  <div class="form">
    <form action="#" @submit.prevent="submitHandler">
      <label class="form__label" for="email">
        {{ $t('Укажите email который использовали для входа и мы отправим на него ссылку') }}
      </label>
      <base-input
        class="form__input-recover"
        id="email"
        :placeholder="$t('Введите свой email')"
        type="text"
        required
        @input="setEmail"
      ></base-input>
      <!-- <base-checkbox v-model="form.memorize">Запомнить меня</base-checkbox> -->
      <refactor-base-button>{{ $t('Отправить') }}</refactor-base-button>

      <div v-show="showError" class="form-error">
        <span v-if="error">{{ error.msg }}</span>
      </div>
    </form>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import ModalConfirm from '@/components/Modal/ModalConfirm';

export default {
  name: 'RefactorLoginByEmail',
  props: {
    error: {
      type: Object,
      default: null,
    },
    screenWidth: {
      type: Number,
    },
  },
  data() {
    return {
      form: {
        memorize: true,
        email: null,
        redirect: '/',
      },
    };
  },
  computed: {
    showError() {
      return this.error && this.error.type === 'email';
    },
  },
  methods: {
    ...mapActions('auth', ['authByEmail']),
    async submitHandler() {
      const { memorize, email, redirect } = this.form;
      if (email && redirect) {
        const res = await this.authByEmail({ memorize, email, redirect });
        if (res) {
          this.$emit('vuedals:new', {
            name: 'modalConfirm',
            component: ModalConfirm,
            dismissable: false,
            props: {
              title: 'Вам послано письмо с ссылкой на вход в систему',
              showCancel: false,
              handleConfirm: () => {
                this.$emit('vuedals:close');
              },
              confirmText: this.$t('Закрыть'),
            },
            size: 'md',
          });
        }
      }
    },
    setEmail(e) {
      this.form.email = e.target.value;
    },
  },
};
</script>

<template>
  <div class="simple-layout">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'SimpleLayout',
};
</script>

<style lang="less">
.simple-layout {
  background-image: linear-gradient(rgba(12, 153, 255, 0.36) 0%, rgba(70, 144, 255, 0) 50%);
  background-size: 100% 100vh;
  background-repeat: no-repeat;
}
</style>

<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.375 0.499512C1.33947 0.499512 0.5 1.33898 0.5 2.37451C0.5 3.41005 1.33947 4.24951 2.375 4.24951C3.41053 4.24951 4.25 3.41005 4.25 2.37451C4.25 1.33898 3.41053 0.499512 2.375 0.499512ZM2.375 6.12451C1.33947 6.12451 0.5 6.96398 0.5 7.99951C0.5 9.03505 1.33947 9.87451 2.375 9.87451C3.41053 9.87451 4.25 9.03505 4.25 7.99951C4.25 6.96398 3.41053 6.12451 2.375 6.12451ZM0.5 13.6245C0.5 12.589 1.33947 11.7495 2.375 11.7495C3.41053 11.7495 4.25 12.589 4.25 13.6245C4.25 14.66 3.41053 15.4995 2.375 15.4995C1.33947 15.4995 0.5 14.66 0.5 13.6245ZM6.75 1.12451C6.05964 1.12451 5.5 1.68416 5.5 2.37451C5.5 3.06487 6.05964 3.62451 6.75 3.62451H14.25C14.9404 3.62451 15.5 3.06487 15.5 2.37451C15.5 1.68416 14.9404 1.12451 14.25 1.12451H6.75ZM5.5 7.99951C5.5 7.30916 6.05964 6.74951 6.75 6.74951H14.25C14.9404 6.74951 15.5 7.30916 15.5 7.99951C15.5 8.68987 14.9404 9.24951 14.25 9.24951H6.75C6.05964 9.24951 5.5 8.68987 5.5 7.99951ZM6.75 12.3745C6.05964 12.3745 5.5 12.9342 5.5 13.6245C5.5 14.3149 6.05964 14.8745 6.75 14.8745H14.25C14.9404 14.8745 15.5 14.3149 15.5 13.6245C15.5 12.9342 14.9404 12.3745 14.25 12.3745H6.75Z"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height', 'title', 'id'],
};
</script>

<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.05 4C14.5242 4 13.0582 4.90786 12.0765 6.00916C12.0358 6.05483 11.9642 6.05483 11.9235 6.00916C10.9418 4.90786 9.47583 4 7.95 4C5.178 4 3 6.15577 3 8.89947C3 12.2581 6.04437 14.9964 10.6595 19.1475L10.695 19.1794L11.3309 19.7481C11.7115 20.0884 12.2875 20.0874 12.6669 19.7455L13.305 19.1705L13.3164 19.1603C17.945 15.0059 21 12.264 21 8.89947C21 6.15577 18.822 4 16.05 4Z"
      fill="#999EAD"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height', 'title', 'id'],
};
</script>

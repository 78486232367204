<template>
  <div class="main-authority">
    <div class="main-authority__title">{{ $t('Авторитетное признание') }}</div>
    <div class="main-authority__list">
      <div class="main-authority__item">
        <div class="main-authority__item-photo">
          <div class="main-authority__item-photo-bg">
            <img src="../../assets/img-authority-ran.png" alt="" srcset="../../assets/img-authority-ran@2x.png 2x" />
            <div class="main-authority__item-photo-circle circle-ran"></div>
          </div>
        </div>
        <div class="main-authority__item-title">{{ $t('Положительное заключение экспертизы РАН') }}</div>
        <div class="main-authority__item-text">{{ $t('и рекомендации к использованию в учебном процессе') }}</div>
      </div>
      <div class="main-authority__item">
        <div class="main-authority__item-photo">
          <div class="main-authority__item-photo-bg is-red">
            <img src="../../assets/img-authority-authors.png" alt="" srcset="../../assets/img-authority-authors@2x.png 2x" />
            <div class="main-authority__item-photo-circle circle-method"></div>
          </div>
        </div>
        <div class="main-authority__item-title">{{ $t('Методические разработки авторов') }}</div>
        <div class="main-authority__item-text">
          {{ $t('преподавателей, учёных, исследователей из ведущих педагогических вузов России') }}
        </div>
      </div>
      <div class="main-authority__item">
        <div class="main-authority__item-photo">
          <div class="main-authority__item-photo-bg">
            <img src="../../assets/img-authority-misis.png" alt="" srcset="../../assets/img-authority-misis@2x.png 2x" />
            <div class="main-authority__item-photo-circle circle-dev"></div>
          </div>
        </div>
        <div class="main-authority__item-title">{{ $t('Разработка уроков с & nbsp;участием профессоров') }}</div>
        <div class="main-authority__item-text">{{ $t('НИТУ «МИСиС» и МФТИ') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainAuthority',
  components: {},
};
</script>

<style lang="less">
@import '~less_vars';

.main-authority {
  &__title {
    font-weight: bold;
    font-size: 50px;
    color: #fc2767;
    margin-bottom: 65px;
    letter-spacing: 0.025em;
  }

  &__subtitle {
    font-weight: bold;
    font-size: 19px;
    line-height: 23px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgba(176, 176, 176, 0.5);
    margin-bottom: 47px;
    padding-left: 40px;
    position: relative;

    &::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 5px solid @pink-color;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 3px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 230px;
  }

  &__item {
    width: 32%;
    background-color: #fff;
    padding: 192px 80px 60px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.14);
    border-radius: 12px;
    position: relative;

    &-photo {
      position: absolute;
      top: -160px;
      left: 14%;
      border-radius: 50%;

      &-bg {
        width: 300px;
        height: 300px;
        background-color: @primary-color;
        border-radius: 50%;
        position: relative;

        &.is-red {
          background-color: @secondary-color;
        }
      }

      img {
        display: block;
        width: 240px;
        height: 240px;
        border-radius: 50%;
        position: absolute;
        left: 10%;
        top: 10%;
        z-index: 2;
      }

      &-circle {
        background-color: transparent;
        border-radius: 50%;
        position: absolute;
        width: 183px;
        height: 183px;

        &.circle-ran {
          border: 21px solid @secondary-color;
          right: -23px;
          top: -38px;
          z-index: -1;
        }

        &.circle-method {
          border: 21px solid @primary-color;
          left: -23px;
          top: -14px;
          z-index: -1;
        }

        &.circle-dev {
          border: 21px solid @secondary-color;
          left: -15px;
          top: -37px;
        }
      }
    }

    &-title {
      font-weight: bold;
      font-size: @subheader-fz;
      line-height: 27px;
      color: #000000;
      margin-bottom: 15px;
    }

    &-text {
      font-size: @bigger-fz;
      line-height: normal;
      color: #7e7e7e;
    }
  }

  @media (max-width: 1200px) {
    &__item {
      &-photo {
        left: 6%;
      }
    }
  }

  @media (max-width: 768px) {
    &__list {
      flex-direction: column;
      margin-top: 0;
    }

    &__item {
      width: 100%;
      padding: 20px 20px;

      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }

      &:nth-child(n) {
        margin-top: 0;
      }

      &-photo {
        display: none;
      }
    }
  }

  @media (max-width: 480px) {
    &__title {
      color: #fff;
      margin-bottom: 20px;
      font-size: 35px;
    }

    &__subtitle {
      color: #fff;
      margin-bottom: 20px;

      &::before {
        border-color: #fff;
      }
    }

    &__item {
      min-height: 160px;
    }
  }
}
</style>

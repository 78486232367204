<!--eslint-disable max-len-->
<template>
  <svg :width="width" :height="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16ZM8 10C7.30964 10 6.75 10.5596 6.75 11.25C6.75 11.9404 7.30964 12.5 8 12.5C8.69036 12.5 9.25 11.9404 9.25 11.25C9.25 10.5596 8.69036 10 8 10ZM8 3C7.20351 3 6.58726 3.69808 6.68605 4.48842L7.15697 8.25579C7.21012 8.68095 7.57153 9 8 9C8.42847 9 8.78988 8.68095 8.84303 8.25579L9.31395 4.48842C9.41274 3.69808 8.79649 3 8 3Z"
      fill="#060E1F"
    />
  </svg>
</template>

<script>
export default {
  props: {
    viewBox: String,
    width: Number,
    height: Number,
  },
};
</script>

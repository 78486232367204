const teacherMaterials = (HTTP) => ({
  namespaced: true,
  state: {
    pending: null,
    teacherMaterials: [],
    shortTeacherMaterials: [],
    teacherMaterialsItem: null,
  },
  getters: {
    teacherMaterials: (state) => state.teacherMaterials,
    pending: ({ pending }) => pending,
    shortTeacherMaterials: ({ shortTeacherMaterials }) => shortTeacherMaterials,
    teacherMaterialsItem: (state) => state.teacherMaterialsItem,
  },
  mutations: {
    SAVE_SHORTTEACHERMATERIALS(state, value) {
      state.shortTeacherMaterials = value;
    },
    SAVE_TEACHERMATERIALS(state, value) {
      state.teacherMaterials = value;
    },
    SET_PENDING(state, value) {
      state.pending = value;
    },
    SET_TEACHERMATERIALSITEM(state, value) {
      state.teacherMaterialsItem = value;
    },
  },
  actions: {
    // eslint-disable-next-line consistent-return
    async getShortTeacherMaterials({ commit }) {
      try {
        commit('SET_PENDING', true);
        const { data } = await HTTP.get('/api/getshortteachermaterials/');
        if (data && data.error) {
          throw new Error(data.error);
        }
        commit('SAVE_SHORTTEACHERMATERIALS', data);
        commit('SET_PENDING', false);
      } catch (e) {
        console.error('getShortTeacherMaterials error: ', e.message);
      }
    },
    // eslint-disable-next-line consistent-return
    async getTeacherMaterials({ commit }) {
      try {
        commit('SET_PENDING', true);
        const { data } = await HTTP.get('/api/getteachermaterials/');
        if (data && data.error) {
          throw new Error(data.error);
        }
        commit('SAVE_TEACHERMATERIALS', data);
        commit('SET_PENDING', false);
      } catch (e) {
        console.error('getTeacherMaterials error: ', e.message);
      }
    },
    // eslint-disable-next-line consistent-return
    async getTeacherMaterialsItem({ commit }, id) {
      try {
        commit('SET_PENDING', true);
        const { data } = await HTTP.get(`/api/getteachermaterialsitem/`, { params: { id } });
        if (data && data.error) {
          throw new Error(data.error);
        }
        commit('SET_TEACHERMATERIALSITEM', data);
        commit('SET_PENDING', false);
      } catch (e) {
        console.error('getTeacherMaterials error: ', e.message);
      }
    },
  },
});

export default teacherMaterials;

import _ from 'lodash';

export default function getTagsColor(subjectName) {
  const themeCollection = {
    алгебра: 'tag-blue',
    геометрия: 'tag-dark-blue',
    информатика: 'tag-light-green',
    физика: 'tag-violet',
    химия: 'tag-khaki',
    обж: 'tag-orange',
    биология: 'tag-green',
    история: 'tag-dark-orange',
    обществознание: 'tag-dark-brown',
    литература: 'tag-dark-ping',
    'русский язык': 'tag-red',
    английский: 'tag-light-blue',
    'английский язык': 'tag-light-blue',
    татарский: 'tag-dark-green',
    'татарский язык': 'tag-dark-green',
    география: 'tag-yellow',
    симуляторы: 'tag-light-pink',
    'доп. материалы': 'tag-dark-violet',
    иностранный: 'tag-light-blue',
    'иностранный язык': 'tag-light-blue',
    classNumber: 'tag-primary',
  };
  const lowerSubjectName = subjectName.toLowerCase().trim();
  const theme = _.has(themeCollection, lowerSubjectName) ? themeCollection[lowerSubjectName] : 'yellow';
  return theme;
}
